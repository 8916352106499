export const TrainedParkingAssistConstant = {
  columns: {
    csvValidationResultsTable: {
      columns: ['csv_string_number', 'validation_errors'],
      columnsMap: {
        validation_errors: {
          name: 'validation_errors',
        },
      },
    },
    tpaReproGroupsStatusesTable: {
      columns: [
        'rec_group',
        'test_run_id',
        'is_saved_in_ddb',
        'is_submitted',
        'error_message',
      ],
    },
  },
};
