export const PlaylistsConstant = {
  playlistsDisplayedColumns: [
    'playlist_id',
    'created_timestamp',
    'updated_timestamp',
    'actions',
  ],
  playlistDisplayedColumns: [
    'drive_id',
    'step',
    'splits',
    'validation_verdict',
  ],
};
