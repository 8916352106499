<mat-card>
  <mat-card-content>
    <mat-form-field appearance="fill">
      <mat-label>Project</mat-label>
      <mat-select
        [(value)]="projectSelected"
        (selectionChange)="onProjectChange($event)"
      >
        <mat-option *ngFor="let project of projects" [value]="project">{{
          project
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-grid-list [cols]="cols$ | async" rowHeight="6em">
      <mat-grid-tile
        *ngFor="let vin of (vinsList$ | async)?.vin_map"
        (click)="onVinSelected(null, vin)"
      >
        <button
          fxLayout="row"
          fxLayoutAlign="center center"
          mat-stroked-button
          class="vin"
          [color]="vinSelected.key === vin.key ? 'warn' : 'accent'"
        >
          <span>{{ vin.key }}</span>
          <ng-container [ngSwitch]="vin.value">
            <span
              fxLayout="row"
              fxLayoutAlign="center center"
              *ngSwitchCase="vinsApprovalsConstant.statuses.approved.value"
            >
              <mat-icon class="green-icon">check_circle</mat-icon>
            </span>
            <span
              fxLayout="row"
              fxLayoutAlign="center center"
              *ngSwitchCase="vinsApprovalsConstant.statuses.rejected.value"
            >
              <mat-icon class="red-icon">cancel</mat-icon>
            </span>
            <span
              fxLayout="row"
              fxLayoutAlign="center center"
              *ngSwitchCase="vinsApprovalsConstant.statuses.notSet.value"
              ><mat-icon>radio_button_unchecked</mat-icon></span
            >
            <span fxLayout="row" fxLayoutAlign="center center" *ngSwitchDefault>
              <mat-icon>radio_button_unchecked</mat-icon>
            </span>
          </ng-container>
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>

<div fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="start start">
  <div
    *ngIf="(driveIdsApprovalList$ | async)?.length"
    fxFlex="25"
    class="driveids-viewport-container"
  >
    <cdk-virtual-scroll-viewport itemSize="50" class="driveids-viewport">
      <mat-selection-list [multiple]="false">
        <mat-list-option
          *cdkVirtualFor="let driveIdsApproval of driveIdsApprovalList$ | async"
          [value]="driveIdsApproval"
          (click)="onDriveIdSelected(driveIdsApproval)"
        >
          <div fxLayout="row" fxLayoutAlign="start center">
            <div mat-line>{{ driveIdsApproval.drive_id }}</div>
            <ng-container [ngSwitch]="driveIdsApproval.approval_status">
              <span
                class="drive-id-icon"
                fxLayout="row"
                fxLayoutAlign="center center"
                *ngSwitchCase="vinsApprovalsConstant.statuses.approved.value"
              >
                <mat-icon class="green-icon">check_circle</mat-icon>
              </span>
              <span
                class="drive-id-icon"
                fxLayout="row"
                fxLayoutAlign="center center"
                *ngSwitchCase="vinsApprovalsConstant.statuses.rejected.value"
              >
                <mat-icon class="red-icon">cancel</mat-icon>
              </span>
              <span
                class="drive-id-icon"
                fxLayout="row"
                fxLayoutAlign="center center"
                *ngSwitchCase="vinsApprovalsConstant.statuses.notSet.value"
                ><mat-icon>radio_button_unchecked</mat-icon></span
              >
              <span
                class="drive-id-icon"
                fxLayout="row"
                fxLayoutAlign="center center"
                *ngSwitchDefault
              >
                <mat-icon>radio_button_unchecked</mat-icon>
              </span>
            </ng-container>
          </div>
        </mat-list-option>
      </mat-selection-list>
    </cdk-virtual-scroll-viewport>
  </div>
  <mat-card
    class="approvals-spacer"
    *ngIf="
      (driveIdsApprovalList$ | async)?.length &&
      !setDriveIdApprovalsPayload.variables.drive_id.length
    "
  >
    <mat-card-header fxFlex fxLayoutAlign="center center">
      <mat-card-subtitle
        >Please click on your Drive ID in the list</mat-card-subtitle
      >
    </mat-card-header>
  </mat-card>
  <div *ngIf="setDriveIdApprovalsPayload.variables.drive_id.length">
    <div fxLayout="row wrap" fxLayoutAlign="start start">
      <mat-card
        class="approvals-card"
        *ngFor="
          let approval of setDriveIdApprovalsPayload.variables.approvals;
          let i = index
        "
      >
        <mat-card-header>
          <mat-card-title>{{ approvalsNames[i] }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-form-field appearance="fill">
            <mat-label>Approval</mat-label>
            <div fxLayout="row" fxLayoutAlign="center center">
              <ng-container [ngSwitch]="approval.approval">
                <span
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  *ngSwitchCase="vinsApprovalsConstant.statuses.approved.value"
                >
                  <mat-icon class="green-icon approval-icon"
                    >check_circle</mat-icon
                  >
                </span>
                <span
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  *ngSwitchCase="vinsApprovalsConstant.statuses.rejected.value"
                >
                  <mat-icon class="red-icon approval-icon">cancel</mat-icon>
                </span>
                <span
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  *ngSwitchCase="vinsApprovalsConstant.statuses.notSet.value"
                  ><mat-icon class="approval-icon"
                    >radio_button_unchecked</mat-icon
                  ></span
                >
                <span
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  *ngSwitchDefault
                >
                  <mat-icon class="approval-icon"
                    >radio_button_unchecked</mat-icon
                  >
                </span>
              </ng-container>
              <mat-select [(value)]="approval.approval">
                <mat-option
                  *ngFor="let status of approvalStatuses"
                  [value]="status"
                  >{{ status }}
                </mat-option>
              </mat-select>
            </div>
          </mat-form-field>
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Comment</mat-label>
            <textarea
              matNativeControl
              [(ngModel)]="approval.comment"
              cdkTextareaAutosize
              [cdkAutosizeMinRows]="5"
              [cdkAutosizeMaxRows]="5"
            ></textarea>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </div>
    <mat-card-actions>
      <button
        mat-raised-button
        color="primary"
        type="button"
        (click)="onApprovalsSubmit()"
      >
        Submit
      </button>
      <div
        *ngIf="setDriveIdApprovalsSuccessMessage"
        class="mat-card-actions-result-message"
      >
        <strong> {{ setDriveIdApprovalsSuccessMessage }}</strong>
      </div>
    </mat-card-actions>
  </div>
</div>
