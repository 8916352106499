import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { TmaUtilsStaticService } from '@core/services/tma-utils-static.service';
import { TrainedParkingAssistConstant } from '@modules/trained-parking-assist/constants/trained-parking-assist.constant';
import { VinsApprovalsConstant } from '@modules/vins-approvals/constants/vins-approvals.constant';
import { TableConstant } from '@shared/constants/table.constant';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class TableComponent implements AfterViewInit, OnChanges {
  @Input() dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @Input() displayedColumns: string[] = [];
  @Input() enableDetails = true;
  @Input() filterPlaceholder: string = '';
  @Input() multipleSelections = true;
  @Input() showAllSelectionsCheckbox = false;
  @Input() selection = new SelectionModel<any>(true, []);
  @Input() isAsyncDataSource = true;
  @Input() isSelectionButtonVisible = true;
  @Input() isSortDisabled = false;
  @Input() spinner = false;
  @Input() filterTooltip = '';
  @Input() showPagination = true;
  @Input() showItemHover = true;
  @Input() showFilter = true;
  @Input() showExactFilterCheckbox = false;
  @Input() sortKey = 'createdTimeStamp';
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('filter')
  filterElementRef!: ElementRef;
  @Output() tableItemSelected: EventEmitter<object> = new EventEmitter();
  @Output() filterChanged: EventEmitter<string> = new EventEmitter();

  tableConstant = TableConstant;

  trainedParkingAssistConstant = TrainedParkingAssistConstant;

  vinsApprovalsConstant = VinsApprovalsConstant;
  displayedColumnsWithExpand: string[] = [];
  expandedElement: any | null = null;
  detailsDisplayedColumns = ['property', 'value'];
  selectedRadioElement: any;

  exactFilter = false;

  get detailsDataSource(): object[] {
    const detailsDataSource = [];

    if (this.expandedElement) {
      for (const property in this.expandedElement) {
        detailsDataSource.push({
          property,
          value: this.expandedElement[property],
        });
      }
    } else {
      detailsDataSource.push({});
    }

    return detailsDataSource;
  }

  private resetTableData(): void {
    this.displayedColumns = [];
    this.displayedColumnsWithExpand = [];
    this.dataSource.data = [];
  }

  private resetFilters(): void {
    if (this.filterElementRef) {
      this.filterElementRef.nativeElement.value = '';

      let event = new KeyboardEvent('keyup', { bubbles: true });

      this.filterElementRef.nativeElement.dispatchEvent(event);
    }
  }

  ngAfterViewInit(): void {
    if (!this.isAsyncDataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.displayedColumns?.currentValue.length) {
      this.displayedColumnsWithExpand = [
        ...changes.displayedColumns.currentValue,
      ];

      if (this.isSelectionButtonVisible) {
        this.displayedColumnsWithExpand.unshift('select');
      }

      if (this.enableDetails) {
        this.displayedColumnsWithExpand.unshift('expand');
      }
    } else {
      this.resetTableData();
      this.resetFilters();
    }

    if (changes.dataSource) {
      this.dataSource.paginator = this.paginator;

      this.dataSource.sort = this.sort;

      this.dataSource.filterPredicate = (data, filter) => {
        let filterResult = false;

        if (this.exactFilter) {
          for (const key in data) {
            if (data[key].trim().toLowerCase() === filter) {
              filterResult = true;
            }
          }
        } else {
          const re = new RegExp(filter);

          for (const key in data) {
            if (typeof data[key] === 'string') {
              if (re.test(data[key].trim().toLowerCase())) {
                filterResult = true;
              }
            }
          }
        }

        return filterResult;
      };
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    if (this.filterChanged.observers.length) {
      this.filterChanged.emit(filterValue);

      return;
    }

    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;

    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.tableItemSelected.emit(this.selection.selected);

      return;
    }

    this.selection.select(...this.dataSource.data);
    this.tableItemSelected.emit(this.selection.selected);
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  checked(row: any): boolean {
    return this.selection.isSelected(row);
  }

  checkboxRowSelected($event: MatCheckboxChange | null, row: any): void | null {
    this.selection.toggle(row);
    this.tableItemSelected.emit(this.selection.selected);
  }

  radioRowSelected($event: MatRadioChange | null, row: any): void {
    this.selectedRadioElement = row;
    this.tableItemSelected.emit(this.selectedRadioElement);
  }

  isBoolean(value: any): boolean {
    return TmaUtilsStaticService.isBoolean(value);
  }

  protected readonly TableConstant = TableConstant;
}
