import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const isUppercaseValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  let isUppercaseValidator: { isUppercase: boolean } | null = null;

  if (control.value) {
    const uppercaseValue = control.value.toUpperCase();

    if (uppercaseValue !== control.value) {
      isUppercaseValidator = { isUppercase: true };
    }
  }

  return isUppercaseValidator;
};
