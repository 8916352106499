export const TableConstant = {
  jsonPipeProperties: {
    kpiJobReport: 'kpiJobReport',
    metadataJobReport: 'metadataJobReport',
    kpiProjectIds: 'kpiProjectIds',
    gtSettings: 'gtSettings',
    matchingGenerator: 'matchingGenerator',
    processingState: 'processingState',
    tcSessionRequest: 'tc-session-request',
    tcProcessingState: 'processing-state',
    tcKpiRequest: 'kpi-request',
    matchingStatus: 'matching-status',
    tcStatus: 'tc-status',
    reproResults: 'repro-results',
    notifications: 'notifications',
    matching: 'matching',
    original_tc_paths: 'original_tc_paths',
    GT_settings: 'GT_settings',
  },
};
