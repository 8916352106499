import * as PlaylistActions from '@modules/playlists/store/playlist.actions';
import { createReducer, on } from '@ngrx/store';

export const playlistFeatureKey = 'playlist';

export interface State {
  playlist: Playlist | null;
  driveIds: DriveIds;
}

export const initialState: State = {
  playlist: null,
  driveIds: {
    items: [],
    nextToken: null,
  },
};

export const reducer = createReducer(
  initialState,

  on(PlaylistActions.loadPlaylist, (state) => state),
  on(PlaylistActions.loadPlaylistSuccess, (state, action) => {
    return {
      ...state,
      playlist: action.data.data.getPlaylist.items[0] || null,
    };
  }),

  on(PlaylistActions.loadDriveIds, (state) => state),
  on(PlaylistActions.loadDriveIdsSuccess, (state, action) => {
    return {
      ...state,
      driveIds: {
        items: action.data.data.getPlaylist.items,
        nextToken: action.data.data.getPlaylist.nextToken,
      },
    };
  })
);
