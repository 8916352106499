import { Component, Input } from '@angular/core';
import { DmrVendorLinkTypesConstant } from '@modules/dmr-home/constants/dmr-vendor-link-types.constant';

@Component({
  selector: 'app-vendors',
  templateUrl: './drm-vendors.component.html',
  styleUrls: ['./drm-vendors.component.scss'],
})
export class DrmVendorsComponent {
  @Input() magnaVendors?: MagnaVendor[];

  vendorLinkTypes = DmrVendorLinkTypesConstant;
}
