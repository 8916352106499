import { Injectable } from '@angular/core';
import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { SnackBarConstant } from '@constants/snack-bar.constant';
import { AuthService } from '@core/services/auth.service';
import { SnackBarService } from '@core/services/snack-bar.service';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class S3Service {
  async generatePresignedUrl(
    bucketName: string,
    key: string
  ): Promise<string | null> {
    const command = new GetObjectCommand({
      Bucket: bucketName,
      Key: key,
    });

    try {
      return await getSignedUrl(this.s3Client, command, {
        expiresIn: 3600,
      });
    } catch (error) {
      return null;
    }
  }

  private async initializeS3Client() {
    const credentials = await this.authService.getUserPoolCredentials();

    if (credentials) {
      this.s3Client = new S3Client({
        region: environment.AWS_REGION,
        credentials,
      });
    } else {
      this.snackBarService.open(
        'Failed to retrieve AWS credentials.',
        SnackBarConstant.actions.dismiss,
        3000
      );
    }
  }

  async downloadS3File(bucketName: string, key: string) {
    return await this.generatePresignedUrl(bucketName, key);
  }

  private s3Client: S3Client;

  constructor(
    private authService: AuthService,
    private snackBarService: SnackBarService
  ) {
    this.initializeS3Client().then(() => {});
  }
}
