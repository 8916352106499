import { Injectable } from '@angular/core';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';
import {
  GetTestrunExecutionsPayload,
  GetTestrunExecutionsResponse,
  GetTestRunSubmissionsPayload,
  GetTestRunSubmissionsResponse,
  SaveTestRunListPayload,
  SaveTestRunListResponse,
  SaveTestRunPayload,
  SaveTestRunResponse,
  TestRunConfigurationPayload,
  TestRunConfigurationResponse,
  TestrunExecutionRetriggerPayload,
  TestrunExecutionRetriggerResponse,
  TestrunStartPayload,
  TestrunStartResponse,
  TestrunStorePayload,
  TestrunStoreResponse,
} from '@model/payloads';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TestRunConfigurationService {
  constructor(private graphqlApiService: AwsAmplifyApiService) {}

  queryTestRunConfiguration(
    testRunConfigurationPayload: TestRunConfigurationPayload
  ): Observable<TestRunConfigurationResponse> {
    return this.graphqlApiService.graphQLQuery(testRunConfigurationPayload);
  }

  getTestRunSubmissions(
    getTestRunSubmissionsPayload: GetTestRunSubmissionsPayload
  ): Observable<GetTestRunSubmissionsResponse> {
    return this.graphqlApiService.graphQLQuery(getTestRunSubmissionsPayload);
  }

  getTestrunExecutions(
    getTestrunExecutionsPayload: GetTestrunExecutionsPayload
  ): Observable<GetTestrunExecutionsResponse> {
    return this.graphqlApiService.graphQLQuery(getTestrunExecutionsPayload);
  }

  testrunStore(
    testrunStorePayload: TestrunStorePayload
  ): Observable<TestrunStoreResponse> {
    return this.graphqlApiService.graphQLQuery(testrunStorePayload);
  }

  testrunStart(
    testrunStartPayload: TestrunStartPayload
  ): Observable<TestrunStartResponse> {
    return this.graphqlApiService.graphQLQuery(testrunStartPayload);
  }

  testrunRetrigger(
    testrunExecutionRetriggerPayload: TestrunExecutionRetriggerPayload
  ): Observable<TestrunExecutionRetriggerResponse> {
    return this.graphqlApiService.graphQLQuery(
      testrunExecutionRetriggerPayload
    );
  }

  saveTestRun(
    saveTestRunPayload: SaveTestRunPayload
  ): Observable<SaveTestRunResponse> {
    return this.graphqlApiService.graphQLQuery(saveTestRunPayload);
  }

  saveTestRunList(
    saveTestRunListPayload: SaveTestRunListPayload
  ): Observable<SaveTestRunListResponse> {
    return this.graphqlApiService.graphQLQuery(saveTestRunListPayload);
  }
}
