import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ProjectsEnvironmentsConstant } from '@constants/projects-environments.constant';
import {
  AwsAmplifyConfigConstant,
  AwsCnAmplifyConfigConstant,
} from '@core/amplify/aws-amplify-config.constant';
import { environment } from '@environments/environment';
import { Amplify } from 'aws-amplify';
import { AppModule } from './app/app.module';

if (environment.AWS_REGION === ProjectsEnvironmentsConstant.eu.region) {
  Amplify.configure(AwsAmplifyConfigConstant);
} else if (environment.AWS_REGION === ProjectsEnvironmentsConstant.cn.region) {
  Amplify.API.configure(AwsCnAmplifyConfigConstant);
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
