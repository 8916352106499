import { Component } from '@angular/core';
import { DmrVendorsConstant } from '@modules/dmr-home/constants/dmr-vendors.constant';

@Component({
  templateUrl: './dmr-home.component.html',
  styleUrls: ['./dmr-home.component.scss'],
})
export class DmrHomeComponent {
  magnaVendors: MagnaVendor[] = DmrVendorsConstant;
}
