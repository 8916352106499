import { environment } from '@environments/environment';

export const AwsAmplifyConfigConstant = {
  Auth: {
    region: environment.AWS_REGION,
    userPoolId: environment.AWS_COGNITO_USER_POOL_ID,
    identityPoolId: environment.AWS_IDENTITY_POOL_ID,
    userPoolWebClientId: environment.AWS_COGNITO_CLIENT_ID,
    oauth: {
      domain: environment.AWS_COGNITO_CUSTOM_DOMAIN,
      redirectSignIn: `https://${environment.AWS_CLOUDFRONT_DOMAIN}/`,
      redirectSignOut: `https://${environment.AWS_CLOUDFRONT_DOMAIN}/`,
      responseType: 'token',
    },
  },
  aws_appsync_graphqlEndpoint: environment.API.graphql_endpoint,
  aws_appsync_region: environment.AWS_REGION,
  aws_appsync_authenticationType: environment.AWS_AUTH_TYPE,
};

export const AwsCnAmplifyConfigConstant = {
  aws_appsync_graphqlEndpoint: environment.API.graphql_endpoint,
  aws_appsync_region: environment.AWS_REGION,
  aws_appsync_authenticationType: environment.APP_SYNC_AUTH_TYPE,
};
