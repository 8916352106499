import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DdbTablesConstant } from '@constants/ddb-tables.constant';
import { PipelinesConstant } from '@constants/pipelines.constant';
import { SnackBarConstant } from '@constants/snack-bar.constant';
import { UiMessagesConstant } from '@constants/ui-messages.constant';
import { TriggerPipelineExecutionMutation } from '@core/amplify/aws-apmlify-mutation.constant';
import {
  GetPipelineQuery,
  ListInnovizHilSwExchangesQuery,
  QueryInnovizHilSwExchangesByVersionQuery,
} from '@core/amplify/aws-apmlify-query.constant';
import { LambdasService } from '@core/services/lambdas.service';
import { SnackBarService } from '@core/services/snack-bar.service';
import { PipelineMetaData } from '@model/payloads';
import { InnovizConstant } from '@modules/innoviz/constants/innoviz.constant';
import {
  InnovizActions,
  InnovizActionTypes,
  LoadInnovizHilSwExchangesData,
  LoadInnovizHilSwExchangesDataByVersion,
  LoadPipelineData,
  ResetInnovizStore,
} from '@modules/innoviz/store/innoviz.actions';
import * as fromInnoviz from '@modules/innoviz/store/innoviz.reducer';
import {
  selectFeatureInnovizHilSwExchanges,
  selectFeaturePipelineMetaData,
} from '@modules/innoviz/store/innoviz.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-innoviz',
  templateUrl: './innoviz.component.html',
  styleUrls: ['./innoviz.component.scss'],
})
export class InnovizComponent implements OnInit, OnDestroy {
  pipelineMetaData$: Observable<PipelineMetaData> = this.store.select(
    selectFeaturePipelineMetaData
  );
  pipelineMetaData: PipelineMetaData = {
    entry_point_arn: '',
    name: '',
  };

  innovizHilSwExchanges$: Observable<LoadInnovizHilSwExchanges> =
    this.store.select(selectFeatureInnovizHilSwExchanges);
  innovizHilSwExchanges: LoadInnovizHilSwExchanges = {
    items: [],
    nextToken: null,
  };
  innovizHilSwExchangesSelected: InnovizHilSwExchange[] = [];

  innovizHilSwExchangesTableColumns: string[] = [];
  innovizHilSwExchangesTableDataSource =
    new MatTableDataSource<InnovizHilSwExchange>();
  innovizHilSwExchangesTableSelection = new SelectionModel<any>(true, []);
  innovizHilSwExchangesTableSpinner = false;

  onInnovizHilSwExchangeStartedSuccessMessage = '';
  onInnovizHilSwExchangeStartedErrorMessage = '';

  private innovizHilSwExchangesTableColumnsToExclude = [
    DdbTablesConstant.PartnersDxDynamoDBTable.columns.s3_source_bucket.name,
    DdbTablesConstant.PartnersDxDynamoDBTable.columns.s3_source_prefix.name,
    DdbTablesConstant.PartnersDxDynamoDBTable.columns.s3_target_bucket.name,
    DdbTablesConstant.PartnersDxDynamoDBTable.columns.s3_target_prefix.name,
    DdbTablesConstant.PartnersDxDynamoDBTable.columns.type.name,
  ];

  resetActionMessages(): void {
    this.onInnovizHilSwExchangeStartedSuccessMessage = '';
    this.onInnovizHilSwExchangeStartedErrorMessage = '';
  }

  resetSelections(): void {
    this.innovizHilSwExchangesTableSelection.clear();
    this.innovizHilSwExchangesSelected = [];
  }

  onApplyFilter(version: string): void {
    if (version) {
      this.store.dispatch(
        new LoadInnovizHilSwExchangesDataByVersion({
          query: QueryInnovizHilSwExchangesByVersionQuery,
          variables: {
            version,
            limit: 1000,
            nextToken: null,
          },
        })
      );
    } else {
      this.onFetchInnovizHilSwExchanges();
    }
  }

  onFetchInnovizHilSwExchanges(): void {
    this.store.dispatch(
      new LoadInnovizHilSwExchangesData({
        query: ListInnovizHilSwExchangesQuery,
        variables: {
          type: DdbTablesConstant.PartnersDxDynamoDBTable.columns.type.values
            .exchange,
          limit: 1000,
          nextToken: null,
        },
      })
    );
  }

  onInnovizHilSwExchangeSelected(selectedItems: any): void {
    this.innovizHilSwExchangesSelected = selectedItems;
  }

  onInnovizHilSwExchangeStarted(): void {
    this.resetActionMessages();

    let parameters: any = { [InnovizConstant.apiFields.hil_list.name]: [] };

    this.innovizHilSwExchangesSelected.map((selectedExchange: any) => {
      parameters[InnovizConstant.apiFields.hil_list.name].push({
        [DdbTablesConstant.PartnersDxDynamoDBTable.columns.deployment_version
          .name]:
          selectedExchange[
            DdbTablesConstant.PartnersDxDynamoDBTable.columns.deployment_version
              .name
          ],
        [`${DdbTablesConstant.PartnersDxDynamoDBTable.columns.deployment_content.name}s`]:
          [
            selectedExchange[
              DdbTablesConstant.PartnersDxDynamoDBTable.columns
                .deployment_content.name
            ],
          ],
      });
    });

    parameters = JSON.stringify(parameters);

    this.snackBarService.open(
      UiMessagesConstant.onInnovizHilSwExchangeStartedSuccessMessage,
      SnackBarConstant.actions.dismiss,
      3000
    );

    this.lambdasService
      .triggerPipelineExecution({
        query: TriggerPipelineExecutionMutation,
        variables: {
          input: {
            pipeline: this.pipelineMetaData.name,
            entry_point_arn: this.pipelineMetaData.entry_point_arn,
            parameters,
            action: PipelinesConstant.actions.start,
          },
        },
      })
      .subscribe(() => {
        this.resetSelections();

        this.onInnovizHilSwExchangeStartedSuccessMessage =
          UiMessagesConstant.onInnovizHilSwExchangedSuccessMessage;
        this.onInnovizHilSwExchangeStartedErrorMessage = '';

        this.snackBarService.open(
          UiMessagesConstant.onInnovizHilSwExchangedSuccessMessage,
          SnackBarConstant.actions.dismiss,
          3000
        );
      });
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetInnovizStore());
  }

  ngOnInit(): void {
    this.pipelineMetaData$.subscribe((pipelineMetaData) => {
      this.pipelineMetaData = pipelineMetaData;
    });

    this.innovizHilSwExchanges$.subscribe((innovizHilSwExchanges) => {
      this.innovizHilSwExchanges = innovizHilSwExchanges;

      this.innovizHilSwExchangesTableColumns = Object.keys(
        this.innovizHilSwExchanges.items[0] || {}
      );

      this.innovizHilSwExchangesTableColumnsToExclude.map(
        (innovizHilSwExchangesTableColumn) =>
          this.innovizHilSwExchangesTableColumns.splice(
            this.innovizHilSwExchangesTableColumns.indexOf(
              innovizHilSwExchangesTableColumn
            ),
            1
          )
      );

      this.innovizHilSwExchangesTableDataSource = new MatTableDataSource(
        this.innovizHilSwExchanges.items
      );

      this.innovizHilSwExchangesTableSpinner = false;
    });

    this.actions$
      .pipe(ofType(InnovizActionTypes.LoadInnovizHilSwExchangesData))
      .subscribe(() => {
        this.innovizHilSwExchangesTableSpinner = true;

        this.resetSelections();
        this.resetActionMessages();
      });

    this.actions$
      .pipe(ofType(InnovizActionTypes.LoadInnovizHilSwExchangesDataByVersion))
      .subscribe(() => {
        this.innovizHilSwExchangesTableSpinner = true;

        this.resetSelections();
        this.resetActionMessages();
      });

    this.actions$
      .pipe(ofType(InnovizActionTypes.GraphQLQueryFailure))
      .subscribe(() => {
        this.onInnovizHilSwExchangeStartedSuccessMessage = '';
        this.onInnovizHilSwExchangeStartedErrorMessage =
          UiMessagesConstant.onInnovizHilSwExchangeStartedErrorMessage;

        this.innovizHilSwExchangesTableSpinner = false;
      });

    this.store.dispatch(
      new LoadPipelineData({
        query: GetPipelineQuery,
        variables: {
          name: PipelinesConstant.names.DX_Innoviz_Uploader_v1,
        },
      })
    );

    this.store.dispatch(
      new LoadInnovizHilSwExchangesData({
        query: ListInnovizHilSwExchangesQuery,
        variables: {
          type: DdbTablesConstant.PartnersDxDynamoDBTable.columns.type.values
            .exchange,
          limit: 1000,
          nextToken: null,
        },
      })
    );
  }

  constructor(
    private store: Store<fromInnoviz.State>,
    private actions$: Actions<InnovizActions>,
    private lambdasService: LambdasService,
    private snackBarService: SnackBarService
  ) {}
}
