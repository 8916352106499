export const ProjectsConstant: { [key: string]: any } = {
  projectsList: ['ADCAM', 'LIDAR', 'MFK5', 'MFK120', 'MIK'],
  ALL: {
    name: 'All',
    ReProcessingTypes: [
      {
        value: 'adcam_low_only',
        placeholder: 'adcam_low_only',
      },
      {
        value: 'adcam_low_fusion1_mrr',
        placeholder: 'adcam_low_fusion1_mrr',
      },
      {
        value: 'adcam_low_fusion2_mrr_srr',
        placeholder: 'adcam_low_fusion2_mrr_srr',
      },
      {
        value: 'adcam_fusion_mrc_x2e',
        placeholder: 'adcam_fusion_mrc_x2e',
      },
      {
        value: 'adcam_fusion_src_x2e',
        placeholder: 'adcam_fusion_src_x2e',
      },
      {
        value: 'adcam_fusion_mrc_src_x2e',
        placeholder: 'adcam_fusion_mrc_src_x2e',
      },
      { value: 'lidar_no_sils', placeholder: 'lidar_no_sils' },
      { value: 'lidar', placeholder: 'lidar' },
      { value: 'mfk5', placeholder: 'mfk5' },
      { value: 'mfk120', placeholder: 'mfk120' },
    ],
  },
  ADCAM: {
    name: 'ADCAM',
    id: 'L2G8680',
    ReProcessingTypes: [
      {
        value: 'adcam_low_only',
        placeholder: 'adcam_low_only',
      },
      {
        value: 'adcam_low_fusion1_mrr',
        placeholder: 'adcam_low_fusion1_mrr',
      },
      {
        value: 'adcam_low_fusion2_mrr_srr',
        placeholder: 'adcam_low_fusion2_mrr_srr',
      },
      {
        value: 'adcam_fusion_mrc_x2e',
        placeholder: 'adcam_fusion_mrc_x2e',
      },
      {
        value: 'adcam_fusion_src_x2e',
        placeholder: 'adcam_fusion_src_x2e',
      },
      {
        value: 'adcam_fusion_mrc_src_x2e',
        placeholder: 'adcam_fusion_mrc_src_x2e',
      },
    ],
  },
  LIDAR: {
    name: 'LIDAR',
    id: 'L2G8810',
    ReProcessingTypes: [
      { value: 'lidar_no_sils', placeholder: 'lidar_no_sils' },
      { value: 'lidar', placeholder: 'lidar' },
    ],
    transcoderStreamsSelectionDataSource: [
      { stream: 'VBS00' },
      { stream: 'VBS01' },
      { stream: 'VBS02' },
      { stream: 'VBS03' },
      { stream: 'VBS04' },
      { stream: 'VBS05' },
      { stream: 'VBS06' },
      { stream: 'RFC01' },
      { stream: 'RFC02' },
      { stream: 'RFC03' },
      { stream: 'RFC13' },
      { stream: 'RFL01' },
      { stream: 'RFL02' },
      { stream: 'RFL03' },
      { stream: 'DBG01' },
      { stream: 'DBG02' },
      { stream: 'DBG06' },
      { stream: 'SLC01' },
      { stream: 'SLC02' },
      { stream: 'PTC01' },
      { stream: 'PTC02' },
      { stream: 'RFI01' },
      { stream: 'PCP01' },
      { stream: 'PRLBL' },
    ],
  },
  MFK5: {
    name: 'MFK5',
    id: 'L2Gxxxx',
    ReProcessingTypes: [{ value: 'mfk5', placeholder: 'mfk5' }],
  },
  MFK120: {
    name: 'MFK120',
    id: 'L2H4310',
    ReProcessingTypes: [{ value: 'mfk120', placeholder: 'mfk120' }],
  },
  MIK: {
    name: 'MIK',
    id: 'L2H5050',
    transcoderStreamsSelectionDataSource: [
      { stream: 'VBS' },
      { stream: 'VIU' },
    ],
    ReProcessingTypes: [
      {
        value: 'mik_sil',
        placeholder: 'mik_sil',
      },
      {
        value: 'mik_hil',
        placeholder: 'mik_hil',
      },
    ],
  },
  L2G8680: {
    name: 'ADCAM',
    id: 'L2G8680',
    ReProcessingTypes: [
      {
        value: 'adcam_low_only',
        placeholder: 'adcam_low_only',
      },
      {
        value: 'adcam_low_fusion1_mrr',
        placeholder: 'adcam_low_fusion1_mrr',
      },
      {
        value: 'adcam_low_fusion2_mrr_srr',
        placeholder: 'adcam_low_fusion2_mrr_srr',
      },
      {
        value: 'adcam_fusion_mrc_x2e',
        placeholder: 'adcam_fusion_mrc_x2e',
      },
      {
        value: 'adcam_fusion_src_x2e',
        placeholder: 'adcam_fusion_src_x2e',
      },
      {
        value: 'adcam_fusion_mrc_src_x2e',
        placeholder: 'adcam_fusion_mrc_src_x2e',
      },
    ],
  },
  L2G8810: {
    name: 'LIDAR',
    id: 'L2G8810',
    ReProcessingTypes: [
      { value: 'lidar_no_sils', placeholder: 'lidar_no_sils' },
      { value: 'lidar', placeholder: 'lidar' },
    ],
  },
  L2Gxxxx: {
    name: 'MFK5',
    id: 'L2Gxxxx',
    ReProcessingTypes: [{ value: 'mfk5', placeholder: 'mfk5' }],
  },
  L2H4310: {
    name: 'MFK120',
    id: 'L2H4310',
    ReProcessingTypes: [{ value: 'mfk120', placeholder: 'mfk120' }],
  },
  L2H5050: {
    name: 'MIK',
    id: 'L2H5050',
    transcoderStreamsSelectionDataSource: [
      { stream: 'VBS' },
      { stream: 'VIU' },
    ],
    ReProcessingTypes: [
      {
        value: 'mik_sil',
        placeholder: 'mik_sil',
      },
      {
        value: 'mik_hil',
        placeholder: 'mik_hil',
      },
    ],
  },
};
