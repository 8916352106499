import { Action } from '@ngrx/store';
import { GraphQLQueryError } from '@model/payloads';
import {
  GetPipelineDataPayload,
  GetPipelineDataResponse,
  LoadInnovizHilSwExchangesPayload,
  LoadInnovizHilSwExchangesResponse,
  QueryInnovizHilSwExchangesByVersionPayload,
  QueryInnovizHilSwExchangesByVersionResponse,
} from '@model/payloads';

export enum InnovizActionTypes {
  ResetInnovizStore = '[Innoviz] Reset Innoviz Store',
  GraphQLQueryFailure = '[Innoviz] Load GraphQLQuery Failure',
  LoadPipelineData = '[Innoviz] Load PipelineData',
  LoadPipelineDataSuccess = '[Innoviz] Load PipelineData Success',
  LoadInnovizHilSwExchangesData = '[Innoviz] Load LoadInnovizHilSwExchangesData',
  LoadInnovizHilSwExchangesDataSuccess = '[Innoviz] Load InnovizHilSwExchangesData Success',
  LoadInnovizHilSwExchangesDataByVersion = '[Innoviz] Load LoadInnovizHilSwExchangesDataByVersion',
  LoadInnovizHilSwExchangesDataByVersionSuccess = '[Innoviz] Load LoadInnovizHilSwExchangesDataByVersion Success',
}

export class ResetInnovizStore implements Action {
  readonly type = InnovizActionTypes.ResetInnovizStore;
}

export class GraphQLQueryFailure implements Action {
  readonly type = InnovizActionTypes.GraphQLQueryFailure;
  constructor(public payload: GraphQLQueryError) {}
}

export class LoadPipelineData implements Action {
  readonly type = InnovizActionTypes.LoadPipelineData;
  constructor(public payload: GetPipelineDataPayload) {}
}

export class LoadPipelineDataSuccess implements Action {
  readonly type = InnovizActionTypes.LoadPipelineDataSuccess;
  constructor(public payload: GetPipelineDataResponse) {}
}

export class LoadInnovizHilSwExchangesData implements Action {
  readonly type = InnovizActionTypes.LoadInnovizHilSwExchangesData;
  constructor(public payload: LoadInnovizHilSwExchangesPayload) {}
}

export class LoadInnovizHilSwExchangesDataSuccess implements Action {
  readonly type = InnovizActionTypes.LoadInnovizHilSwExchangesDataSuccess;
  constructor(public payload: LoadInnovizHilSwExchangesResponse) {}
}

export class LoadInnovizHilSwExchangesDataByVersion implements Action {
  readonly type = InnovizActionTypes.LoadInnovizHilSwExchangesDataByVersion;
  constructor(public payload: QueryInnovizHilSwExchangesByVersionPayload) {}
}

export class LoadInnovizHilSwExchangesDataByVersionSuccess implements Action {
  readonly type =
    InnovizActionTypes.LoadInnovizHilSwExchangesDataByVersionSuccess;
  constructor(public payload: QueryInnovizHilSwExchangesByVersionResponse) {}
}

export type InnovizActions =
  | ResetInnovizStore
  | GraphQLQueryFailure
  | LoadPipelineData
  | LoadPipelineDataSuccess
  | LoadInnovizHilSwExchangesData
  | LoadInnovizHilSwExchangesDataSuccess
  | LoadInnovizHilSwExchangesDataByVersion
  | LoadInnovizHilSwExchangesDataByVersionSuccess;
