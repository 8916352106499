export const VinsApprovalsConstant = {
  statuses: {
    approved: {
      value: 'APPROVED',
      placeholder: 'Approved',
    },
    rejected: {
      value: 'REJECTED',
      placeholder: 'Rejected',
    },
    notSet: {
      value: 'NOT_SET',
      placeholder: 'Not Set',
    },
  },
  approval_users: {
    annotation: {
      name: 'annotation',
      placeholder: 'Comment',
    },
    dmr: {
      name: 'dmr',
      placeholder: 'Comment',
    },
    kpi: {
      name: 'kpi',
      placeholder: 'Comment',
    },
    reprocessing: {
      name: 'reprocessing',
      placeholder: 'Comment',
    },
    partner: {
      name: 'partner',
      placeholder: 'Comment',
    },
  },
  approval_users_mfk120: {
    data_manager: {
      name: 'Data Manager',
      placeholder: 'Comment',
    },
    data_collection: {
      name: 'Data Collection',
      placeholder: 'Comment',
    },
    kpi_team: {
      name: 'KPI Team',
      placeholder: 'Comment',
    },
    reprocessing: {
      name: 'Reprocessing',
      placeholder: 'Comment',
    },
  },
};
