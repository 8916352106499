import { AdGroupsConstant } from '@constants/ad-groups.constant';
import { ProjectsConstant } from '@constants/projects.constant';
import { environment } from '@environments/environment';
import { DmrVendorLinkTypesConstant } from '@modules/dmr-home/constants/dmr-vendor-link-types.constant';

export const DmrVendorsConstant: MagnaVendor[] = [
  {
    link: '/tma/test-run-configuration',
    linkType: DmrVendorLinkTypesConstant.routerLink,
    title: 'Test Management Application',
    subTitle: '',
    enabledGroups: [
      AdGroupsConstant.LOCAL_USER,
      AdGroupsConstant.MIT_INF_ADAS_Project_Support_Admin,
      AdGroupsConstant.ELC_AWS_DMR_Developers,
      AdGroupsConstant.ELC_AWS_Magna_DMR_Data_Engineers,
      AdGroupsConstant.ELC_AWS_TMA_Repro,
      AdGroupsConstant.ELC_AWS_TMA_HIL,
      AdGroupsConstant.ELC_AWS_Magna_FO_BMW,
      AdGroupsConstant.ELC_AWS_KPI_Testing_Magna,
      AdGroupsConstant.BMW_ELC_AWS_Defect_Managers,
      AdGroupsConstant.ELC_AWS_Magna_FO_Audi,
      AdGroupsConstant.AUDI_ELC_AWS_Defect_Managers,
      AdGroupsConstant.ELC_AWS_TMA_Data_Ingestion,
      AdGroupsConstant.BMW_ELC_AWS_Defect_Managers,
      AdGroupsConstant.ELC_AWS_TMA_Application_FO,
      AdGroupsConstant.ELC_AWS_TMA_baseSW_FO,
      AdGroupsConstant.ELC_AWS_DMR_Octagon_Admins,
      AdGroupsConstant.ELC_AWS_DMR_Octagon_bmw_user,
      AdGroupsConstant.ELC_AWS_DMR_Octagon_audi_user,
    ],
    disabled: false,
  },
  {
    link: '/tma/transcoding',
    linkType: DmrVendorLinkTypesConstant.routerLink,
    title: 'Manual Transcoding',
    subTitle: '',
    enabledGroups: [],
    disabled: false,
  },
  {
    link: '/tma/trained-parking-assist',
    linkType: DmrVendorLinkTypesConstant.routerLink,
    title: 'MFK120 Trained Parking Assist',
    subTitle: '',
    enabledGroups: [],
    disabled:
      environment.projectName === ProjectsConstant.LIDAR.name ? false : true,
  },
  {
    link: '/tma/playlists',
    linkType: DmrVendorLinkTypesConstant.routerLink,
    title: 'Playlists',
    subTitle: '',
    enabledGroups: [],
    disabled: environment.envName === 'test' ? true : false,
  },
  {
    link: '',
    linkType: '',
    title: '',
    subTitle: '',
    enabledGroups: [],
    disabled: false,
  },
  {
    link: '',
    linkType: '',
    title: '',
    subTitle: '',
    enabledGroups: [],
    disabled: false,
  },
  {
    link: `${environment.tmaV1Url}/main/magna/defect_traces`,
    linkType: DmrVendorLinkTypesConstant.href,
    title: 'Defect Exchange',
    subTitle: '',
    enabledGroups: [],
    disabled:
      environment.projectName === ProjectsConstant.LIDAR.name ? false : true,
  },
  {
    link: '/tma/cariad',
    linkType: DmrVendorLinkTypesConstant.routerLink,
    title: 'Cariad Exchange',
    subTitle: '',
    enabledGroups: [],
    disabled:
      environment.projectName === ProjectsConstant.LIDAR.name ? false : true,
  },
  {
    link: '/tma/innoviz',
    linkType: DmrVendorLinkTypesConstant.routerLink,
    title: 'Innoviz Exchange',
    subTitle: '',
    enabledGroups: [],
    disabled:
      environment.projectName === ProjectsConstant.LIDAR.name ? false : true,
  },
  {
    link: `${environment.tmaV1Url}/main/pipelines`,
    linkType: DmrVendorLinkTypesConstant.href,
    title: 'Pipelines',
    subTitle: '',
    enabledGroups: [],
    disabled: false,
  },
  {
    link: '/tma/data-browser',
    linkType: DmrVendorLinkTypesConstant.routerLink,
    title: 'Data Browser',
    subTitle: '',
    enabledGroups: [
      AdGroupsConstant.LOCAL_USER,
      AdGroupsConstant.MIT_INF_ADAS_Project_Support_Admin,
      AdGroupsConstant.ELC_AWS_DMR_Developers,
      AdGroupsConstant.ELC_AWS_Magna_DMR_Data_Engineers,
      AdGroupsConstant.ELC_AWS_TMA_Repro,
      AdGroupsConstant.ELC_AWS_TMA_HIL,
      AdGroupsConstant.ELC_AWS_Magna_FO_BMW,
      AdGroupsConstant.ELC_AWS_KPI_Testing_Magna,
      AdGroupsConstant.BMW_ELC_AWS_Defect_Managers,
      AdGroupsConstant.ELC_AWS_Magna_FO_Audi,
      AdGroupsConstant.AUDI_ELC_AWS_Defect_Managers,
      AdGroupsConstant.ELC_AWS_TMA_Data_Ingestion,
      AdGroupsConstant.BMW_ELC_AWS_Defect_Managers,
      AdGroupsConstant.ELC_AWS_TMA_Application_FO,
      AdGroupsConstant.ELC_AWS_TMA_baseSW_FO,
      AdGroupsConstant.ELC_AWS_DMR_Octagon_Admins,
      AdGroupsConstant.ELC_AWS_DMR_Octagon_bmw_user,
      AdGroupsConstant.ELC_AWS_DMR_Octagon_audi_user,
    ],
    disabled: false,
  },
  {
    link: '',
    linkType: '',
    title: '',
    subTitle: '',
    enabledGroups: [],
    disabled: false,
  },
  {
    link: '/tma/vins-approvals',
    linkType: DmrVendorLinkTypesConstant.routerLink,
    title: 'Approvals',
    subTitle: '',
    enabledGroups: [],
    disabled: false,
  },
  {
    link: '/tma/splits-approvals',
    linkType: DmrVendorLinkTypesConstant.routerLink,
    title: 'MFK120 Approvals',
    subTitle: '',
    enabledGroups: [],
    disabled:
      environment.projectName === ProjectsConstant.LIDAR.name ? false : true,
  },
  {
    link: '',
    linkType: '',
    title: '',
    subTitle: '',
    enabledGroups: [],
    disabled: false,
  },
  {
    link: 'https://eu-central-1.quicksight.aws.amazon.com/sn/start/analyses',
    linkType: DmrVendorLinkTypesConstant.href,
    title: 'Dashboards',
    subTitle: '',
    enabledGroups: [
      AdGroupsConstant.LOCAL_USER,
      AdGroupsConstant.MIT_INF_ADAS_Project_Support_Admin,
      AdGroupsConstant.ELC_AWS_DMR_Developers,
      AdGroupsConstant.ELC_AWS_Magna_DMR_Data_Engineers,
      AdGroupsConstant.ELC_AWS_TMA_Repro,
      AdGroupsConstant.ELC_AWS_TMA_HIL,
      AdGroupsConstant.ELC_AWS_Magna_FO_BMW,
      AdGroupsConstant.ELC_AWS_KPI_Testing_Magna,
      AdGroupsConstant.BMW_ELC_AWS_Defect_Managers,
      AdGroupsConstant.ELC_AWS_Magna_FO_Audi,
      AdGroupsConstant.AUDI_ELC_AWS_Defect_Managers,
      AdGroupsConstant.ELC_AWS_TMA_Data_Ingestion,
      AdGroupsConstant.BMW_ELC_AWS_Defect_Managers,
      AdGroupsConstant.ELC_AWS_TMA_Application_FO,
      AdGroupsConstant.ELC_AWS_TMA_baseSW_FO,
      AdGroupsConstant.ELC_AWS_DMR_Octagon_Admins,
      AdGroupsConstant.ELC_AWS_DMR_Octagon_bmw_user,
      AdGroupsConstant.ELC_AWS_DMR_Octagon_audi_user,
    ],
    disabled: false,
  },
  {
    link: 'https://semantic-search-ui.test.adas.aws.magna.intranet/',
    linkType: DmrVendorLinkTypesConstant.href,
    title: 'Ticketing Tool',
    subTitle: '',
    enabledGroups: [
      AdGroupsConstant.LOCAL_USER,
      AdGroupsConstant.ELC_AWS_DMR_Developers,
      AdGroupsConstant.ELC_AWS_TMA_Repro,
    ],
    disabled: false,
  },
];
