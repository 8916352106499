import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { ICredentials } from '@aws-amplify/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  getCurrentUser(): Observable<CognitoUser | any> {
    return from(Auth.currentAuthenticatedUser());
  }

  async getUserPoolCredentials(): Promise<ICredentials> {
    return await Auth.currentCredentials();
  }
}
