<div class="row">
  <mat-card class="full-width-card">
    <mat-card-content>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-form-field appearance="fill">
          <mat-label>Use case</mat-label>
          <mat-select
            [(value)]="useCaseSelectedId"
            (selectionChange)="onUseCaseSelect($event)"
          >
            <mat-option
              *ngFor="let useCase of useCases"
              [value]="useCase.UseCaseId"
              >{{ useCase.UseCaseName }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <a
          mat-raised-button
          color="primary"
          matTooltip="Download report"
          matTooltipPosition="below"
          [href]="reportLink"
          [disabled]="!reportLink"
          ><mat-icon>file_download</mat-icon></a
        >
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div *ngIf="useCaseSelectedId">
  <div class="row">
    <mat-card class="full-width-card">
      <mat-card-header>
        <mat-card-title
          >{{ this.validationLevel }} splits validation summary</mat-card-title
        >
      </mat-card-header>
      <mat-card-content>
        <mat-list>
          <mat-list-item>
            <div mat-line>
              validated_splits_number:
              <b>{{
                useCaseSplitValidationSummary?.validated_splits_number | json
              }}</b>
            </div>
          </mat-list-item>
          <mat-list-item>
            <div mat-line>
              problematic_splits_number:
              <b>{{
                useCaseSplitValidationSummary?.problematic_splits_number | json
              }}</b>
            </div>
          </mat-list-item>
          <mat-list-item>
            <div mat-line>
              total_splits_number:
              <b>{{
                useCaseSplitValidationSummary?.total_splits_number | json
              }}</b>
            </div>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>

  <div class-="row">
    <mat-card>
      <mat-card-header>
        <mat-card-title
          >{{ this.validationLevel }} splits validation details</mat-card-title
        >
      </mat-card-header>

      <mat-card-content>
        <div class="row">
          <div class="col">
            <mat-form-field appearance="fill">
              <mat-label>Split Index</mat-label>
              <input
                matInput
                (keyup.enter)="onSearchBySplitIndex($event)"
                [placeholder]="'Ex.1'"
                matTooltip="Input Split Index and click 'Enter'"
                matTooltipPosition="below"
              />
            </mat-form-field>
          </div>
          <div class="col">
            <mat-button-toggle-group
              (change)="onSplitsFilterChange($event)"
              name="filters"
              aria-label="Filters"
            >
              <mat-button-toggle value="all">All</mat-button-toggle>
              <mat-button-toggle value="NOK"
                >Show problematic splits</mat-button-toggle
              >
              <mat-button-toggle value="OK">Show OK splits</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <app-table
          [displayedColumns]="splitsTableColumns"
          [dataSource]="filteredSplitsTableDataSource"
          (tableItemSelected)="onSplitsTableItemSelected($event)"
          [spinner]="splitsTableSpinner"
          [multipleSelections]="false"
          [showFilter]="false"
          [isSortDisabled]="true"
          [enableDetails]="false"
        ></app-table>
      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayoutAlign="start center">
        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="onLoadInitSplits()"
          matTooltip="Refresh table data"
          matTooltipPosition="below"
          [disabled]="splitsTableSpinner"
        >
          <mat-icon>refresh</mat-icon>
        </button>
        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="onLoadMoreSplits()"
          matTooltip="Load more items"
          matTooltipPosition="below"
          [disabled]="splitsTableSpinner"
        >
          <mat-icon>system_update_alt</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card>
      <mat-card-header>
        <mat-card-title>
          {{ this.validationLevel }} validation breakdown for the selected split
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <app-table
          [displayedColumns]="splitDetailsTableColumns"
          [dataSource]="filteredSplitDetailsTableSource"
          [isSelectionButtonVisible]="false"
          [enableDetails]="false"
          [filterPlaceholder]="'OK'"
          [showItemHover]="false"
          [showExactFilterCheckbox]="true"
        ></app-table>
      </mat-card-content>
    </mat-card>
  </div>
</div>
