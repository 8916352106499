import { TmaUtilsStaticService } from '@core/services/tma-utils-static.service';
import { PipelineMetaData } from '@model/payloads';
import {
  InnovizActions,
  InnovizActionTypes,
} from '@modules/innoviz/store/innoviz.actions';

export const innovizFeatureKey = 'innoviz';

export interface State {
  pipelineMetaData: PipelineMetaData;
  innovizHilSwExchanges: LoadInnovizHilSwExchanges;
}

export const initialState: State = {
  pipelineMetaData: {
    entry_point_arn: '',
    name: '',
  },
  innovizHilSwExchanges: {
    items: [],
    nextToken: null,
  },
};

export function reducer(state = initialState, action: InnovizActions): State {
  switch (action.type) {
    case InnovizActionTypes.ResetInnovizStore:
      return { ...initialState };

    case InnovizActionTypes.LoadPipelineDataSuccess:
      return {
        ...state,
        pipelineMetaData: action.payload.data.getPipeline,
      };

    case InnovizActionTypes.LoadInnovizHilSwExchangesDataSuccess:
      const listInnovizHilSwExchanges = JSON.parse(
        action.payload.data.listInnovizHilSwExchanges.items
      );

      listInnovizHilSwExchanges.map(
        (innovizHilSwExchange: InnovizHilSwExchange) => {
          innovizHilSwExchange['upload-timestamp'] =
            TmaUtilsStaticService.formatDate(
              innovizHilSwExchange['upload-timestamp']
            );
        }
      );

      return {
        ...state,
        innovizHilSwExchanges: {
          items: listInnovizHilSwExchanges,
          nextToken: action.payload.data.listInnovizHilSwExchanges.nextToken,
        },
      };

    case InnovizActionTypes.LoadInnovizHilSwExchangesDataByVersionSuccess:
      const innovizHilSwExchangesByVersion = JSON.parse(
        action.payload.data.queryInnovizHilSwExchangesByVersion.items
      );

      innovizHilSwExchangesByVersion.map(
        (innovizHilSwExchangeByVersion: InnovizHilSwExchange) => {
          innovizHilSwExchangeByVersion['upload-timestamp'] =
            TmaUtilsStaticService.formatDate(
              innovizHilSwExchangeByVersion['upload-timestamp']
            );
        }
      );

      return {
        ...state,
        innovizHilSwExchanges: {
          items: innovizHilSwExchangesByVersion,
          nextToken:
            action.payload.data.queryInnovizHilSwExchangesByVersion.nextToken,
        },
      };

    default:
      return state;
  }
}
