<app-header [drawer]="drawer"></app-header>

<mat-drawer-container>
  <mat-drawer #drawer mode="side">
    <mat-selection-list [multiple]="false">
      <mat-list-option
        *ngFor="let tabsRoute of tabsRoutesConstant"
        [routerLink]="tabsRoute.routerLink"
        [value]="tabsRoute.routerLink"
        [disabled]="tabsRoute.disabled"
      >
        {{ tabsRoute.placeholder | uppercase }}
      </mat-list-option>
    </mat-selection-list>
    <button
      mat-raised-button
      color="primary"
      type="button"
      (click)="signOut(drawer)"
    >
      Sign Out
    </button>
  </mat-drawer>

  <mat-drawer-content>
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
