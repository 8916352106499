<form
  [formGroup]="createTestRunConfigurationForm"
  novalidate
  (ngSubmit)="onSubmit()"
>
  <mat-accordion class="trc-accordion_trc-panel_rc-accordion">
    <mat-expansion-panel class="trc-accordion_trc-panel_rc-accordion_rc-panel">
      <mat-expansion-panel-header>
        <mat-panel-title>New configuration</mat-panel-title>
      </mat-expansion-panel-header>

      <app-form
        class="trc-accordion_trc-panel_rc-accordion_rc-panel_rc-form"
        [formGroup]="reproceccingConfigurationFormGroup"
        [rowHeight]="'10em'"
        [formControlsMetadata]="reproceccingConfigurationControlsMetadata"
        [formControlsConfig]="reproceccingConfigurationControlsConfig"
        (formValueChanged)="onReproceccingConfigurationFormValueChanges($event)"
        (submitted)="onSubmitReprocessingConfigurationForm($event)"
      ></app-form>
    </mat-expansion-panel>
  </mat-accordion>

  <app-table
    class="trc-accordion_trc-panel_rc-table"
    [displayedColumns]="reprocessingConfigsColumns"
    [dataSource]="filteredReprocessingConfigsDataSource"
    (tableItemSelected)="onReprocessingConfigsDataItemSelected($event)"
    [filterPlaceholder]="'2022-07-11'"
    [multipleSelections]="false"
    [selection]="reprocessingConfigsTableSelection"
    [spinner]="reprocessingConfigsTableSpinner"
    [isSortDisabled]="true"
  ></app-table>

  <mat-card-actions
    class="trc-accordion_trc-panel_rc-actions"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <button
      class="trc-accordion_trc-panel_rc-actions_sync-button"
      mat-raised-button
      color="primary"
      type="button"
      (click)="onFetchReprocessingConfigurations()"
      matTooltip="Refresh data"
      matTooltipPosition="below"
    >
      <mat-icon>refresh</mat-icon>
    </button>
  </mat-card-actions>

  <mat-accordion class="trc-accordion_trc-panel_ts-accordion">
    <mat-expansion-panel class="trc-accordion_trc-panel_ts-accordion_ts-panel">
      <mat-expansion-panel-header>
        <mat-panel-title>New test session</mat-panel-title>
      </mat-expansion-panel-header>

      <app-form
        class="trc-accordion_trc-panel_ts-accordion_ts-panel_ts-form"
        [formGroup]="testSessionFormGroup"
        [rowHeight]="'10em'"
        [formControlsMetadata]="testSessionControlsMetadata"
        [formControlsConfig]="testSessionControlsConfig"
        (submitted)="onSubmitTestSessionForm($event)"
        [actionName]="testSessionActionName"
        (actionTriggered)="openDialog()"
      ></app-form>
    </mat-expansion-panel>
  </mat-accordion>

  <app-table
    class="trc-accordion_trc-panel_ts-table"
    [displayedColumns]="testSessionsColumns"
    [dataSource]="filteredTestSessionsDataSource"
    (tableItemSelected)="onTestSessionsDataItemsSelected($event)"
    [filterPlaceholder]="'2022-07-11'"
    [selection]="testSessionsTableSelection"
    [spinner]="testSessionsTableSpinner"
    [isSortDisabled]="true"
  ></app-table>

  <mat-card-actions
    class="trc-accordion_trc-panel_ts-actions"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <button
      class="trc-accordion_trc-panel_ts-actions_sync-button"
      mat-raised-button
      color="primary"
      type="button"
      (click)="onFetchTestSessions()"
      matTooltip="Refresh data"
      matTooltipPosition="below"
    >
      <mat-icon>refresh</mat-icon>
    </button>
  </mat-card-actions>

  <div class="row">
    <div class="col">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Label</mat-label>
        <input
          matInput
          placeholder="Label"
          formControlName="testrun_config_label"
        />
        <mat-error
          *ngIf="
            createTestRunConfigurationForm.controls.testrun_config_label.hasError(
              'required'
            )
          "
        >
          Field is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col">
      <mat-form-field appearance="fill">
        <mat-label>Ecu Init triggering mode</mat-label>
        <mat-select formControlName="EcuInitTriggeringMode">
          <mat-option
            *ngFor="let ecuInitTriggeringMode of ecuInitTriggeringModes"
            [value]="ecuInitTriggeringMode"
            >{{ ecuInitTriggeringMode }}</mat-option
          >
        </mat-select>
        <mat-error
          *ngIf="
            createTestRunConfigurationForm.controls.EcuInitTriggeringMode.hasError(
              'required'
            )
          "
        >
          Field is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Transcoder type</mat-label>
        <mat-select
          formControlName="transcoding_execution_type"
          (selectionChange)="onTranscoderTypeChange($event)"
        >
          <mat-option *ngFor="let type of transcodersTypes" [value]="type">{{
            type
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Streams selection type</mat-label>
        <mat-select formControlName="streams_selection_type">
          <mat-option
            *ngFor="let type of tcSteamsSelectionTypes"
            [value]="type"
            >{{ type }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Bordnet version</mat-label>
        <mat-select
          formControlName="bordnet_version"
          (selectionChange)="onBordnetVersionChange($event)"
        >
          <mat-option *ngFor="let type of bordnetVersions" [value]="type">{{
            type
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Fibex file name</mat-label>
        <mat-select formControlName="fibex_xml_filename">
          <mat-option *ngFor="let type of fibexFileNames" [value]="type">{{
            type
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <app-table
    class="trc-accordion_trc-panel_transc-streams-table"
    *ngIf="isStreamsTableVisible"
    [displayedColumns]="transcoderStreamsSelectionColumns"
    [dataSource]="transcoderStreamsSelectionDataSource"
    (tableItemSelected)="onTranscoderStreamsSelected($event)"
    [filterPlaceholder]="'VBS00'"
    [isAsyncDataSource]="false"
  ></app-table>

  <div class="row">
    <div class="col">
      <md-input-container formGroupName="session_to_kpiscipts_mapping">
        <div fxLayout="row" fxLayoutAlign="space-between start">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>KPI project name</mat-label>
            <mat-select
              formControlName="kpiProjectName"
              (selectionChange)="onKpiProjectSelectionChange($event)"
            >
              <mat-option
                *ngFor="let kpiProjects of kpiProjects$ | async"
                [value]="kpiProjects.name"
                >{{ kpiProjects.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <button
            class="reset-kpi-setting-button"
            mat-mini-fab
            color="primary"
            type="button"
            (click)="onResetKpiSettings()"
            matTooltip="Reset KPI settings"
            matTooltipPosition="below"
          >
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </md-input-container>
    </div>
    <div class="col">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Function</mat-label>
        <mat-select
          formControlName="function"
          (selectionChange)="onKpiFunctionsChange($event)"
        >
          <mat-option
            *ngFor="let function of kpiFunctions"
            [value]="function"
            >{{ function }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Report chunk size</mat-label>
        <input
          type="number"
          matInput
          placeholder="Report chunk size"
          formControlName="report_chunk_size"
        />
        <mat-error
          *ngIf="
            createTestRunConfigurationForm.controls.report_chunk_size.hasError(
              'min'
            )
          "
        >
          Should be more then <strong>1</strong>
        </mat-error>
        <mat-error
          *ngIf="
            createTestRunConfigurationForm.controls.report_chunk_size.hasError(
              'max'
            )
          "
        >
          Should be less then <strong>5000</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Stream</mat-label>
        <mat-select
          formControlName="matching_stream"
          (selectionChange)="onKpiStreamSelectionChange($event)"
        >
          <mat-option *ngFor="let stream of kpiStreams" [value]="stream">{{
            stream
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Ground Truth Settings</mat-label>
        <mat-select
          formControlName="gt_selection"
          (selectionChange)="onKpiGtSelectionChange($event)"
        >
          <mat-option
            *ngFor="let gtSelection of kpiGtSelections"
            [value]="gtSelection"
            >{{ gtSelection }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Cluster</mat-label>
        <mat-select
          formControlName="cluster"
          (selectionChange)="onKpiClusterSelectionChange($event)"
        >
          <mat-option *ngFor="let cluster of kpiClusters" [value]="cluster">{{
            cluster
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Report merge variant</mat-label>
        <mat-select formControlName="report_merge_variant">
          <mat-option
            *ngFor="let report_merge_variant of report_merge_variants"
            [value]="report_merge_variant"
            >{{ report_merge_variant }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-checkbox
        class="full-width"
        id="enable_pcp_postprocessing"
        formControlName="enable_pcp_postprocessing"
        >Post-processing</mat-checkbox
      >
      <mat-checkbox
        class="full-width"
        id="comparison_report"
        formControlName="comparison_report"
        >Comparison report</mat-checkbox
      >
      <mat-checkbox
        class="full-width"
        id="matching_enabled"
        formControlName="matching_enabled"
        (change)="onKpiMatchingChange($event)"
        [disabled]="!isOdKpiProjectSelected"
        >KPI Matching</mat-checkbox
      >
    </div>
  </div>

  <div class="row">
    <div class="col">
      <input
        type="file"
        accept=".json"
        class="file-input"
        (change)="onAttachFile($event)"
        #fileUploadInput
      />
      <div class="file-upload">
        <button
          mat-mini-fab
          color="primary"
          (click)="onAttachFileButtonClick($event)"
          matTooltip="Upload matching configuration .json file"
          matTooltipPosition="below"
          [disabled]="!isAttachFileButtonsEnabled"
        >
          <mat-icon>attach_file</mat-icon>
        </button>

        <button
          class="delete-button"
          mat-mini-fab
          color="primary"
          type="button"
          matTooltip="Remove existing file"
          matTooltipPosition="below"
          (click)="onDeleteAttachedFile()"
          [disabled]="!isAttachFileButtonsEnabled"
        >
          <mat-icon>delete</mat-icon>
        </button>

        <span class="file-name">{{ fileName || 'No file uploaded yet' }}</span>
      </div>
      <div
        *ngIf="Object.keys(parsedMatchingGenerator).length"
        class="file-name"
        [innerHTML]="parsedMatchingGenerator | prettyJson"
      ></div>
    </div>
  </div>

  <mat-error>
    <span *ngIf="createTestRunConfigurationForm.errors?.functionIsMandatory">
      Function is mandatory field for any selected KPI project name
    </span>
  </mat-error>

  <mat-error>
    <span *ngIf="createTestRunConfigurationForm.errors?.testSuiteIsMandatory">
      Test suite is mandatory for any selected KPI project name
    </span>
  </mat-error>

  <app-test-suites-table
    *ngIf="selectedKpiProject && selectedKpiProject.id"
    [selectedKpiProjectId]="selectedKpiProject.id"
    (kpiTestSuiteSelected)="onKpiTestSuiteSelected($event)"
  ></app-test-suites-table>

  <mat-card-actions class="form-buttons-group">
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!createTestRunConfigurationForm.valid"
    >
      Submit
    </button>
  </mat-card-actions>
</form>
