import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreakpointGridColumnsService {
  cols$: Observable<number>;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.cols$ = breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(map((result) => this.getCols(result)));
  }

  getCols(result: BreakpointState): number {
    let cols: number = 8;

    if (result.matches) {
      if (result.breakpoints[Breakpoints.XSmall]) {
        cols = 1;
      }

      if (result.breakpoints[Breakpoints.Small]) {
        cols = 3;
      }

      if (result.breakpoints[Breakpoints.Medium]) {
        cols = 4;
      }

      if (result.breakpoints[Breakpoints.Large]) {
        cols = 6;
      }

      if (result.breakpoints[Breakpoints.XLarge]) {
        cols = 8;
      }
    }

    return cols;
  }
}
