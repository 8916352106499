<div fxLayout="row" fxLayoutAlign="end start">
  <mat-card>
    <mat-card-content>
      <a
        mat-raised-button
        color="primary"
        matTooltip="Download report"
        matTooltipPosition="below"
        [href]="reportLink"
        [disabled]="!reportLink"
        ><mat-icon>file_download</mat-icon></a
      >
    </mat-card-content>
  </mat-card>
</div>

<mat-card>
  <mat-card-header>
    <mat-card-title>L0 events</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <app-table
      [displayedColumns]="eventsTableColumns"
      [dataSource]="filteredEventsTableDataSource"
      [isSelectionButtonVisible]="false"
      [showItemHover]="false"
      [enableDetails]="false"
      [filterPlaceholder]="'LengthWithoutGPS'"
    ></app-table>
    <div class="l0-middle-table-container">
      <mat-card-header>
        <mat-card-title>Missing events</mat-card-title>
      </mat-card-header>
      <app-table
        [displayedColumns]="missingEventsTableColumns"
        [dataSource]="filteredMissingEventsTableDataSource"
        [isSelectionButtonVisible]="false"
        [showItemHover]="false"
        [enableDetails]="false"
        [filterPlaceholder]="'LocationFloor'"
      ></app-table>
    </div>
    <div class="l0-middle-table-container">
      <mat-card-header>
        <mat-card-title>Scenario timestamp validation</mat-card-title>
      </mat-card-header>
      <app-table
        [displayedColumns]="scenarioTimestampValidationTableColumns"
        [dataSource]="filteredScenarioTimestampValidationTableDataSource"
        [isSelectionButtonVisible]="false"
        [showItemHover]="false"
        [enableDetails]="false"
        [filterPlaceholder]="'LocationFloor'"
      ></app-table>
    </div>
  </mat-card-content>
</mat-card>
