<div fxLayout="row" fxLayoutAlign="space-around start">
  <div fxFlex="30" class="approval-clips-table-container">
    <app-drive-ids-list
      [title]="'Approval clips'"
      [isApproval]="'true'"
      [approvalClipId]="null"
      (driveIdsTableItemSelected)="onDriveIdsTableItemSelected($event)"
      (driveIdsTableReloaded)="onApprovalClipsTableReloaded()"
    ></app-drive-ids-list>
  </div>

  <div fxFlex="70" fxLayout="column" fxLayoutAlign="space-between start">
    <mat-card>
      <mat-card-content>
        <mat-tab-group (selectedTabChange)="onSelectedTabChange($event)">
          <mat-tab label="Approval">
            <div fxLayout="row wrap" fxLayoutAlign="start start">
              <mat-card
                class="approvals-card"
                *ngFor="
                  let approval of approvalsListPayloadForApprovalClip.variables
                    .approvals;
                  let i = index
                "
              >
                <mat-card-header>
                  <mat-card-title>{{ approvalsNames[i] }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <mat-form-field appearance="fill">
                    <mat-label>Approval</mat-label>
                    <div fxLayout="row" fxLayoutAlign="center center">
                      <ng-container [ngSwitch]="approval.approval">
                        <span
                          fxLayout="row"
                          fxLayoutAlign="center center"
                          *ngSwitchCase="
                            vinsApprovalsConstant.statuses.approved.value
                          "
                        >
                          <mat-icon class="green-icon approval-icon"
                            >check_circle</mat-icon
                          >
                        </span>
                        <span
                          fxLayout="row"
                          fxLayoutAlign="center center"
                          *ngSwitchCase="
                            vinsApprovalsConstant.statuses.rejected.value
                          "
                        >
                          <mat-icon class="red-icon approval-icon"
                            >cancel</mat-icon
                          >
                        </span>
                        <span
                          fxLayout="row"
                          fxLayoutAlign="center center"
                          *ngSwitchCase="
                            vinsApprovalsConstant.statuses.notSet.value
                          "
                          ><mat-icon class="approval-icon"
                            >radio_button_unchecked</mat-icon
                          ></span
                        >
                        <span
                          fxLayout="row"
                          fxLayoutAlign="center center"
                          *ngSwitchDefault
                        >
                          <mat-icon class="approval-icon"
                            >radio_button_unchecked</mat-icon
                          >
                        </span>
                      </ng-container>
                      <mat-select [(value)]="approval.approval">
                        <mat-option
                          *ngFor="let status of approvalStatuses"
                          [value]="status"
                          >{{ status }}
                        </mat-option>
                      </mat-select>
                    </div>
                  </mat-form-field>
                  <mat-form-field appearance="fill" class="full-width">
                    <mat-label>Comment</mat-label>
                    <textarea
                      matNativeControl
                      [(ngModel)]="approval.comment"
                      cdkTextareaAutosize
                      [cdkAutosizeMinRows]="5"
                      [cdkAutosizeMaxRows]="5"
                    ></textarea>
                  </mat-form-field>
                </mat-card-content>
              </mat-card>
            </div>

            <mat-card>
              <mat-card-actions>
                <button
                  mat-raised-button
                  color="primary"
                  type="button"
                  (click)="onApprovalClipApprovalsSubmit()"
                  [disabled]="!approvalDriveIdSelected.length"
                >
                  Submit
                </button>
              </mat-card-actions>
            </mat-card>

            <app-approval-validation
              [driveId]="approvalDriveIdSelected"
              [type]="approvalDriveIdTypeSelected"
            ></app-approval-validation>
          </mat-tab>
          <mat-tab label="General">
            <app-g-validation
              [driveId]="approvalDriveIdSelected"
            ></app-g-validation>
          </mat-tab>
          <mat-tab label="L0">
            <app-l0-validation
              [driveId]="approvalDriveIdSelected"
            ></app-l0-validation>
          </mat-tab>
          <mat-tab label="L1">
            <app-l-validation
              [validationLevel]="'L1'"
              [driveId]="approvalDriveIdSelected"
            ></app-l-validation>
          </mat-tab>
          <mat-tab label="L2">
            <app-l-validation
              [validationLevel]="'L2'"
              [driveId]="approvalDriveIdSelected"
            ></app-l-validation>
          </mat-tab>
          <mat-tab label="L2 ME">
            <app-l-validation
              [validationLevel]="'L2ME'"
              [driveId]="approvalDriveIdSelected"
            ></app-l-validation>
          </mat-tab>
          <mat-tab label="Normal Drive IDs">
            <div fxLayout="row" fxLayoutAlign="start start">
              <div fxFlex="50">
                <app-drive-ids-list
                  [isApproval]="'false'"
                  [approvalClipId]="approvalClipIdSelected"
                  (driveIdsTableItemSelected)="
                    onNormalDriveIdsTableItemSelected($event)
                  "
                  (driveIdsTableReloaded)="onNormalDriveIdsTableReloaded()"
                ></app-drive-ids-list>
              </div>
              <mat-card fxFlex="50">
                <mat-card-content>
                  <div fxLayout="row wrap" fxLayoutAlign="start start">
                    <div
                      class="approvals-card"
                      *ngFor="
                        let approval of approvalsListPayloadForNormalDriveId
                          .variables.approvals;
                        let i = index
                      "
                    >
                      <mat-card-header>
                        <mat-card-title>{{ approvalsNames[i] }}</mat-card-title>
                      </mat-card-header>
                      <mat-card-content>
                        <mat-form-field appearance="fill">
                          <mat-label>Approval</mat-label>
                          <div fxLayout="row" fxLayoutAlign="center center">
                            <ng-container [ngSwitch]="approval.approval">
                              <span
                                fxLayout="row"
                                fxLayoutAlign="center center"
                                *ngSwitchCase="
                                  vinsApprovalsConstant.statuses.approved.value
                                "
                              >
                                <mat-icon class="green-icon approval-icon"
                                  >check_circle</mat-icon
                                >
                              </span>
                              <span
                                fxLayout="row"
                                fxLayoutAlign="center center"
                                *ngSwitchCase="
                                  vinsApprovalsConstant.statuses.rejected.value
                                "
                              >
                                <mat-icon class="red-icon approval-icon"
                                  >cancel</mat-icon
                                >
                              </span>
                              <span
                                fxLayout="row"
                                fxLayoutAlign="center center"
                                *ngSwitchCase="
                                  vinsApprovalsConstant.statuses.notSet.value
                                "
                                ><mat-icon class="approval-icon"
                                  >radio_button_unchecked</mat-icon
                                ></span
                              >
                              <span
                                fxLayout="row"
                                fxLayoutAlign="center center"
                                *ngSwitchDefault
                              >
                                <mat-icon class="approval-icon"
                                  >radio_button_unchecked</mat-icon
                                >
                              </span>
                            </ng-container>
                            <mat-select [(value)]="approval.approval">
                              <mat-option
                                *ngFor="let status of approvalStatuses"
                                [value]="status"
                                >{{ status }}
                              </mat-option>
                            </mat-select>
                          </div>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="full-width">
                          <mat-label>Comment</mat-label>
                          <textarea
                            matNativeControl
                            [(ngModel)]="approval.comment"
                            cdkTextareaAutosize
                            [cdkAutosizeMinRows]="5"
                            [cdkAutosizeMaxRows]="5"
                          ></textarea>
                        </mat-form-field>
                      </mat-card-content>
                    </div>
                  </div>
                </mat-card-content>
                <mat-card-actions>
                  <button
                    mat-raised-button
                    color="primary"
                    type="button"
                    (click)="onNormalDriveIdApprovalsSubmit()"
                    [disabled]="!normalDriveIdSelected.length"
                  >
                    Submit
                  </button>
                </mat-card-actions>
              </mat-card>
            </div>
          </mat-tab>
          <mat-tab label="Upload normal to approval data mapping">
            <app-approval-clips-uploader></app-approval-clips-uploader>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </mat-card>

    <mat-card
      class="full-width-card"
      *ngIf="normalDriveIdSelected && selectedTabIndex === 6"
    >
      <mat-card-content>
        <mat-tab-group>
          <mat-tab label="Verdict">
            <app-approval-validation
              [driveId]="normalDriveIdSelected"
              [type]="normalDriveIdTypeSelected"
            ></app-approval-validation>
          </mat-tab>
          <mat-tab label="General">
            <app-g-validation
              [driveId]="normalDriveIdSelected"
            ></app-g-validation>
          </mat-tab>
          <mat-tab label="L0">
            <app-l0-validation
              [driveId]="normalDriveIdSelected"
            ></app-l0-validation>
          </mat-tab>
          <mat-tab label="L1">
            <app-l-validation
              [validationLevel]="'L1'"
              [driveId]="normalDriveIdSelected"
            ></app-l-validation>
          </mat-tab>
          <mat-tab label="L2">
            <app-l-validation
              [validationLevel]="'L2'"
              [driveId]="normalDriveIdSelected"
            ></app-l-validation>
          </mat-tab>
          <mat-tab label="L2 ME">
            <app-l-validation
              [validationLevel]="'L2ME'"
              [driveId]="normalDriveIdSelected"
            ></app-l-validation>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </mat-card>
  </div>
</div>
