import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDataBrowser from './data-browser.reducer';

export const selectDataBrowserState =
  createFeatureSelector<fromDataBrowser.State>(
    fromDataBrowser.dataBrowserFeatureKey
  );

export const selectFeatureDataBrowserData = createSelector(
  selectDataBrowserState,
  (state: fromDataBrowser.State) => state.dataBrowser
);

export const selectFeatureDrivingSession = createSelector(
  selectDataBrowserState,
  (state: fromDataBrowser.State) => state.drivingSession
);

export const selectFeatureDrivingSessionS3Structure = createSelector(
  selectDataBrowserState,
  (state: fromDataBrowser.State) => state.drivingSessionS3Structure
);
