<mat-card>
  <mat-card-content>
    <input
      type="file"
      accept=".csv"
      class="file-input"
      (change)="onAttachFile($event)"
      #fileUploadInput
    />

    <div class="file-upload">
      <button
        mat-mini-fab
        color="primary"
        (click)="fileUploadInput.click()"
        matTooltip="Click to upload .csv file"
        matTooltipPosition="below"
      >
        <mat-icon>attach_file</mat-icon>
      </button>

      <button
        mat-mini-fab
        color="primary"
        type="button"
        matTooltip="Click to remove existing file before upload new one"
        matTooltipPosition="below"
        (click)="onResetFileData()"
      >
        <mat-icon>delete</mat-icon>
      </button>

      <span class="file-name">{{ fileName || 'No file uploaded yet' }}</span>
    </div>

    <div
      *ngIf="attachFileSuccessMessage"
      class="mat-card-actions-result-message"
    >
      <strong> {{ attachFileSuccessMessage }}</strong>
    </div>

    <mat-error
      *ngIf="attachFileErrorMessage"
      class="mat-card-actions-error-message"
      ><strong>{{ attachFileErrorMessage }}</strong></mat-error
    >

    <mat-card>
      <mat-card-header>
        <mat-card-title>Parsed .csv data</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <app-table
          [displayedColumns]="parsedSheetTableColumns"
          [dataSource]="filteredParsedSheetTableDataSource"
          [filterPlaceholder]="'BT80206_20191214_221412'"
          [multipleSelections]="false"
          [isSelectionButtonVisible]="false"
          [enableDetails]="false"
        ></app-table>
      </mat-card-content>
      <mat-card-actions>
        <button
          [disabled]="!fileName.length || !attachFileSuccessMessage.length"
          (click)="onSubmit()"
          mat-raised-button
          color="primary"
          type="button"
        >
          Submit
        </button>
      </mat-card-actions>
    </mat-card>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="dataCollectionApprovalClipsLinkageResponse">
  <mat-card-header>
    <mat-card-title>Submitting result</mat-card-title>
    <mat-card-subtitle
      >Clips not found:
      {{
        dataCollectionApprovalClipsLinkageResponse['clips-not-found'] | json
      }}</mat-card-subtitle
    >
    <mat-card-subtitle
      >Not approval clips:
      {{
        dataCollectionApprovalClipsLinkageResponse['not-approval-clip'] | json
      }}</mat-card-subtitle
    >
    <mat-card-subtitle
      >Not data collection clip:
      {{
        dataCollectionApprovalClipsLinkageResponse['not-datacollection-clip']
          | json
      }}</mat-card-subtitle
    >
  </mat-card-header>
</mat-card>
