import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const isJsonValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  let isJsonValidator: { isJson: boolean } | null = null;

  if (control.value) {
    try {
      JSON.parse(control.value);
      isJsonValidator = null;
    } catch (e) {
      isJsonValidator = { isJson: true };
    }
  }

  return isJsonValidator;
};
