import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SnackBarConstant } from '@constants/snack-bar.constant';
import { UiMessagesConstant } from '@constants/ui-messages.constant';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';
import {
  GetDrivingSessionNewQuery,
  GetMfk120AggregatedValidation,
} from '@core/amplify/aws-apmlify-query.constant';
import { S3Service } from '@core/services/s3.service';
import { SnackBarService } from '@core/services/snack-bar.service';
import { TmaUtilsStaticService } from '@core/services/tma-utils-static.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-g-validation',
  templateUrl: './g-validation.component.html',
  styleUrls: ['./g-validation.component.scss'],
})
export class GValidationComponent implements OnChanges {
  @Input() driveId: string;

  unsorted = TmaUtilsStaticService.unsortedKeyValuePipeCompareFn;

  reportLink: string | null = null;

  drivingSessionForMFK120: DrivingSessionForMFK120 | null = null;

  mandatoryEventValidation: MandatoryEventValidation | null = null;

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.driveId.currentValue.length &&
      changes.driveId.previousValue !== changes.driveId.currentValue
    ) {
      this.awsAmplifyApiService
        .graphQLQuery({
          query: GetDrivingSessionNewQuery,
          variables: {
            drive_id: changes.driveId.currentValue,
            verbose: true,
          },
        })
        .subscribe(
          (driveSession) => {
            try {
              const parsedDriveSession = JSON.parse(
                driveSession.data.getDrivingSessionNew
              );

              this.drivingSessionForMFK120 = {
                a_step: parsedDriveSession['a-step'] || '',
                campaign: parsedDriveSession['campaign'] || '',
                country: parsedDriveSession['country'] || '',
                drive_id: parsedDriveSession['drive-id'] || '',
                level_0_validation_score:
                  parsedDriveSession['level-0-validation-score'] || '',
                s3_source_bucket: parsedDriveSession['s3-source-bucket'] || '',
                s3_source_prefix: parsedDriveSession['s3-source-prefix'] || '',
                start_upload_timestamp:
                  parsedDriveSession['start-upload-timestamp'] || '',
              };
            } catch (e) {
              this.snackBarService.open(
                `DriveSession JSON parsing error: ${e}`,
                SnackBarConstant.actions.dismiss,
                3000
              );
            }
          },
          (error) => {
            this.snackBarService.showGraphQlErrorMessage(error);
          }
        );

      this.fetchValidationResult(
        changes.driveId.currentValue,
        'General'
      ).subscribe(
        (validationResult) => {
          try {
            const validationData: ValidationResultData = JSON.parse(
              validationResult.data.getMfk120AggregatedValidation
            );

            this.mandatoryEventValidation = {
              drive_id: changes.driveId.currentValue,
              distinct_critical_mandatory_event_category_count:
                validationData?.distinct_critical_mandatory_event_category_count,
              missing_critical: validationData?.missing_critical,
              missing_mandatory_event_category_count:
                validationData?.missing_mandatory_event_category_count,
            };

            this.s3Service
              .downloadS3File(
                validationData?.report?.s3_bucket,
                validationData?.report?.s3_key
              )
              .then((link) => {
                this.reportLink = link;
              });
          } catch (e) {
            this.snackBarService.open(
              `General ${UiMessagesConstant.mfk120ValidationJSONParsingError}: ${e}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
          }
        },
        (error) => {
          this.snackBarService.showGraphQlErrorMessage(error);
        }
      );
    } else if (!changes.driveId.currentValue.length) {
      this.resetData();
    }
  }

  private fetchValidationResult(
    drive_id: string,
    validation_id: string
  ): Observable<any> {
    return this.awsAmplifyApiService.graphQLQuery({
      query: GetMfk120AggregatedValidation,
      variables: {
        drive_id,
        validation_id,
      },
    });
  }

  private resetData(): void {
    this.reportLink = '';
    this.drivingSessionForMFK120 = null;
    this.mandatoryEventValidation = null;
  }

  constructor(
    private s3Service: S3Service,
    private awsAmplifyApiService: AwsAmplifyApiService,
    private snackBarService: SnackBarService
  ) {}
}
