<mat-card>
  <mat-card-content>
    <div fxLayout="column" fxLayoutAlign="start start">
      <mat-radio-group fxLayoutGap [value]="currentProjectName">
        <mat-radio-button
          *ngFor="let projectsName of projectsNames"
          [value]="projectsName"
          (change)="onProjectNameChange($event.value)"
        >
          {{ projectsName }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-accordion class="tc-accordion">
      <mat-expansion-panel class="tc-accordion_tc-triggering-panel">
        <mat-expansion-panel-header>
          <mat-panel-title>Transcoding triggering</mat-panel-title>
        </mat-expansion-panel-header>

        <app-transcoding-triggering-new
          [currentProjectName]="currentProjectName"
        ></app-transcoding-triggering-new>
      </mat-expansion-panel>

      <mat-expansion-panel
        class="tc-accordion_tc-monitoring-panel"
        expanded="true"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>Transcoding sessions execution</mat-panel-title>
        </mat-expansion-panel-header>

        <div
          class="tc-accordion_tc-monitoring-panel_tc-date-picker"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <mat-form-field appearance="fill">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input
              [formGroup]="tcSessionsRangeFormGroup"
              [rangePicker]="picker"
            >
              <input
                matStartDate
                formControlName="start"
                placeholder="Start date"
              />
              <input matEndDate formControlName="end" placeholder="End date" />
            </mat-date-range-input>
            <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error
              *ngIf="
                tcSessionsRangeFormGroup.controls.start.hasError(
                  'matStartDateInvalid'
                )
              "
              >Invalid start date</mat-error
            >
            <mat-error
              *ngIf="
                tcSessionsRangeFormGroup.controls.end.hasError(
                  'matEndDateInvalid'
                )
              "
              >Invalid end date</mat-error
            >
          </mat-form-field>

          <mat-card-actions
            class="tc-accordion_tc-panel_tc-actions"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <button
              class="tc-accordion_tc-panel_tc-actions_submit-button"
              mat-raised-button
              color="primary"
              type="button"
              (click)="onSubmitTranscodingSessionsByDates()"
              [disabled]="
                tcSessionsRangeFormGroup.invalid || tcSessionsTableSpinner
              "
            >
              Submit
            </button>
          </mat-card-actions>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between start">
          <div fxFlex="70">
            <app-table
              [displayedColumns]="tcSessionsColumns"
              [dataSource]="filteredTcSessionsDataSource"
              (tableItemSelected)="onTcSessionSelected($event)"
              [selection]="tcSessionsTableSelection"
              [spinner]="tcSessionsTableSpinner"
              [multipleSelections]="false"
              [showFilter]="false"
              [isSortDisabled]="true"
            ></app-table>
          </div>
          <div fxFlex="30" class="progress-container">
            <div class="progress">
              <mat-card-subtitle
                >Transcoding progress
                {{ transcodingSuccessPercent }} %</mat-card-subtitle
              >
              <mat-progress-bar
                color="primary"
                mode="determinate"
                [value]="transcodingSuccessPercent!"
              ></mat-progress-bar>
            </div>

            <div class="progress">
              <mat-card-subtitle
                >Matching progress
                {{ matchingSuccessPercent }} %</mat-card-subtitle
              >
              <mat-progress-bar
                color="primary"
                mode="determinate"
                [value]="matchingSuccessPercent!"
              ></mat-progress-bar>
            </div>
          </div>
        </div>

        <mat-card-actions fxLayout="row" fxLayoutAlign="start center">
          <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="onSubmitTranscodingSessionsByDates()"
            matTooltip="Refresh table data"
            matTooltipPosition="below"
            [disabled]="tcSessionsTableSpinner"
          >
            <mat-icon>refresh</mat-icon>
          </button>
          <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="onLoadMoreTcSessions()"
            matTooltip="Load more items"
            matTooltipPosition="below"
            [disabled]="tcSessionsTableSpinner"
          >
            <mat-icon>system_update_alt</mat-icon>
          </button>
        </mat-card-actions>

        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-card class="filters-card">
            <mat-card-header>
              <mat-card-subtitle>Filters</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <mat-button-toggle-group
                (change)="onFilterValueChange($event)"
                [disabled]="!tcSessionSelected['tc-session-id']"
                name="filters"
                aria-label="Filters"
              >
                <mat-button-toggle value="all">All</mat-button-toggle>
                <mat-button-toggle value="transcoding"
                  >Transcoding failed</mat-button-toggle
                >
                <mat-button-toggle value="matching"
                  >Matching failed</mat-button-toggle
                >
              </mat-button-toggle-group>
            </mat-card-content>
          </mat-card>
        </div>

        <app-table
          [displayedColumns]="tcSessionExecutionsColumns"
          [dataSource]="filteredTcSessionExecutionsDataSource"
          (tableItemSelected)="onTcSessionExecutionsSelected($event)"
          [selection]="tcSessionExecutionsTableSelection"
          [spinner]="tcSessionExecutionsTableSpinner"
          [multipleSelections]="false"
          [showFilter]="false"
          [isSortDisabled]="true"
        ></app-table>

        <mat-card-actions fxLayout="row" fxLayoutAlign="start center">
          <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="
              succeeded === null
                ? onLoadMoreTcSessionExecutions()
                : onLoadMoreTcSessionExecutionsFilteredByTcStatus()
            "
            matTooltip="Load more items"
            matTooltipPosition="below"
            [disabled]="
              tcSessionExecutionsTableSpinner ||
              !tcSessionSelected['tc-session-id']
            "
          >
            <mat-icon>system_update_alt</mat-icon>
          </button>

          <a
            [disabled]="!tcSessionSelected['tc-session-id']"
            mat-raised-button
            color="primary"
            matTooltip="Export table to .xlsx sheet"
            matTooltipPosition="below"
            (click)="exportTableToXlsx()"
            ><mat-icon>file_download</mat-icon></a
          >

          <a
            [href]="tcSessionExecutionSelected['matching_log_group']"
            [disabled]="!tcSessionExecutionSelected['matching_log_group']"
            mat-raised-button
            color="primary"
            matTooltip="Open matching log group"
            matTooltipPosition="below"
            target="_blank"
            ><mat-icon>link</mat-icon></a
          >
        </mat-card-actions>

        <app-table
          [displayedColumns]="tcSessionKpiColumns"
          [dataSource]="filteredTcSessionKpiDataSource"
          (tableItemSelected)="onKpiJobExecutionsItemSelected($event)"
          [selection]="tcSessionKpiTableSelection"
          [spinner]="tcSessionKpiTableSpinner"
          [multipleSelections]="false"
          [showFilter]="false"
          [isSortDisabled]="true"
        ></app-table>

        <mat-card
          *ngFor="let kpiJobExecutionReport of kpiJobExecutionReports"
          class="kpi-report-links-card"
        >
          <mat-card-header>
            <mat-card-subtitle
              >Report ID: {{ kpiJobExecutionReport.id }}</mat-card-subtitle
            >
          </mat-card-header>
          <mat-button-toggle-group>
            <mat-button-toggle
              *ngIf="kpiJobExecutionReport.download_link_report.length"
              (click)="reportLink.click()"
            >
              <mat-icon>download_for_offline</mat-icon>
              <a #reportLink [href]="kpiJobExecutionReport.download_link_report"
                >Report</a
              >
            </mat-button-toggle>
            <mat-button-toggle
              *ngIf="kpiJobExecutionReport.download_link_meta.length"
              (click)="metaLink.click()"
            >
              <mat-icon>download_for_offline</mat-icon>
              <a #metaLink [href]="kpiJobExecutionReport.download_link_meta"
                >Meta</a
              >
            </mat-button-toggle>
            <mat-button-toggle
              *ngIf="kpiJobExecutionReport.download_link_data.length"
              (click)="dataLink.click()"
            >
              <mat-icon>download_for_offline</mat-icon>
              <a #dataLink [href]="kpiJobExecutionReport.download_link_data"
                >Raw Data</a
              >
            </mat-button-toggle>
          </mat-button-toggle-group>
        </mat-card>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card-content>
</mat-card>
