<mat-card>
  <mat-card-header>
    <div fxLayout="column" fxLayoutAlign="space-between start">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button mat-icon-button (click)="goPlaylists($event)">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <mat-card-title>Playlist</mat-card-title>
      </div>

      <div
        *ngIf="(playlist$ | async)?.playlist_id"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <mat-card-subtitle>{{
          (playlist$ | async)?.playlist_id
        }}</mat-card-subtitle>
        <button mat-icon-button>
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>

  <mat-card-content>
    <mat-form-field appearance="fill">
      <mat-label>Drive ID</mat-label>
      <input
        matInput
        (keyup.enter)="onSearchByDriveId($event)"
        [placeholder]="'Ex.LJ02766_20220926_140233'"
        matTooltip="Input Drive ID and click 'Enter'"
        matTooltipPosition="below"
      />
    </mat-form-field>

    <mat-card-actions fxLayout="row" fxLayoutAlign="start center">
      <button
        mat-raised-button
        color="primary"
        type="button"
        (click)="reloadTable()"
        matTooltip="Refresh table data"
        matTooltipPosition="below"
        [disabled]="spinner"
      >
        <mat-icon>refresh</mat-icon>
      </button>

      <button
        mat-raised-button
        color="primary"
        type="button"
        (click)="queryMore()"
        matTooltip="Load more items"
        matTooltipPosition="below"
        [disabled]="spinner"
      >
        <mat-icon>system_update_alt</mat-icon>
      </button>
    </mat-card-actions>

    <mat-table class="mat-elevation-z8" [dataSource]="dataSource">
      <ng-container matColumnDef="drive_id">
        <mat-header-cell *matHeaderCellDef>Drive ID</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.drive_id }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="splits">
        <mat-header-cell *matHeaderCellDef>Split Ranges</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.splits | json }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="step">
        <mat-header-cell *matHeaderCellDef>Step</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.step }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="validation_verdict">
        <mat-header-cell *matHeaderCellDef>Validation Verdict</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.validation_verdict }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"> </mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <mat-paginator
      [pageSizeOptions]="[5, 10, 25, 100]"
      [showFirstLastButtons]="true"
    ></mat-paginator>
  </mat-card-content>
</mat-card>
