<form [formGroup]="formGroup" novalidate (ngSubmit)="onSubmit()">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{ formTitle }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <ng-container *ngFor="let control of formControlsMetadata | keyvalue">
        <ng-container [ngSwitch]="control.value.inputType">
          <mat-form-field
            appearance="fill"
            *ngSwitchCase="'textarea'"
            class="full-width"
          >
            <mat-label>{{ control.value.placeHolder }}</mat-label>
            <textarea
              matInput
              [name]="control.value.formControlName"
              [placeholder]="control.value.placeHolder"
              [formControlName]="control.value.formControlName"
              [matTooltip]="control.value.options.tooltip"
              cdkTextareaAutosize
              [cdkAutosizeMinRows]="cdkAutosizeMinRows"
              [cdkAutosizeMaxRows]="cdkAutosizeMaxRows"
            ></textarea>
            <mat-error
              *ngIf="
                formGroup.controls[control.value.formControlName].hasError(
                  'required'
                )
              "
              >{{ control.value.placeHolder }} is
              <strong>required</strong>
            </mat-error>
            <mat-error
              *ngIf="
                formGroup.controls[control.value.formControlName].hasError(
                  'isJson'
                )
              "
              >JSON format is
              <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </ng-container>
      </ng-container>
    </mat-card-content>
    <mat-card-actions
      *ngIf="isFormActionsVisible"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="!formGroup.valid"
      >
        Submit
      </button>
    </mat-card-actions>
  </mat-card>
</form>
