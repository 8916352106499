import { Injectable } from '@angular/core';
import { SnackBarConstant } from '@constants/snack-bar.constant';
import { UiMessagesConstant } from '@constants/ui-messages.constant';
import { SnackBarService } from '@core/services/snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class TmaUtilsService {
  parseDDbResolverResponse(response: any, dataKey: string): any[] {
    let parsedList = [];

    try {
      parsedList = JSON.parse(response.data[dataKey].items);
    } catch (e) {
      this.snackBarService.open(
        `${
          UiMessagesConstant.appsyncResponseParsingErrorMessage + dataKey
        }: ${e}`,
        SnackBarConstant.actions.dismiss,
        3000
      );
    }

    return parsedList;
  }

  constructor(private snackBarService: SnackBarService) {}
}
