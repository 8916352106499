import { Component, OnInit } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { ProjectsEnvironmentsConstant } from '@constants/projects-environments.constant';
import { TabsRoutesConstant } from '@constants/tabs-routes.constant';
import { MsalUserAgentService } from '@core/services/msal-user-agent.service';
import { environment } from '@environments/environment';
import { Auth, Hub } from 'aws-amplify';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  environment = environment;

  tabsRoutesConstant = TabsRoutesConstant;

  ngOnInit() {
    if (environment.AWS_REGION === ProjectsEnvironmentsConstant.eu.region) {
      Hub.listen('auth', ({ payload }) => {
        const { event } = payload;

        if (event === 'signIn' || event === 'oAuthSignIn') {
          this.router.navigate(['/dmr-home']).then(() => {});
        }
      });

      setInterval(() => {
        Auth.currentAuthenticatedUser().then((cognitoUser) => {
          if (cognitoUser) {
            Auth.userSession(cognitoUser).then((session) => {
              if (!session.isValid()) {
                Auth.federatedSignIn().then(() => {});
              }
            });
          }
        });
      }, 30 * 60000);
    } else {
      this.router.navigate(['/dmr-home']).then(() => {});
    }
  }

  async signOut(drawer: MatDrawer): Promise<void> {
    await drawer.close();
    await this.router.navigate(['/']);

    if (environment.AWS_REGION === ProjectsEnvironmentsConstant.eu.region) {
      await Auth.signOut();
    } else {
      const msalInstance = this.msalUserAgentService.getInstance();

      return msalInstance.logout();
    }
  }

  constructor(
    private router: Router,
    private msalUserAgentService: MsalUserAgentService
  ) {}

  protected readonly Auth = Auth;
}
