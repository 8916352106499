<form
  [formGroup]="transcodingTriggeringForm"
  novalidate
  (ngSubmit)="onSubmit()"
>
  <mat-card class="transcoding-triggering-card">
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Transcoder type</mat-label>
            <mat-select
              formControlName="TranscoderType"
              (selectionChange)="onTranscoderTypeChange($event)"
            >
              <mat-option
                *ngFor="let type of transcodersTypes"
                [value]="type"
                >{{ type }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Transcoding source type</mat-label>
            <mat-select
              formControlName="TranscodingSourceType"
              (selectionChange)="transcodingSourceTypeSelectionChange($event)"
            >
              <mat-option
                *ngFor="let type of transcodingSourceTypes"
                [value]="type"
                >{{ type }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Streams selection type</mat-label>
            <mat-select formControlName="StreamsSelectionType">
              <mat-option
                *ngFor="let type of tcSteamsSelectionTypes"
                [value]="type"
                >{{ type }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Label</mat-label>
            <input matInput placeholder="Label" formControlName="LabelText" />
            <mat-error
              *ngIf="
                transcodingTriggeringForm.controls.LabelText.hasError(
                  'required'
                )
              "
            >
              Field is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Bordnet version</mat-label>
            <mat-select
              formControlName="bordnet_version"
              (selectionChange)="onBordnetVersionChange($event)"
            >
              <mat-option *ngFor="let type of bordnetVersions" [value]="type">{{
                type
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Fibex file name</mat-label>
            <mat-select formControlName="fibex_xml_filename">
              <mat-option *ngFor="let type of fibexFileNames" [value]="type">{{
                type
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-checkbox
            class="full-width"
            id="ForceTranscoding"
            formControlName="ForceTranscoding"
            >Force transcoding</mat-checkbox
          >
        </div>
      </div>
      <app-table
        class="trc-accordion_trc-panel_transc-streams-table"
        *ngIf="isStreamsTableVisible"
        [displayedColumns]="transcoderStreamsSelectionColumns"
        [dataSource]="transcoderStreamsSelectionDataSource"
        (tableItemSelected)="onTranscoderStreamsSelected($event)"
        [filterPlaceholder]="'VBS00'"
        [isAsyncDataSource]="false"
      ></app-table>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>{{ sourcesFormControlTitle }}</mat-label>
            <textarea
              matInput
              [name]="sourcesFormControlTitle"
              [placeholder]="sourcesFormControlTitle"
              formControlName="Sources"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="5"
              matTooltip="Use comma as a separator"
            ></textarea>
            <mat-error
              *ngIf="
                transcodingTriggeringForm.controls.Sources.hasError('required')
              "
            >
              Field is <strong>required</strong>
            </mat-error>
            <mat-error
              *ngIf="
                transcodingTriggeringForm.controls.Sources.hasError(
                  'isCommaSeparator'
                )
              "
            >
              Comma separator is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <md-input-container formGroupName="KpiSettings">
            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field appearance="fill" class="full-width">
                <mat-label>KPI project name</mat-label>
                <mat-select
                  formControlName="KpiProjectName"
                  (selectionChange)="onKpiProjectSelectionChange($event)"
                >
                  <mat-option
                    *ngFor="let kpiProject of kpiProjects$ | async"
                    [value]="kpiProject.name"
                    >{{ kpiProject.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <button
                class="reset-kpi-setting-button"
                mat-mini-fab
                color="primary"
                type="button"
                (click)="onResetKpiSettings()"
                matTooltip="Reset KPI settings"
                matTooltipPosition="below"
              >
                <mat-icon>refresh</mat-icon>
              </button>
            </div>
          </md-input-container>
        </div>
        <div class="col">
          <md-input-container formGroupName="KpiSettings">
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Function</mat-label>
              <mat-select
                formControlName="function"
                (selectionChange)="onKpiFunctionsChange($event)"
              >
                <mat-option
                  *ngFor="let function of kpiFunctions"
                  [value]="function"
                  >{{ function }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </md-input-container>
        </div>
        <div class="col">
          <md-input-container formGroupName="KpiSettings">
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Report chunk size</mat-label>
              <input
                type="number"
                matInput
                placeholder="Report chunk size"
                formControlName="ReportChunkSize"
              />
              <mat-error
                *ngIf="
                  transcodingTriggeringForm.controls.KpiSettings.controls.ReportChunkSize.hasError(
                    'min'
                  )
                "
              >
                Should be more then <strong>1</strong>
              </mat-error>
              <mat-error
                *ngIf="
                  transcodingTriggeringForm.controls.KpiSettings.controls.ReportChunkSize.hasError(
                    'max'
                  )
                "
              >
                Should be less then <strong>5000</strong>
              </mat-error>
            </mat-form-field>
          </md-input-container>
        </div>
        <div class="col">
          <md-input-container formGroupName="KpiSettings">
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Stream</mat-label>
              <mat-select
                formControlName="Stream"
                (selectionChange)="onKpiStreamSelectionChange($event)"
              >
                <mat-option
                  *ngFor="let stream of kpiStreams"
                  [value]="stream"
                  >{{ stream }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </md-input-container>
        </div>
        <div class="col">
          <md-input-container formGroupName="KpiSettings">
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Ground Truth Settings</mat-label>
              <mat-select
                formControlName="GtSelection"
                (selectionChange)="onKpiGtSelectionChange($event)"
              >
                <mat-option
                  *ngFor="let gtSelection of kpiGtSelections"
                  [value]="gtSelection"
                  >{{ gtSelection }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </md-input-container>
        </div>
        <div class="col">
          <md-input-container formGroupName="KpiSettings">
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Cluster</mat-label>
              <mat-select
                formControlName="Cluster"
                (selectionChange)="onKpiClusterSelectionChange($event)"
              >
                <mat-option
                  *ngFor="let cluster of kpiClusters"
                  [value]="cluster"
                  >{{ cluster }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </md-input-container>
        </div>
        <div class="col">
          <md-input-container formGroupName="KpiSettings">
            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field appearance="fill" class="full-width">
                <mat-label>Report merge variant</mat-label>
                <mat-select formControlName="report_merge_variant">
                  <mat-option
                    *ngFor="let report_merge_variant of report_merge_variants"
                    [value]="report_merge_variant"
                    >{{ report_merge_variant }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </md-input-container>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <md-input-container formGroupName="KpiSettings">
            <mat-checkbox
              class="full-width"
              id="EnablePcpPostprocessing"
              formControlName="EnablePcpPostprocessing"
              >Post-processing</mat-checkbox
            >
          </md-input-container>
          <md-input-container formGroupName="KpiSettings">
            <mat-checkbox
              class="full-width"
              id="comparison_report"
              formControlName="comparison_report"
              >Comparison report</mat-checkbox
            >
          </md-input-container>
          <md-input-container formGroupName="KpiSettings">
            <mat-checkbox
              class="full-width"
              id="MatchingEnabled"
              formControlName="MatchingEnabled"
              (change)="onKpiMatchingChange($event)"
              [disabled]="!isOdKpiProjectSelected"
              >KPI Matching</mat-checkbox
            >
          </md-input-container>
          <md-input-container formGroupName="KpiSettings">
            <mat-checkbox
              class="full-width"
              id="MatchingForced"
              formControlName="MatchingForced"
              [disabled]="!isOdKpiProjectSelected"
              >Force Matching</mat-checkbox
            >
          </md-input-container>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <input
            type="file"
            accept=".json"
            class="file-input"
            (change)="onAttachFile($event)"
            #fileUploadInput
          />
          <div class="file-upload">
            <button
              mat-mini-fab
              color="primary"
              (click)="onAttachFileButtonClick($event)"
              matTooltip="Upload matching configuration .json file"
              matTooltipPosition="below"
              [disabled]="!isAttachFileButtonsEnabled"
            >
              <mat-icon>attach_file</mat-icon>
            </button>

            <button
              class="delete-button"
              mat-mini-fab
              color="primary"
              type="button"
              matTooltip="Remove existing file"
              matTooltipPosition="below"
              (click)="onDeleteAttachedFile()"
              [disabled]="!isAttachFileButtonsEnabled"
            >
              <mat-icon>delete</mat-icon>
            </button>

            <span class="file-name">{{
              fileName || 'No file uploaded yet'
            }}</span>
          </div>
          <div
            *ngIf="Object.keys(parsedMatchingGenerator).length"
            class="file-name"
            [innerHTML]="parsedMatchingGenerator | prettyJson"
          ></div>
        </div>
      </div>
      <app-test-suites-table
        *ngIf="selectedKpiProject && selectedKpiProject.id"
        [selectedKpiProjectId]="selectedKpiProject.id"
        (kpiTestSuiteSelected)="onKpiTestSuiteSelected($event)"
      ></app-test-suites-table>
    </mat-card-content>
    <mat-card-actions class="form-buttons-group">
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="!transcodingTriggeringForm.valid"
      >
        Submit
      </button>
    </mat-card-actions>
  </mat-card>
</form>
