export const DdbTablesConstant = {
  PartnersDxDynamoDBTable: {
    columns: {
      deployment_version: {
        name: 'deployment-version',
        values: {},
      },
      deployment_content: {
        name: 'deployment-content',
        values: {},
      },
      s3_source_bucket: {
        name: 's3-source-bucket',
        values: {},
      },
      s3_source_prefix: {
        name: 's3-source-prefix',
        values: {},
      },
      s3_target_bucket: {
        name: 's3-target-bucket',
        values: {},
      },
      s3_target_prefix: {
        name: 's3-target-prefix',
        values: {},
      },
      type: {
        name: 'type',
        values: {
          exchange: 'EXCHANGE',
        },
      },
    },
  },
};
