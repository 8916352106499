import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DataBrowserComponent } from '@modules/data-browser/components/data-browser/data-browser.component';
import { DataBrowserEffects } from '@modules/data-browser/store/data-browser.effects';
import { InnovizComponent } from '@modules/innoviz/components/innoviz/innoviz.component';
import { InnovizEffects } from '@modules/innoviz/store/innoviz.effects';
import { TestRunConfigurationNewComponent } from '@modules/test-run-configuration/components/test-run-configuration-new/test-run-configuration-new.component';
import { TestRunConfigurationComponent } from '@modules/test-run-configuration/components/test-run-configuration/test-run-configuration.component';
import { TestSessionPlaylistDialogComponent } from '@modules/test-run-configuration/components/test-session-playlist-dialog/test-session-playlist-dialog.component';
import { TestRunConfigurationEffects } from '@modules/test-run-configuration/store/test-run-configuration.effects';
import { TmaDashboardComponent } from '@modules/tma-dashboard.component';
import { TrainedParkingAssistComponent } from '@modules/trained-parking-assist/components/trained-parking-assist/trained-parking-assist.component';
import { TranscodingTriggeringNewComponent } from '@modules/transcoding/components/transcoding-triggering-new/transcoding-triggering-new.component';
import { VinsApprovalsComponent } from '@modules/vins-approvals/components/vins-approvals/vins-approvals.component';
import { VinsApprovalsEffects } from '@modules/vins-approvals/store/vins-approvals.effects';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '@shared/shared.module';
import { CariadFormComponent } from './cariad/components/cariad-form/cariad-form.component';
import { CariadComponent } from './cariad/components/cariad/cariad.component';
import { TestSuitesTableComponent } from './kpi-configuration/components/test-suites-table/test-suites-table.component';
import { PlaylistComponent } from './playlists/components/playlist/playlist.component';
import { PlaylistsComponent } from './playlists/components/playlists/playlists.component';
import { PlaylistEffects } from './playlists/store/playlist.effects';
import { RmTestRunConfigurationComponent } from './rm-test-run-configuration/components/rm-test-run-configuration/rm-test-run-configuration.component';
import { ApprovalClipsUploaderComponent } from './splits-approvals/components/approval-clips-uploader/approval-clips-uploader.component';
import { ApprovalValidationComponent } from './splits-approvals/components/approval-validation/approval-validation.component';
import { DriveIdsListComponent } from './splits-approvals/components/drive-ids-list/drive-ids-list.component';
import { GValidationComponent } from './splits-approvals/components/g-validation/g-validation.component';
import { LValidationComponent } from './splits-approvals/components/l-validation/l-validation.component';
import { L0ValidationComponent } from './splits-approvals/components/l0-validation/l0-validation.component';
import { SplitsApprovalsComponent } from './splits-approvals/components/splits-approvals/splits-approvals.component';
import { TestRunConfigurationCreateComponent } from './test-run-configuration/components/test-run-configuration-create/test-run-configuration-create.component';
import { TranscodingComponent } from './transcoding/components/transcoding/transcoding.component';

@NgModule({
  declarations: [
    TmaDashboardComponent,
    TestRunConfigurationComponent,
    TestSessionPlaylistDialogComponent,
    DataBrowserComponent,
    InnovizComponent,
    VinsApprovalsComponent,
    TrainedParkingAssistComponent,
    TestRunConfigurationNewComponent,
    TranscodingComponent,
    TestSuitesTableComponent,
    TranscodingTriggeringNewComponent,
    TestRunConfigurationCreateComponent,
    CariadComponent,
    CariadFormComponent,
    SplitsApprovalsComponent,
    LValidationComponent,
    GValidationComponent,
    L0ValidationComponent,
    DriveIdsListComponent,
    ApprovalClipsUploaderComponent,
    ApprovalValidationComponent,
    RmTestRunConfigurationComponent,
    PlaylistsComponent,
    PlaylistComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EffectsModule.forFeature([
      TestRunConfigurationEffects,
      DataBrowserEffects,
      InnovizEffects,
      VinsApprovalsEffects,
      PlaylistEffects,
    ]),
  ],
})
export class TmaDashboardModule {}
