import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacySelectChange } from '@angular/material/legacy-select';
import { ProjectsConstant } from '@constants/projects.constant';
import { UiMessagesConstant } from '@constants/ui-messages.constant';
import {
  GetDriveIdsApprovalListQuery,
  GetVinsListQuery,
  SetDriveIdApprovalsQuery,
} from '@core/amplify/aws-apmlify-query.constant';
import { BreakpointGridColumnsService } from '@core/services/breakpoint-grid-columns.service';
import { TmaUtilsStaticService } from '@core/services/tma-utils-static.service';
import { SetDriveIdApprovalsPayload } from '@model/payloads';
import { VinsApprovalsConstant } from '@modules/vins-approvals/constants/vins-approvals.constant';
import * as VinsApprovalsActions from '@modules/vins-approvals/store/vins-approvals.actions';
import * as fromVinsApprovals from '@modules/vins-approvals/store/vins-approvals.reducer';
import {
  selectFeatureDriveIdsApprovalList,
  selectFeatureVinsList,
} from '@modules/vins-approvals/store/vins-approvals.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-vins-approvals',
  templateUrl: './vins-approvals.component.html',
  styleUrls: ['./vins-approvals.component.scss'],
})
export class VinsApprovalsComponent implements OnInit, OnDestroy {
  approvalsNames = [
    'Data Management',
    'Labelling & Annot.',
    'KPI Test',
    'Reprocessing',
    'Partner',
  ];

  approvalStatuses = [
    VinsApprovalsConstant.statuses.notSet.value,
    VinsApprovalsConstant.statuses.approved.value,
    VinsApprovalsConstant.statuses.rejected.value,
  ];

  cols$: Observable<number> = this.breakpointGridColumnsService.cols$;

  driveIdsApprovalList$: Observable<DriveIdApproval[]> = this.store.select(
    selectFeatureDriveIdsApprovalList
  );

  initApprovals: Approval[] = [
    {
      approval: VinsApprovalsConstant.statuses.notSet.value,
      comment: '',
      user: VinsApprovalsConstant.approval_users.dmr.name,
      email: '',
    },
    {
      approval: VinsApprovalsConstant.statuses.notSet.value,
      comment: '',
      user: VinsApprovalsConstant.approval_users.annotation.name,
      email: '',
    },
    {
      approval: VinsApprovalsConstant.statuses.notSet.value,
      comment: '',
      user: VinsApprovalsConstant.approval_users.kpi.name,
      email: '',
    },
    {
      approval: VinsApprovalsConstant.statuses.notSet.value,
      comment: '',
      user: VinsApprovalsConstant.approval_users.reprocessing.name,
      email: '',
    },
    {
      approval: VinsApprovalsConstant.statuses.notSet.value,
      comment: '',
      user: VinsApprovalsConstant.approval_users.partner.name,
      email: '',
    },
  ];

  projects = [
    ProjectsConstant.ADCAM.name,
    ProjectsConstant.LIDAR.name,
    ProjectsConstant.MFK5.name,
    ProjectsConstant.MFK120.name,
  ];

  projectSelected = ProjectsConstant.LIDAR.name;

  setDriveIdApprovalsSuccessMessage = '';

  setDriveIdApprovalsPayload: SetDriveIdApprovalsPayload = {
    query: SetDriveIdApprovalsQuery,
    variables: {
      drive_id: '',
      vin: '',
      approvals: TmaUtilsStaticService.getDeepCopy(this.initApprovals),
    },
  };

  vinsApprovalsConstant = VinsApprovalsConstant;

  vinsList$: Observable<VinsList> = this.store.select(selectFeatureVinsList);

  vinSelected: Vin = {
    key: '',
    value: VinsApprovalsConstant.statuses.notSet.value,
  };

  ngOnDestroy(): void {
    this.store.dispatch(VinsApprovalsActions.resetDriveIdsApprovalList());
  }

  ngOnInit(): void {
    this.actions$
      .pipe(ofType(VinsApprovalsActions.setDriveIdApprovalsSuccess))
      .subscribe(() => {
        this.setDriveIdApprovalsSuccessMessage =
          UiMessagesConstant.setDriveIdApprovalsSuccessMessage;

        this.loadVinsList();
        this.loadDriveIdsApprovalList();
      });

    this.loadVinsList();
  }

  onApprovalsSubmit(): void {
    this.setDriveIdApprovalsSuccessMessage = '';

    this.store.dispatch(
      VinsApprovalsActions.setDriveIdApprovals(this.setDriveIdApprovalsPayload)
    );
  }

  onDriveIdSelected(driveIdApproval: DriveIdApproval): void {
    this.setDriveIdApprovalsPayload.variables.drive_id =
      driveIdApproval.drive_id;

    this.setDriveIdApprovalsPayload.variables.approvals = driveIdApproval
      .approvals.length
      ? driveIdApproval.approvals
      : TmaUtilsStaticService.getDeepCopy(this.initApprovals);
  }

  onProjectChange(project: MatLegacySelectChange): void {
    this.restVinsList();
    this.resetDriveIds();

    this.store.dispatch(
      VinsApprovalsActions.loadVinsList({
        query: GetVinsListQuery,
        variables: {
          project: project.value,
        },
      })
    );
  }

  onVinSelected($event: any, selected: Vin): void {
    this.resetDriveIds();

    this.vinSelected = selected;

    this.setDriveIdApprovalsPayload.variables.vin = this.vinSelected.key;

    this.loadDriveIdsApprovalList();
  }

  private loadDriveIdsApprovalList(): void {
    this.store.dispatch(
      VinsApprovalsActions.loadDriveIdsApprovalList({
        query: GetDriveIdsApprovalListQuery,
        variables: {
          vin: this.vinSelected.key,
        },
      })
    );
  }

  private loadVinsList(): void {
    this.store.dispatch(
      VinsApprovalsActions.loadVinsList({
        query: GetVinsListQuery,
        variables: {
          project: this.projectSelected,
        },
      })
    );
  }

  private restVinsList(): void {
    this.store.dispatch(VinsApprovalsActions.resetVinsList());
  }

  private resetDriveIds(): void {
    this.store.dispatch(VinsApprovalsActions.resetDriveIdsApprovalList());
    this.setDriveIdApprovalsPayload.variables.drive_id = '';
  }

  constructor(
    private actions$: Actions,
    private store: Store<fromVinsApprovals.State>,
    private formBuilder: UntypedFormBuilder,
    private breakpointGridColumnsService: BreakpointGridColumnsService
  ) {}
}
