<mat-card>
  <mat-card-content>
    <mat-form-field appearance="fill">
      <mat-label>Project</mat-label>
      <mat-select
        [value]="currentProject"
        (selectionChange)="onProjectChange($event.value)"
      >
        <mat-option [value]="project" *ngFor="let project of projects">
          {{ project }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>Playlists</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-form-field appearance="fill">
      <mat-label>Playlist ID</mat-label>
      <input
        matInput
        (keyup.enter)="onSearchByPlaylistId($event)"
        [placeholder]="'Ex.pl-20240418130000'"
        matTooltip="Input Playlist ID and click 'Enter'"
        matTooltipPosition="below"
      />
    </mat-form-field>

    <mat-card-actions fxLayout="row" fxLayoutAlign="start center">
      <button
        mat-raised-button
        color="primary"
        type="button"
        (click)="reloadTable()"
        matTooltip="Refresh table data"
        matTooltipPosition="below"
        [disabled]="spinner"
      >
        <mat-icon>refresh</mat-icon>
      </button>

      <button
        mat-raised-button
        color="primary"
        type="button"
        (click)="queryMore()"
        matTooltip="Load more items"
        matTooltipPosition="below"
        [disabled]="spinner"
      >
        <mat-icon>system_update_alt</mat-icon>
      </button>

      <button mat-mini-fab color="primary" matTooltip="Add new playlist">
        <mat-icon>add</mat-icon>
      </button>
    </mat-card-actions>

    <mat-table class="mat-elevation-z8" [dataSource]="dataSource">
      <ng-container matColumnDef="playlist_id">
        <mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.playlist_id }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="created_timestamp">
        <mat-header-cell *matHeaderCellDef>Created</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.created_timestamp }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="updated_timestamp">
        <mat-header-cell *matHeaderCellDef>Updated</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.updated_timestamp }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button mat-icon-button (click)="goPlaylistPage($event, row)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button>
            <mat-icon>start</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"> </mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <mat-paginator
      [pageSizeOptions]="[5, 10, 25, 100]"
      [showFirstLastButtons]="true"
    ></mat-paginator>
  </mat-card-content>
</mat-card>
