import * as fromPlaylist from '@modules/playlists/store/playlist.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectPlaylistState = createFeatureSelector<fromPlaylist.State>(
  fromPlaylist.playlistFeatureKey
);

export const selectFeaturePlaylist = createSelector(
  selectPlaylistState,
  (state: fromPlaylist.State) => state.playlist
);

export const selectFeatureDriveIds = createSelector(
  selectPlaylistState,
  (state: fromPlaylist.State) => state.driveIds
);
