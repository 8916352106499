import { Injectable } from '@angular/core';
import { FormGroup, ValidatorFn } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class KpiSettingsFieldsValidator {
  isKpiFunctionSelected(): ValidatorFn | any {
    return (formGroup: FormGroup): ValidatorFn | any => {
      const kpiProjectIds = JSON.parse(
        formGroup.get('kpi_project_ids')?.value || '[]'
      );
      const kpiFunction = formGroup.get('function')?.value;

      if (kpiProjectIds.length && !kpiFunction.length) {
        return { functionIsMandatory: true };
      }

      return null;
    };
  }

  isKpiTestSuiteSelected(): ValidatorFn | any {
    return (formGroup: FormGroup): ValidatorFn | any => {
      const kpiProjectIds = JSON.parse(
        formGroup.get('kpi_project_ids')?.value || '[]'
      );
      const kpiTestsuitName =
        formGroup.get('session_to_kpiscipts_mapping')?.get('kpiTestsuitName')
          ?.value || '';

      if (kpiProjectIds.length && !kpiTestsuitName.length) {
        return { testSuiteIsMandatory: true };
      }

      return null;
    };
  }
}
