import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';
import {
  QueryGetPlaylist,
  QueryGetPlaylists,
} from '@core/amplify/aws-apmlify-query.constant';
import { TmaUtilsStaticService } from '@core/services/tma-utils-static.service';
import { environment } from '@environments/environment';
import { PlaylistsConstant } from '@modules/playlists/constants/playlists.constant';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-playlists',
  templateUrl: './playlists.component.html',
  styleUrls: ['./playlists.component.scss'],
})
export class PlaylistsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  currentProject = environment.projectName;
  projects = environment.projectsNames;
  playlists: Playlist[] = [];
  limit = 50;
  nextToken: string | null = null;
  displayedColumns: string[] = PlaylistsConstant.playlistsDisplayedColumns;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  spinner = false;

  goPlaylistPage(event: Event, playlist: Playlist): void {
    this.router
      .navigate([playlist.playlist_id], { relativeTo: this.activatedRoute })
      .then(() => {});
  }

  queryMore(): void {
    if (this.nextToken) {
      this.renderTable();
    }
  }

  reloadTable(): void {
    this.resetTableData();

    this.renderTable();
  }

  ngOnInit() {
    this.renderTable();
  }

  onProjectChange(project: string): void {
    this.currentProject = project;

    this.reloadTable();
  }

  onSearchByPlaylistId($event: Event): void {
    this.resetTableData();

    const playlist_id = ($event?.target as HTMLInputElement).value;

    if (playlist_id.length) {
      this.spinner = true;

      this.queryPlaylist(playlist_id).subscribe(
        (getPlaylistResponse: GetPlaylistResponse) => {
          this.nextToken = getPlaylistResponse.data.getPlaylist.nextToken;

          this.setPlaylistsTableData(
            getPlaylistResponse.data.getPlaylist.items
          );

          this.spinner = false;
        },
        () => {
          this.spinner = false;
        }
      );
    } else {
      this.renderTable();
    }
  }

  private queryPlaylists(): Observable<any> {
    return this.awsAmplifyApiService.graphQLQuery({
      query: QueryGetPlaylists,
      variables: {
        project: this.currentProject,
        type: 'config',
        limit: this.limit,
        nextToken: this.nextToken,
      },
    });
  }

  private queryPlaylist(playlist_id: string): Observable<any> {
    return this.awsAmplifyApiService.graphQLQuery({
      query: QueryGetPlaylist,
      variables: {
        playlist_id,
        sk: 'config',
        limit: 1,
        nextToken: null,
      },
    });
  }

  private resetTableData(): void {
    this.dataSource.data = [];

    this.nextToken = null;
  }

  private renderTable(): void {
    this.spinner = true;

    this.queryPlaylists().subscribe(
      (getPlaylistsResponse: GetPlaylistsResponse) => {
        this.nextToken = getPlaylistsResponse.data.getPlaylists.nextToken;

        this.setPlaylistsTableData(
          getPlaylistsResponse.data.getPlaylists.items
        );

        this.spinner = false;
      },
      () => {
        this.spinner = false;
      }
    );
  }

  private setPlaylistsTableData(playlists: Playlist[]): void {
    const tableData = [...playlists];

    tableData.map((item) => {
      item.created_timestamp = TmaUtilsStaticService.formatDate(
        item.created_timestamp
      );

      item.updated_timestamp = TmaUtilsStaticService.formatDate(
        item.updated_timestamp
      );
    });

    this.dataSource.paginator = this.paginator;
    this.dataSource.data = this.dataSource.data.concat(tableData);
  }

  constructor(
    private awsAmplifyApiService: AwsAmplifyApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}
}
