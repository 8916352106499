import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SnackBarConstant } from '@constants/snack-bar.constant';
import { UiMessagesConstant } from '@constants/ui-messages.constant';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';
import { GetMfk120AggregatedValidation } from '@core/amplify/aws-apmlify-query.constant';
import { S3Service } from '@core/services/s3.service';
import { SnackBarService } from '@core/services/snack-bar.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-l0-validation',
  templateUrl: './l0-validation.component.html',
  styleUrls: ['./l0-validation.component.scss'],
})
export class L0ValidationComponent implements OnChanges {
  @Input() driveId: string;

  reportLink: string | null = null;

  eventsTableColumns: string[] = [];
  eventsTableDataSource = new MatTableDataSource<any>();
  filteredEventsTableDataSource = new MatTableDataSource<any>();

  missingEventsTableColumns: string[] = [];
  missingEventsTableDataSource = new MatTableDataSource<any>();
  filteredMissingEventsTableDataSource = new MatTableDataSource<any>();

  scenarioTimestampValidationTableColumns: string[] = [];
  scenarioTimestampValidationTableDataSource = new MatTableDataSource<any>();
  filteredScenarioTimestampValidationTableDataSource =
    new MatTableDataSource<any>();

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.driveId.currentValue.length &&
      changes.driveId.previousValue !== changes.driveId.currentValue
    ) {
      this.fetchValidationResult(changes.driveId.currentValue, 'L0').subscribe(
        (validationResult) => {
          try {
            const validationData: ValidationResultData = JSON.parse(
              validationResult.data.getMfk120AggregatedValidation
            );

            const L0_Events = validationData?.L0_Events;

            this.renderEventsTable(L0_Events);

            const Missing_Events = validationData?.Missing_Events;

            this.renderMissingEventsTable(Missing_Events);

            const Scenario_Timestamp_Validation =
              validationData?.Scenario_Timestamp_Validation;

            this.renderScenarioTimestampValidationTable(
              Scenario_Timestamp_Validation
            );

            this.s3Service
              .downloadS3File(
                validationData?.report?.s3_bucket,
                validationData?.report?.s3_key
              )
              .then((link) => {
                this.reportLink = link;
              });
          } catch (e) {
            this.snackBarService.open(
              `LO ${UiMessagesConstant.mfk120ValidationJSONParsingError}: ${e}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
          }
        },
        (error) => {
          this.snackBarService.showGraphQlErrorMessage(error);
        }
      );
    } else if (!changes.driveId.currentValue.length) {
      this.resetEventsTable();

      this.resetMissingEventsTable();

      this.resetScenarioTimestampValidationTable();

      this.reportLink = '';
    }
  }

  private fetchValidationResult(
    drive_id: string,
    validation_id: string
  ): Observable<any> {
    return this.awsAmplifyApiService.graphQLQuery({
      query: GetMfk120AggregatedValidation,
      variables: {
        drive_id,
        validation_id,
      },
    });
  }

  private renderEventsTable(L0_Events: L0_Event[]): void {
    if (L0_Events?.length) {
      this.eventsTableColumns = Object.keys(L0_Events[0]);
    }

    this.eventsTableDataSource = new MatTableDataSource(L0_Events);

    this.filteredEventsTableDataSource = new MatTableDataSource(
      this.eventsTableDataSource.data
    );
  }

  private resetEventsTable(): void {
    this.eventsTableColumns = [];

    this.eventsTableDataSource = new MatTableDataSource<any>();

    this.filteredEventsTableDataSource = new MatTableDataSource<any>();
  }

  private renderMissingEventsTable(Missing_Events: Missing_Event[]): void {
    if (Missing_Events?.length) {
      this.missingEventsTableColumns = Object.keys(Missing_Events[0]);
    }

    this.missingEventsTableDataSource = new MatTableDataSource(Missing_Events);

    this.filteredMissingEventsTableDataSource = new MatTableDataSource(
      this.missingEventsTableDataSource.data
    );
  }

  private resetMissingEventsTable(): void {
    this.missingEventsTableColumns = [];

    this.missingEventsTableDataSource = new MatTableDataSource<any>();

    this.filteredMissingEventsTableDataSource = new MatTableDataSource<any>();
  }

  private renderScenarioTimestampValidationTable(
    Scenario_Timestamp_Validation: Scenario_Timestamp_Validation[]
  ): void {
    if (Scenario_Timestamp_Validation?.length) {
      this.scenarioTimestampValidationTableColumns = Object.keys(
        Scenario_Timestamp_Validation[0]
      );
    }

    this.scenarioTimestampValidationTableDataSource = new MatTableDataSource(
      Scenario_Timestamp_Validation
    );

    this.filteredScenarioTimestampValidationTableDataSource =
      new MatTableDataSource(
        this.scenarioTimestampValidationTableDataSource.data
      );
  }

  private resetScenarioTimestampValidationTable(): void {
    this.scenarioTimestampValidationTableColumns = [];

    this.scenarioTimestampValidationTableDataSource =
      new MatTableDataSource<any>();

    this.filteredScenarioTimestampValidationTableDataSource =
      new MatTableDataSource<any>();
  }

  constructor(
    private s3Service: S3Service,
    private awsAmplifyApiService: AwsAmplifyApiService,
    private snackBarService: SnackBarService
  ) {}
}
