<form [formGroup]="formGroup" novalidate (ngSubmit)="submit()">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{ formTitle }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-grid-list
        [cols]="!cols ? (cols$ | async) : cols"
        [rowHeight]="rowHeight"
      >
        <mat-grid-tile
          *ngFor="let control of formControlsMetadata | keyvalue: unsorted"
        >
          <ng-container [ngSwitch]="control.value.inputType">
            <mat-form-field appearance="fill" *ngSwitchCase="inputTypes.input">
              <mat-label>{{ control.value.placeHolder }}</mat-label>
              <input
                matInput
                [name]="control.value.formControlName.toLocaleLowerCase()"
                [placeholder]="control.value.placeHolder"
                [formControlName]="control.value.formControlName"
                [matTooltip]="control.value.options.tooltip"
              />
              <mat-error
                *ngIf="
                  formGroup.controls[control.value.formControlName].hasError(
                    'required'
                  )
                "
                >{{ control.value.placeHolder }} is
                <strong>required</strong>
              </mat-error>
              <mat-error
                *ngIf="
                  formGroup.controls[control.value.formControlName].hasError(
                    'isUppercase'
                  )
                "
                >Uppercase is
                <strong>required</strong>
              </mat-error>
              <mat-error
                *ngIf="
                  formGroup.controls[control.value.formControlName].hasError(
                    'min'
                  )
                "
                >Should be more then
                <strong>1</strong>
              </mat-error>
              <mat-error
                *ngIf="
                  formGroup.controls[control.value.formControlName].hasError(
                    'max'
                  )
                "
                >Should be less then
                <strong>2500</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field
              appearance="fill"
              *ngSwitchCase="inputTypes.autocomplete"
            >
              <mat-label>{{ control.value.placeHolder }}</mat-label>
              <input
                matInput
                [name]="control.value.formControlName"
                [placeholder]="control.value.placeHolder"
                [formControlName]="control.value.formControlName"
                [matAutocomplete]="auto"
                [matTooltip]="control.value.options.tooltip"
              />
              <mat-autocomplete #auto="matAutocomplete" panelWidth="auto">
                <mat-option
                  *ngFor="
                    let option of control.value.options.filteredInputOptions
                  "
                  [value]="option.value"
                  [ngClass]="
                    option.description?.length ? 'extended-option-height' : ''
                  "
                >
                  <div>{{ option.placeholder }}</div>
                  <small class="magna-description">{{
                    option.description
                  }}</small>
                </mat-option>
              </mat-autocomplete>
              <mat-error
                *ngIf="
                  formGroup.controls[control.value.formControlName].hasError(
                    'required'
                  )
                "
                >{{ control.value.placeHolder }} is
                <strong>required</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" *ngSwitchCase="inputTypes.select">
              <mat-label>{{ control.value.placeHolder }}</mat-label>
              <mat-select
                [formControlName]="control.value.formControlName"
                [matTooltip]="control.value.options.tooltip"
              >
                <mat-option
                  *ngFor="let option of control.value.options.selectOptions"
                  [value]="option"
                  >{{ option }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <ng-container *ngSwitchCase="inputTypes.checkbox">
              <mat-checkbox
                [matTooltip]="control.value.options.tooltip"
                [formControlName]="control.value.formControlName"
                >{{ control.value.placeHolder }}</mat-checkbox
              >
            </ng-container>
            <ng-container *ngSwitchCase="inputTypes.radio">
              <div fxLayout="column" fxLayoutAlign="start start">
                <label>{{ control.value.placeHolder }}</label>
                <mat-radio-group
                  fxLayoutGap
                  [name]="control.value.formControlName"
                  [formControlName]="control.value.formControlName"
                  [matTooltip]="control.value.options.tooltip"
                >
                  <mat-radio-button
                    *ngFor="
                      let option of control.value.options.filteredInputOptions
                    "
                    [value]="option.value"
                    >{{ option.placeholder }}</mat-radio-button
                  >
                </mat-radio-group>
              </div>
            </ng-container>
            <mat-form-field
              appearance="fill"
              *ngSwitchCase="inputTypes.textarea"
            >
              <mat-label>{{ control.value.placeHolder }}</mat-label>
              <textarea
                matInput
                [name]="control.value.formControlName"
                [placeholder]="control.value.placeHolder"
                [formControlName]="control.value.formControlName"
                [matTooltip]="control.value.options.tooltip"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="2"
              ></textarea>
              <mat-error
                *ngIf="
                  formGroup.controls[control.value.formControlName].hasError(
                    'required'
                  )
                "
                >{{ control.value.placeHolder }} is
                <strong>required</strong>
              </mat-error>
              <mat-error
                *ngIf="
                  formGroup.controls[control.value.formControlName].hasError(
                    'isJson'
                  )
                "
                >JSON format is
                <strong>required</strong>
              </mat-error>
              <mat-error
                *ngIf="
                  formGroup.controls[control.value.formControlName].hasError(
                    'isUppercase'
                  )
                "
                >Uppercase is
                <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </ng-container>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card-content>
    <mat-card-actions
      *ngIf="isFormActionsVisible"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="!formGroup.valid || isSubmitButtonDisabled"
      >
        Submit
      </button>
      <button
        *ngIf="actionName"
        mat-raised-button
        color="primary"
        type="button"
        (click)="action()"
      >
        {{ actionName }}
      </button>
    </mat-card-actions>
  </mat-card>
</form>
