import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';
import {
  TriggerPipelineExecutionMutationPayload,
  TriggerPipelineExecutionMutationResponse,
} from '@model/payloads';

@Injectable({
  providedIn: 'root',
})
export class LambdasService {
  constructor(private graphqlApiService: AwsAmplifyApiService) {}

  triggerPipelineExecution(
    triggerPipelineExecutionMutationPayload: TriggerPipelineExecutionMutationPayload
  ): Observable<TriggerPipelineExecutionMutationResponse> {
    return this.graphqlApiService.graphQLQuery(
      triggerPipelineExecutionMutationPayload
    );
  }
}
