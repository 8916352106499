<form [formGroup]="cariadExchangeForm" novalidate (ngSubmit)="onSubmit()">
  <mat-card class="cariad-card">
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Download links</mat-label>
            <textarea
              matInput
              name="Download links"
              placeholder="Download links"
              formControlName="download_links"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="5"
              matTooltip="Use comma as a separator"
            ></textarea>
            <mat-error
              *ngIf="
                cariadExchangeForm.controls.download_links.hasError('required')
              "
            >
              Field is <strong>required</strong>
            </mat-error>
            <mat-error
              *ngIf="
                cariadExchangeForm.controls.download_links.hasError(
                  'isCommaSeparator'
                )
              "
            >
              Comma separator is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Target folder name</mat-label>
            <input
              matInput
              placeholder="Target folder name"
              formControlName="target_folder_name"
            />
            <mat-error
              *ngIf="
                cariadExchangeForm.controls.target_folder_name.hasError(
                  'required'
                )
              "
            >
              Field is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Comment</mat-label>
            <input matInput placeholder="Comment" formControlName="comment" />
            <mat-error
              *ngIf="cariadExchangeForm.controls.comment.hasError('required')"
            >
              Field is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-checkbox
            class="full-width"
            id="extract_zip_files"
            formControlName="extract_zip_files"
            >Extract zip files</mat-checkbox
          >
        </div>
        <div class="col">
          <mat-checkbox
            class="full-width"
            id="delete_after_unzip"
            formControlName="delete_after_unzip"
            >Delete after unzip</mat-checkbox
          >
        </div>
        <div class="col">
          <mat-checkbox
            class="full-width"
            id="unzip_only"
            formControlName="unzip_only"
            >Unzip only</mat-checkbox
          >
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions class="form-buttons-group">
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="!cariadExchangeForm.valid"
      >
        Submit
      </button>
    </mat-card-actions>
  </mat-card>
</form>
