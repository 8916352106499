import { Injectable } from '@angular/core';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';
import {
  GetReprocessingConfigurationsPayload,
  GetReprocessingConfigurationsResponse,
  GetReprocessingInfoPayload,
  GetReprocessingInfoResponse,
  SaveReprocessingConfigurationPayload,
  SaveReprocessingConfigurationResponse,
} from '@model/payloads';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReprocessingConfigurationService {
  constructor(private graphqlApiService: AwsAmplifyApiService) {}

  saveReproceccingConfiguration(
    querySaveReprocessingConfigurationPayload: SaveReprocessingConfigurationPayload
  ): Observable<SaveReprocessingConfigurationResponse> {
    return this.graphqlApiService.graphQLQuery(
      querySaveReprocessingConfigurationPayload
    );
  }

  getReproceccingConfigurations(
    getReprocessingConfigurationsPayload: GetReprocessingConfigurationsPayload
  ): Observable<GetReprocessingConfigurationsResponse> {
    return this.graphqlApiService.graphQLQuery(
      getReprocessingConfigurationsPayload
    );
  }

  getReproceccingInfo(
    getReprocessingInfoPayload: GetReprocessingInfoPayload
  ): Observable<GetReprocessingInfoResponse> {
    return this.graphqlApiService.graphQLQuery(getReprocessingInfoPayload);
  }
}
