<mat-card>
  <mat-card-content>
    <mat-grid-list [cols]="3" rowHeight="8em">
      <mat-grid-tile
        *ngFor="let magnaVendor of magnaVendors"
        [ngSwitch]="magnaVendor.linkType"
      >
        <a
          [disabled]="magnaVendor.disabled"
          fxLayout="row"
          fxLayoutAlign="center center"
          *ngSwitchCase="vendorLinkTypes.routerLink"
          [routerLink]="magnaVendor.link"
          mat-stroked-button
          >{{ magnaVendor.title }}
        </a>
        <a
          [disabled]="magnaVendor.disabled"
          fxLayout="row"
          fxLayoutAlign="center center"
          *ngSwitchCase="vendorLinkTypes.href"
          [href]="magnaVendor.link"
          mat-stroked-button
          target="_blank"
          >{{ magnaVendor.title }}
        </a>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
