export const TranscodingConstant = {
  defaultTranscoder: 'Liangdao VBS',
  transcodingSourceTypesList: [
    'Original DS',
    'Reprocessed DS - QueueID',
    'Reprocessed DS - External TestOrderID',
  ],
  transcodingSourceTypesMap: {
    by_drive_ids: 'Original DS',
    by_test_run_ids: 'Reprocessed DS - External TestOrderID',
    byt_queue_ids: 'Reprocessed DS - QueueID',
  },
  steamsSelectionTypes: ['automatic', 'manual'],
  steamsSelectionTypesMap: {
    automatic: 'automatic',
    manual: 'manual',
  },
  sourcesFormControlTitlesMap: {
    by_drive_ids: 'Drive IDs List',
    by_test_run_ids: 'Test Run IDs List',
    byt_queue_ids: 'Queue IDs List',
  },
};
