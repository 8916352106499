import { ClipboardModule } from '@angular/cdk/clipboard';
import { LayoutModule } from '@angular/cdk/layout';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { TextareaFormComponent } from '@shared/components/textarea-form/textarea-form.component';
import { PrettyJsonPipe } from '@shared/pipes/pretty-json.pipe';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { AppRoutingModule } from '../app-routing.module';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { FormComponent } from './components/form/form.component';
import { HeaderComponent } from './components/header/header.component';
import { TableComponent } from './components/table/table.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FormComponent,
    TableComponent,
    TextareaFormComponent,
    PrettyJsonPipe,
    ConfirmDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AppRoutingModule,
    AmplifyAuthenticatorModule,
    MatToolbarModule,
    MatGridListModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    BrowserModule,
    MatTableModule,
    MatListModule,
    MatExpansionModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    TextFieldModule,
    MatSnackBarModule,
    LayoutModule,
    FlexLayoutModule,
    MatTooltipModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatSortModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    ClipboardModule,
    ScrollingModule,
    NgxCsvParserModule,
    FormsModule,
    NgOptimizedImage,
    MatBadgeModule,
    MatProgressBarModule,
    MatNativeDateModule,
    MatDatepickerModule,
    HttpClientModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatTreeModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    AppRoutingModule,
    AmplifyAuthenticatorModule,
    MatToolbarModule,
    MatGridListModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    BrowserModule,
    MatTableModule,
    MatListModule,
    MatExpansionModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    ReactiveFormsModule,
    HeaderComponent,
    FormComponent,
    MatCheckboxModule,
    TextFieldModule,
    MatSnackBarModule,
    LayoutModule,
    FlexLayoutModule,
    MatTooltipModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    TableComponent,
    MatSortModule,
    MatDividerModule,
    TextareaFormComponent,
    MatProgressSpinnerModule,
    ClipboardModule,
    ScrollingModule,
    NgxCsvParserModule,
    FormsModule,
    NgOptimizedImage,
    MatBadgeModule,
    MatProgressBarModule,
    PrettyJsonPipe,
    MatNativeDateModule,
    MatDatepickerModule,
    HttpClientModule,
    MatButtonToggleModule,
    MatTabsModule,
    ConfirmDialogComponent,
    MatTreeModule,
  ],
})
export class SharedModule {}
