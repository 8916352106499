<mat-toolbar color="primary">
  <mat-toolbar-row>
    <mat-icon (click)="drawer.toggle()">menu</mat-icon>
    <mat-icon routerLink="/dmr-home">home</mat-icon>
    <mat-card-title-group>{{ environment.envName }}</mat-card-title-group>
    <span class="spacer"></span>
    <img
      priority
      ngSrc="/assets/magna-logo.svg"
      alt="Magna logo"
      height="36"
      width="177"
    />
  </mat-toolbar-row>
</mat-toolbar>
