export const GetDriveIdsApprovalListQuery = `query getDriveIdsApprovalList($vin: ID!) {
  getDriveIdsApprovalList(vin: $vin) {
    approval_status,
    drive_ids
  }
}`;

export const GetDrivingSessionQuery = `query getDrivingSession($drive_id: ID!, $verbose: Boolean) {
  getDrivingSession(drive_id: $drive_id, verbose: $verbose) {
    drive_id
    VIN
    local_drive_date
    local_drive_time_start
    country
    s3_source_bucket
    s3_source_prefix
    s3_target_bucket
    s3_target_prefix
    campaign
    logger
    logger_version
    project
    data_provider
    distance_driven_km
    ml_process_type
    file_source_directory_path
    start_upload_timestamp
    end_upload_timestamp
    customer
    total_recordings
    total_data_size
    total_data_size_GB
    driving_side_of_country
    steering_wheel_side
    head_light_type
    events
    queues
    ss_customer_vin
    ss_customer_bordnet
    ss_customer_astep
    ss_customer_country
    ss_customer_project
    ss_customer_campaign
    ss_customer_logger
    version
    object_schema_version
    upload_status
    uploaded_with
    drive_start_nano_timestamp
    drive_stop_nano_timestamp
    bordnet
    bordnet_versions
    a_step
    level_0_validation_score
    upload_type
    drive_duration_in_seconds
    last_updated_timestamp_utc
    batch_id
    ticket_id
    execution_id
    epm_clips_count
    comment
    upload_session_name
    repaired
    collection_type
    bertrandt_use_case_id
    archived
    level_0_validation_info {
      is_root_folder_valid
      is_meta_file_valid
      is_folder_structure_valid
      all_split_files_present
      pre_labels_found
      live_labels_found
      live_labels_timestamps_found
      upload_to_s3_verified
      drive_id
      drive_duration_time_valid
      files_over_4GB_present
      start_end_timestamps_are_valid
      is_folder_timestamp_matches_start_ts
      is_meta_json_present
      is_config_json_present
    }
    level_1_validation_info {
      is_validation_successful
      validation_attempt_counter
      last_validation_timestamp
      validation_tool_version
    }
    bordnet_versions_map {
      key
      value {
        a_step
        bordnet
        conversion_label
        status
        s3_target_bucket
        s3_target_prefix
        creation_time_utc
      }
    }
    before_pre_labels {
      key
      value
    }
    partners_info_map {
      key
      value {
        partner
        s3_target_bucket
        s3_target_prefix
        upload_timestamp_utc
        total_files_count
        total_upload_data_size_GB
      }
    }
    before_pre_labels_map {
      key
      value
    }
  }
}`;

export const GetDrivingSessionNewQuery = `query getDrivingSessionNew($drive_id: ID, $verbose: Boolean) {
  getDrivingSessionNew(drive_id: $drive_id, verbose: $verbose)
}`;

export const CariadInbound = `query cariadInbound($target_folder_name: String, $download_links: [String], $extract_zip_files: Boolean, $delete_after_unzip: Boolean, $unzip_only: Boolean, $comment: String) {
  cariadInbound(target_folder_name: $target_folder_name, download_links: $download_links, extract_zip_files: $extract_zip_files, delete_after_unzip: $delete_after_unzip, unzip_only: $unzip_only, comment: $comment) {
    session_id
    s3_target_bucket
    s3_target_prefix
    num_of_files_to_download
    extract_zip_files
    delete_after_unzip
    unzip_only
  }
}`;

export const getDrivingSessionS3StructureQuery = `query getDrivingSessionS3Structure($drive_id: ID!) {
  getDrivingSessionS3Structure(drive_id: $drive_id) {
    s3_bucket,
    s3_prefix,
    total_prefixes_found,
    total_objects_count,
    total_objects_size,
    total_objects_size_GB,
    total_objects_size_TB,
    limit,
    folders_list {
      prefix,
      folder_objects_count,
      folder_objects_size,
      s3_objects_list {
        s3_bucket,
        object_name,
        s3_parent_prefix,
        folder,
        s3_object_key,
        last_modified_date,
        storage_class,
        size
      }
    }
  }
}`;

export const GetKPIJobsExecutionsChunks = `
query getKPIJobsExecutionsChunks($id: String!) {
  getKPIJobsExecutionsChunks(
    id: $id
  )
}`;

export const GetKpiMetaQuery = `
query get_kpi_meta {
  get_kpi_meta
}
`;

export const GetKpiProjectsQuery = `
query getKPIProjects {
  getKPIProjects
}`;

export const GetKpiReportsDownloadLinksQuery = `
query getKpiReportsDownloadLinks($project_id: String!, $job_id: String!) {
  getKpiReportsDownloadLinks(
    project_id: $project_id,
    job_id: $job_id
  )
}`;

export const GetKpiScriptConfigurationsQuery = `
query getKPIScriptConfigurations($project_id: String!) {
  getKPIScriptConfigurations(
    project_id: $project_id
  )
}`;

export const GetMikReproValidationResults = `
query getMikReproValidationResults($ext_test_order_id: String, $queue_id: Int) {
  getMikReproValidationResults(
    ext_test_order_id: $ext_test_order_id,
    queue_id: $queue_id
  )
}`;

export const GetKpiTestsuitesQuery = `
query getKPITestsuites($project_id: String!, $skip: Int!, $limit: Int!) {
  getKPITestsuites(
    project_id: $project_id,
    skip: $skip,
    limit: $limit
  )
}`;

export const GetPipelineQuery = `query getPipeline($name:  String!) {
    getPipeline(name: $name) {
      name
      entry_point_arn
    }
  }
`;

export const GetReprocessingConfigurationsQuery = `
query getReProcessingConfigurations {
  getReProcessingConfigurations
}`;

export const GetReprocessingInfoQuery = `
query getReprocessingInfo($ext_test_order_id: String!) {
  getReprocessingInfo(
    ext_test_order_id: $ext_test_order_id
  )
}`;

export const CancelTestRunExecution = `query cancelTestRunExecution($id: ID) {
  cancelTestRunExecution(id: $id) {
    test_run_execution_state
  }
}`;

export const GetReprocessingProgressQuery = `query getReprocessingProgress($ext_test_order_id: String!) {
  getReprocessingProgress(ext_test_order_id: $ext_test_order_id) {
    ext_test_order_id,
    ds_count,
    progress_status,
    vbs_transcoding_status,
    drive_id_reprocessing_status,
    drive_id_transcoding_status,
    playlist_ds_count
  }
}`;

export const GetTestRunSubmissionsQuery = `
query getTestRunSubmissions {
  getTestRunSubmissions
}`;

export const GetTestSessionsQuery = `
query getTestSessions {
  getTestSessions
}`;

export const GetTestRunExecution = `
query getTestRunExecution($test_run_id: ID) {
  getTestRunExecution(
    test_run_id: $test_run_id
  ) {
    testRunId
    createdTimeStamp
    projectId
    parent
    testRunConfig
    reprocessingConfig
    testSessions
    sessionToKPIScriptsMapping
    testRunConfigLabel
    response
    transcodingProgress
    transcodingStatus
    reprocessingProgress
    reprocessingStatus
    reprocessingStatusText
    reprocessingSuccessRate
    testOrderId
    selectedTranscodingType
    owner
    processingState {
      version
      reproState {
        requested
        succeeded
        failed
      }
      tcState {
        requested
        succeeded
        failed
      }
      matchingState {
        requested
        succeeded
        failed
      }
    }
    reprocessing_started
    test_run_execution_state
    matching_progress
    matching_status
  }
}`;

export const GetTestrunExecutionsQuery = `
query getTestRunExecutions {
  getTestRunExecutions
}`;

export const GetVinsListQuery = `query getVinsList($project: String!) {
  getVinsList(project: $project) {
    project,
    vin_map
  }
}`;

export const ListInnovizHilSwExchangesQuery = `
query listInnovizHilSwExchanges($type: String, $limit: Int, $nextToken: String) {
  listInnovizHilSwExchanges(type: $type, limit: $limit, nextToken: $nextToken) {
    items
    nextToken
  }
}
`;

export const ListReproResultByTestRunIdQuery = `query listReproResultByTestRunId($ext_test_order_id: String, $limit: Int, $nextToken: String){
  listReproResultByTestRunId(ext_test_order_id: $ext_test_order_id, limit: $limit, nextToken: $nextToken) {
    items
    nextToken
  }
}`;

export const ListTestRunExecutionsQuery = `query listTestRunExecutions($limit: Int, $nextToken: String){
  listTestRunExecutions(limit: $limit, nextToken: $nextToken) {
    items
    nextToken
  }
}`;

export const ListTestRunExecutionsByOwnerQuery = `query listTestRunExecutionsByOwner($owner: String, $limit: Int, $nextToken: String){
  listTestRunExecutionsByOwner(owner: $owner, limit: $limit, nextToken: $nextToken) {
    items
    nextToken
  }
}`;

export const ListTestRunExecutionsByTestRunIdQuery = `query listTestRunExecutionsByTestRunId($test_order_id: String, $limit: Int, $nextToken: String){
  listTestRunExecutionsByTestRunId(test_order_id: $test_order_id, limit: $limit, nextToken: $nextToken) {
    items
    nextToken
  }
}`;

export const QueryBuilderQuery: string = `
query queryBuilderQuery($query: QueryBuilderQuery!) {
  queryBuilderQuery(query: $query)
}`;

export const QueryDataBrowserQuery = `
query queryDataBrowser($query: String!) {
  queryDataBrowser(query: $query)
}`;

export const QueryInnovizHilSwExchangesByVersionQuery = `
query queryInnovizHilSwExchangesByVersion($version: String, $limit: Int, $nextToken: String) {
  queryInnovizHilSwExchangesByVersion(version: $version, limit: $limit, nextToken: $nextToken) {
    items
    nextToken
  }
}
`;

export const QueryTcSessionByIdQuery = `query queryTcSessionById($tc_session_id: String, $more_then_tc_item_id: Int, $limit: Int, $nextToken: String){
  queryTcSessionById(tc_session_id: $tc_session_id, more_then_tc_item_id: $more_then_tc_item_id, limit: $limit, nextToken: $nextToken) {
    items
    nextToken
  }
}`;

export const QueryTcSessionsByIdMatchingStatusFilteredQuery = `query queryTcSessionsByIdMatchingStatusFiltered($tc_session_id: String, $more_then_tc_item_id: Int, $succeeded: Boolean, $limit: Int, $nextToken: String){
  queryTcSessionsByIdMatchingStatusFiltered(tc_session_id: $tc_session_id, more_then_tc_item_id: $more_then_tc_item_id, succeeded: $succeeded, limit: $limit, nextToken: $nextToken) {
    items
    nextToken
  }
}`;

export const QueryTcSessionsByIdTcStatusFilteredQuery = `query queryTcSessionsByIdTcStatusFiltered($tc_session_id: String, $more_then_tc_item_id: Int, $succeeded: Boolean, $limit: Int, $nextToken: String){
  queryTcSessionsByIdTcStatusFiltered(tc_session_id: $tc_session_id, more_then_tc_item_id: $more_then_tc_item_id, succeeded: $succeeded, limit: $limit, nextToken: $nextToken) {
    items
    nextToken
  }
}`;

export const QueryTcSessionsByProjectQuery = `query queryTcSessionsByProject($project: String, $start: AWSDate, $end: AWSDate,  $limit: Int, $nextToken: String){
  queryTcSessionsByProject(project: $project, start: $start, end: $end, limit: $limit, nextToken: $nextToken) {
    items
    nextToken
  }
}`;

export const GetVehicleApprovalsByProject = `query getVehicleApprovalsByProject($is_approval_clip_project: String, $approval_clip_id: String, $limit: Int, $nextToken: String){
  getVehicleApprovalsByProject(is_approval_clip_project: $is_approval_clip_project, approval_clip_id: $approval_clip_id, limit: $limit, nextToken: $nextToken) {
    items
    nextToken
  }
}`;

export const GetVehicleApprovalsByDriveId = `query getVehicleApprovalsByDriveId($approval_clip_id: String, $drive_id: String, $limit: Int, $nextToken: String){
  getVehicleApprovalsByDriveId(approval_clip_id: $approval_clip_id, drive_id: $drive_id, limit: $limit, nextToken: $nextToken) {
    items
    nextToken
  }
}`;

export const DataCollectionApprovalClipsLinkage = `query dataCollectionApprovalClipsLinkage($data: String){
  dataCollectionApprovalClipsLinkage(data: $data)
}`;

export const GetMfk120AggregatedValidation = `
    query getMfk120AggregatedValidation($drive_id: String, $validation_id: String){
        getMfk120AggregatedValidation(drive_id: $drive_id, validation_id: $validation_id)
}`;

export const GetMfk120AggregatedValidationSplits = `
  query getMfk120AggregatedValidationSplits($drive_id: String, $validation_id: String, $limit: Int, $nextToken: String, $filter: TableMfk120AggregatedValidationFilterInput) {
    getMfk120AggregatedValidationSplits(drive_id: $drive_id, validation_id: $validation_id, limit: $limit, nextToken: $nextToken, filter: $filter) {
      items
      nextToken
  }
}`;

export const QueryTestOrdersByDates = `
  query queryTestOrdersByDates($project_id: String, $start: AWSDate, $end: AWSDate, $testrun_config_label: String, $limit: Int, $nextToken: String) {
    queryTestOrdersByDates(project_id: $project_id, start: $start, end: $end, testrun_config_label: $testrun_config_label, limit: $limit, nextToken: $nextToken) {
      items
      nextToken
  }
}`;

export const QueryTestRunExecutionsByDates = `
  query queryTestRunExecutionsByDates($project_id: String, $start: AWSDate, $end: AWSDate, $limit: Int, $nextToken: String) {
    queryTestRunExecutionsByDates(project_id: $project_id, start: $start, end: $end, limit: $limit, nextToken: $nextToken) {
      items
      nextToken
  }
}`;

export const QueryVrctQuery = `
query queryVRCT($queries: VRCTQuery!) {
  queryVRCT(queries: $queries)
}`;

export const SaveKpitoolTriggerQuery = `
query save_kpitool_trigger($test_order_id: String!, $test_run_id: String!, $kpi_script: String!) {
  save_kpitool_trigger(
    test_order_id: $test_order_id,
    test_run_id: $test_run_id,
    kpi_script: $kpi_script
  )
}`;

export const SaveReprocessingConfigurationQuery = `
query save_reprocessing_configuration($reprocessing_configuration: String!) {
  save_reprocessing_configuration(reprocessing_configuration: $reprocessing_configuration)
}`;

export const SaveTestOrderQuery = `
query save_test_order($bordnet_version: String, $fibex_xml_filename: String, $cluster: String!, $test_order: String!, $reprocessing_config: String!, $test_sessions: String!, $kpi_project_ids: [String!]!, $session_to_kpiscipts_mapping: String!, $session_to_kpithresholds_mapping: String!, $testrun_config_label: String!, $transcoding_execution_type: String!, $transcoder_streams: String!, $dlt_transcoder_msg_ids: String!, $streams_selection_type: String!, $gt_selection: String!, $matching_generator: String!, $matching_enabled: Boolean!, $matching_stream: String!, $enable_pcp_postprocessing: Boolean!, $report_chunk_size: Int!, $report_merge_variant: String!, $function: String!, $comparison_report: Boolean) {
  save_test_order(
    bordnet_version: $bordnet_version
    fibex_xml_filename: $fibex_xml_filename
    cluster: $cluster
    test_order: $test_order
    reprocessing_config: $reprocessing_config
    test_sessions: $test_sessions
    kpi_project_ids: $kpi_project_ids
    session_to_kpiscipts_mapping: $session_to_kpiscipts_mapping
    session_to_kpithresholds_mapping: $session_to_kpithresholds_mapping
    testrun_config_label: $testrun_config_label
    transcoding_execution_type: $transcoding_execution_type
    transcoder_streams: $transcoder_streams
    dlt_transcoder_msg_ids: $dlt_transcoder_msg_ids
    streams_selection_type: $streams_selection_type
    gt_selection: $gt_selection
    matching_generator: $matching_generator
    matching_enabled: $matching_enabled
    matching_stream: $matching_stream
    enable_pcp_postprocessing: $enable_pcp_postprocessing
    report_chunk_size: $report_chunk_size
    report_merge_variant: $report_merge_variant
    function: $function,
    comparison_report: $comparison_report
  )
}`;

export const SaveTestOrderQueryTmpMik = `
query save_test_order($bordnet_version: String, $fibex_xml_filename: String, $cluster: String!, $test_order: String!, $reprocessing_config: String!, $test_sessions: String!, $kpi_project_ids: [String!]!, $session_to_kpiscipts_mapping: String!, $session_to_kpithresholds_mapping: String!, $testrun_config_label: String!, $transcoding_execution_type: String!, $transcoder_streams: String!, $dlt_transcoder_msg_ids: String!, $streams_selection_type: String!, $gt_selection: String!, $matching_generator: String!, $matching_enabled: Boolean!, $matching_stream: String!, $enable_pcp_postprocessing: Boolean!, $report_chunk_size: Int!, $report_merge_variant: String!, $function: String!) {
  save_test_order(
    bordnet_version: $bordnet_version
    fibex_xml_filename: $fibex_xml_filename
    cluster: $cluster
    test_order: $test_order
    reprocessing_config: $reprocessing_config
    test_sessions: $test_sessions
    kpi_project_ids: $kpi_project_ids
    session_to_kpiscipts_mapping: $session_to_kpiscipts_mapping
    session_to_kpithresholds_mapping: $session_to_kpithresholds_mapping
    testrun_config_label: $testrun_config_label
    transcoding_execution_type: $transcoding_execution_type
    transcoder_streams: $transcoder_streams
    dlt_transcoder_msg_ids: $dlt_transcoder_msg_ids
    streams_selection_type: $streams_selection_type
    gt_selection: $gt_selection
    matching_generator: $matching_generator
    matching_enabled: $matching_enabled
    matching_stream: $matching_stream
    enable_pcp_postprocessing: $enable_pcp_postprocessing
    report_chunk_size: $report_chunk_size
    report_merge_variant: $report_merge_variant
    function: $function
  )
}`;

export const SaveTestRunQuery = `
query save_test_run($testrun_id: String!, $testrun_config: String!, $reprocessing_config: String!, $test_sessions: String!, $session_to_kpiscipts_mapping: String!, $testrun_config_label: String!, $response: String!, $status: String!, $testOrderId: String!) {
  save_test_run(
    testrun_id: $testrun_id,
    testrun_config: $testrun_config,
    reprocessing_config: $reprocessing_config,
    test_sessions: $test_sessions,
    session_to_kpiscipts_mapping: $session_to_kpiscipts_mapping,
    testrun_config_label: $testrun_config_label,
    response: $response,
    status: $status,
    testOrderId: $testOrderId
  )
}`;

export const SaveTestRunListQuery = `
query save_test_run_list($testrun_list: [TestRun]) {
  save_test_run_list(
    testrun_list: $testrun_list
  )
}`;

export const SaveTestSessionQuery = `
query save_test_session($test_session: String!) {
  save_test_session(test_session: $test_session)
}`;

export const GetBordnetVersionsQuery = `
query get_bordnet_versions($project: String) {
  get_bordnet_versions(project: $project)
}`;

export const SetDriveIdApprovalsQuery = `query setDriveIdApprovals($drive_id: ID!, $vin: ID!, $approvals: [Approval!]!) {
  setDriveIdApprovals(drive_id: $drive_id, vin: $vin, approvals: $approvals) {
    is_success
    drive_id
    vin
  }
}`;

export const GetApprovalsListQuery = `query getApprovalsList($drive_id: ID) {
  getApprovalsList(drive_id: $drive_id) {
    drive_id
    approval_list {
      user
      approval
      comment
      email
    }
  }
}`;

export const PostApprovalsListQuery = `query postApprovalsList($drive_ids_list: [ID], $approvals: [Approval]) {
  postApprovalsList(drive_ids_list: $drive_ids_list, approvals: $approvals) {
    is_success
    drive_ids
  }
}`;

export const TestRunConfigurationQuery = `
query test_run_configuration {
  test_run_configuration {
    id
    label
    bmw_job_version
    bordnet_version
    ecu_init_string_version
    dut_version
    rbs_version
    vehicle_type
    hil_sw_version
    sil_sw_version
    conti_processed_data
    aptiv_processed_data
    type
    configupdate_version
    ecu_init_version
  }
}
`;

export const UpdateTestRunConfigurationQuery = `
query updateTestRunConfiguration($test_run_configuration: RmUpdateTestRunConfigurationRequest) {
  updateTestRunConfiguration(
    test_run_configuration: $test_run_configuration
  ) {
    message
  }
}
`;

export const DeleteTestRunConfiguration = `
query deleteTestRunConfiguration($label: String) {
  deleteTestRunConfiguration(
    label: $label
  ) {
    message
  }
}
`;

export const AddNewTestRunConfiguration = `
query addNewTestRunConfiguration($test_run_configuration: RmPostTestRunConfigurationRequest) {
  addNewTestRunConfiguration(
    test_run_configuration: $test_run_configuration
  ) {
    message
  }
}
`;

export const TestrunStartQuery = `
query testrun_start($test_run_id: String!){
  testrun_start(
    test_run_id: $test_run_id
  )
}
`;

export const TestrunStoreQuery = `
query testrun_store($test_run_id: String!, $test_run_config: String!){
  testrun_store(
    test_run_id: $test_run_id,
    test_run_config: $test_run_config
  )
}
`;

export const TmaTcTriggeringQuery = `
query tma_tc_triggering($transcoding_configuration: String!) {
  tma_tc_triggering(
    transcoding_configuration: $transcoding_configuration
  )
}`;

export const QueryGetPlaylists = `query getPlaylists($project: String, $type: String, $limit: Int, $nextToken: String){
  getPlaylists(project: $project, type: $type, limit: $limit, nextToken: $nextToken) {
    items {
      playlist_id
      sk
      configurations {
        drive_sessions_number
        steps
        steps_number
      }
      created_timestamp
      playlist_name
      playlist_version
      project
      status
      type
      updated_timestamp
      validation_verdict
      step
      splits
      execution_details {
        duration
        status
        tc_session_id
        test_run_ids
      }
      execution_id
      execution_name
      execution_request {
        repro_test_run_config {
          aptiv_processed_data
          bmw_job_version
          conti_processed_data
          dut_version
          ecu_init_string_version
          ecu_init_version
          hil_sw_version
          rbs_version
          sil_sw_version
          vehicle_type
        }
        repro_type
        steps
        transcoding_request
      }
    }
    nextToken
  }
}`;

export const QueryGetPlaylist = `query getPlaylist($playlist_id: ID, $sk: String, $limit: Int, $nextToken: String){
  getPlaylist(playlist_id: $playlist_id, sk: $sk, limit: $limit, nextToken: $nextToken) {
    items {
      playlist_id
      sk
      configurations {
        drive_sessions_number
        steps
        steps_number
      }
      created_timestamp
      drive_id
      playlist_name
      playlist_version
      project
      status
      type
      updated_timestamp
      validation_verdict
      step
      splits
      execution_details {
        duration
        status
        tc_session_id
        test_run_ids
      }
      execution_id
      execution_name
      execution_request {
        repro_test_run_config {
          aptiv_processed_data
          bmw_job_version
          conti_processed_data
          dut_version
          ecu_init_string_version
          ecu_init_version
          hil_sw_version
          rbs_version
          sil_sw_version
          vehicle_type
        }
        repro_type
        steps
        transcoding_request
      }
    }
    nextToken
  }
}`;
