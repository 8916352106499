import { Injectable } from '@angular/core';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';
import { Observable } from 'rxjs';
import {
  GetTestSessionsPayload,
  GetTestSessionsResponse,
  QuerySaveTestSessionPayload,
  QuerySaveTestSessionResponse,
} from '@model/payloads';

@Injectable({
  providedIn: 'root',
})
export class TestSessionService {
  constructor(private graphqlApiService: AwsAmplifyApiService) {}

  saveTestSession(
    querySaveTestSessionPayload: QuerySaveTestSessionPayload
  ): Observable<QuerySaveTestSessionResponse> {
    return this.graphqlApiService.graphQLQuery(querySaveTestSessionPayload);
  }

  getTestSessions(
    getTestSessionsPayload: GetTestSessionsPayload
  ): Observable<GetTestSessionsResponse> {
    return this.graphqlApiService.graphQLQuery(getTestSessionsPayload);
  }
}
