import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-textarea-form',
  templateUrl: './textarea-form.component.html',
})
export class TextareaFormComponent implements OnInit {
  @Input() formGroup = new UntypedFormGroup({});
  @Input() formTitle = '';
  @Input() rowHeight = '50em';
  @Input() formControlsMetadata: FormControlsMetadata = {};
  @Input() formControlsConfig: FormControlsConfig = {};
  @Input() isFormActionsVisible: boolean = true;
  @Input() cdkAutosizeMinRows: number = 10;
  @Input() cdkAutosizeMaxRows: number = 50;
  @Output() formSubmitted: EventEmitter<FormControlsConfig> =
    new EventEmitter();
  @Output() formValueChanged: EventEmitter<FormControlsConfig> =
    new EventEmitter();

  ngOnInit(): void {
    this.formGroup.valueChanges.subscribe(
      (formControlsConfig: FormControlsConfig) => {
        this.formValueChanged.emit(formControlsConfig);
      }
    );
  }

  onSubmit(): void {
    this.formSubmitted.emit(this.formGroup.value);
  }
}
