import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { QueryBuilderQuery } from '@core/amplify/aws-apmlify-query.constant';
import { TmaUtilsStaticService } from '@core/services/tma-utils-static.service';
import { QueryBuilderPayload } from '@model/payloads';
import { LoadTestSessionPlayListData } from '@modules/test-run-configuration/store/test-run-configuration.actions';
import * as fromTestRunConfiguration from '@modules/test-run-configuration/store/test-run-configuration.reducer';
import { Store } from '@ngrx/store';
import { isUppercaseValidator } from '@validators/is-uppercase.validator';

@Component({
  selector: 'app-test-session-playlist-dialog',
  templateUrl: './test-session-playlist-dialog.component.html',
})
export class TestSessionPlaylistDialogComponent {
  queryBuilderControlsMetadata: QueryBuilderFormControlsMetadata = {
    VIN: TmaUtilsStaticService.getFromControlMetadata('VIN', 'VIN', 'input', {
      tooltip: 'Vehicle Identification Number',
    }),
    astep: TmaUtilsStaticService.getFromControlMetadata('astep', 'Astep'),
    bordnet: TmaUtilsStaticService.getFromControlMetadata('bordnet', 'Bordnet'),
    campaign: TmaUtilsStaticService.getFromControlMetadata(
      'campaign',
      'Campaign'
    ),
    comment: TmaUtilsStaticService.getFromControlMetadata('comment', 'Comment'),
    country: TmaUtilsStaticService.getFromControlMetadata('country', 'Country'),
    customer: TmaUtilsStaticService.getFromControlMetadata(
      'customer',
      'Customer'
    ),
    date: TmaUtilsStaticService.getFromControlMetadata('date', 'Date'),
    driving_side: TmaUtilsStaticService.getFromControlMetadata(
      'driving_side',
      'Driving side'
    ),
    events: TmaUtilsStaticService.getFromControlMetadata('events', 'Events'),
    head_light_type: TmaUtilsStaticService.getFromControlMetadata(
      'head_light_type',
      'Head light type'
    ),
    limit: TmaUtilsStaticService.getFromControlMetadata('limit', 'Limit'),
    logger_version: TmaUtilsStaticService.getFromControlMetadata(
      'logger_version',
      'Logger version'
    ),
    project: TmaUtilsStaticService.getFromControlMetadata('project', 'Project'),
    queues: TmaUtilsStaticService.getFromControlMetadata('queues', 'Queues'),
    steering_wheel_side: TmaUtilsStaticService.getFromControlMetadata(
      'steering_wheel_side',
      'Steering wheel side'
    ),
    time: TmaUtilsStaticService.getFromControlMetadata('time', 'Time'),
    show_event_details: TmaUtilsStaticService.getFromControlMetadata(
      'show_event_details',
      'Show event details',
      'checkbox'
    ),
    show_devices: TmaUtilsStaticService.getFromControlMetadata(
      'show_devices',
      'Show devices',
      'checkbox'
    ),
    extended: TmaUtilsStaticService.getFromControlMetadata(
      'extended',
      'Extended',
      'checkbox'
    ),
    verbose: TmaUtilsStaticService.getFromControlMetadata(
      'verbose',
      'Verbose',
      'checkbox'
    ),
  };
  queryBuilderControlsConfig: FormControlsConfig = {
    VIN: null,
    astep: [null, [isUppercaseValidator]],
    bordnet: null,
    campaign: null,
    comment: null,
    country: null,
    customer: null,
    date: null,
    driving_side: null,
    events: null,
    head_light_type: null,
    limit: [null, [Validators.required]],
    logger_version: null,
    project: null,
    queues: null,
    steering_wheel_side: null,
    time: null,
    show_event_details: false,
    show_devices: false,
    extended: false,
    verbose: false,
  };
  queryBuilderFormGroup = this.formBuilder.group(
    this.queryBuilderControlsConfig
  );

  onSubmit(formControlsConfig: FormControlsConfig): void {
    const query =
      TmaUtilsStaticService.filterEmptyFormFieldsValues<FormControlsConfig>(
        formControlsConfig
      );

    const payload: QueryBuilderPayload = {
      query: QueryBuilderQuery,
      variables: { query },
    };
    this.store.dispatch(new LoadTestSessionPlayListData(payload));
    this.dialogRef.close();
  }

  constructor(
    public dialogRef: MatDialogRef<TestSessionPlaylistDialogComponent>,
    private store: Store<fromTestRunConfiguration.State>,
    private formBuilder: UntypedFormBuilder
  ) {}
}
