<mat-card>
  <mat-card-content>
    <mat-form-field appearance="fill">
      <mat-label>Test Run ID</mat-label>
      <input
        matInput
        (keyup.enter)="onLoadMoreTestRunExecutionsByTestRunId($event)"
        [placeholder]="'Ex.200937188'"
        matTooltip="Input your Test Run ID and click 'Enter'"
        matTooltipPosition="below"
      />
    </mat-form-field>

    <div fxLayout="row" fxLayoutAlign="space-between start">
      <div fxFlex="70">
        <div class="container mat-elevation-z8">
          <div class="loading-shade" *ngIf="isTestRunExecutionsLoadingResults">
            <mat-spinner
              *ngIf="isTestRunExecutionsLoadingResults"
            ></mat-spinner>
          </div>

          <div class="table-container">
            <table
              mat-table
              [dataSource]="testRunExecutionsData"
              matSort
              matSortDisabled="true"
            >
              <ng-container matColumnDef="test-order-id">
                <th mat-header-cell *matHeaderCellDef>Test Run ID</th>
                <td mat-cell *matCellDef="let row">
                  {{ row['test-order-id'] }}
                </td>
              </ng-container>

              <ng-container matColumnDef="createdTimeStamp">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  disableClear
                >
                  Created
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row['createdTimeStamp'] }}
                </td>
              </ng-container>

              <ng-container matColumnDef="testrun-config-label">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  disableClear
                >
                  Test Run Config Label
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row['testrun-config-label'] }}
                </td>
              </ng-container>

              <ng-container matColumnDef="owner">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  disableClear
                >
                  Owner
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row['owner'] }}
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="
                  testRunExecutionsDisplayedColumns;
                  sticky: true
                "
              ></tr>
              <tr
                (click)="onTestRunExecutionSelected($event, row)"
                mat-row
                [ngClass]="
                  row['test-order-id'] === selectedTestRunId
                    ? 'row-selected'
                    : ''
                "
                *matRowDef="let row; columns: testRunExecutionsDisplayedColumns"
              ></tr>
            </table>
          </div>
        </div>

        <mat-card-actions fxLayout="row" fxLayoutAlign="start center">
          <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="onFetchTestRunExecutions($event)"
            matTooltip="Refresh table data"
            matTooltipPosition="below"
          >
            <mat-icon>refresh</mat-icon>
          </button>
          <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="onLoadMoreTestRunExecutions($event)"
            matTooltip="Load more items"
            matTooltipPosition="below"
          >
            <mat-icon>system_update_alt</mat-icon>
          </button>
          <button
            mat-raised-button
            color="primary"
            type="button"
            [disabled]="isStartButtonDisabled"
            (click)="onStartTestRunExecutions()"
            matTooltip="Start test run configuration"
            matTooltipPosition="below"
          >
            <mat-icon>start</mat-icon>
          </button>
          <div class="badge-container">
            <div
              [matBadge]="testRunExecutionsItemsNumber"
              matBadgeOverlap="false"
              class="demo-section"
            >
              Records
            </div>
          </div>
        </mat-card-actions>
      </div>

      <div fxFlex="30" class="progress-container">
        <div class="progress">
          <mat-card-subtitle
            >Reprocessing complete
            {{ reprocessingPercentComplete }} %</mat-card-subtitle
          >
          <mat-progress-bar
            color="primary"
            mode="determinate"
            [value]="reprocessingPercentComplete!"
          ></mat-progress-bar>
        </div>

        <div class="progress">
          <mat-card-subtitle
            >Reprocessing success
            {{ reprocessingSuccessPercent }} %</mat-card-subtitle
          >
          <mat-progress-bar
            color="primary"
            mode="determinate"
            [value]="reprocessingSuccessPercent!"
          ></mat-progress-bar>
        </div>

        <div class="progress">
          <mat-card-subtitle
            >Transcoding success
            {{ transcodingSuccessPercent }} %</mat-card-subtitle
          >
          <mat-progress-bar
            color="primary"
            mode="determinate"
            [value]="transcodingSuccessPercent!"
          ></mat-progress-bar>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>KPI execution details</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <app-table
      class="
        trc-accordion_trm-panel_rr-accordion_kpi-execs-panel_kpi-execs-table
      "
      [displayedColumns]="kpiJobExecutionsColumns"
      [dataSource]="kpiJobExecutionsDataSource"
      [isAsyncDataSource]="false"
      [isSortDisabled]="true"
      [multipleSelections]="false"
      [selection]="kpiJobExecutionsTableSelection"
      (tableItemSelected)="onKpiJobExecutionsItemSelected($event)"
    ></app-table>

    <mat-card-actions fxLayout="row" fxLayoutAlign="start center">
      <a
        mat-raised-button
        color="primary"
        matTooltip="Download report"
        matTooltipPosition="below"
        [href]="kpiJobExecutionReportLink"
        [disabled]="!kpiJobExecutionReportLink"
        ><mat-icon>file_download</mat-icon></a
      >
    </mat-card-actions>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-content>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Reprocessing details</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="container mat-elevation-z8">
        <div class="loading-shade" *ngIf="isReproResultsLoadingResults">
          <mat-spinner *ngIf="isReproResultsLoadingResults"></mat-spinner>
        </div>

        <div class="table-container">
          <table
            mat-table
            [dataSource]="reproResultsData"
            matSort
            matSortDisabled="true"
          >
            <ng-container matColumnDef="created_date">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                disableClear
              >
                Created
              </th>
              <td mat-cell *matCellDef="let row">
                {{ row['created_date'] }}
              </td>
            </ng-container>

            <ng-container matColumnDef="updated_date">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                disableClear
              >
                Updated
              </th>
              <td mat-cell *matCellDef="let row">
                {{ row['updated_date'] }}
              </td>
            </ng-container>

            <ng-container matColumnDef="recording_id">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                disableClear
              >
                Recording ID
              </th>
              <td mat-cell *matCellDef="let row">
                {{ row['recording_id'] }}
              </td>
            </ng-container>

            <ng-container matColumnDef="hil_group_id">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                disableClear
              >
                Hil Group ID
              </th>
              <td mat-cell *matCellDef="let row">
                {{ row['hil_group_id'] }}
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                disableClear
              >
                Repro Status
              </th>
              <td mat-cell *matCellDef="let row">
                {{ row['status'] }}
              </td>
            </ng-container>

            <ng-container matColumnDef="vbs_transcoded">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                disableClear
              >
                VBS Transcoded Status
              </th>
              <td mat-cell *matCellDef="let row">
                {{ row['vbs_transcoded'] }}
              </td>
            </ng-container>

            <ng-container matColumnDef="hil_error">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                disableClear
              >
                HIL Error
              </th>
              <td mat-cell *matCellDef="let row">
                {{ row['hil_error'] }}
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="reproResultsDisplayedColumns; sticky: true"
            ></tr>
            <tr
              (click)="renderReproDetailsMap($event, row)"
              mat-row
              [ngClass]="
                row['queue_id'] === selectedReproQueueId ? 'row-selected' : ''
              "
              *matRowDef="let row; columns: reproResultsDisplayedColumns"
            ></tr>
          </table>
        </div>
      </div>

      <mat-card-actions fxLayout="row" fxLayoutAlign="start center">
        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="onLoadMoreReproResultsByTestRunId($event)"
          matTooltip="Load more items"
          matTooltipPosition="below"
        >
          <mat-icon>system_update_alt</mat-icon>
        </button>
        <div class="badge-container">
          <div
            [matBadge]="reproResultsItemsNumber"
            matBadgeOverlap="false"
            class="demo-section"
          >
            Records
          </div>
        </div>
      </mat-card-actions>

      <div class="container mat-elevation-z8">
        <div class="table-container">
          <table mat-table [dataSource]="reproResultDetailsData">
            <ng-container matColumnDef="property">
              <th mat-header-cell *matHeaderCellDef>PROPERTY</th>
              <td mat-cell *matCellDef="let row">{{ row.property }}:</td>
            </ng-container>
            <ng-container matColumnDef="value" [sticky]="true">
              <th mat-header-cell *matHeaderCellDef>VALUE</th>
              <td mat-cell *matCellDef="let row">{{ row.value }}</td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="
                reproResultDetailsDisplayedColumns;
                sticky: true
              "
            ></tr>
            <tr
              mat-row
              class="details-row"
              *matRowDef="let row; columns: reproResultDetailsDisplayedColumns"
            ></tr>
          </table>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-card-content>
</mat-card>
