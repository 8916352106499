<mat-card>
  <mat-card-content>
    <div fxLayout="column" fxLayoutAlign="start start">
      <mat-radio-group fxLayoutGap [value]="currentProjectName">
        <mat-radio-button
          *ngFor="let projectsName of projectsNames"
          [value]="projectsName"
          (change)="onProjectNameChange($event.value)"
        >
          {{ projectsName }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-accordion class="trc-accordion">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Create Test Run Configuration</mat-panel-title>
        </mat-expansion-panel-header>
        <app-rm-test-run-configuration
          [currentProjectName]="currentProjectName"
        ></app-rm-test-run-configuration>
      </mat-expansion-panel>

      <mat-expansion-panel class="trc-accordion_trc-panel">
        <mat-expansion-panel-header>
          <mat-panel-title>Create Reprocessing Configuration</mat-panel-title>
        </mat-expansion-panel-header>
        <app-test-run-configuration-create
          [currentProjectName]="currentProjectName"
        ></app-test-run-configuration-create>
      </mat-expansion-panel>

      <mat-expansion-panel class="trc-accordion_trs-panel" expanded="true">
        <mat-expansion-panel-header>
          <mat-panel-title>Submit Test Run Configuration to RM</mat-panel-title>
        </mat-expansion-panel-header>

        <div
          class="trc-accordion_trs-panel_trc-date-picker"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <mat-form-field appearance="fill">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input
              [formGroup]="testOrdersRangeFormGroup"
              [rangePicker]="picker"
            >
              <input
                matStartDate
                formControlName="start"
                placeholder="Start date"
              />
              <input matEndDate formControlName="end" placeholder="End date" />
            </mat-date-range-input>
            <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error
              *ngIf="
                testOrdersRangeFormGroup.controls.start.hasError(
                  'matStartDateInvalid'
                )
              "
              >Invalid start date</mat-error
            >
            <mat-error
              *ngIf="
                testOrdersRangeFormGroup.controls.end.hasError(
                  'matEndDateInvalid'
                )
              "
              >Invalid end date</mat-error
            >
          </mat-form-field>

          <mat-card-actions
            class="trc-accordion_trs-panel_trc-actions"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <button
              class="trc-accordion_trs-panel_trc-actions_submit-button"
              mat-raised-button
              color="primary"
              type="button"
              (click)="onSubmitTestOrdersByDates()"
              [disabled]="
                testOrdersRangeFormGroup.invalid || testOrdersTableSpinner
              "
            >
              Submit
            </button>
          </mat-card-actions>
        </div>

        <app-table
          class="trc-accordion_trc-panel_trs-table"
          [displayedColumns]="testOrdersTableColumns"
          [dataSource]="filteredTestOrdersTableDataSource"
          (tableItemSelected)="onTestRunSubmissionItemSelected($event)"
          [multipleSelections]="false"
          [selection]="testOrdersTableSelection"
          [spinner]="testOrdersTableSpinner"
          [showFilter]="false"
          [isSortDisabled]="true"
        ></app-table>

        <mat-card-actions
          class="trc-accordion_trc-panel_trs-actions"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <button
            class="trc-accordion_trc-panel_trs-actions_sync-button"
            mat-raised-button
            color="primary"
            type="button"
            (click)="onSubmitTestOrdersByDates()"
            matTooltip="Refresh table data"
            matTooltipPosition="below"
          >
            <mat-icon>refresh</mat-icon>
          </button>

          <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="onLoadMoreTestOrders()"
            matTooltip="Load more"
            matTooltipPosition="below"
            [disabled]="testOrdersTableSpinner"
          >
            <mat-icon>system_update_alt</mat-icon>
          </button>

          <button
            class="trc-accordion_trc-panel_trs-actions_start-button"
            mat-raised-button
            color="primary"
            type="button"
            matTooltip="Submit Test Run Configuration to RM"
            matTooltipPosition="below"
            (click)="onSubmitTestOrderForm()"
            [disabled]="isSubmitTestRunExecutionButtonDisabled"
          >
            <mat-icon>start</mat-icon>
          </button>
        </mat-card-actions>
      </mat-expansion-panel>

      <mat-expansion-panel class="trc-accordion_trm-panel" expanded="true">
        <mat-expansion-panel-header>
          <mat-panel-title>Test Run Configuration execution</mat-panel-title>
        </mat-expansion-panel-header>

        <div
          class="trc-accordion_trm-panel_tre-date-picker"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <mat-form-field appearance="fill">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input
              [formGroup]="testRunExecutionsRangeFormGroup"
              [rangePicker]="picker1"
            >
              <input
                matStartDate
                formControlName="start"
                placeholder="Start date"
              />
              <input matEndDate formControlName="end" placeholder="End date" />
            </mat-date-range-input>
            <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker1></mat-date-range-picker>

            <mat-error
              *ngIf="
                testRunExecutionsRangeFormGroup.controls.start.hasError(
                  'matStartDateInvalid'
                )
              "
              >Invalid start date</mat-error
            >
            <mat-error
              *ngIf="
                testRunExecutionsRangeFormGroup.controls.end.hasError(
                  'matEndDateInvalid'
                )
              "
              >Invalid end date</mat-error
            >
          </mat-form-field>

          <mat-card-actions
            class="tc-accordion_tc-panel_tc-actions"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <button
              class="trc-accordion_trm-panel_trc-actions_submit-button"
              mat-raised-button
              color="primary"
              type="button"
              (click)="onSubmitTestRunExecutionsByDates()"
              [disabled]="
                testRunExecutionsRangeFormGroup.invalid ||
                testRunExecutionsTableSpinner
              "
            >
              Submit
            </button>
          </mat-card-actions>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between start">
          <div fxFlex="70">
            <app-table
              class="trc-accordion_trm-panel_trc-table"
              [displayedColumns]="testRunExecutionsTableColumns"
              [dataSource]="filteredTestRunExecutionsTableDataSource"
              (tableItemSelected)="onTestrunExecutionsItemSelected($event)"
              [multipleSelections]="false"
              [selection]="testRunExecutionsTableSelection"
              [spinner]="testRunExecutionsTableSpinner"
              [showFilter]="false"
              [isSortDisabled]="true"
            ></app-table>

            <div fxLayout="column" fxLayoutAlign="start start">
              <mat-card-actions
                class="trc-accordion_trm-panel_trc-actions"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                <button
                  class="trc-accordion_trm-panel_trc-actions_sync-button"
                  mat-raised-button
                  color="primary"
                  type="button"
                  (click)="onSubmitTestRunExecutionsByDates()"
                  matTooltip="Refresh table data"
                >
                  <mat-icon>refresh</mat-icon>
                </button>

                <button
                  mat-raised-button
                  color="primary"
                  type="button"
                  (click)="onLoadMoreTestRunExecutions()"
                  matTooltip="Load more"
                  matTooltipPosition="below"
                  [disabled]="testRunExecutionsTableSpinner"
                >
                  <mat-icon>system_update_alt</mat-icon>
                </button>

                <button
                  class="trc-accordion_trm-panel_trc-actions_start-button"
                  mat-raised-button
                  color="primary"
                  type="button"
                  (click)="onStartTestRunConfig()"
                  [disabled]="
                    testrunExecutionSelected.reprocessing_started ||
                    !testrunExecutionSelected.testRunId.length
                  "
                  matTooltip="Start execution"
                >
                  <mat-icon>start</mat-icon>
                </button>

                <button
                  class="trc-accordion_trm-panel_trc-actions_sync-button"
                  mat-raised-button
                  color="primary"
                  type="button"
                  (click)="openCancelTestRunExecutionConfirmDialog()"
                  [disabled]="
                    !testrunExecutionSelected.testRunId.length ||
                    testrunExecutionSelected.test_run_execution_state ===
                      'canceled' ||
                    testrunExecutionSelected.test_run_execution_state ===
                      'cancelling' ||
                    testrunExecutionSelected.test_run_execution_state ===
                      'completed'
                  "
                  matTooltip="Cancel execution"
                >
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-card-actions>

              <div
                *ngIf="startTestrunExecutionSuccessMessage"
                class="mat-card-actions-result-message"
              >
                <strong> {{ startTestrunExecutionSuccessMessage }}</strong>
              </div>

              <mat-error
                *ngIf="startTestrunExecutionErrorMessage"
                class="mat-card-actions-error-message"
                ><strong>{{
                  startTestrunExecutionErrorMessage
                }}</strong></mat-error
              >
            </div>
          </div>
          <div fxFlex="30" class="progress-container">
            <mat-card>
              <mat-card-header
                fxLayout="column"
                fxLayoutAlign="space-between start"
              >
                <mat-card-title>Progress details</mat-card-title>
                <mat-card-subtitle fxLayout="row" fxLayoutAlign="start center"
                  >Reprocessing started:
                  <ng-container
                    [ngSwitch]="testrunExecutionSelected.reprocessing_started"
                  >
                    <mat-icon *ngSwitchCase="true" class="green-icon"
                      >check_circle</mat-icon
                    >
                  </ng-container></mat-card-subtitle
                >
                <mat-card-subtitle
                  >Reprocessing progress:
                  <b>{{
                    testrunExecutionSelected.reprocessingProgress
                  }}</b></mat-card-subtitle
                >
                <mat-card-subtitle
                  >Reprocessing status:
                  <b>{{
                    testrunExecutionSelected.reprocessingStatus
                  }}</b></mat-card-subtitle
                >
                <mat-card-subtitle
                  >Transcoding progress:
                  <b>{{
                    testrunExecutionSelected.transcodingProgress
                  }}</b></mat-card-subtitle
                >
                <mat-card-subtitle
                  >Transcoding status:
                  <b>{{
                    testrunExecutionSelected.transcodingStatus
                  }}</b></mat-card-subtitle
                >
                <mat-card-subtitle
                  >Matching progress:
                  <b>{{
                    testrunExecutionSelected.matching_progress
                  }}</b></mat-card-subtitle
                >
                <mat-card-subtitle
                  >Matching status:
                  <b>{{
                    testrunExecutionSelected.matching_status
                  }}</b></mat-card-subtitle
                >
              </mat-card-header>
            </mat-card>
          </div>
        </div>

        <mat-accordion class="trc-accordion_trm-panel_rr-accordion">
          <mat-expansion-panel
            expanded="true"
            class="trc-accordion_trm-panel_rr-accordion_kpi-execs-panel"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>KPI jobs execution</mat-panel-title>
            </mat-expansion-panel-header>

            <app-table
              class="
                trc-accordion_trm-panel_rr-accordion_kpi-execs-panel_kpi-execs-table
              "
              [displayedColumns]="kpiJobExecutionsColumns"
              [dataSource]="filteredKpiJobExecutionsDataSource"
              [isAsyncDataSource]="false"
              [isSortDisabled]="true"
              [multipleSelections]="false"
              [selection]="kpiJobExecutionsTableSelection"
              (tableItemSelected)="onKpiJobExecutionsItemSelected($event)"
              [spinner]="kpiJobExecutionsTableSpinner"
            ></app-table>

            <mat-card
              *ngFor="let kpiJobExecutionReport of kpiJobExecutionReports"
              class="kpi-report-links-card"
            >
              <mat-card-header>
                <mat-card-subtitle
                  >Report ID: {{ kpiJobExecutionReport.id }}</mat-card-subtitle
                >
              </mat-card-header>
              <mat-button-toggle-group>
                <mat-button-toggle
                  *ngIf="kpiJobExecutionReport.download_link_report.length"
                  (click)="reportLink.click()"
                >
                  <mat-icon>download_for_offline</mat-icon>
                  <a
                    #reportLink
                    [href]="kpiJobExecutionReport.download_link_report"
                    >Report</a
                  >
                </mat-button-toggle>
                <mat-button-toggle
                  *ngIf="kpiJobExecutionReport.download_link_meta.length"
                  (click)="metaLink.click()"
                >
                  <mat-icon>download_for_offline</mat-icon>
                  <a #metaLink [href]="kpiJobExecutionReport.download_link_meta"
                    >Meta</a
                  >
                </mat-button-toggle>
                <mat-button-toggle
                  *ngIf="kpiJobExecutionReport.download_link_data.length"
                  (click)="dataLink.click()"
                >
                  <mat-icon>download_for_offline</mat-icon>
                  <a #dataLink [href]="kpiJobExecutionReport.download_link_data"
                    >Raw Data</a
                  >
                </mat-button-toggle>
              </mat-button-toggle-group>
            </mat-card>
          </mat-expansion-panel>

          <mat-expansion-panel
            class="trc-accordion_trm-panel_rr-accordion_rr-panel"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>Reprocessing jobs execution</mat-panel-title>
            </mat-expansion-panel-header>

            <app-table
              class="trc-accordion_trm-panel_rr-accordion_rr-panel_rr-table"
              [displayedColumns]="reproceccingInfoColumns"
              [dataSource]="reproceccingInfoDataSource"
              [multipleSelections]="false"
              [isAsyncDataSource]="false"
              [isSortDisabled]="true"
              (tableItemSelected)="onReproResultsItemSelected($event)"
            ></app-table>

            <mat-card
              class="validation-header"
              *ngIf="currentProjectName === projectsConstant.MIK.name"
            >
              <mat-card-header>
                <mat-card-title>Repro validation results</mat-card-title>
                <mat-card-subtitle
                  >Verdict:
                  <b>{{ getMikReproValidationVerdict }}</b></mat-card-subtitle
                >
              </mat-card-header>
              <mat-card-content>
                <cdk-virtual-scroll-viewport
                  appendOnly
                  itemSize="50"
                  fxFlex="30"
                  class="viewport"
                >
                  <mat-list>
                    <mat-list-item
                      *ngFor="
                        let item of getMikReproValidationResults
                          | keyvalue: unsorted
                      "
                    >
                      <div
                        mat-line
                        fxLayout="row"
                        fxLayoutAlign="space-between center"
                      >
                        <div>{{ item.key }}:</div>
                        <div>
                          <b>{{ item.value }}</b>
                        </div>
                      </div>
                    </mat-list-item>
                  </mat-list>
                </cdk-virtual-scroll-viewport>
              </mat-card-content>
            </mat-card>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card-content>
</mat-card>
