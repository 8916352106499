import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SnackBarConstant } from '@constants/snack-bar.constant';
import { TableConstant } from '@constants/table.constant';
import { UiMessagesConstant } from '@constants/ui-messages.constant';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';
import {
  GetKPIJobsExecutionsChunks,
  GetKpiReportsDownloadLinksQuery,
  QueryTcSessionByIdQuery,
  QueryTcSessionsByIdMatchingStatusFilteredQuery,
  QueryTcSessionsByIdTcStatusFilteredQuery,
  QueryTcSessionsByProjectQuery,
} from '@core/amplify/aws-apmlify-query.constant';
import { KpiService } from '@core/services/kpi.service';
import { SnackBarService } from '@core/services/snack-bar.service';
import { TmaUtilsStaticService } from '@core/services/tma-utils-static.service';
import { TmaUtilsService } from '@core/services/tma-utils.service';
import { environment } from '@environments/environment';
import { GraphQLQueryError } from '@model/payloads';
import { TranscodingResultItem } from '@model/transcoding';

// @ts-ignore
import moment from 'moment';
import { Observable } from 'rxjs';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-transcoding',
  templateUrl: './transcoding.component.html',
  styleUrls: ['./transcoding.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class TranscodingComponent implements OnInit {
  succeeded!: boolean | null;

  reprocessingReportData = [];

  reprocessingReportDataNextToken: string | null = null;

  projectsNames = environment.projectsNames;
  currentProjectName = environment.projectName;

  tcSessionsRangeFormGroup = this.fb.group({
    start: [
      moment().subtract(1, 'days').format('YYYY-MM-DD'),
      Validators.required,
    ],
    end: [moment().add(1, 'days').format('YYYY-MM-DD'), Validators.required],
  });
  tcSessionsColumns: string[] = [];
  tcSessionsDataSource = new MatTableDataSource<any>([]);
  filteredTcSessionsDataSource = new MatTableDataSource<any>([]);
  tcSessionsTableSelection = new SelectionModel<any>(true, []);
  tcSessionsTableSpinner = false;
  tcSessionSelected: any = {};
  tcSessionsNextToken: string | null = null;
  tcSessionsLimit = 100;

  tcSessionExecutionsColumns: string[] = [];
  tcSessionExecutionsDataSource = new MatTableDataSource<any>();
  filteredTcSessionExecutionsDataSource = new MatTableDataSource<any>();
  tcSessionExecutionsTableSelection = new SelectionModel<any>(true, []);
  tcSessionExecutionsTableSpinner = false;
  tcSessionExecutionSelected: any = {};
  tcSessionExecutionsLimit = 100;
  tcSessionExecutionsNextToken: string | null = null;

  transcodingSuccessPercent: number | null = null;
  matchingSuccessPercent: number | null = null;

  tcSessionKpiColumns: string[] = [];
  tcSessionKpiDataSource = new MatTableDataSource<any>([]);
  filteredTcSessionKpiDataSource = new MatTableDataSource<any>([]);
  tcSessionKpiTableSelection = new SelectionModel<any>(true, []);
  tcSessionKpiTableSpinner = false;

  kpiJobExecutionReports: KpiReport[];

  getReprocessingReportData(jsonString: string): any {
    try {
      let data = JSON.parse(jsonString);

      data.map((transcodingResultItem: TranscodingResultItem) => {
        delete transcodingResultItem?.['tc-item-id'];

        delete transcodingResultItem?.['repro-results'];

        transcodingResultItem.transcoding =
          transcodingResultItem?.['tc-status']?.succeeded === undefined
            ? ''
            : transcodingResultItem?.['tc-status']?.succeeded;
        transcodingResultItem.transcoding_messages =
          transcodingResultItem?.['tc-status']?.messages.join(',') || '';

        delete transcodingResultItem?.['tc-status'];

        transcodingResultItem.matching =
          transcodingResultItem?.['matching-status']?.succeeded === undefined
            ? ''
            : transcodingResultItem?.['matching-status']?.succeeded;
        transcodingResultItem.matching_messages =
          transcodingResultItem?.['matching-status']?.messages.join(',') || '';

        delete transcodingResultItem?.['matching-status'];
      });

      return data;
    } catch (e) {
      this.snackBarService.open(
        `queryTcSessionById JSON parsing error: ${e}`,
        SnackBarConstant.actions.dismiss,
        3000
      );

      return [];
    }
  }

  downloadReprocessingReportFile(): void {
    const fileName = `${this.tcSessionSelected['tc-session-id']}.xlsx`;

    const wch = 30;
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      this.reprocessingReportData
    );
    ws['!cols'] = [
      { wch },
      { wch },
      { wch },
      { wch },
      { wch },
      { wch },
      { wch },
      { wch },
      { wch },
      { wch },
      { wch },
      { wch },
      { wch },
      { wch },
    ];
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, fileName);
  }

  onLoadMoreReprocessingReportData(): void {
    this.fetchTranscodingSessionExecutions(
      this.tcSessionSelected['tc-session-id'],
      null,
      this.reprocessingReportDataNextToken
    ).subscribe(
      (response) => {
        this.reprocessingReportDataNextToken =
          response.data.queryTcSessionById.nextToken;

        const data = this.getReprocessingReportData(
          response.data.queryTcSessionById.items
        );

        this.reprocessingReportData = this.reprocessingReportData.concat(data);

        if (this.reprocessingReportDataNextToken === null) {
          this.downloadReprocessingReportFile();
        } else {
          this.onLoadMoreReprocessingReportData();
        }
      },
      (error: GraphQLQueryError) => {
        const messages =
          TmaUtilsStaticService.getGraphQLQueryErrorMessages(error);

        this.snackBarService.open(
          `queryTcSessionById error: ${messages}`,
          SnackBarConstant.actions.dismiss,
          3000
        );
      }
    );
  }

  onLoadMoreTcSessions(): void {
    if (this.tcSessionsNextToken) {
      this.tcSessionsTableSpinner = true;

      const { start, end } = this.getDatePickerDatesRange();

      this.fetchTranscodingSessionsByDates(
        this.currentProjectName,
        start,
        end,
        this.tcSessionsLimit,
        this.tcSessionsNextToken
      ).subscribe((response) => {
        this.tcSessionsNextToken =
          response.data.queryTcSessionsByProject.nextToken;

        const parsedTcSessions = this.tmaUtilsService.parseDDbResolverResponse(
          response,
          'queryTcSessionsByProject'
        );

        this.renderTcSessionsTable(parsedTcSessions);

        this.tcSessionsTableSpinner = false;
      });
    }
  }

  onLoadMoreTcSessionExecutionsFilteredByTcStatus(): void {
    if (this.tcSessionExecutionsNextToken) {
      this.tcSessionExecutionsTableSpinner = true;

      this.fetchTranscodingSessionExecutionsTcStatusFiltered(
        this.tcSessionSelected['tc-session-id'],
        this.tcSessionExecutionsLimit,
        this.tcSessionExecutionsNextToken,
        this.succeeded
      ).subscribe((response) => {
        this.tcSessionExecutionsNextToken =
          response.data.queryTcSessionsByIdTcStatusFiltered.nextToken;

        const parsedTcSessionExecutions =
          this.tmaUtilsService.parseDDbResolverResponse(
            response,
            'queryTcSessionsByIdTcStatusFiltered'
          );

        this.renderTcSessionExecutionsTable(parsedTcSessionExecutions);

        this.tcSessionExecutionsTableSpinner = false;
      });
    }
  }

  onLoadMoreTcSessionExecutions(): void {
    if (this.tcSessionExecutionsNextToken) {
      this.tcSessionExecutionsTableSpinner = true;

      this.fetchTranscodingSessionExecutions(
        this.tcSessionSelected['tc-session-id'],
        this.tcSessionExecutionsLimit,
        this.tcSessionExecutionsNextToken
      ).subscribe((response) => {
        this.tcSessionExecutionsNextToken =
          response.data.queryTcSessionById.nextToken;

        const parsedTcSessionExecutions =
          this.tmaUtilsService.parseDDbResolverResponse(
            response,
            'queryTcSessionById'
          );

        this.renderTcSessionExecutionsTable(parsedTcSessionExecutions);

        this.tcSessionExecutionsTableSpinner = false;
      });
    }
  }

  exportTableToXlsx(): void {
    this.reprocessingReportData = [];

    this.onLoadMoreReprocessingReportData();
  }

  ngOnInit(): void {
    this.onSubmitTranscodingSessionsByDates();
  }

  onProjectNameChange(currentProjectName: string): void {
    this.currentProjectName = currentProjectName;

    this.onSubmitTranscodingSessionsByDates();
  }

  onKpiJobExecutionsItemSelected(selected: any): void {
    this.kpiJobExecutionReports = [];

    if (selected.projectID && selected.kpiJobID) {
      this.kpiService
        .getKpiReportsDownloadLinks({
          query: GetKpiReportsDownloadLinksQuery,
          variables: {
            project_id: selected.projectID,
            job_id: selected.kpiJobID,
          },
        })
        .subscribe((GetKpiReportsDownloadLinksResponse) => {
          try {
            this.kpiJobExecutionReports = JSON.parse(
              GetKpiReportsDownloadLinksResponse.data.getKpiReportsDownloadLinks
            );
          } catch (e) {
            this.snackBarService.open(
              `KPI report links parsing error: ${e}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
          }
        });
    }
  }

  onSubmitTranscodingSessionsByDates(): void {
    this.tcSessionsTableSpinner = true;

    this.resetTcSessionsTableData();

    this.resetTcSessionExecutionsTableData();

    this.resetKpiJobExecutionsTable();

    const { start, end } = this.getDatePickerDatesRange();

    this.fetchTranscodingSessionsByDates(
      this.currentProjectName,
      start,
      end,
      this.tcSessionsLimit,
      null
    ).subscribe(
      (response) => {
        this.tcSessionsNextToken =
          response.data.queryTcSessionsByProject.nextToken;

        const parsedTcSessions = this.tmaUtilsService.parseDDbResolverResponse(
          response,
          'queryTcSessionsByProject'
        );

        this.renderTcSessionsTable(parsedTcSessions);

        this.tcSessionsTableSpinner = false;
      },
      (error: GraphQLQueryError) => {
        this.snackBarService.showGraphQlErrorMessage(error);

        this.tcSessionsTableSpinner = false;
      }
    );
  }

  onTcSessionSelected(selected: any): void {
    this.tcSessionSelected = selected;

    this.tcSessionExecutionSelected = {};

    this.kpiJobExecutionReports = [];

    const tcSessionId = this.tcSessionSelected['tc-session-id'];

    this.onTcSessionTcAllFilterSelected();

    this.renderKpiJobExecutionsTable(tcSessionId);
  }

  onFilterValueChange(change: MatButtonToggleChange): void {
    if (change.value === 'all') {
      this.onTcSessionTcAllFilterSelected();
    } else if (change.value === 'transcoding') {
      this.onTcSessionTcStatusFilterSelected(false);
    } else if (change.value === 'matching') {
      this.onTcSessionMatchingFilterSelected(false);
    }
  }

  onTcSessionTcAllFilterSelected(): void {
    this.succeeded = null;

    const tcSessionId = this.tcSessionSelected['tc-session-id'];

    this.renderTcSessionProgress();

    this.resetTcSessionExecutionsTableData();

    this.tcSessionExecutionsTableSpinner = true;

    this.fetchTranscodingSessionExecutions(
      tcSessionId,
      this.tcSessionExecutionsLimit,
      null
    ).subscribe(
      (response) => {
        this.tcSessionExecutionsNextToken =
          response.data.queryTcSessionById.nextToken;

        const parsedTcSessionExecutions =
          this.tmaUtilsService.parseDDbResolverResponse(
            response,
            'queryTcSessionById'
          );

        this.renderTcSessionExecutionsTable(parsedTcSessionExecutions);

        this.tcSessionExecutionsTableSpinner = false;
      },
      (error: GraphQLQueryError) => {
        this.snackBarService.showGraphQlErrorMessage(error);

        this.tcSessionExecutionsTableSpinner = false;
      }
    );
  }

  onTcSessionMatchingFilterSelected(succeeded: boolean): void {
    this.succeeded = succeeded;

    const tcSessionId = this.tcSessionSelected['tc-session-id'];

    this.renderTcSessionProgress();

    this.resetTcSessionExecutionsTableData();

    this.tcSessionExecutionsTableSpinner = true;

    this.fetchTranscodingSessionExecutionsMatchingStatusFiltered(
      tcSessionId,
      this.tcSessionExecutionsLimit,
      null,
      succeeded
    ).subscribe(
      (response) => {
        this.tcSessionExecutionsNextToken =
          response.data.queryTcSessionsByIdMatchingStatusFiltered.nextToken;

        const parsedTcSessionExecutions =
          this.tmaUtilsService.parseDDbResolverResponse(
            response,
            'queryTcSessionsByIdMatchingStatusFiltered'
          );

        this.renderTcSessionExecutionsTable(parsedTcSessionExecutions);

        this.tcSessionExecutionsTableSpinner = false;
      },
      (error: GraphQLQueryError) => {
        this.snackBarService.showGraphQlErrorMessage(error);

        this.tcSessionExecutionsTableSpinner = false;
      }
    );
  }

  onTcSessionTcStatusFilterSelected(succeeded: boolean): void {
    this.succeeded = succeeded;

    const tcSessionId = this.tcSessionSelected['tc-session-id'];

    this.renderTcSessionProgress();

    this.resetTcSessionExecutionsTableData();

    this.tcSessionExecutionsTableSpinner = true;

    this.fetchTranscodingSessionExecutionsTcStatusFiltered(
      tcSessionId,
      this.tcSessionExecutionsLimit,
      null,
      succeeded
    ).subscribe(
      (response) => {
        this.tcSessionExecutionsNextToken =
          response.data.queryTcSessionsByIdTcStatusFiltered.nextToken;

        const parsedTcSessionExecutions =
          this.tmaUtilsService.parseDDbResolverResponse(
            response,
            'queryTcSessionsByIdTcStatusFiltered'
          );

        this.renderTcSessionExecutionsTable(parsedTcSessionExecutions);

        this.tcSessionExecutionsTableSpinner = false;
      },
      (error: GraphQLQueryError) => {
        this.snackBarService.showGraphQlErrorMessage(error);

        this.tcSessionExecutionsTableSpinner = false;
      }
    );
  }

  onTcSessionExecutionsSelected(selected: any): void {
    this.tcSessionExecutionSelected = selected;
  }

  private getDatePickerDatesRange(): { start: string; end: string } {
    return {
      start: moment(this.tcSessionsRangeFormGroup.value.start).format(
        'YYYY-MM-DD'
      ),
      end: moment(this.tcSessionsRangeFormGroup.value.end).format('YYYY-MM-DD'),
    };
  }

  private fetchKPIJobsExecutionsChunks(id: string): Observable<any> {
    return this.awsAmplifyApiService.graphQLQuery({
      query: GetKPIJobsExecutionsChunks,
      variables: {
        id,
      },
    });
  }

  private fetchTranscodingSessionsByDates(
    project: string,
    start: string,
    end: string,
    limit: number,
    nextToken: string | null
  ): Observable<any> {
    return this.awsAmplifyApiService.graphQLQuery({
      query: QueryTcSessionsByProjectQuery,
      variables: {
        project,
        start,
        end,
        limit,
        nextToken,
      },
    });
  }

  private fetchTranscodingSessionExecutions(
    tc_session_id: string,
    limit: number | null,
    nextToken: string | null
  ): Observable<any> {
    return this.awsAmplifyApiService.graphQLQuery({
      query: QueryTcSessionByIdQuery,
      variables: {
        tc_session_id,
        more_then_tc_item_id: 0,
        limit,
        nextToken,
      },
    });
  }

  private fetchTranscodingSessionExecutionsTcStatusFiltered(
    tc_session_id: string,
    limit: number,
    nextToken: string | null,
    succeeded: boolean | null
  ): Observable<any> {
    return this.awsAmplifyApiService.graphQLQuery({
      query: QueryTcSessionsByIdTcStatusFilteredQuery,
      variables: {
        tc_session_id,
        more_then_tc_item_id: 0,
        succeeded,
        limit,
        nextToken,
      },
    });
  }

  private fetchTranscodingSessionExecutionsMatchingStatusFiltered(
    tc_session_id: string,
    limit: number,
    nextToken: string | null,
    succeeded: boolean | null
  ): Observable<any> {
    return this.awsAmplifyApiService.graphQLQuery({
      query: QueryTcSessionsByIdMatchingStatusFilteredQuery,
      variables: {
        tc_session_id,
        more_then_tc_item_id: 0,
        succeeded,
        limit,
        nextToken,
      },
    });
  }

  private parseProxyLambdaResolverResponse(
    response: any,
    dataKey: string
  ): any[] {
    let parsedList = [];

    try {
      parsedList = JSON.parse(response.data[dataKey]);
    } catch (e) {
      this.snackBarService.open(
        `${
          UiMessagesConstant.appsyncResponseParsingErrorMessage + dataKey
        }: ${e}`,
        SnackBarConstant.actions.dismiss,
        3000
      );
    }

    return parsedList;
  }

  private resetTcSessionsTableData(): void {
    this.tcSessionSelected = {};

    this.tcSessionsDataSource = new MatTableDataSource<any>([]);

    this.filteredTcSessionsDataSource = new MatTableDataSource<any>([]);
  }

  private resetTcSessionExecutionsTableData(): void {
    this.tcSessionExecutionsDataSource = new MatTableDataSource<any>([]);

    this.filteredTcSessionExecutionsDataSource = new MatTableDataSource<any>(
      []
    );
  }

  private resetKpiJobExecutionsTable(): void {
    this.tcSessionKpiDataSource = new MatTableDataSource<any>([]);

    this.filteredTcSessionKpiDataSource = new MatTableDataSource<any>([]);
  }

  private renderTcSessionExecutionsTable(tcSessionExecutions: any[]): void {
    if (tcSessionExecutions.length) {
      this.tcSessionExecutionsColumns = [
        ...TableConstant.tcSessionExecutionsColumns,
      ];
    }

    tcSessionExecutions.map((tcSessionExecution) => {
      if (tcSessionExecution['created-timestamp']) {
        tcSessionExecution['created-timestamp'] =
          TmaUtilsStaticService.formatDate(
            tcSessionExecution['created-timestamp']
          );
      }

      if (tcSessionExecution['tc-status']) {
        tcSessionExecution['transcoding status'] =
          tcSessionExecution['tc-status'].succeeded;
      }

      if (tcSessionExecution['matching-status']) {
        tcSessionExecution['matching status'] =
          tcSessionExecution['matching-status'].succeeded;
      }
    });

    this.tcSessionExecutionsDataSource = new MatTableDataSource(
      this.tcSessionExecutionsDataSource.data.concat(tcSessionExecutions)
    );

    this.filteredTcSessionExecutionsDataSource = new MatTableDataSource(
      this.tcSessionExecutionsDataSource.data
    );
  }

  private renderTcSessionsTable(tcSessions: any[]): void {
    if (tcSessions.length) {
      this.tcSessionsColumns = [...TableConstant.tcSessionsColumns];
    }

    tcSessions.map((tcSession) => {
      tcSession['created-timestamp'] = TmaUtilsStaticService.formatDate(
        tcSession['created-timestamp']
      );
    });

    this.tcSessionsDataSource = new MatTableDataSource(
      this.tcSessionsDataSource.data.concat(tcSessions)
    );

    this.filteredTcSessionsDataSource = new MatTableDataSource(
      this.tcSessionsDataSource.data
    );
  }

  private fillTcSessionKpiTableData(tcSessionKpiChunks: any[]): void {
    const tableData = tcSessionKpiChunks;

    if (tableData.length) {
      this.tcSessionKpiColumns = [...TableConstant.kpiJobsExecutionsColumns];
    }

    tableData.map((tcSessionKpi) => {
      tcSessionKpi.createdTimeStamp = TmaUtilsStaticService.formatDate(
        tcSessionKpi.createdTimeStamp
      );

      tcSessionKpi.updatedTimeStamp = TmaUtilsStaticService.formatDate(
        tcSessionKpi.updatedTimeStamp
      );
    });

    this.tcSessionKpiDataSource = new MatTableDataSource(tableData);

    this.filteredTcSessionKpiDataSource = new MatTableDataSource(
      this.tcSessionKpiDataSource.data
    );
  }

  private renderKpiJobExecutionsTable(tcSessionId: string): void {
    this.tcSessionKpiTableSpinner = true;

    this.fetchKPIJobsExecutionsChunks(tcSessionId).subscribe(
      (response) => {
        const parsedTcSessionKpiChunks = this.parseProxyLambdaResolverResponse(
          response,
          'getKPIJobsExecutionsChunks'
        );

        this.fillTcSessionKpiTableData(parsedTcSessionKpiChunks);

        this.tcSessionKpiTableSpinner = false;
      },
      (error: GraphQLQueryError) => {
        this.tcSessionKpiTableSpinner = false;
      }
    );
  }

  private renderTcSessionProgress(): void {
    const processingState = this.tcSessionSelected['processing-state'];

    if (processingState['transcoding']) {
      const tcProcessingState = processingState['transcoding'];

      if (tcProcessingState['requested'] > 0) {
        this.transcodingSuccessPercent = Math.round(
          ((tcProcessingState['failed'] + tcProcessingState['succeeded']) *
            100) /
            tcProcessingState['requested']
        );
      } else {
        this.transcodingSuccessPercent = 0;
      }
    } else {
      this.transcodingSuccessPercent = 0;
    }

    if (processingState['matching']) {
      const matchingState = processingState['matching'];

      if (matchingState['requested'] > 0) {
        this.matchingSuccessPercent = Math.round(
          ((matchingState['failed'] + matchingState['succeeded']) * 100) /
            matchingState['requested']
        );
      } else {
        this.matchingSuccessPercent = 0;
      }
    } else {
      this.matchingSuccessPercent = 0;
    }
  }

  constructor(
    private awsAmplifyApiService: AwsAmplifyApiService,
    private snackBarService: SnackBarService,
    private fb: FormBuilder,
    private kpiService: KpiService,
    private tmaUtilsService: TmaUtilsService
  ) {}
}
