import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatLegacyCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacySelectChange } from '@angular/material/legacy-select';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { FormConstant } from '@constants/form.constant';
import { ProjectsConstant } from '@constants/projects.constant';
import { SnackBarConstant } from '@constants/snack-bar.constant';
import { TableConstant } from '@constants/table.constant';
import { UiMessagesConstant } from '@constants/ui-messages.constant';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';
import {
  GetBordnetVersionsQuery,
  GetKpiMetaQuery,
  GetKpiProjectsQuery,
  GetReprocessingConfigurationsQuery,
  GetTestSessionsQuery,
  SaveReprocessingConfigurationQuery,
  SaveTestOrderQuery,
  SaveTestOrderQueryTmpMik,
  SaveTestSessionQuery,
  TestRunConfigurationQuery,
} from '@core/amplify/aws-apmlify-query.constant';
import { SnackBarService } from '@core/services/snack-bar.service';
import { TmaUtilsStaticService } from '@core/services/tma-utils-static.service';
import { environment } from '@environments/environment';
import {
  QuerySaveTestSessionPayload,
  SaveReprocessingConfigurationPayload,
  SaveTestOrderPayload,
} from '@model/payloads';
import { TestSessionPlaylistDialogComponent } from '@modules/test-run-configuration/components/test-session-playlist-dialog/test-session-playlist-dialog.component';
import { TestRunConfigurationConstant } from '@modules/test-run-configuration/constants/test-run-configuration.constant';
import {
  GetKpiMetaData,
  GetKpiProjectsData,
  GetReproceccingConfigurationsData,
  GetTestSessionsData,
  LoadTestRunConfigurationData,
  SaveReproceccingConfigurationData,
  SaveTestOrderData,
  SaveTestSessionData,
  TestRunConfigurationActions,
  TestRunConfigurationActionTypes,
} from '@modules/test-run-configuration/store/test-run-configuration.actions';
import {
  selectFeatureKpiMeta,
  selectFeatureKpiProjects,
  selectFeatureReproceccingConfigLabels,
  selectFeatureReproceccingConfigurations,
  selectFeatureTestSessions,
} from '@modules/test-run-configuration/store/test-run-configuration.selectors';
import { TranscodingConstant } from '@modules/transcoding/constants/transcoding.constant';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isJsonValidator } from '@validators/is-json.validator';
import { KpiSettingsFieldsValidator } from '@validators/kpi-settings-fields.validator';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-test-run-configuration-create',
  templateUrl: './test-run-configuration-create.component.html',
  styleUrls: ['./test-run-configuration-create.component.scss'],
})
export class TestRunConfigurationCreateComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() currentProjectName: string = environment.projectName;

  @ViewChild('fileUploadInput')
  fileUploadElementRef!: ElementRef;
  fileName = '';

  reprocessingConfigLabels$: Observable<string[]> = this.store.select(
    selectFeatureReproceccingConfigLabels
  );

  reproceccingConfigurations$: Observable<ReproceccingConfiguration[]> =
    this.store.select(selectFeatureReproceccingConfigurations);

  testSessions$: Observable<TestSession[]> = this.store.select(
    selectFeatureTestSessions
  );

  kpiProjects$: Observable<KpiProject[]> = this.store.select(
    selectFeatureKpiProjects
  );

  kpiProjects: KpiProject[];
  selectedKpiProject: KpiProject | { id: '' };

  transcodersTypes = environment.transcodingExecutionTypeInputOptions.map(
    (option) => option.value
  );
  tcSteamsSelectionTypes = TranscodingConstant.steamsSelectionTypes;

  bordnetVersionsMap: any = {};
  bordnetVersions: any[] = [];
  fibexFileNames: any[] = [];

  kpiGtSelections = TestRunConfigurationConstant.gtSelectionList;
  kpiClusters = TestRunConfigurationConstant.clusters;
  kpiStreams = TestRunConfigurationConstant.streams;

  transcoderStreamsSelectionColumns: string[] = ['stream'];
  transcoderStreamsSelectionDataSource = new MatTableDataSource<any>(
    environment.defaultTranscoderStreamsSelectionDataSource
  );

  kpiMeta$: Observable<KpiMeta> = this.store.select(selectFeatureKpiMeta);
  kpiMeta: KpiMeta;
  report_merge_variants: string[] = [];
  kpiFunctions = TestRunConfigurationConstant.functions;

  createTestRunConfigurationForm = this.fb.group(
    {
      cluster: '',
      test_order: '',
      reprocessing_config: ['', Validators.required],
      test_sessions: ['', Validators.required],
      kpi_project_ids: '[]',
      session_to_kpiscipts_mapping: this.fb.group({
        kpiProjectName: '',
        kpiScripts: '',
        kpiTestsuitName: '',
      }),
      session_to_kpithresholds_mapping: '[]',
      testrun_config_label: ['', [Validators.required]],
      transcoding_execution_type: TranscodingConstant.defaultTranscoder,
      transcoder_streams: '',
      dlt_transcoder_msg_ids: '',
      streams_selection_type:
        TranscodingConstant.steamsSelectionTypesMap.automatic,
      gt_selection: '',
      matching_generator: '{}',
      matching_enabled: { value: false, disabled: true },
      matching_stream: '',
      enable_pcp_postprocessing: false,
      report_chunk_size: [100, [Validators.min(1), Validators.max(5000)]],
      bordnet_version: { value: '', disabled: true },
      fibex_xml_filename: { value: '', disabled: true },
      report_merge_variant: '',
      function: '',
      // TODO: Remove disabling after new parameter will be in MIK env
      comparison_report: {
        value: false,
        disabled:
          environment.projectName === ProjectsConstant.MIK.name ? true : false,
      },
      EcuInitTriggeringMode: 'disabled',
    },
    {
      validators: [
        this.kpiSettingsFieldsValidator.isKpiFunctionSelected(),
        this.kpiSettingsFieldsValidator.isKpiTestSuiteSelected(),
      ],
    }
  );

  ecuInitTriggeringModes = ['disabled', 'auto', 'forced'];

  reproceccingConfigurationControlsMetadata: ReproceccingConfigurationControlsMetadata =
    {
      ProductName: TmaUtilsStaticService.getFromControlMetadata(
        FormConstant.formControlsNames.ProductName,
        'Project',
        'radio',
        {
          filteredInputOptions: environment.projectsFilteredInputOptions,
          tooltip: '',
        }
      ),
      ReProcessingType: TmaUtilsStaticService.getFromControlMetadata(
        'ReProcessingType',
        'Reprocessing type',
        'autocomplete',
        {
          inputOptions: environment.defaultProjectReProcessingTypes,
          filteredInputOptions: environment.defaultProjectReProcessingTypes,
          tooltip: '',
        }
      ),
      HIL: TmaUtilsStaticService.getFromControlMetadata('HIL', 'HIL'),
      ReProcessingConfigLabel: TmaUtilsStaticService.getFromControlMetadata(
        'ReProcessingConfigLabel',
        'Reprocessing config label',
        'autocomplete',
        {
          inputOptions: [],
          filteredInputOptions: [],
          tooltip: '',
        }
      ),
      TestType: TmaUtilsStaticService.getFromControlMetadata(
        'TestType',
        'Test type',
        'autocomplete',
        {
          inputOptions:
            environment.projectName === ProjectsConstant.MIK.name
              ? [
                  {
                    value: 'HilOnlyNoTranscoding',
                    placeholder: 'HilOnlyNoTranscoding',
                  },
                ]
              : [
                  { value: 'AnnotellKPI', placeholder: 'AnnotellKPI' },
                  { value: 'ExidaKPI', placeholder: 'ExidaKPI' },
                  { value: 'AevKPI', placeholder: 'AevKPI' },
                  { value: 'MagnaKPI', placeholder: 'MagnaKPI' },
                  {
                    value: 'HilOnlyNoTranscoding',
                    placeholder: 'HilOnlyNoTranscoding',
                  },
                  { value: 'PnC', placeholder: 'PnC' },
                  { value: 'CI', placeholder: 'CI' },
                ],
          filteredInputOptions:
            environment.projectName === ProjectsConstant.MIK.name
              ? [
                  {
                    value: 'HilOnlyNoTranscoding',
                    placeholder: 'HilOnlyNoTranscoding',
                  },
                ]
              : [
                  { value: 'AnnotellKPI', placeholder: 'AnnotellKPI' },
                  { value: 'ExidaKPI', placeholder: 'ExidaKPI' },
                  { value: 'AevKPI', placeholder: 'AevKPI' },
                  { value: 'MagnaKPI', placeholder: 'MagnaKPI' },
                  {
                    value: 'HilOnlyNoTranscoding',
                    placeholder: 'HilOnlyNoTranscoding',
                  },
                  { value: 'PnC', placeholder: 'PnC' },
                  { value: 'CI', placeholder: 'CI' },
                ],
          tooltip: '',
        }
      ),
      TestRunPriority: TmaUtilsStaticService.getFromControlMetadata(
        'TestRunPriority',
        'Test run priority',
        'autocomplete',
        {
          inputOptions: [
            { value: 'Low', placeholder: 'Low' },
            { value: 'Medium', placeholder: 'Medium' },
            { value: 'High', placeholder: 'High' },
          ],
          filteredInputOptions: [
            { value: 'Low', placeholder: 'Low' },
            { value: 'Medium', placeholder: 'Medium' },
            { value: 'High', placeholder: 'High' },
          ],
          tooltip: '',
        }
      ),
      LabelText: TmaUtilsStaticService.getFromControlMetadata(
        'LabelText',
        'Label text'
      ),
      Comment: TmaUtilsStaticService.getFromControlMetadata(
        'Comment',
        'Comment'
      ),
    };
  reproceccingConfigurationControlsConfig: FormControlsConfig = {
    ProductName: environment.projectId,
    ReProcessingType: [null, [Validators.required]],
    HIL: null,
    ReProcessingConfigLabel: [null, [Validators.required]],
    TestType:
      environment.projectName === ProjectsConstant.MIK.name
        ? ['HilOnlyNoTranscoding', [Validators.required]]
        : [null, [Validators.required]],
    TestRunPriority: 'Low',
    LabelText: [null, [Validators.required]],
    Comment: null,
  };
  reproceccingConfigurationFormGroup = this.fb.group(
    this.reproceccingConfigurationControlsConfig
  );

  reprocessingConfigsColumns: string[] = [];
  reprocessingConfigsDataSource = new MatTableDataSource<any>();
  filteredReprocessingConfigsDataSource = new MatTableDataSource<any>();
  reprocessingConfigsTableSelection = new SelectionModel<any>(true, []);
  reprocessingConfigsTableSpinner = false;

  testSessionsColumns: string[] = [];
  testSessionsDataSource = new MatTableDataSource<any>();
  filteredTestSessionsDataSource = new MatTableDataSource<any>();
  testSessionsTableSelection = new SelectionModel<any>(true, []);
  testSessionsTableSpinner = false;

  testSessionControlsMetadata: TestSessionControlsMetadata = {
    ProjectID: TmaUtilsStaticService.getFromControlMetadata(
      FormConstant.formControlsNames.ProjectID,
      'Project',
      'radio',
      {
        filteredInputOptions: environment.projectsFilteredInputOptions,
        tooltip: '',
      }
    ),
    TestSessionPriority: TmaUtilsStaticService.getFromControlMetadata(
      'TestSessionPriority',
      'Test session priority',
      'autocomplete',
      {
        inputOptions: [
          { value: 'Low', placeholder: 'Low' },
          { value: 'Medium', placeholder: 'Medium' },
          { value: 'High', placeholder: 'High' },
        ],
        filteredInputOptions: [
          { value: 'Low', placeholder: 'Low' },
          { value: 'Medium', placeholder: 'Medium' },
          { value: 'High', placeholder: 'High' },
        ],
        tooltip: '',
      }
    ),
    TestSessionRanking: TmaUtilsStaticService.getFromControlMetadata(
      'TestSessionRanking',
      'Test session ranking'
    ),
    PlayListVersion: TmaUtilsStaticService.getFromControlMetadata(
      'PlayListVersion',
      'Playlist version',
      'autocomplete',
      {
        inputOptions: [{ value: '2', placeholder: '2' }],
        filteredInputOptions: [{ value: '2', placeholder: '2' }],
        tooltip: '',
      }
    ),
    PlayList: TmaUtilsStaticService.getFromControlMetadata(
      'PlayList',
      'Playlist',
      'textarea'
    ),
    PlayListLearning: TmaUtilsStaticService.getFromControlMetadata(
      'PlayListLearning',
      'Playlist learning',
      'textarea'
    ),
    LabelText: TmaUtilsStaticService.getFromControlMetadata(
      'LabelText',
      'Label text'
    ),
    Comment: TmaUtilsStaticService.getFromControlMetadata(
      'Comment',
      'Comment',
      'textarea'
    ),
  };
  testSessionControlsConfig: FormControlsConfig = {
    ProjectID: environment.projectId,
    TestSessionPriority: ['Low', [Validators.required]],
    TestSessionRanking: [null, [Validators.required]],
    PlayListVersion: '2',
    PlayList: [null, [Validators.required, isJsonValidator]],
    PlayListLearning: [{ value: null, disabled: true }, [isJsonValidator]],
    LabelText: [null, [Validators.required]],
    Comment: '',
  };
  testSessionFormGroup = this.fb.group(this.testSessionControlsConfig);
  testSessionActionName = 'Generate PlayList';

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentProjectName && changes.currentProjectName.currentValue) {
      this.reprocessingConfigsTableSelection.clear();
      this.testSessionsTableSelection.clear();

      this.createTestRunConfigurationForm.patchValue({
        reprocessing_config: '',
        test_sessions: '',
      });

      this.filteredReprocessingConfigsDataSource.data =
        this.reprocessingConfigsDataSource.data;

      this.filteredTestSessionsDataSource.data =
        this.testSessionsDataSource.data;

      this.filteredReprocessingConfigsDataSource.data =
        this.filteredReprocessingConfigsDataSource.data.filter(
          (reprocessingConfig) =>
            reprocessingConfig.ProjectID ===
            ProjectsConstant[changes.currentProjectName.currentValue].id
        );

      this.filteredTestSessionsDataSource.data =
        this.filteredTestSessionsDataSource.data.filter(
          (testSession) =>
            testSession.ProjectID ===
            ProjectsConstant[changes.currentProjectName.currentValue].id
        );
    }
  }

  ngOnInit(): void {
    this.store.dispatch(
      new LoadTestRunConfigurationData({
        query: TestRunConfigurationQuery,
        variables: {},
      })
    );

    this.store.dispatch(
      new GetReproceccingConfigurationsData({
        query: GetReprocessingConfigurationsQuery,
        variables: {},
      })
    );

    this.store.dispatch(
      new GetTestSessionsData({
        query: GetTestSessionsQuery,
        variables: {},
      })
    );

    this.awsAmplifyApiService
      .graphQLQuery({
        query: GetBordnetVersionsQuery,
        variables: {
          project: this.currentProjectName.toLowerCase(),
        },
      })
      .subscribe((bordnetVersionsMap: any) => {
        try {
          this.bordnetVersionsMap = JSON.parse(
            bordnetVersionsMap.data.get_bordnet_versions
          );
          this.bordnetVersions = Object.keys(this.bordnetVersionsMap);
        } catch (e) {
          this.snackBarService.open(
            'get_bordnet_versions JSON parsing error',
            SnackBarConstant.actions.dismiss,
            3000
          );
        }
      });

    this.actions$
      .pipe(
        ofType(
          TestRunConfigurationActionTypes.GetReproceccingConfigurationsData
        )
      )
      .subscribe(() => {
        this.reprocessingConfigsTableSpinner = true;
      });

    this.actions$
      .pipe(ofType(TestRunConfigurationActionTypes.GetTestSessionsData))
      .subscribe(() => {
        this.testSessionsTableSpinner = true;
      });

    this.subscriptions.add(
      this.reprocessingConfigLabels$.subscribe((reprocessingConfigLabels) => {
        const inputOptions = reprocessingConfigLabels.map((label) => {
          return {
            value: label,
            placeholder: label,
          };
        });

        this.reproceccingConfigurationControlsMetadata.ReProcessingConfigLabel.options.inputOptions =
          inputOptions;

        this.reproceccingConfigurationControlsMetadata.ReProcessingConfigLabel.options.filteredInputOptions =
          inputOptions;
      })
    );

    this.subscriptions.add(
      this.reproceccingConfigurations$.subscribe(
        (reproceccingConfigurations) => {
          if (reproceccingConfigurations.length) {
            this.reprocessingConfigsColumns = [
              ...TableConstant.reprocessingConfigsColumns,
            ];
          }

          reproceccingConfigurations.map((reproceccingConfiguration) => {
            reproceccingConfiguration.createdTimeStamp =
              TmaUtilsStaticService.formatDate(
                reproceccingConfiguration.createdTimeStamp
              );
          });

          this.reprocessingConfigsDataSource = new MatTableDataSource(
            reproceccingConfigurations
          );

          this.filteredReprocessingConfigsDataSource = new MatTableDataSource(
            this.reprocessingConfigsDataSource.data.filter(
              (reprocessingConfig) =>
                reprocessingConfig.ProjectID ===
                ProjectsConstant[this.currentProjectName].id
            )
          );

          this.reprocessingConfigsTableSpinner = false;
        }
      )
    );

    this.subscriptions.add(
      this.testSessions$.subscribe((testSessions) => {
        if (testSessions.length) {
          this.testSessionsColumns = [...TableConstant.testSessionsColumns];
        }

        testSessions.map((testSession) => {
          testSession.createdTimeStamp = TmaUtilsStaticService.formatDate(
            testSession.createdTimeStamp
          );
        });

        this.testSessionsDataSource = new MatTableDataSource(testSessions);

        this.filteredTestSessionsDataSource = new MatTableDataSource(
          this.testSessionsDataSource.data.filter(
            (testSession) =>
              testSession.ProjectID ===
              ProjectsConstant[this.currentProjectName].id
          )
        );

        this.testSessionsTableSpinner = false;
      })
    );

    this.subscriptions.add(
      this.kpiProjects$.subscribe((kpiProjects) => {
        this.kpiProjects = kpiProjects;
      })
    );

    this.subscriptions.add(
      this.kpiMeta$.subscribe((kpiMeta) => {
        this.kpiMeta = kpiMeta;
        this.report_merge_variants = this.kpiMeta.report_merge_variants;
      })
    );

    this.store.dispatch(
      new GetReproceccingConfigurationsData({
        query: GetReprocessingConfigurationsQuery,
        variables: {},
      })
    );

    this.store.dispatch(
      new GetTestSessionsData({
        query: GetTestSessionsQuery,
        variables: {},
      })
    );

    this.store.dispatch(
      new GetKpiProjectsData({
        query: GetKpiProjectsQuery,
      })
    );

    this.store.dispatch(
      new GetKpiMetaData({
        query: GetKpiMetaQuery,
        variables: {},
      })
    );
  }

  onAttachFileButtonClick($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.fileUploadElementRef.nativeElement.click();
  }

  onAttachFile(event: Event) {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    if (file) {
      this.fileName = file.name;

      const fileReader = new FileReader();

      fileReader.onload = () => {
        if (typeof fileReader.result === 'string') {
          try {
            this.createTestRunConfigurationForm.patchValue({
              matching_generator: fileReader.result,
            });
          } catch (err) {
            this.snackBarService.open(
              UiMessagesConstant.KpiOdConfigParsingErrorMessage,
              SnackBarConstant.actions.dismiss,
              3000
            );
          }
        } else {
          this.snackBarService.open(
            UiMessagesConstant.KpiOdConfigParsingErrorMessage,
            SnackBarConstant.actions.dismiss,
            3000
          );
        }
      };
      fileReader.onerror = (error) => {
        this.snackBarService.open(
          UiMessagesConstant.KpiOdConfigParsingErrorMessage,
          SnackBarConstant.actions.dismiss,
          3000
        );
      };

      fileReader.readAsText(file, 'UTF-8');
    }
  }

  openDialog(): void {
    this.dialog.open(TestSessionPlaylistDialogComponent, { width: '100%' });
  }

  onDeleteAttachedFile(): void {
    this.fileUploadElementRef.nativeElement.value = '';
    this.fileName = '';

    this.createTestRunConfigurationForm.patchValue({
      matching_generator: '{}',
    });
  }

  onFetchReprocessingConfigurations(): void {
    this.reprocessingConfigsTableSpinner = true;

    this.store.dispatch(
      new GetReproceccingConfigurationsData({
        query: GetReprocessingConfigurationsQuery,
        variables: {},
      })
    );
  }

  onFetchTestSessions(): void {
    this.store.dispatch(
      new GetTestSessionsData({
        query: GetTestSessionsQuery,
        variables: {},
      })
    );
  }

  onTranscoderTypeChange(event: MatLegacySelectChange): void {
    if (event.value === 'Magna VBS + DLT') {
      this.createTestRunConfigurationForm.controls.bordnet_version.enable();
      this.createTestRunConfigurationForm.controls.fibex_xml_filename.enable();
    } else {
      this.createTestRunConfigurationForm.controls.bordnet_version.disable();
      this.createTestRunConfigurationForm.controls.fibex_xml_filename.disable();
    }
  }

  onBordnetVersionChange(event: MatLegacySelectChange): void {
    this.fibexFileNames = this.bordnetVersionsMap[event.value];
  }

  onKpiFunctionsChange(event: MatLegacySelectChange): void {
    this.createTestRunConfigurationForm.patchValue({
      function: event.value,
    });
  }

  onKpiGtSelectionChange(event: MatLegacySelectChange): void {
    this.createTestRunConfigurationForm.patchValue({
      gt_selection: event.value,
    });
  }

  onKpiClusterSelectionChange(event: MatLegacySelectChange): void {
    this.createTestRunConfigurationForm.patchValue({
      cluster: event.value,
    });
  }

  onKpiProjectSelectionChange($event: MatLegacySelectChange): void {
    const selectedKpiProjects = this.kpiProjects.filter(
      (kpiProject) => $event.value === kpiProject.name
    );

    this.selectedKpiProject = selectedKpiProjects[0];

    this.createTestRunConfigurationForm.patchValue({
      kpi_project_ids: JSON.stringify([this.selectedKpiProject.id]),
    });

    if (this.isPcpOrMiscKpiProjectSelected) {
      this.createTestRunConfigurationForm.patchValue({
        gt_selection: '',
        cluster: '',
        report_chunk_size: 100,
      });
    }

    if (!this.isPcpOrOdorMiscKpiProjectSelected) {
      this.createTestRunConfigurationForm.patchValue({
        matching_stream: '',
      });
    }
  }

  onKpiStreamSelectionChange(event: MatLegacySelectChange): void {
    this.createTestRunConfigurationForm.patchValue({
      matching_stream: event.value,
    });
  }

  onKpiTestSuiteSelected(selectedTestsuite: KpiTestsuite): void {
    this.createTestRunConfigurationForm.patchValue({
      session_to_kpiscipts_mapping: {
        kpiScripts: selectedTestsuite?.id,
        kpiTestsuitName: selectedTestsuite?.name,
      },
    });
  }

  onKpiMatchingChange(event: MatLegacyCheckboxChange): void {
    if (!event.checked) {
      this.onDeleteAttachedFile();
    }
  }

  onReproceccingConfigurationFormValueChanges(
    formControlsConfig: FormControlsConfig
  ): void {
    if (formControlsConfig['ProductName']) {
      this.reproceccingConfigurationControlsMetadata.ReProcessingType.options.inputOptions =
        ProjectsConstant[formControlsConfig['ProductName']].ReProcessingTypes;

      this.reproceccingConfigurationControlsMetadata.ReProcessingType.options.filteredInputOptions =
        this.reproceccingConfigurationControlsMetadata.ReProcessingType.options.inputOptions;
    }
  }

  onReprocessingConfigsDataItemSelected(selected: any): void {
    this.createTestRunConfigurationForm.patchValue({
      reprocessing_config: JSON.stringify(selected),
    });
  }

  onResetKpiSettings(): void {
    this.createTestRunConfigurationForm.patchValue({
      kpi_project_ids: '[]',
      session_to_kpiscipts_mapping: {
        kpiProjectName: '',
        kpiScripts: '',
        kpiTestsuitName: '',
      },
      session_to_kpithresholds_mapping: '[]',
      matching_enabled: false,
      matching_stream: '',
      enable_pcp_postprocessing: false,
      gt_selection: '',
      report_chunk_size: 100,
      cluster: '',
      report_merge_variant: '',
      function: '',
    });

    this.onDeleteAttachedFile();

    this.selectedKpiProject = { id: '' };
  }

  onTestSessionsDataItemsSelected(selected: any): void {
    this.createTestRunConfigurationForm.patchValue({
      test_sessions: selected.length ? JSON.stringify(selected) : '',
    });
  }

  onTranscoderStreamsSelected(streamsSelected: any): void {
    const transcoder_streams = streamsSelected.length
      ? `{"streams": ${JSON.stringify(
          streamsSelected.map((selection: any) => selection.stream)
        )}}`
      : '';

    this.createTestRunConfigurationForm.patchValue({
      transcoder_streams: transcoder_streams,
    });
  }

  onSubmitReprocessingConfigurationForm(
    formControlsConfig: FormControlsConfig
  ): void {
    formControlsConfig[FormConstant.formControlsNames.ProjectID] =
      ProjectsConstant[
        formControlsConfig[FormConstant.formControlsNames.ProductName]
      ].id;
    formControlsConfig.ProductName =
      ProjectsConstant[
        formControlsConfig[FormConstant.formControlsNames.ProductName]
      ].name;

    const payload: SaveReprocessingConfigurationPayload = {
      query: SaveReprocessingConfigurationQuery,
      variables: {
        reprocessing_configuration: JSON.stringify(formControlsConfig),
      },
    };

    this.store.dispatch(new SaveReproceccingConfigurationData(payload));
  }

  onSubmitTestSessionForm(formControlsConfig: FormControlsConfig): void {
    const payload: QuerySaveTestSessionPayload = {
      query: SaveTestSessionQuery,
      variables: {
        test_session: JSON.stringify(formControlsConfig),
      },
    };

    this.store.dispatch(new SaveTestSessionData(payload));
  }

  onSubmit(): void {
    const variables = { ...this.createTestRunConfigurationForm.getRawValue() };

    // TODO: Remove if else after new parameter will be in MIK env
    if (environment.projectName === ProjectsConstant.MIK.name) {
      delete variables.comparison_report;
    }

    const payload: SaveTestOrderPayload = {
      query:
        environment.projectName === ProjectsConstant.MIK.name
          ? SaveTestOrderQueryTmpMik
          : SaveTestOrderQuery,
      variables,
    };

    payload.variables.session_to_kpiscipts_mapping = JSON.stringify(
      payload.variables.session_to_kpiscipts_mapping
    );

    payload.variables.kpi_project_ids = JSON.parse(
      // @ts-ignore
      payload.variables.kpi_project_ids
    );

    let test_order = '';

    try {
      if (
        this.createTestRunConfigurationForm.controls.test_sessions.value &&
        this.createTestRunConfigurationForm.controls.reprocessing_config
          .value &&
        JSON.parse(
          this.createTestRunConfigurationForm.controls.test_sessions.value
        ).length
      ) {
        const testSessions = JSON.parse(
          this.createTestRunConfigurationForm.controls.test_sessions.value
        );
        const reprocessingConfig = JSON.parse(
          this.createTestRunConfigurationForm.controls.reprocessing_config.value
        );

        test_order = TmaUtilsStaticService.getLegacyTestOrderJson(
          this.createTestRunConfigurationForm.controls.testrun_config_label
            .value,
          testSessions,
          reprocessingConfig,
          this.createTestRunConfigurationForm.controls.EcuInitTriggeringMode
            .value
        );
      }

      payload.variables.test_order = test_order;

      this.store.dispatch(new SaveTestOrderData(payload));
    } catch (e) {
      this.snackBarService.open(
        UiMessagesConstant.testRunConfigurationPayloadError,
        SnackBarConstant.actions.dismiss,
        3000
      );
    }
  }

  get isPcpOrOdorMiscKpiProjectSelected(): boolean {
    return /od|pcp|misc/.test(
      (
        this.createTestRunConfigurationForm
          ?.get('session_to_kpiscipts_mapping')
          ?.get('kpiProjectName')?.value || ''
      ).toLowerCase()
    );
  }

  get isPcpOrMiscKpiProjectSelected(): boolean {
    return /misc|pcp/.test(
      (
        this.createTestRunConfigurationForm
          ?.get('session_to_kpiscipts_mapping')
          ?.get('kpiProjectName')?.value || ''
      ).toLowerCase()
    );
  }

  get isStreamsTableVisible(): boolean {
    return (
      environment.projectName === ProjectsConstant.LIDAR.name &&
      this.createTestRunConfigurationForm.controls.streams_selection_type
        .value === 'manual'
    );
  }

  get isOdKpiProjectSelected(): boolean {
    return /od/.test(
      (
        this.createTestRunConfigurationForm
          ?.get('session_to_kpiscipts_mapping')
          ?.get('kpiProjectName')?.value || ''
      ).toLowerCase()
    );
  }

  get isAttachFileButtonsEnabled(): boolean {
    const matchingEnabled =
      this.createTestRunConfigurationForm.controls.matching_enabled.value;

    if (matchingEnabled !== null) {
      return matchingEnabled;
    }

    return true;
  }

  get parsedMatchingGenerator(): object {
    const matchingGenerator =
      this.createTestRunConfigurationForm.controls.matching_generator.value;

    if (matchingGenerator !== null) {
      try {
        return JSON.parse(matchingGenerator);
      } catch (e) {
        return {};
      }
    } else {
      return {};
    }
  }

  private subscriptions = new Subscription();

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private snackBarService: SnackBarService,
    private actions$: Actions<TestRunConfigurationActions>,
    public dialog: MatDialog,
    private awsAmplifyApiService: AwsAmplifyApiService,
    private kpiSettingsFieldsValidator: KpiSettingsFieldsValidator
  ) {}

  protected readonly Object = Object;
}
