import { ProjectsEnvironmentsConstant } from '@constants/projects-environments.constant';
import { ProjectsConstant } from '@constants/projects.constant';

export const environment = {
  production: true,
  envName: 'test',
  AWS_REGION: ProjectsEnvironmentsConstant.cn.region,
  AWS_COGNITO_USER_POOL_ID: 'eu-central-1_3PHYukpbg',
  AWS_IDENTITY_POOL_ID: 'eu-central-1:6ca0941d-523c-4790-becc-d067686f5293',
  AWS_COGNITO_CLIENT_ID: '25mrs9kaqbaoljdgolonbfebdi',
  AWS_COGNITO_CUSTOM_DOMAIN: 'octagon-test.auth.eu-central-1.amazoncognito.com',
  AWS_CLOUDFRONT_DOMAIN: 'dow9s3igtz41i.cloudfront.net',
  AWS_AUTH_TYPE: 'AMAZON_COGNITO_USER_POOLS',
  API: {
    graphql_endpoint:
      'https://xhk73prlhngergawecvrgmbckm.appsync-api.cn-north-1.amazonaws.com.cn/graphql',
    mock: false,
  },
  AZURE_AD: {
    auth: {
      clientId: '91465c34-0725-47a5-8124-10bba0ef7fce',
      authority:
        'https://login.microsoftonline.com/c760270c-f3da-4cfa-9737-03808ef5579f',
      redirectUri: 'https://octagon-lidar-test-v2.adas.magna.cn',
    },
    cache: {
      cacheLocation: 'sessionStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  },
  APP_SYNC_AUTH_TYPE: 'OPENID_CONNECT',
  tmaV1Url: 'https://d3gijlapwkl1av.cloudfront.net',
  projectName: ProjectsConstant.LIDAR.name,
  projectId: ProjectsConstant.LIDAR.id,
  projectsNames: [
    ProjectsConstant.LIDAR.name,
    ProjectsConstant.MFK120.name,
    ProjectsConstant.MFK5.name,
    ProjectsConstant.ADCAM.name,
  ],
  projectsFilteredInputOptions: [
    {
      value: ProjectsConstant.LIDAR.id,
      placeholder: ProjectsConstant.LIDAR.name,
    },
    {
      value: ProjectsConstant.MFK120.id,
      placeholder: ProjectsConstant.MFK120.name,
    },
    {
      value: ProjectsConstant.MFK5.id,
      placeholder: ProjectsConstant.MFK5.name,
    },
    {
      value: ProjectsConstant.ADCAM.id,
      placeholder: ProjectsConstant.ADCAM.name,
    },
  ],
  defaultProjectReProcessingTypes: ProjectsConstant.LIDAR.ReProcessingTypes,
  defaultTranscoderStreamsSelectionDataSource:
    ProjectsConstant.LIDAR.transcoderStreamsSelectionDataSource,
  transcodingExecutionTypeInputOptions: [
    { value: 'None', placeholder: 'None' },
    { value: 'Magna VBS', placeholder: 'Magna VBS' },
    { value: 'Magna VBS + DLT', placeholder: 'Magna VBS + DLT' },
    { value: 'Magna VBS + DLT + SPI', placeholder: 'Magna VBS + DLT + SPI' },
    { value: 'Liangdao VBS', placeholder: 'Liangdao VBS' },
    {
      value: 'Liangdao VBS w/ Annot.',
      placeholder: 'Liangdao VBS with Annotations',
    },
    { value: 'DLT', placeholder: 'DLT' },
    { value: 'SPI', placeholder: 'SPI' },
    { value: 'RefCam', placeholder: 'RefCam' },
  ],
};
