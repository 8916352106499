import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromVinsApprovals from '@modules/vins-approvals/store/vins-approvals.reducer';

export const selectVinsApprovalsState =
  createFeatureSelector<fromVinsApprovals.State>(
    fromVinsApprovals.vinsApprovalsFeatureKey
  );

export const selectFeatureVinsList = createSelector(
  selectVinsApprovalsState,
  (state: fromVinsApprovals.State) => state.vinsList
);

export const selectFeatureVinSelected = createSelector(
  selectVinsApprovalsState,
  (state: fromVinsApprovals.State) => state.vinSelected
);

export const selectFeatureDriveIdsApprovalList = createSelector(
  selectVinsApprovalsState,
  (state: fromVinsApprovals.State) => state.driveIdsApprovalList
);
