import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { ProjectsEnvironmentsConstant } from '@constants/projects-environments.constant';
import { MsalUserAgentService } from '@core/services/msal-user-agent.service';
import { environment } from '@environments/environment';
import { Auth, Cache } from 'aws-amplify';
// @ts-ignore
import { isJwtExpired } from 'jwt-check-expiration';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (environment.AWS_REGION === ProjectsEnvironmentsConstant.eu.region) {
      return Auth.currentAuthenticatedUser().then(
        (cognitoUser) => {
          if (cognitoUser) {
            return Auth.userSession(cognitoUser).then((session) => {
              if (!session.isValid()) {
                return false;
              }

              return true;
            });
          } else {
            return false;
          }
        },
        () => {
          return false;
        }
      );
    } else if (
      environment.AWS_REGION === ProjectsEnvironmentsConstant.cn.region
    ) {
      this.msalUserAgentService.init();

      const msalInstance = this.msalUserAgentService.getInstance();

      const loginRequest = {
        scopes: ['openid', 'profile', 'User.Read'],
      };

      return msalInstance
        .acquireTokenSilent(loginRequest)
        .then((currentAuthUser: any) => {
          if (isJwtExpired(currentAuthUser.idToken.rawIdToken)) {
            msalInstance.loginRedirect(loginRequest);
          }

          Cache.setItem('federatedInfo', {
            token: currentAuthUser.idToken.rawIdToken,
          });

          return msalInstance.getAccount() !== null;
        })
        .catch((err: any) => {
          msalInstance.loginRedirect(loginRequest);

          return false;
        });
    } else {
      return false;
    }
  }

  constructor(private msalUserAgentService: MsalUserAgentService) {}
}
