import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatLegacyCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacySelectChange } from '@angular/material/legacy-select';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ProjectsConstant } from '@constants/projects.constant';
import { SnackBarConstant } from '@constants/snack-bar.constant';
import { UiMessagesConstant } from '@constants/ui-messages.constant';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';
import {
  GetBordnetVersionsQuery,
  GetKpiMetaQuery,
  GetKpiProjectsQuery,
  TmaTcTriggeringQuery,
} from '@core/amplify/aws-apmlify-query.constant';
import { AuthService } from '@core/services/auth.service';
import { SnackBarService } from '@core/services/snack-bar.service';
import { environment } from '@environments/environment';
import { GraphQLQueryError } from '@model/payloads';
import { TestRunConfigurationConstant } from '@modules/test-run-configuration/constants/test-run-configuration.constant';
import {
  GetKpiMetaData,
  GetKpiProjectsData,
} from '@modules/test-run-configuration/store/test-run-configuration.actions';
import {
  selectFeatureKpiMeta,
  selectFeatureKpiProjects,
} from '@modules/test-run-configuration/store/test-run-configuration.selectors';
import { TranscodingConstant } from '@modules/transcoding/constants/transcoding.constant';
import { Store } from '@ngrx/store';
import { isCommaSeparatorValidator } from '@validators/is-comma-separator.validator';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-transcoding-triggering-new',
  templateUrl: './transcoding-triggering-new.component.html',
  styleUrls: ['./transcoding-triggering-new.component.scss'],
})
export class TranscodingTriggeringNewComponent implements OnInit {
  @ViewChild('fileUploadInput')
  fileUploadElementRef!: ElementRef;
  fileName = '';

  @Input() currentProjectName = environment.projectName;

  kpiMeta$: Observable<KpiMeta> = this.store.select(selectFeatureKpiMeta);
  kpiMeta: KpiMeta;
  report_merge_variants: string[] = [];

  kpiProjects$: Observable<KpiProject[]> = this.store.select(
    selectFeatureKpiProjects
  );
  kpiProjects: KpiProject[];
  selectedKpiProject: KpiProject | { id: '' };

  transcodersTypes = environment.transcodingExecutionTypeInputOptions.map(
    (option) => option.value
  );
  transcodingSourceTypes = TranscodingConstant.transcodingSourceTypesList;
  tcSteamsSelectionTypes = TranscodingConstant.steamsSelectionTypes;
  transcoderStreamsSelectionColumns: string[] = ['stream'];
  transcoderStreamsSelectionDataSource = new MatTableDataSource<any>(
    environment.defaultTranscoderStreamsSelectionDataSource
  );

  bordnetVersionsMap: any = {};
  bordnetVersions: any[] = [];
  fibexFileNames: any[] = [];

  kpiGtSelections = TestRunConfigurationConstant.gtSelectionList;
  kpiClusters = TestRunConfigurationConstant.clusters;
  kpiStreams = TestRunConfigurationConstant.streams;
  kpiFunctions = TestRunConfigurationConstant.functions;

  transcodingTriggeringForm = this.fb.group({
    Project: this.currentProjectName,
    Owner: '',
    TranscoderType: TranscodingConstant.defaultTranscoder,
    TranscodingSourceType:
      TranscodingConstant.transcodingSourceTypesMap.by_drive_ids,
    DriveIDList: '',
    ExternalTestOrderIDList: '',
    QueueIDList: '',
    StreamsSelectionType: TranscodingConstant.steamsSelectionTypesMap.automatic,
    StreamSelection: [],
    LabelText: ['', [Validators.required]],
    ForceTranscoding: false,
    KpiSettings: this.fb.group({
      KpiProjectId: '',
      KpiProjectName: '',
      KpiTestsuiteId: '',
      KpiTestsuiteName: '',
      MatchingGenerator: '{}',
      GtSelection: '',
      Cluster: '',
      Stream: '',
      MatchingEnabled: { value: false, disabled: true },
      MatchingForced: { value: false, disabled: true },
      EnablePcpPostprocessing: false,
      ReportChunkSize: [100, [Validators.min(1), Validators.max(5000)]],
      report_merge_variant: '',
      function: '',
      comparison_report: false,
    }),
    Sources: ['', [Validators.required, isCommaSeparatorValidator]],
    bordnet_version: { value: '', disabled: true },
    fibex_xml_filename: { value: '', disabled: true },
  });

  sourcesFormControlTitle =
    TranscodingConstant.sourcesFormControlTitlesMap.by_drive_ids;

  onAttachFileButtonClick($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.fileUploadElementRef.nativeElement.click();
  }

  onAttachFile(event: Event) {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    if (file) {
      this.fileName = file.name;

      const fileReader = new FileReader();

      fileReader.onload = () => {
        if (typeof fileReader.result === 'string') {
          try {
            this.transcodingTriggeringForm.patchValue({
              KpiSettings: {
                MatchingGenerator: fileReader.result,
              },
            });
          } catch (err) {
            this.snackBarService.open(
              UiMessagesConstant.KpiOdConfigParsingErrorMessage,
              SnackBarConstant.actions.dismiss,
              3000
            );
          }
        } else {
          this.snackBarService.open(
            UiMessagesConstant.KpiOdConfigParsingErrorMessage,
            SnackBarConstant.actions.dismiss,
            3000
          );
        }
      };
      fileReader.onerror = (error) => {
        this.snackBarService.open(
          UiMessagesConstant.KpiOdConfigParsingErrorMessage,
          SnackBarConstant.actions.dismiss,
          3000
        );
      };

      fileReader.readAsText(file, 'UTF-8');
    }
  }

  onDeleteAttachedFile(): void {
    this.fileUploadElementRef.nativeElement.value = '';
    this.fileName = '';

    this.transcodingTriggeringForm.patchValue({
      KpiSettings: {
        MatchingGenerator: '{}',
      },
    });
  }

  onKpiGtSelectionChange(event: MatLegacySelectChange): void {
    this.transcodingTriggeringForm.patchValue({
      KpiSettings: {
        GtSelection: event.value,
      },
    });
  }

  onKpiClusterSelectionChange(event: MatLegacySelectChange): void {
    this.transcodingTriggeringForm.patchValue({
      KpiSettings: {
        Cluster: event.value,
      },
    });
  }

  onKpiProjectSelectionChange($event: MatLegacySelectChange): void {
    const selectedKpiProjects = this.kpiProjects.filter(
      (kpiProject) => $event.value === kpiProject.name
    );

    this.selectedKpiProject = selectedKpiProjects[0];

    this.transcodingTriggeringForm.patchValue({
      KpiSettings: {
        KpiProjectId: this.selectedKpiProject.id,
      },
    });

    if (this.isPcpOrMiscKpiProjectSelected) {
      this.transcodingTriggeringForm.patchValue({
        KpiSettings: {
          GtSelection: '',
          Cluster: '',
          ReportChunkSize: 100,
        },
      });
    }

    if (!this.isPcpOrOdorMiscKpiProjectSelected) {
      this.transcodingTriggeringForm.patchValue({
        KpiSettings: {
          Stream: '',
        },
      });
    }
  }

  onKpiStreamSelectionChange(event: MatLegacySelectChange): void {
    this.transcodingTriggeringForm.patchValue({
      KpiSettings: {
        Stream: event.value,
      },
    });
  }

  onKpiFunctionsChange(event: MatLegacySelectChange): void {
    this.transcodingTriggeringForm.patchValue({
      KpiSettings: {
        function: event.value,
      },
    });
  }

  transcodingSourceTypeSelectionChange($event: MatLegacySelectChange): void {
    if (
      $event.value ===
      TranscodingConstant.transcodingSourceTypesMap.by_drive_ids
    ) {
      this.sourcesFormControlTitle =
        TranscodingConstant.sourcesFormControlTitlesMap.by_drive_ids;
    } else if (
      $event.value ===
      TranscodingConstant.transcodingSourceTypesMap.by_test_run_ids
    ) {
      this.sourcesFormControlTitle =
        TranscodingConstant.sourcesFormControlTitlesMap.by_test_run_ids;
    } else if (
      $event.value ===
      TranscodingConstant.transcodingSourceTypesMap.byt_queue_ids
    ) {
      this.sourcesFormControlTitle =
        TranscodingConstant.sourcesFormControlTitlesMap.byt_queue_ids;
    }

    this.resetFormSources();
  }

  onSubmit(): void {
    this.transcodingTriggeringForm.patchValue({
      Project: this.currentProjectName,
    });

    this.setFormSourcesForTranscodingRequest();

    const transcodingConfiguration = {
      ...this.transcodingTriggeringForm.getRawValue(),
    };

    // @ts-ignore
    delete transcodingConfiguration.Sources;

    this.awsAmplifyApiService
      .graphQLQuery({
        query: TmaTcTriggeringQuery,
        variables: {
          transcoding_configuration: JSON.stringify(transcodingConfiguration),
        },
      })
      .subscribe(
        (response) => {
          this.snackBarService.open(
            `${response.data.tma_tc_triggering}`,
            SnackBarConstant.actions.dismiss,
            3000
          );
        },
        (error: GraphQLQueryError) => {
          const messages: string = error.errors
            .map((error) => error.message)
            .join();

          this.snackBarService.open(
            `Error of transcoding triggering: ${messages}`,
            SnackBarConstant.actions.dismiss,
            3000
          );
        }
      );
  }

  onTranscoderTypeChange(event: MatLegacySelectChange): void {
    if (event.value === 'Magna VBS + DLT') {
      this.transcodingTriggeringForm.controls.bordnet_version.enable();
      this.transcodingTriggeringForm.controls.fibex_xml_filename.enable();
    } else {
      this.transcodingTriggeringForm.controls.bordnet_version.disable();
      this.transcodingTriggeringForm.controls.fibex_xml_filename.disable();
    }
  }

  ngOnInit(): void {
    this.awsAmplifyApiService
      .graphQLQuery({
        query: GetBordnetVersionsQuery,
        variables: {
          project: 'mik',
        },
      })
      .subscribe((bordnetVersionsMap: any) => {
        try {
          this.bordnetVersionsMap = JSON.parse(
            bordnetVersionsMap.data.get_bordnet_versions
          );
          this.bordnetVersions = Object.keys(this.bordnetVersionsMap);
        } catch (e) {
          this.snackBarService.open(
            'get_bordnet_versions JSON parsing error',
            SnackBarConstant.actions.dismiss,
            3000
          );
        }
      });

    this.authService.getCurrentUser().subscribe((user) => {
      this.transcodingTriggeringForm.patchValue({
        Owner: user.attributes.email,
      });
    });

    this.kpiProjects$.subscribe((kpiProjects) => {
      this.kpiProjects = kpiProjects;
    });

    this.kpiMeta$.subscribe((kpiMeta) => {
      this.kpiMeta = kpiMeta;
      this.report_merge_variants = this.kpiMeta.report_merge_variants;
    });

    this.store.dispatch(
      new GetKpiProjectsData({
        query: GetKpiProjectsQuery,
      })
    );

    this.store.dispatch(
      new GetKpiMetaData({
        query: GetKpiMetaQuery,
        variables: {},
      })
    );
  }

  onBordnetVersionChange(event: MatLegacySelectChange): void {
    this.fibexFileNames = this.bordnetVersionsMap[event.value];
  }

  onKpiTestSuiteSelected(selectedTestsuite: KpiTestsuite): void {
    this.transcodingTriggeringForm.patchValue({
      KpiSettings: {
        KpiTestsuiteId: selectedTestsuite.id,
        KpiTestsuiteName: selectedTestsuite.name,
      },
    });
  }

  onKpiMatchingChange(event: MatLegacyCheckboxChange): void {
    if (!event.checked) {
      this.onDeleteAttachedFile();
    }
  }

  onResetKpiSettings(): void {
    this.transcodingTriggeringForm.patchValue({
      KpiSettings: {
        KpiProjectId: '',
        KpiProjectName: '',
        KpiTestsuiteId: '',
        KpiTestsuiteName: '',
        GtSelection: '',
        Cluster: '',
        Stream: '',
        MatchingEnabled: false,
        MatchingForced: false,
        EnablePcpPostprocessing: false,
        ReportChunkSize: 100,
        report_merge_variant: '',
        function: '',
      },
    });

    this.onDeleteAttachedFile();

    this.selectedKpiProject = { id: '' };
  }

  onTranscoderStreamsSelected(streamsSelected: any): void {
    this.transcodingTriggeringForm.patchValue({
      StreamSelection: streamsSelected.map((sream: any) => sream.stream),
    });
  }

  get isOdKpiProjectSelected(): boolean {
    return /od/.test(
      (
        this.transcodingTriggeringForm.controls.KpiSettings.controls
          .KpiProjectName.value || ''
      ).toLowerCase()
    );
  }

  get isPcpOrOdorMiscKpiProjectSelected(): boolean {
    return /od|pcp|misc/.test(
      (
        this.transcodingTriggeringForm.controls.KpiSettings.controls
          .KpiProjectName.value || ''
      ).toLowerCase()
    );
  }

  get isPcpOrMiscKpiProjectSelected(): boolean {
    return /misc|pcp/.test(
      (
        this.transcodingTriggeringForm.controls.KpiSettings.controls
          .KpiProjectName.value || ''
      ).toLowerCase()
    );
  }

  get isAttachFileButtonsEnabled(): boolean {
    const matchingEnabled =
      this.transcodingTriggeringForm.controls.KpiSettings.controls
        .MatchingEnabled.value;

    if (matchingEnabled !== null) {
      return matchingEnabled;
    }

    return true;
  }

  get parsedMatchingGenerator(): object {
    const matchingGenerator =
      this.transcodingTriggeringForm.controls.KpiSettings.controls
        .MatchingGenerator.value;

    if (matchingGenerator !== null) {
      try {
        return JSON.parse(matchingGenerator);
      } catch (e) {
        return {};
      }
    } else {
      return {};
    }
  }

  get isStreamsTableVisible(): boolean {
    return (
      environment.projectName === ProjectsConstant.LIDAR.name &&
      this.transcodingTriggeringForm.controls.StreamsSelectionType.value ===
        'manual'
    );
  }

  private resetFormSources(): void {
    this.transcodingTriggeringForm.patchValue({
      DriveIDList: '',
      ExternalTestOrderIDList: '',
      QueueIDList: '',
      Sources: '',
    });
  }

  private setFormSourcesForTranscodingRequest(): void {
    const selectedTranscodingSourceType =
      this.transcodingTriggeringForm.controls.TranscodingSourceType.value;

    const inputtedSources =
      this.transcodingTriggeringForm.controls.Sources.value;

    if (
      selectedTranscodingSourceType ===
      TranscodingConstant.transcodingSourceTypesMap.by_drive_ids
    ) {
      this.transcodingTriggeringForm.patchValue({
        DriveIDList: inputtedSources,
        ExternalTestOrderIDList: '',
        QueueIDList: '',
      });
    } else if (
      selectedTranscodingSourceType ===
      TranscodingConstant.transcodingSourceTypesMap.by_test_run_ids
    ) {
      this.transcodingTriggeringForm.patchValue({
        DriveIDList: '',
        ExternalTestOrderIDList: inputtedSources,
        QueueIDList: '',
      });
    } else if (
      selectedTranscodingSourceType ===
      TranscodingConstant.transcodingSourceTypesMap.byt_queue_ids
    ) {
      this.transcodingTriggeringForm.patchValue({
        DriveIDList: '',
        ExternalTestOrderIDList: '',
        QueueIDList: inputtedSources,
      });
    }
  }

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private store: Store,
    private snackBarService: SnackBarService,
    private awsAmplifyApiService: AwsAmplifyApiService
  ) {}

  protected readonly Object = Object;
}
