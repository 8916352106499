import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PlaylistsConstant } from '@modules/playlists/constants/playlists.constant';
import * as PlaylistActions from '@modules/playlists/store/playlist.actions';
import * as fromPlaylist from '@modules/playlists/store/playlist.reducer';
import {
  selectFeatureDriveIds,
  selectFeaturePlaylist,
} from '@modules/playlists/store/playlist.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss'],
})
export class PlaylistComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  playlist$: Observable<Playlist | null> = this.store.select(
    selectFeaturePlaylist
  );
  driveIds$: Observable<DriveIds> = this.store.select(selectFeatureDriveIds);

  playlist: Playlist | null = null;

  limit = 100;
  nextToken: string | null = null;
  displayedColumns: string[] = PlaylistsConstant.playlistDisplayedColumns;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  spinner = false;

  goPlaylists(event: Event): void {
    this.router.navigate(['/tma/playlists']).then(() => {});
  }

  queryMore(): void {
    if (this.nextToken) {
      this.spinner = true;

      this.store.dispatch(
        PlaylistActions.loadDriveIds({
          playlist_id: this.playlist?.playlist_id || '',
          limit: this.limit,
          nextToken: this.nextToken,
        })
      );
    }
  }

  reloadTable(): void {
    this.resetTableData();

    this.spinner = true;

    this.store.dispatch(
      PlaylistActions.loadDriveIds({
        playlist_id: this.playlist?.playlist_id || '',
        limit: this.limit,
        nextToken: null,
      })
    );
  }

  ngOnInit() {
    this.playlist$.subscribe((playlist) => {
      this.playlist = playlist;
    });

    this.driveIds$.subscribe((driveIds) => {
      this.nextToken = driveIds.nextToken;

      this.setDriveIdsTableData(driveIds.items);

      this.spinner = false;
    });
  }

  onSearchByDriveId($event: Event): void {
    this.resetTableData();

    const drive_id = ($event?.target as HTMLInputElement).value;
  }

  private resetTableData(): void {
    this.dataSource.data = [];

    this.nextToken = null;
  }

  private setDriveIdsTableData(playlists: Playlist[]): void {
    const tableData = [...playlists];

    this.dataSource.paginator = this.paginator;

    this.dataSource.data = this.dataSource.data.concat(tableData);
  }

  constructor(
    private store: Store<fromPlaylist.State>,
    private router: Router
  ) {}
}
