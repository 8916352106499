import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as Msal from 'msal';

@Injectable({
  providedIn: 'root',
})
export class MsalUserAgentService {
  msalInstance: any;

  init(): void {
    const msalConfig: any = environment.AZURE_AD;

    this.msalInstance = new Msal.UserAgentApplication(msalConfig);
  }

  getInstance(): any {
    return this.msalInstance;
  }

  acquireTokenSilent(loginRequest: { scopes: string[] }): Promise<any> {
    return this.msalInstance.acquireTokenSilent(loginRequest);
  }

  constructor() {}
}
