import { ProjectsConstant } from '@constants/projects.constant';
import { environment } from '@environments/environment';

export const TabsRoutesConstant = [
  {
    routerLink: '/tma/test-run-configuration',
    placeholder: 'test management application',
    disabled: false,
  },
  {
    routerLink: '/tma/transcoding',
    placeholder: 'manual transcoding',
    disabled: false,
  },
  {
    routerLink: '/tma/trained-parking-assist',
    placeholder: 'mfk120 trained parking assist',
    disabled:
      environment.projectName === ProjectsConstant.LIDAR.name ? false : true,
  },
  {
    routerLink: '/tma/playlists',
    placeholder: 'playlists',
    disabled: environment.envName === 'test' ? true : false,
  },
  {
    routerLink: '/tma/cariad',
    placeholder: 'cariad exchange',
    disabled:
      environment.projectName === ProjectsConstant.LIDAR.name ? false : true,
  },
  {
    routerLink: '/tma/innoviz',
    placeholder: 'innoviz exchange',
    disabled:
      environment.projectName === ProjectsConstant.LIDAR.name ? false : true,
  },
  {
    routerLink: '/tma/data-browser',
    placeholder: 'data browser',
    disabled: false,
  },
  {
    routerLink: '/tma/vins-approvals',
    placeholder: 'approvals',
    disabled: false,
  },
  {
    routerLink: '/tma/splits-approvals',
    placeholder: 'mfk120 approvals',
    disabled:
      environment.projectName === ProjectsConstant.LIDAR.name ? false : true,
  },
];
