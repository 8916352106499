import { TmaUtilsStaticService } from '@core/services/tma-utils-static.service';
import * as VinsApprovalsActions from '@modules/vins-approvals/store/vins-approvals.actions';
import { createReducer, on } from '@ngrx/store';

export const vinsApprovalsFeatureKey = 'vinsApprovals';

export interface State {
  vinsList: VinsList;
  vinSelected: string;
  driveIdsApprovalList: DriveIdApproval[];
}

export const initialState: State = {
  vinsList: {
    project: '',
    vin_map: [],
  },
  vinSelected: '',
  driveIdsApprovalList: [],
};

export const reducer = createReducer(
  initialState,

  on(VinsApprovalsActions.resetVinsList, (state) => {
    return {
      ...state,
      vinsList: initialState.vinsList,
    };
  }),

  on(VinsApprovalsActions.loadVinsListSuccess, (state, action) => {
    const vinsMap = JSON.parse(action.data.getVinsList.vin_map);

    const vinsList = {
      project: action.data.getVinsList.project,
      vin_map: vinsMap,
    };

    return {
      ...state,
      vinsList,
    };
  }),

  on(VinsApprovalsActions.loadDriveIdsApprovalList, (state, action) => ({
    ...state,
    vinSelected: action.variables.vin,
  })),

  on(VinsApprovalsActions.resetDriveIdsApprovalList, (state) => ({
    ...state,
    driveIdsApprovalList: initialState.driveIdsApprovalList,
  })),

  on(VinsApprovalsActions.loadDriveIdsApprovalListSuccess, (state, action) => {
    const driveIdsApprovalList = JSON.parse(
      action.data.getDriveIdsApprovalList.drive_ids
    );

    driveIdsApprovalList.map((driveIdApproval: DriveIdApproval) => {
      driveIdApproval.updated_timestamp = TmaUtilsStaticService.formatDate(
        driveIdApproval.updated_timestamp
      );
    });

    return {
      ...state,
      driveIdsApprovalList,
    };
  })
);
