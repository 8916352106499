import { createAction, props } from '@ngrx/store';
import {
  GetDriveIdsApprovalListPayload,
  GetDriveIdsApprovalListResponse,
  GetVinsListPayload,
  GetVinsListResponse,
  SetDriveIdApprovalsPayload,
  SetDriveIdApprovalsResponse,
} from '@model/payloads';
import { GraphQLQueryError } from '@model/payloads';

export const resetVinsList = createAction('[VinsApprovals] Reset VinsList');

export const resetDriveIdsApprovalList = createAction(
  '[VinsApprovals] Reset DriveIdsApprovalList'
);

export const loadVinsList = createAction(
  '[VinsApprovals] Load VinsList',
  props<GetVinsListPayload>()
);

export const loadVinsListSuccess = createAction(
  '[VinsApprovals] Load VinsList Success',
  props<GetVinsListResponse>()
);

export const loadVinsListFailure = createAction(
  '[VinsApprovals] Load VinsList Failure',
  props<GraphQLQueryError>()
);

export const loadDriveIdsApprovalList = createAction(
  '[VinsApprovals] Load DriveIdsApprovalList',
  props<GetDriveIdsApprovalListPayload>()
);

export const loadDriveIdsApprovalListSuccess = createAction(
  '[VinsApprovals] Load DriveIdsApprovalList Success',
  props<GetDriveIdsApprovalListResponse>()
);

export const loadDriveIdsApprovalListFailure = createAction(
  '[VinsApprovals] Load DriveIdsApprovalList Failure',
  props<GraphQLQueryError>()
);

export const setDriveIdApprovals = createAction(
  '[VinsApprovals] Set DriveIdApprovals',
  props<SetDriveIdApprovalsPayload>()
);

export const setDriveIdApprovalsSuccess = createAction(
  '[VinsApprovals] Set DriveIdApprovals Success',
  props<SetDriveIdApprovalsResponse>()
);

export const setDriveIdApprovalsFailure = createAction(
  '[VinsApprovals] Set DriveIdApprovals Failure',
  props<GraphQLQueryError>()
);
