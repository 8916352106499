import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { GetKpiTestsuitesQuery } from '@core/amplify/aws-apmlify-query.constant';
import { KpiService } from '@core/services/kpi.service';
import { TmaUtilsStaticService } from '@core/services/tma-utils-static.service';
import { GetKpiTestsuitesResponse } from '@model/payloads';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-test-suites-table',
  templateUrl: './test-suites-table.component.html',
  styleUrls: ['./test-suites-table.component.scss'],
})
export class TestSuitesTableComponent implements AfterViewInit, OnChanges {
  @Input() selectedKpiProjectId!: string;
  @Output() kpiTestSuiteSelected: EventEmitter<KpiTestsuite> =
    new EventEmitter();
  displayedColumns: string[] = ['name', 'description', 'created_at'];
  data: KpiTestsuite[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  selectedTestSuiteId!: string;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.selectedKpiProjectId &&
      changes.selectedKpiProjectId.currentValue
    ) {
      this.isLoadingResults = true;

      this.getTestSuites(this.selectedKpiProjectId, 10, 0).subscribe(
        (getKpiTestSuitesResponse) => {
          const getKPITestsuites = JSON.parse(
            getKpiTestSuitesResponse!.data.getKPITestsuites
          );

          this.isLoadingResults = false;
          this.isRateLimitReached = getKPITestsuites === null;

          if (getKPITestsuites === null) {
            return [];
          }

          this.resultsLength = getKPITestsuites.total;

          return (this.data = getKPITestsuites.testsuites.map(
            (testsuite: KpiTestsuite) => {
              testsuite.created_at = TmaUtilsStaticService.formatDate(
                testsuite.created_at
              );

              return testsuite;
            }
          ));
        }
      );
    }
  }

  getTestSuites(
    project_id: string,
    limit: number,
    page: number
  ): Observable<GetKpiTestsuitesResponse> {
    return this.kpiService.getKpiTestSuites({
      query: GetKpiTestsuitesQuery,
      variables: {
        project_id,
        skip: page * limit,
        limit,
      },
    });
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;

          return this.getTestSuites(
            this.selectedKpiProjectId,
            10,
            this.paginator.pageIndex
          ).pipe(catchError(() => observableOf(null)));
        }),
        map((getKpiTestSuitesResponse) => {
          const getKPITestsuites = JSON.parse(
            getKpiTestSuitesResponse!.data.getKPITestsuites
          );

          this.isLoadingResults = false;
          this.isRateLimitReached = getKPITestsuites === null;

          if (getKPITestsuites === null) {
            return [];
          }

          this.resultsLength = getKPITestsuites.total;

          return getKPITestsuites.testsuites.map((testsuite: KpiTestsuite) => {
            testsuite.created_at = TmaUtilsStaticService.formatDate(
              testsuite.created_at
            );

            return testsuite;
          });
        })
      )
      .subscribe((data) => (this.data = data));
  }

  onTestSuiteSelected($event: Event, row: KpiTestsuite) {
    this.selectedTestSuiteId = row.id;

    this.kpiTestSuiteSelected.emit(row);
  }

  constructor(private kpiService: KpiService) {}
}
