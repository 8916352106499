import { TmaUtilsStaticService } from '@core/services/tma-utils-static.service';
import {
  QueryBuilderResponse,
  QueryVrctResponse,
  TestrunExecution,
} from '@model/payloads';
import {
  TestRunConfigurationActions,
  TestRunConfigurationActionTypes,
} from './test-run-configuration.actions';

export const testRunConfigurationFeatureKey = 'testRunConfiguration';

export interface State {
  semanticSearchData: QueryBuilderResponse;
  queryVrctData: QueryVrctResponse;
  testSessionPlayList: string | null;
  rmTestRunConfigurations: TestRunConfigurationResponseData[];
  reproceccingConfigLabels: string[];
  reproceccingConfigurations: ReproceccingConfiguration[];
  testSessions: TestSession[];
  testRunSubmissions: TestRunSubmission[];
  testrunExecutions: TestrunExecution[];
  kpiMeta: KpiMeta;
  kpiProjects: KpiProject[];
  kpiTestsuites: KpiTestsuite[];
  kpiScriptsConfigurations: KpiScriptConfiguration[];
  reprocessingInfo: ReproceccingInfo[];
}

export const initialState: State = {
  semanticSearchData: { data: { queryBuilderQuery: '' } },
  queryVrctData: { data: { queryVRCT: '' } },
  testSessionPlayList: null,
  rmTestRunConfigurations: [],
  reproceccingConfigLabels: [],
  reproceccingConfigurations: [],
  testSessions: [],
  testRunSubmissions: [],
  testrunExecutions: [],
  kpiMeta: {
    projects: [],
    mfk5_categories: [],
    lidar_categories: [],
    adcam_categories: [],
    adcam_low_categories: [],
    mik_categories: [],
    datasets: [],
    dataset_status: [],
    reports: [],
    report_status: [],
    report_merge_variants: [],
    report_comparison_variants: [],
    tasks: [],
    task_status: [],
    usecase_status: [],
    scripts: [],
    jobs: [],
    clusters: [],
    cluster_status: [],
  },
  kpiProjects: [],
  kpiTestsuites: [],
  kpiScriptsConfigurations: [],
  reprocessingInfo: [],
};

export function reducer(
  state = initialState,
  action: TestRunConfigurationActions
): State {
  switch (action.type) {
    case TestRunConfigurationActionTypes.ResetTestRunConfigurationStore:
      return { ...initialState };

    case TestRunConfigurationActionTypes.LoadSemanticSearchDataSuccess:
      return {
        ...state,
        semanticSearchData: action.payload.queryBuilderResponse,
      };

    case TestRunConfigurationActionTypes.LoadQueryVrtcDataSuccess:
      return {
        ...state,
        queryVrctData: action.payload,
      };

    case TestRunConfigurationActionTypes.LoadTestSessionPlayListDataSuccess:
      const testSessionPlayList = JSON.stringify(
        TmaUtilsStaticService.generatePlayListFromLegacyTestSessions(
          action.payload.queryBuilderResponse,
          action.payload.queryVrctResponse
        )
      );

      return {
        ...state,
        testSessionPlayList,
      };

    case TestRunConfigurationActionTypes.LoadTestRunConfigurationDataSuccess:
      return {
        ...state,
        rmTestRunConfigurations: action.payload.data.test_run_configuration,
        reproceccingConfigLabels:
          action.payload.data.test_run_configuration.map(
            (reproceccingConfig: TestRunConfigurationResponseData) =>
              reproceccingConfig.label
          ),
      };

    case TestRunConfigurationActionTypes.GetReproceccingConfigurationsDataSuccess:
      const reproceccingConfigurations = JSON.parse(
        action.payload.data.getReProcessingConfigurations
      );

      return {
        ...state,
        reproceccingConfigurations,
      };

    case TestRunConfigurationActionTypes.GetTestSessionsDataSuccess:
      const testSessions = JSON.parse(action.payload.data.getTestSessions);

      return {
        ...state,
        testSessions,
      };

    case TestRunConfigurationActionTypes.GetTestRunSubmissionsDataSuccess:
      const testRunSubmissions = JSON.parse(
        action.payload.data.getTestRunSubmissions
      );

      return {
        ...state,
        testRunSubmissions,
      };

    case TestRunConfigurationActionTypes.GetTestrunExecutionsDataSuccess:
      const testrunExecutions = JSON.parse(
        action.payload.data.getTestRunExecutions
      );

      return {
        ...state,
        testrunExecutions,
      };

    case TestRunConfigurationActionTypes.GetKpiMetaDataSuccess:
      const kpiMeta = JSON.parse(action.payload.data.get_kpi_meta);

      return {
        ...state,
        kpiMeta,
      };

    case TestRunConfigurationActionTypes.GetKpiProjectsDataSuccess:
      const kpiProjects = JSON.parse(action.payload.data.getKPIProjects);

      return {
        ...state,
        kpiProjects,
      };

    case TestRunConfigurationActionTypes.GetKpiTestsuitesDataSuccess:
      const kpiTestsuites = JSON.parse(action.payload.data.getKPITestsuites)[
        'testsuites'
      ];

      return {
        ...state,
        kpiTestsuites,
      };

    case TestRunConfigurationActionTypes.GetKpiScriptConfigurationsDataSuccess:
      const getKpiScriptsConfigurations = JSON.parse(
        action.payload.data.getKPIScriptConfigurations
      );

      return {
        ...state,
        kpiScriptsConfigurations: getKpiScriptsConfigurations.kpis,
      };

    case TestRunConfigurationActionTypes.GetReprocessingInfoDataSuccess:
      const reprocessingInfo = JSON.parse(
        action.payload.data.getReprocessingInfo
      );

      return {
        ...state,
        reprocessingInfo,
      };

    default:
      return state;
  }
}
