import { Injectable } from '@angular/core';
import { SnackBarConstant } from '@constants/snack-bar.constant';
import { DynamoDbService } from '@core/services/dynamo-db.service';
import { SnackBarService } from '@core/services/snack-bar.service';
import { GraphQLQueryError } from '@model/payloads';
import {
  GraphQLQueryFailure,
  InnovizActions,
  InnovizActionTypes,
  LoadInnovizHilSwExchangesData,
  LoadInnovizHilSwExchangesDataByVersion,
  LoadInnovizHilSwExchangesDataByVersionSuccess,
  LoadInnovizHilSwExchangesDataSuccess,
  LoadPipelineData,
  LoadPipelineDataSuccess,
} from '@modules/innoviz/store/innoviz.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  map,
} from 'rxjs/operators';

@Injectable()
export class InnovizEffects {
  loadPipelineData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InnovizActionTypes.LoadPipelineData),
      concatMap((data: LoadPipelineData) => {
        return this.dynamoDbService.getPipeline(data.payload).pipe(
          map(
            (getPipelineDataResponse) =>
              new LoadPipelineDataSuccess(getPipelineDataResponse)
          ),
          catchError((error: GraphQLQueryError) => {
            const messages: string = error.errors
              .map((error) => error.message)
              .join();
            this.snackBarService.open(
              `Error of getting pipeline data: ${messages}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
            return of(new GraphQLQueryFailure(error));
          })
        );
      })
    )
  );

  loadInnovizHilSwExchanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InnovizActionTypes.LoadInnovizHilSwExchangesData),
      concatMap((data: LoadInnovizHilSwExchangesData) => {
        return this.dynamoDbService
          .loadInnovizHilSwExchanges(data.payload)
          .pipe(
            map(
              (loadInnovizHilSwExchangesResponse) =>
                new LoadInnovizHilSwExchangesDataSuccess(
                  loadInnovizHilSwExchangesResponse
                )
            ),
            catchError((error: GraphQLQueryError) => {
              const messages: string = error.errors
                .map((error) => error.message)
                .join();
              this.snackBarService.open(
                `Error of getting innoviz hil-sw exchanges data: ${messages}`,
                SnackBarConstant.actions.dismiss,
                3000
              );
              return of(new GraphQLQueryFailure(error));
            })
          );
      })
    )
  );

  loadInnovizHilSwExchangesByVersion$ = createEffect(() =>
    this.actions$.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      ofType(InnovizActionTypes.LoadInnovizHilSwExchangesDataByVersion),
      concatMap((data: LoadInnovizHilSwExchangesDataByVersion) => {
        return this.dynamoDbService
          .queryInnovizHilSwExchangesByVersion(data.payload)
          .pipe(
            map(
              (queryInnovizHilSwExchangesByVersionResponse) =>
                new LoadInnovizHilSwExchangesDataByVersionSuccess(
                  queryInnovizHilSwExchangesByVersionResponse
                )
            ),
            catchError((error: GraphQLQueryError) => {
              const messages: string = error.errors
                .map((error) => error.message)
                .join();
              this.snackBarService.open(
                `Error of getting innoviz hil-sw exchanges by version data: ${messages}`,
                SnackBarConstant.actions.dismiss,
                3000
              );
              return of(new GraphQLQueryFailure(error));
            })
          );
      })
    )
  );

  constructor(
    private actions$: Actions<InnovizActions>,
    private dynamoDbService: DynamoDbService,
    private snackBarService: SnackBarService
  ) {}
}
