import { Injectable } from '@angular/core';
import { SnackBarConstant } from '@constants/snack-bar.constant';
import { SemanticSearchService } from '@core/services/semantic-search.service';
import { SnackBarService } from '@core/services/snack-bar.service';
import { GraphQLQueryError } from '@model/payloads';
import {
  DataBrowserActions,
  DataBrowserActionTypes,
  GraphQLQueryFailure,
  LoadDataBrowser,
  LoadDataBrowserSuccess,
  LoadDrivingSession,
  LoadDrivingSessionS3Structure,
  LoadDrivingSessionS3StructureSuccess,
  LoadDrivingSessionSuccess,
} from '@modules/data-browser/store/data-browser.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

@Injectable()
export class DataBrowserEffects {
  loadDataBrowser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataBrowserActionTypes.LoadDataBrowser),
      concatMap((data: LoadDataBrowser) => {
        return this.semanticSearchService.queryDataBrowser(data.payload).pipe(
          map(
            (queryDataBrowserResponse) =>
              new LoadDataBrowserSuccess(queryDataBrowserResponse)
          ),
          catchError((error: GraphQLQueryError) => {
            const messages: string = error.errors
              .map((error) => error.message)
              .join();
            this.snackBarService.open(
              `Error of getting data browser: ${messages}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
            return of(new GraphQLQueryFailure(error));
          })
        );
      })
    )
  );

  loadDrivingSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataBrowserActionTypes.LoadDrivingSession),
      concatMap((data: LoadDrivingSession) => {
        return this.semanticSearchService.getDrivingSession(data.payload).pipe(
          map(
            (getDrivingSessionResponse) =>
              new LoadDrivingSessionSuccess(getDrivingSessionResponse)
          ),
          catchError((error: GraphQLQueryError) => {
            const messages: string = error.errors
              .map((error) => error.message)
              .join();
            this.snackBarService.open(
              `Error of getting driving session: ${messages}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
            return of(new GraphQLQueryFailure(error));
          })
        );
      })
    )
  );

  loadDrivingSessionS3Structure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataBrowserActionTypes.LoadDrivingSessionS3Structure),
      concatMap((data: LoadDrivingSessionS3Structure) => {
        return this.semanticSearchService
          .getDrivingSessionS3Structure(data.payload)
          .pipe(
            map(
              (getDrivingSessionS3StructureResponse) =>
                new LoadDrivingSessionS3StructureSuccess(
                  getDrivingSessionS3StructureResponse
                )
            ),
            catchError((error: GraphQLQueryError) => {
              const messages: string = error.errors
                .map((error) => error.message)
                .join();
              this.snackBarService.open(
                `Error of getting driving session s3 structure: ${messages}`,
                SnackBarConstant.actions.dismiss,
                3000
              );
              return of(new GraphQLQueryFailure(error));
            })
          );
      })
    )
  );

  constructor(
    private actions$: Actions<DataBrowserActions>,
    private semanticSearchService: SemanticSearchService,
    private snackBarService: SnackBarService
  ) {}
}
