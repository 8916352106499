import { Injectable } from '@angular/core';
import { SnackBarConstant } from '@constants/snack-bar.constant';
import {
  QueryVrctQuery,
  SaveTestRunQuery,
  TestrunStoreQuery,
} from '@core/amplify/aws-apmlify-query.constant';
import { KpiService } from '@core/services/kpi.service';
import { ReprocessingConfigurationService } from '@core/services/reprocessing-configuration.service';
import { SemanticSearchService } from '@core/services/semantic-search.service';
import { SnackBarService } from '@core/services/snack-bar.service';
import { TestOrderService } from '@core/services/test-order.service';
import { TestRunConfigurationService } from '@core/services/test-run-configuration.service';
import { TestSessionService } from '@core/services/test-session.service';
import { TmaUtilsStaticService } from '@core/services/tma-utils-static.service';
import {
  GraphQLQueryError,
  SaveTestRunPayload,
  SaveTestRunResponse,
  TestrunStorePayload,
} from '@model/payloads';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import {
  GetKpiMetaData,
  GetKpiMetaDataSuccess,
  GetKpiProjectsData,
  GetKpiProjectsDataSuccess,
  GetKpiScriptConfigurationsData,
  GetKpiScriptConfigurationsDataSuccess,
  GetKpiTestsuitesData,
  GetKpiTestsuitesDataSuccess,
  GetReproceccingConfigurationsData,
  GetReproceccingConfigurationsDataSuccess,
  GetReprocessingInfoData,
  GetReprocessingInfoDataSuccess,
  GetTestrunExecutionsData,
  GetTestrunExecutionsDataSuccess,
  GetTestRunSubmissionsData,
  GetTestRunSubmissionsDataSuccess,
  GetTestSessionsData,
  GetTestSessionsDataSuccess,
  GraphQLQueryFailure,
  LoadSemanticSearchData,
  LoadSemanticSearchDataSuccess,
  LoadTestRunConfigurationData,
  LoadTestRunConfigurationDataSuccess,
  LoadTestSessionPlayListData,
  LoadTestSessionPlayListDataSuccess,
  RetriggerTestrunData,
  RetriggerTestrunDataFailed,
  RetriggerTestrunDataSuccess,
  SaveKpitoolTriggerData,
  SaveKpitoolTriggerDataFailed,
  SaveKpitoolTriggerDataSuccess,
  SaveReproceccingConfigurationData,
  SaveReproceccingConfigurationDataSuccess,
  SaveTestOrderData,
  SaveTestOrderDataSuccess,
  SaveTestRunDataSuccess,
  SaveTestSessionData,
  SaveTestSessionDataSuccess,
  StartTestrunData,
  StartTestrunDataFailed,
  StartTestrunDataSuccess,
  StoreTestrunConfigurationsData,
  StoreTestrunConfigurationsDataSuccess,
  TestRunConfigurationActions,
  TestRunConfigurationActionTypes,
} from './test-run-configuration.actions';

@Injectable()
export class TestRunConfigurationEffects {
  loadSemanticSearchData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestRunConfigurationActionTypes.LoadSemanticSearchData),
      concatMap((data: LoadSemanticSearchData) => {
        this.snackBarService.open(
          'Semantic search form data has been sent. Wait for response',
          SnackBarConstant.actions.dismiss,
          3000
        );
        return this.semanticSearchService.queryBuilderQuery(data.payload).pipe(
          map((queryBuilderResponse) => {
            this.snackBarService.open(
              'Semantic search results has been generated successfully. Check semantic search results panel',
              SnackBarConstant.actions.dismiss,
              3000
            );
            return new LoadSemanticSearchDataSuccess({
              queryBuilderResponse,
            });
          }),
          catchError((error: GraphQLQueryError) => {
            const messages: string = error.errors
              .map((error) => error.message)
              .join();
            this.snackBarService.open(
              `Error of requesting semantic search data: ${messages}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
            return of(new GraphQLQueryFailure(error));
          })
        );
      })
    )
  );

  loadTestSessionPlaylistData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestRunConfigurationActionTypes.LoadTestSessionPlayListData),
      concatMap((action: LoadTestSessionPlayListData) => {
        this.snackBarService.open(
          'Playlist form data has been sent. Wait for response',
          SnackBarConstant.actions.dismiss,
          3000
        );

        if (action.payload.variables.query.astep?.length) {
          action.payload.variables.query.select_bordnet =
            action.payload.variables.query.astep;
          action.payload.variables.query.astep = '';
        }

        const select_bordnet = action.payload.variables.query.astep;

        return this.semanticSearchService
          .queryBuilderQuery(action.payload)
          .pipe(
            concatMap((queryBuilderResponse) => {
              const variables = TmaUtilsStaticService.getLegacyQueryVrctPayload(
                queryBuilderResponse,
                select_bordnet
              );

              return this.semanticSearchService
                .queryVrct({
                  query: QueryVrctQuery,
                  variables,
                })
                .pipe(
                  map((queryVrctResponse) => {
                    this.snackBarService.open(
                      'Playlist has been generated successfully',
                      SnackBarConstant.actions.dismiss,
                      3000
                    );

                    return new LoadTestSessionPlayListDataSuccess({
                      queryBuilderResponse,
                      queryVrctResponse,
                    });
                  }),
                  catchError((error: GraphQLQueryError) => {
                    const messages: string = error.errors
                      .map((error) => error.message)
                      .join();

                    this.snackBarService.open(
                      `Error of generation playlist: ${messages}`,
                      SnackBarConstant.actions.dismiss,
                      3000
                    );

                    return of(new GraphQLQueryFailure(error));
                  })
                );
            }),
            catchError((error: GraphQLQueryError) => {
              const messages: string = error.errors
                .map((error) => error.message)
                .join();

              this.snackBarService.open(
                `Error of requesting semantic search data: ${messages}`,
                SnackBarConstant.actions.dismiss,
                3000
              );

              return of(new GraphQLQueryFailure(error));
            })
          );
      })
    )
  );

  saveTestSessionData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestRunConfigurationActionTypes.SaveTestSessionData),
      concatMap((data: SaveTestSessionData) => {
        this.snackBarService.open(
          'Test session form data has been sent. Wait for response',
          SnackBarConstant.actions.dismiss,
          3000
        );
        return this.testSessionService.saveTestSession(data.payload).pipe(
          map((querySaveTestSessionResponse) => {
            this.snackBarService.open(
              `Test session data has been sent to save. Response: ${querySaveTestSessionResponse.data.save_test_session}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
            return new SaveTestSessionDataSuccess(querySaveTestSessionResponse);
          }),
          catchError((error: GraphQLQueryError) => {
            const messages: string = error.errors
              .map((error) => error.message)
              .join();
            this.snackBarService.open(
              `Error of saving test session: ${messages}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
            return of(new GraphQLQueryFailure(error));
          })
        );
      })
    )
  );

  loadTestRunConfigurationData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestRunConfigurationActionTypes.LoadTestRunConfigurationData),
      concatMap((data: LoadTestRunConfigurationData) => {
        return this.testRunConfigurationService
          .queryTestRunConfiguration(data.payload)
          .pipe(
            map((testRunConfigurationResponse) => {
              return new LoadTestRunConfigurationDataSuccess(
                testRunConfigurationResponse
              );
            }),
            catchError((error: GraphQLQueryError) => {
              const messages: string = error.errors
                .map((error) => error.message)
                .join();
              this.snackBarService.open(
                `Error of loading test run configurations labels: ${messages}`,
                SnackBarConstant.actions.dismiss,
                3000
              );
              return of(new GraphQLQueryFailure(error));
            })
          );
      })
    )
  );

  saveReproceccingConfigurationData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestRunConfigurationActionTypes.SaveReproceccingConfigurationData),
      concatMap((data: SaveReproceccingConfigurationData) => {
        this.snackBarService.open(
          'Reprocessing configuration form data has been sent. Wait for response',
          SnackBarConstant.actions.dismiss,
          3000
        );
        return this.reprocessingConfigurationService
          .saveReproceccingConfiguration(data.payload)
          .pipe(
            map((querySaveReprocessingConfigurationResponse) => {
              this.snackBarService.open(
                `Reprocessing configuration data has been sent to save. Response: ${querySaveReprocessingConfigurationResponse.data.save_reprocessing_configuration}`,
                SnackBarConstant.actions.dismiss,
                3000
              );
              return new SaveReproceccingConfigurationDataSuccess(
                querySaveReprocessingConfigurationResponse
              );
            }),
            catchError((error: GraphQLQueryError) => {
              const messages: string = error.errors
                .map((error) => error.message)
                .join();
              this.snackBarService.open(
                `Error of saving reprocessing config: ${messages}`,
                SnackBarConstant.actions.dismiss,
                3000
              );
              return of(new GraphQLQueryFailure(error));
            })
          );
      })
    )
  );

  getReproceccingConfigurationsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestRunConfigurationActionTypes.GetReproceccingConfigurationsData),
      concatMap((data: GetReproceccingConfigurationsData) => {
        return this.reprocessingConfigurationService
          .getReproceccingConfigurations(data.payload)
          .pipe(
            map((getReprocessingConfigurationsResponse) => {
              return new GetReproceccingConfigurationsDataSuccess(
                getReprocessingConfigurationsResponse
              );
            }),
            catchError((error: GraphQLQueryError) => {
              const messages: string = error.errors
                .map((error) => error.message)
                .join();
              this.snackBarService.open(
                `Error of getting reprocessing configs: ${messages}`,
                SnackBarConstant.actions.dismiss,
                3000
              );
              return of(new GraphQLQueryFailure(error));
            })
          );
      })
    )
  );

  getTestSessionsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestRunConfigurationActionTypes.GetTestSessionsData),
      concatMap((data: GetTestSessionsData) => {
        return this.testSessionService.getTestSessions(data.payload).pipe(
          map((getTestSessionsResponse) => {
            return new GetTestSessionsDataSuccess(getTestSessionsResponse);
          }),
          catchError((error: GraphQLQueryError) => {
            const messages: string = error.errors
              .map((error) => error.message)
              .join();
            this.snackBarService.open(
              `Error of getting test sessions: ${messages}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
            return of(new GraphQLQueryFailure(error));
          })
        );
      })
    )
  );

  saveTestOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestRunConfigurationActionTypes.SaveTestOrderData),
      concatMap((data: SaveTestOrderData) => {
        this.snackBarService.open(
          'Test run configuration form data has been sent. Wait for response',
          SnackBarConstant.actions.dismiss,
          3000
        );
        return this.testOrderService.saveTestOrder(data.payload).pipe(
          map((saveTestOrderResponse) => {
            this.snackBarService.open(
              `Test run configuration data has been saved. Response: ${saveTestOrderResponse.data.save_test_order}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
            return new SaveTestOrderDataSuccess(saveTestOrderResponse);
          }),
          catchError((error: GraphQLQueryError) => {
            const messages: string = error.errors
              .map((error) => error.message)
              .join();
            this.snackBarService.open(
              `Error saving test run configuration: ${messages}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
            return of(new GraphQLQueryFailure(error));
          })
        );
      })
    )
  );

  getTestRunSubmissionsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestRunConfigurationActionTypes.GetTestRunSubmissionsData),
      concatMap((data: GetTestRunSubmissionsData) => {
        return this.testRunConfigurationService
          .getTestRunSubmissions(data.payload)
          .pipe(
            map((getTestRunSubmissionsResponse) => {
              return new GetTestRunSubmissionsDataSuccess(
                getTestRunSubmissionsResponse
              );
            }),
            catchError((error: GraphQLQueryError) => {
              const messages: string = error.errors
                .map((error) => error.message)
                .join();
              this.snackBarService.open(
                `Error getting test run submissions: ${messages}`,
                SnackBarConstant.actions.dismiss,
                3000
              );
              return of(new GraphQLQueryFailure(error));
            })
          );
      })
    )
  );

  storeTestrunConfigurationsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestRunConfigurationActionTypes.StoreTestrunConfigurationsData),
      concatMap((data: StoreTestrunConfigurationsData) => {
        const testrunStorePayload: TestrunStorePayload = {
          query: TestrunStoreQuery,
          variables: {
            test_run_config: data.payload.testOrder,
            test_run_id: TmaUtilsStaticService.getLegacyRandomTestrunId(),
          },
        };

        return this.testRunConfigurationService
          .testrunStore(testrunStorePayload)
          .pipe(
            map((testrunStoreResponse) => {
              this.snackBarService.open(
                `Test run execution data has been submitted successfully. Your test run id: ${
                  JSON.parse(testrunStoreResponse.data.testrun_store)
                    .test_run_id
                }. Please check and run this test run id in "Test run executions monitoring" tab`,
                SnackBarConstant.actions.dismiss,
                3000
              );

              const testrun_store = JSON.parse(
                testrunStoreResponse.data.testrun_store
              );
              const saveTestRunPayload: SaveTestRunPayload = {
                query: SaveTestRunQuery,
                variables: {
                  testrun_id: testrun_store.test_run_id,
                  testrun_config: data.payload.testOrder,
                  reprocessing_config: data.payload.reprocessingConfig,
                  test_sessions: data.payload.testSessions,
                  session_to_kpiscipts_mapping:
                    data.payload.sessionToKPIScriptsMapping,
                  testrun_config_label: data.payload.testRunConfigLabel,
                  response: testrun_store.status,
                  status: '',
                  testOrderId: data.payload.orderId,
                },
              };

              this.testRunConfigurationService
                .saveTestRun(saveTestRunPayload)
                .pipe(
                  map(
                    (saveTestRunResponse: SaveTestRunResponse) =>
                      new SaveTestRunDataSuccess(saveTestRunResponse)
                  ),
                  catchError((error: GraphQLQueryError) => {
                    const messages: string = error.errors
                      .map((error) => error.message)
                      .join();
                    this.snackBarService.open(
                      `Error of saving test run config: ${messages}`,
                      SnackBarConstant.actions.dismiss,
                      3000
                    );
                    return of(new GraphQLQueryFailure(error));
                  })
                );

              return new StoreTestrunConfigurationsDataSuccess(
                testrunStoreResponse
              );
            }),
            catchError((error: GraphQLQueryError) => {
              const messages: string = error.errors
                .map((error) => error.message)
                .join();
              this.snackBarService.open(
                `Error of storing test run config: ${messages}`,
                SnackBarConstant.actions.dismiss,
                3000
              );
              return of(new GraphQLQueryFailure(error));
            })
          );
      })
    )
  );

  getTestrunExecutionsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestRunConfigurationActionTypes.GetTestrunExecutionsData),
      concatMap((data: GetTestrunExecutionsData) => {
        return this.testRunConfigurationService
          .getTestrunExecutions(data.payload)
          .pipe(
            map((getTestrunExecutionsResponse) => {
              return new GetTestrunExecutionsDataSuccess(
                getTestrunExecutionsResponse
              );
            }),
            catchError((error: GraphQLQueryError) => {
              const messages: string = error.errors
                .map((error) => error.message)
                .join();
              this.snackBarService.open(
                `Error of getting test run executions: ${messages}`,
                SnackBarConstant.actions.dismiss,
                3000
              );
              return of(new GraphQLQueryFailure(error));
            })
          );
      })
    )
  );

  startTestrunConfigurationsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestRunConfigurationActionTypes.StartTestrunData),
      concatMap((data: StartTestrunData) => {
        return this.testRunConfigurationService.testrunStart(data.payload).pipe(
          map((testrunStartResponse) => {
            this.snackBarService.open(
              `Test run configuration has been started. Response: ${testrunStartResponse.data.testrun_start}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
            return new StartTestrunDataSuccess(testrunStartResponse);
          }),
          catchError((error: GraphQLQueryError) => {
            const messages: string = error.errors
              .map((error) => error.message)
              .join();

            this.snackBarService.open(
              `Error of starting test run config: ${messages}`,
              SnackBarConstant.actions.dismiss,
              3000
            );

            of(new GraphQLQueryFailure(error));

            return of(new StartTestrunDataFailed());
          })
        );
      })
    )
  );

  retriggerTestrunConfigurationsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestRunConfigurationActionTypes.RetriggerTestrunData),
      concatMap((data: RetriggerTestrunData) => {
        return this.testRunConfigurationService
          .testrunRetrigger(data.payload)
          .pipe(
            map((testrunExecutionsRetriggerResponse) => {
              this.snackBarService.open(
                `Test run configuration has been retriggered. Response: ${testrunExecutionsRetriggerResponse.data.testrun_retrigger}`,
                SnackBarConstant.actions.dismiss,
                3000
              );

              return new RetriggerTestrunDataSuccess(
                testrunExecutionsRetriggerResponse
              );
            }),
            catchError((error: GraphQLQueryError) => {
              const messages: string = error.errors
                .map((error) => error.message)
                .join();

              this.snackBarService.open(
                `Error of retriggering test run config: ${messages}`,
                SnackBarConstant.actions.dismiss,
                3000
              );

              of(new GraphQLQueryFailure(error));

              return of(new RetriggerTestrunDataFailed());
            })
          );
      })
    )
  );

  getKpiMetaData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestRunConfigurationActionTypes.GetKpiMetaData),
      concatMap((data: GetKpiMetaData) => {
        return this.kpiService.getKpiMeta(data.payload).pipe(
          map((getKpiMetaResponse) => {
            return new GetKpiMetaDataSuccess(getKpiMetaResponse);
          }),
          catchError((error: GraphQLQueryError) => {
            const messages: string = error.errors
              .map((error) => error.message)
              .join();
            this.snackBarService.open(
              `Error of getting kpi meta: ${messages}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
            return of(new GraphQLQueryFailure(error));
          })
        );
      })
    )
  );

  getKpiProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestRunConfigurationActionTypes.GetKpiProjectsData),
      concatMap((data: GetKpiProjectsData) => {
        return this.kpiService.getKpiProjects(data.payload).pipe(
          map((GetKpiProjectsResponse) => {
            return new GetKpiProjectsDataSuccess(GetKpiProjectsResponse);
          }),
          catchError((error: GraphQLQueryError) => {
            const messages: string = error.errors
              .map((error) => error.message)
              .join();
            this.snackBarService.open(
              `Error of getting kpi projects: ${messages}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
            return of(new GraphQLQueryFailure(error));
          })
        );
      })
    )
  );

  getKpiTestsuites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestRunConfigurationActionTypes.GetKpiTestsuitesData),
      concatMap((data: GetKpiTestsuitesData) => {
        return this.kpiService.getKpiTestSuites(data.payload).pipe(
          map((GetKpiTestsuitesResponse) => {
            return new GetKpiTestsuitesDataSuccess(GetKpiTestsuitesResponse);
          }),
          catchError((error: GraphQLQueryError) => {
            const messages: string = error.errors
              .map((error) => error.message)
              .join();
            this.snackBarService.open(
              `Error of getting kpi testsuites: ${messages}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
            return of(new GraphQLQueryFailure(error));
          })
        );
      })
    )
  );

  getKpiScriptConfigurationsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestRunConfigurationActionTypes.GetKpiScriptConfigurationsData),
      concatMap((data: GetKpiScriptConfigurationsData) => {
        return this.kpiService.getKpiScriptConfigurations(data.payload).pipe(
          map((getKpiScriptConfigurationsResponse) => {
            return new GetKpiScriptConfigurationsDataSuccess(
              getKpiScriptConfigurationsResponse
            );
          }),
          catchError((error: GraphQLQueryError) => {
            const messages: string = error.errors
              .map((error) => error.message)
              .join();
            this.snackBarService.open(
              `Error of getting kpi scripts configurations: ${messages}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
            return of(new GraphQLQueryFailure(error));
          })
        );
      })
    )
  );

  getReprocessingInfoData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestRunConfigurationActionTypes.GetReprocessingInfoData),
      concatMap((data: GetReprocessingInfoData) => {
        return this.reprocessingConfigurationService
          .getReproceccingInfo(data.payload)
          .pipe(
            map((getReprocessingInfoResponse) => {
              return new GetReprocessingInfoDataSuccess(
                getReprocessingInfoResponse
              );
            }),
            catchError((error: GraphQLQueryError) => {
              const messages: string = error.errors
                .map((error) => error.message)
                .join();
              this.snackBarService.open(
                `Error of getting reprocessing info: ${messages}`,
                SnackBarConstant.actions.dismiss,
                3000
              );
              return of(new GraphQLQueryFailure(error));
            })
          );
      })
    )
  );

  saveKpitoolTriggerData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TestRunConfigurationActionTypes.SaveKpitoolTriggerData),
      concatMap((data: SaveKpitoolTriggerData) => {
        return this.kpiService.saveKpitoolTrigger(data.payload).pipe(
          map((saveKpitoolTriggerResponse) => {
            this.snackBarService.open(
              `KPI script has been started successfully. Response: ${saveKpitoolTriggerResponse.data.save_kpitool_trigger}`,
              SnackBarConstant.actions.dismiss,
              3000
            );

            return new SaveKpitoolTriggerDataSuccess(
              saveKpitoolTriggerResponse
            );
          }),
          catchError((error: GraphQLQueryError) => {
            const messages: string = error.errors
              .map((error) => error.message)
              .join();

            this.snackBarService.open(
              `Error of saving kpi tool trigger: ${messages}`,
              SnackBarConstant.actions.dismiss,
              3000
            );

            of(new GraphQLQueryFailure(error));

            return of(new SaveKpitoolTriggerDataFailed());
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions<TestRunConfigurationActions>,
    private semanticSearchService: SemanticSearchService,
    private snackBarService: SnackBarService,
    private testSessionService: TestSessionService,
    private testRunConfigurationService: TestRunConfigurationService,
    private reprocessingConfigurationService: ReprocessingConfigurationService,
    private testOrderService: TestOrderService,
    private kpiService: KpiService
  ) {}
}
