import { NgModule } from '@angular/core';
import { DmrHomeComponent } from './components/dmr-home/dmr-home.component';
import { DrmVendorsComponent } from './components/dmr-vendors/drm-vendors.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [DmrHomeComponent, DrmVendorsComponent],
  imports: [SharedModule],
})
export class DmrHomeModule {}
