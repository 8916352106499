<mat-card>
  <mat-card-content>
    <app-table
      [displayedColumns]="innovizHilSwExchangesTableColumns"
      [dataSource]="innovizHilSwExchangesTableDataSource"
      (tableItemSelected)="onInnovizHilSwExchangeSelected($event)"
      [filterPlaceholder]="'MTA_image_lidar_6.7.7'"
      [selection]="innovizHilSwExchangesTableSelection"
      [showAllSelectionsCheckbox]="true"
      (filterChanged)="onApplyFilter($event)"
      [spinner]="innovizHilSwExchangesTableSpinner"
      [filterTooltip]="'Use VERSION to filter exchanges'"
    ></app-table>
  </mat-card-content>

  <mat-card-actions fxLayout="row" fxLayoutAlign="start center">
    <button
      mat-raised-button
      color="primary"
      type="button"
      (click)="onFetchInnovizHilSwExchanges()"
      matTooltip="Click to update table data above"
      matTooltipPosition="below"
    >
      <mat-icon>refresh</mat-icon>
    </button>
    <button
      mat-raised-button
      color="primary"
      type="button"
      [disabled]="!innovizHilSwExchangesSelected.length"
      (click)="onInnovizHilSwExchangeStarted()"
      matTooltip="Click to start exchange for selected items"
      matTooltipPosition="below"
    >
      <mat-icon>start</mat-icon>
    </button>
  </mat-card-actions>

  <div
    *ngIf="onInnovizHilSwExchangeStartedSuccessMessage"
    class="mat-card-actions-result-message"
  >
    <strong> {{ onInnovizHilSwExchangeStartedSuccessMessage }}</strong>
  </div>

  <mat-error
    *ngIf="onInnovizHilSwExchangeStartedErrorMessage"
    class="mat-card-actions-error-message"
    ><strong>{{ onInnovizHilSwExchangeStartedErrorMessage }}</strong></mat-error
  >
</mat-card>
