import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { GraphQLOptions } from '@aws-amplify/api-graphql';
import { ProjectsConstant } from '@constants/projects.constant';
import { SnackBarConstant } from '@constants/snack-bar.constant';
import { TableConstant } from '@constants/table.constant';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';
import {
  AddNewTestRunConfiguration,
  DeleteTestRunConfiguration,
  TestRunConfigurationQuery,
  UpdateTestRunConfigurationQuery,
} from '@core/amplify/aws-apmlify-query.constant';
import { SnackBarService } from '@core/services/snack-bar.service';
import { environment } from '@environments/environment';
import {
  LoadTestRunConfigurationData,
  TestRunConfigurationActions,
  TestRunConfigurationActionTypes,
} from '@modules/test-run-configuration/store/test-run-configuration.actions';
import { selectFeatureRmTestRunConfigurations } from '@modules/test-run-configuration/store/test-run-configuration.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-rm-test-run-configuration',
  templateUrl: './rm-test-run-configuration.component.html',
  styleUrls: ['./rm-test-run-configuration.component.scss'],
})
export class RmTestRunConfigurationComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() currentProjectName = environment.projectName;

  rmTestRunConfigurations$: Observable<TestRunConfigurationResponseData[]> =
    this.store.select(selectFeatureRmTestRunConfigurations);

  createRmTestRunConfigurationForm = this.fb.group({
    id: 0,
    label: ['', Validators.required],
    bmw_job_version: '',
    bordnet_version: '',
    ecu_init_string_version: {
      value: '',
      disabled:
        environment.projectName === ProjectsConstant.MIK.name ? false : true,
    },
    dut_version: {
      value: '',
      disabled:
        environment.projectName === ProjectsConstant.MIK.name ? true : false,
    },
    rbs_version: {
      value: '',
      disabled:
        environment.projectName === ProjectsConstant.MIK.name ? true : false,
    },
    vehicle_type: {
      value: '',
      disabled:
        environment.projectName === ProjectsConstant.MIK.name ? true : false,
    },
    hil_sw_version: '',
    sil_sw_version: '',
    conti_processed_data: '',
    aptiv_processed_data: '',
    type: ProjectsConstant.LIDAR.name.toLowerCase(),
    configupdate_version: {
      value: '',
      disabled: true,
    },
    ecu_init_version: {
      value: '',
      disabled:
        environment.projectName === ProjectsConstant.MIK.name ? true : false,
    },
  });

  rmTestRunConfigurationVehicleTypes = ['', 'G60', 'G61', 'G68', 'G70', 'I20'];

  rmTestRunConfigurationsTableColumns: string[] = [];
  rmTestRunConfigurationsTableDataSource = new MatTableDataSource<any>();
  filteredRmTestRunConfigurationsTableDataSource =
    new MatTableDataSource<any>();
  rmTestRunConfigurationsTableSelection = new SelectionModel<any>(true, []);
  rmTestRunConfigurationsTableSpinner = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentProjectName.currentValue) {
      this.filteredRmTestRunConfigurationsTableDataSource.data =
        this.rmTestRunConfigurationsTableDataSource.data.filter(
          (item) =>
            item.type ===
            ProjectsConstant[changes.currentProjectName.currentValue].name
        );

      this.createRmTestRunConfigurationForm.reset();

      this.createRmTestRunConfigurationForm.patchValue({
        type: changes.currentProjectName.currentValue,
      });

      if (
        changes.currentProjectName.currentValue ===
          ProjectsConstant.MFK5.name ||
        changes.currentProjectName.currentValue === ProjectsConstant.MFK120.name
      ) {
        this.createRmTestRunConfigurationForm.controls.ecu_init_string_version.enable();
        this.createRmTestRunConfigurationForm.controls.configupdate_version.enable();
      } else {
        this.createRmTestRunConfigurationForm.controls.ecu_init_string_version.disable();
        this.createRmTestRunConfigurationForm.controls.configupdate_version.disable();
      }

      if (
        changes.currentProjectName.currentValue === ProjectsConstant.MIK.name
      ) {
        this.createRmTestRunConfigurationForm.controls.ecu_init_string_version.enable();
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.actions$
      .pipe(
        ofType(TestRunConfigurationActionTypes.LoadTestRunConfigurationData)
      )
      .subscribe(() => {
        this.rmTestRunConfigurationsTableSpinner = true;
      });

    this.actions$
      .pipe(
        ofType(
          TestRunConfigurationActionTypes.LoadTestRunConfigurationDataSuccess
        )
      )
      .subscribe(() => {
        this.rmTestRunConfigurationsTableSpinner = false;
      });

    this.subscriptions.add(
      this.rmTestRunConfigurations$.subscribe(
        (testRunConfigurationResponseData) => {
          this.renderRmTestRunConfigurationsTable(
            testRunConfigurationResponseData
          );
        }
      )
    );

    // TODO: uncomment it when pagination will be done, now the request is duplicated in test-run-configuration-create component
    // this.store.dispatch(
    //   new LoadTestRunConfigurationData({
    //     query: TestRunConfigurationQuery,
    //     variables: {},
    //   })
    // );
  }

  onRefreshData(): void {
    this.createRmTestRunConfigurationForm.reset();

    this.createRmTestRunConfigurationForm.patchValue({
      type: this.currentProjectName.toLowerCase(),
    });

    this.createRmTestRunConfigurationForm.controls.label.enable();

    this.store.dispatch(
      new LoadTestRunConfigurationData({
        query: TestRunConfigurationQuery,
        variables: {},
      })
    );
  }

  onSubmit(): void {
    const test_run_configuration =
      this.createRmTestRunConfigurationForm.getRawValue();

    let graphQLOptions: GraphQLOptions;

    if (this.createRmTestRunConfigurationForm.get('id')?.value) {
      // @ts-ignore
      delete test_run_configuration.id;
      delete test_run_configuration.type;

      if (!test_run_configuration['vehicle_type']?.length) {
        test_run_configuration['vehicle_type'] = null;
      }

      graphQLOptions = {
        query: UpdateTestRunConfigurationQuery,
        variables: {
          test_run_configuration,
        },
      };
    } else {
      // @ts-ignore
      delete test_run_configuration.id;

      for (const key in test_run_configuration) {
        // @ts-ignore
        if (key !== 'configupdate_version' || key !== 'vehicle_type') {
          // @ts-ignore
          if (test_run_configuration[key] === null) {
            // @ts-ignore
            test_run_configuration[key] = '';
          }
        }
      }

      test_run_configuration['type'] =
        // @ts-ignore
        test_run_configuration['type'].toLowerCase();

      if (!test_run_configuration['vehicle_type']?.length) {
        test_run_configuration['vehicle_type'] = null;
      }

      if (!test_run_configuration['configupdate_version']?.length) {
        test_run_configuration['configupdate_version'] = null;
      }

      graphQLOptions = {
        query: AddNewTestRunConfiguration,
        variables: {
          test_run_configuration,
        },
      };
    }

    this.awsAmplifyApiService.graphQLQuery(graphQLOptions).subscribe(
      (response) => {
        const message =
          response.data.updateTestRunConfiguration?.message ||
          response.data.addNewTestRunConfiguration?.message;

        this.snackBarService.open(
          message,
          SnackBarConstant.actions.dismiss,
          3000
        );

        this.onRefreshData();
      },
      (error) => {
        this.snackBarService.showGraphQlErrorMessage(error);
      }
    );
  }

  deleteRmTestRunConfiguration(): void {
    this.awsAmplifyApiService
      .graphQLQuery({
        query: DeleteTestRunConfiguration,
        variables: {
          label: this.createRmTestRunConfigurationForm.get('label')?.value,
        },
      })
      .subscribe(
        (response) => {
          this.snackBarService.open(
            `${response.data.deleteTestRunConfiguration.message}`,
            SnackBarConstant.actions.dismiss,
            3000
          );

          this.onRefreshData();
        },
        (error) => {
          this.snackBarService.showGraphQlErrorMessage(error);
        }
      );
  }

  onRmTestRunConfigurationItemSelected(selected: any): void {
    this.createRmTestRunConfigurationForm.patchValue(selected);

    this.createRmTestRunConfigurationForm.controls.label.disable();
  }

  private subscriptions = new Subscription();

  private renderRmTestRunConfigurationsTable(
    items: TestRunConfigurationResponseData[]
  ): void {
    if (items.length) {
      this.rmTestRunConfigurationsTableColumns = [
        ...TableConstant.rmTestRunConfigurationsTableColumns,
      ];
    }

    this.rmTestRunConfigurationsTableDataSource = new MatTableDataSource(items);

    this.filteredRmTestRunConfigurationsTableDataSource =
      new MatTableDataSource(
        this.rmTestRunConfigurationsTableDataSource.data.filter(
          (item) => item.type === this.currentProjectName
        )
      );
  }

  constructor(
    private store: Store,
    private actions$: Actions<TestRunConfigurationActions>,
    private fb: FormBuilder,
    private awsAmplifyApiService: AwsAmplifyApiService,
    private snackBarService: SnackBarService
  ) {}
}
