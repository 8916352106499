import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {
  GetDrivingSessionQuery,
  getDrivingSessionS3StructureQuery,
  QueryDataBrowserQuery,
} from '@core/amplify/aws-apmlify-query.constant';
import { TmaUtilsStaticService } from '@core/services/tma-utils-static.service';
import { environment } from '@environments/environment';
import {
  DataBrowserActions,
  DataBrowserActionTypes,
  LoadDataBrowser,
  LoadDrivingSession,
  LoadDrivingSessionS3Structure,
  ResetDataBrowserStore,
  ResetDrivingSessionS3StructureStore,
  ResetDrivingSessionStore,
} from '@modules/data-browser/store/data-browser.actions';
import * as fromDataBrowser from '@modules/data-browser/store/data-browser.reducer';
import {
  selectFeatureDataBrowserData,
  selectFeatureDrivingSession,
  selectFeatureDrivingSessionS3Structure,
} from '@modules/data-browser/store/data-browser.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-data-browser',
  templateUrl: './data-browser.component.html',
  styleUrls: ['./data-browser.component.scss'],
})
export class DataBrowserComponent implements OnInit, OnDestroy {
  @ViewChild(MatSelectionList) folderNameList!: MatSelectionList;

  environment = environment;

  s3RecordingPath = '';
  foldersNames: string[] = [];
  s3Folders: {
    [key: string]: S3Object[];
  } = {};
  s3Objects: S3Object[] = [];
  s3StructureSpinner = false;

  dataBrowser$: Observable<DataBrowserResponseItem[]> = this.store.select(
    selectFeatureDataBrowserData
  );

  drivingSession$: Observable<DrivingSession> = this.store.select(
    selectFeatureDrivingSession
  );

  drivingSessionS3Structure$: Observable<DrivingSessionS3Structure> =
    this.store.select(selectFeatureDrivingSessionS3Structure);

  dataBrowserControlsMetadata: DataBrowserControlsMetadata = {
    query: TmaUtilsStaticService.getFromControlMetadata(
      'query',
      'SQL query',
      'textarea'
    ),
  };
  dataBrowserControlsConfig: FormControlsConfig = {
    query: [null, [Validators.required]],
  };
  dataBrowserFormGroup = this.formBuilder.group(this.dataBrowserControlsConfig);

  dataBrowserTableColumns: string[] = [];
  dataBrowserTableDataSource = new MatTableDataSource<any>();
  filteredDataBrowserTableDataSource = new MatTableDataSource<any>();
  dataBrowserTableSelection = new SelectionModel<any>(true, []);
  dataBrowserTableSpinner = false;

  onSubmitDataBrowserForm(formControlsConfig: FormControlsConfig): void {
    this.store.dispatch(new ResetDrivingSessionStore());
    this.store.dispatch(new ResetDrivingSessionS3StructureStore());

    const query = formControlsConfig.query;

    this.store.dispatch(
      new LoadDataBrowser({
        query: QueryDataBrowserQuery,
        variables: {
          query,
        },
      })
    );
  }

  onDataBrowserTableItemSelected(selected: any): void {
    this.store.dispatch(new ResetDrivingSessionStore());
    this.store.dispatch(new ResetDrivingSessionS3StructureStore());

    this.store.dispatch(
      new LoadDrivingSession({
        query: GetDrivingSessionQuery,
        variables: {
          drive_id: selected['drive_id'],
          verbose: false,
        },
      })
    );

    this.store.dispatch(
      new LoadDrivingSessionS3Structure({
        query: getDrivingSessionS3StructureQuery,
        variables: {
          drive_id: selected['drive_id'],
        },
      })
    );
  }

  onFolderClick(folderName: string): void {
    this.s3Objects = this.s3Folders[folderName];
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetDataBrowserStore());
  }

  ngOnInit(): void {
    this.dataBrowser$.subscribe((dataBrowserResponseItems) => {
      this.dataBrowserTableColumns = ['drive_id'];

      this.dataBrowserTableDataSource = new MatTableDataSource(
        dataBrowserResponseItems
      );

      this.dataBrowserTableDataSource.data.map((tableItemData) => {
        if (tableItemData['drive-id']) {
          tableItemData['drive_id'] = tableItemData['drive-id'];
        }

        if (tableItemData['re_proc_recording_id']) {
          tableItemData['drive_id'] = tableItemData['re_proc_recording_id'];
        }
      });

      this.filteredDataBrowserTableDataSource = new MatTableDataSource(
        this.dataBrowserTableDataSource.data
      );

      this.dataBrowserTableSpinner = false;
    });

    this.drivingSessionS3Structure$.subscribe((drivingSessionS3Structure) => {
      this.s3RecordingPath = drivingSessionS3Structure.s3_bucket
        ? `s3://${drivingSessionS3Structure.s3_bucket}/${drivingSessionS3Structure.s3_prefix}`
        : '';
      this.s3Folders = {};
      this.s3Objects = [];

      drivingSessionS3Structure.folders_list?.map((s3Folder) => {
        s3Folder.s3_objects_list?.map((s3Object) => {
          if (!this.s3Folders[s3Object.folder]) {
            this.s3Folders[s3Object.folder] = [];
          }

          this.s3Folders[s3Object.folder].push(s3Object);
        });
      });

      this.foldersNames = Object.keys(this.s3Folders);
      this.foldersNames.splice(this.foldersNames.indexOf('recording'), 1);
    });

    this.actions$
      .pipe(ofType(DataBrowserActionTypes.LoadDataBrowser))
      .subscribe(() => {
        this.dataBrowserTableSpinner = true;
      });

    this.actions$
      .pipe(ofType(DataBrowserActionTypes.LoadDrivingSessionS3Structure))
      .subscribe(() => {
        this.s3StructureSpinner = true;
      });

    this.actions$
      .pipe(ofType(DataBrowserActionTypes.LoadDrivingSessionS3StructureSuccess))
      .subscribe(() => {
        this.s3StructureSpinner = false;
      });

    this.actions$
      .pipe(ofType(DataBrowserActionTypes.GraphQLQueryFailure))
      .subscribe(() => {
        this.s3StructureSpinner = false;
        this.dataBrowserTableSpinner = false;
      });
  }

  constructor(
    private store: Store<fromDataBrowser.State>,
    private formBuilder: UntypedFormBuilder,
    private actions$: Actions<DataBrowserActions>
  ) {}
}
