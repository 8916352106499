import {
  DataBrowserActions,
  DataBrowserActionTypes,
} from './data-browser.actions';

export const dataBrowserFeatureKey = 'dataBrowser';

export interface State {
  dataBrowser: DataBrowserResponseItem[];
  drivingSession: DrivingSession;
  drivingSessionS3Structure: DrivingSessionS3Structure;
}

export const initialState: State = {
  dataBrowser: [],
  drivingSession: {
    drive_id: null,
    VIN: null,
    local_drive_date: null,
    local_drive_time_start: null,
    country: null,
    s3_source_bucket: null,
    s3_source_prefix: null,
    s3_target_bucket: null,
    s3_target_prefix: null,
    campaign: null,
    logger: null,
    logger_version: null,
    project: null,
    data_provider: null,
    distance_driven_km: null,
    ml_process_type: null,
    file_source_directory_path: null,
    start_upload_timestamp: null,
    end_upload_timestamp: null,
    customer: null,
    total_recordings: null,
    total_data_size: null,
    total_data_size_GB: null,
    driving_side_of_country: null,
    steering_wheel_side: null,
    head_light_type: null,
    events: null,
    queues: null,
    ss_customer_vin: null,
    ss_customer_bordnet: null,
    ss_customer_astep: null,
    ss_customer_country: null,
    ss_customer_project: null,
    ss_customer_campaign: null,
    ss_customer_logger: null,
    version: null,
    object_schema_version: null,
    upload_status: null,
    uploaded_with: null,
    drive_start_nano_timestamp: null,
    drive_stop_nano_timestamp: null,
    bordnet: null,
    bordnet_versions: null,
    a_step: null,
    level_0_validation_score: null,
    upload_type: null,
    drive_duration_in_seconds: null,
    last_updated_timestamp_utc: null,
    batch_id: null,
    ticket_id: null,
    execution_id: null,
    epm_clips_count: null,
    comment: null,
    upload_session_name: null,
    repaired: null,
    collection_type: null,
    bertrandt_use_case_id: null,
    archived: null,
    level_0_validation_info: {
      is_root_folder_valid: null,
      is_meta_file_valid: null,
      is_folder_structure_valid: null,
      all_split_files_present: null,
      pre_labels_found: null,
      live_labels_found: null,
      live_labels_timestamps_found: null,
      upload_to_s3_verified: null,
      drive_id: null,
      drive_duration_time_valid: null,
      files_over_4GB_present: null,
      start_end_timestamps_are_valid: null,
      is_folder_timestamp_matches_start_ts: null,
      is_meta_json_present: null,
      is_config_json_present: null,
    },
    level_1_validation_info: null,
    bordnet_versions_map: [],
    before_pre_labels: null,
    partners_info_map: null,
  },
  drivingSessionS3Structure: {
    s3_bucket: null,
    s3_prefix: null,
    total_prefixes_found: null,
    total_objects_count: null,
    total_objects_size: null,
    total_objects_size_GB: null,
    total_objects_size_TB: null,
    limit: null,
    folders_list: [],
  },
};

export function reducer(
  state = initialState,
  action: DataBrowserActions
): State {
  switch (action.type) {
    case DataBrowserActionTypes.ResetDataBrowserStore:
      return { ...initialState };

    case DataBrowserActionTypes.ResetDrivingSessionStore:
      return {
        ...state,
        drivingSession: initialState.drivingSession,
      };

    case DataBrowserActionTypes.ResetDrivingSessionS3StructureStore:
      return {
        ...state,
        drivingSessionS3Structure: initialState.drivingSessionS3Structure,
      };

    case DataBrowserActionTypes.LoadDataBrowserSuccess:
      const dataBrowser = JSON.parse(action.payload.data.queryDataBrowser);

      return {
        ...state,
        dataBrowser,
      };

    case DataBrowserActionTypes.LoadDrivingSessionSuccess:
      return {
        ...state,
        drivingSession: action.payload.data.getDrivingSession,
      };

    case DataBrowserActionTypes.LoadDrivingSessionS3StructureSuccess:
      return {
        ...state,
        drivingSessionS3Structure:
          action.payload.data.getDrivingSessionS3Structure,
      };

    default:
      return state;
  }
}
