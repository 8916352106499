export const TestRunConfigurationConstant = {
  initSelections: {
    testrunExecution: {
      testRunId: '',
      createdTimeStamp: '',
      parent: '',
      testRunConfig: '"{}"',
      reprocessingConfig: '',
      testSessions: '',
      sessionToKPIScriptsMapping: '[]',
      testRunConfigLabel: '',
      response: '',
      transcodingProgress: '',
      transcodingStatus: '',
      reprocessingProgress: '',
      reprocessingStatus: null,
      reprocessingSuccessRate: '',
      testOrderId: '',
      selectedTranscodingType: '',
      owner: '',
      reprocessing_started: null,
    },
    sessionToKPIScript: {
      kpiScripts: [],
      testSessionId: '',
      testSessionLabel: '',
    },
  },
  gtSelectionList: [
    'None',
    'GT_OD_MagnaSRS',
    'GT_OD_Exida_Automatic',
    'GT_OD_LiangDao_Automatic',
    'GT_OD_LiangDao_Semi',
    'GT_OD_MagnaSM1',
    'GT_SRS_LiangDao_Semi',
    'GT_SRS_LiangDao_Automatic',
    'GT_PCP_MagnaSRS',
    'GT_PCP_MagnaSM1',
  ],
  clusters: [
    'standard',
    'medium',
    'large',
    'standard_v2',
    'medium_v2',
    'large_v2',
  ],
  streams: ['VBS01', 'VBS06', 'VBS02'],
  functions: ['OD', 'OC', 'PCP', 'BWD'],
  stream: 'VBS01',
  cluster: 'standard',
  gt_selection: 'None',
};
