import { Injectable } from '@angular/core';
import { SnackBarConstant } from '@constants/snack-bar.constant';
import { SemanticSearchService } from '@core/services/semantic-search.service';
import { SnackBarService } from '@core/services/snack-bar.service';
import { GraphQLQueryError } from '@model/payloads';
import * as VinsApprovalsActions from '@modules/vins-approvals/store/vins-approvals.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

@Injectable()
export class VinsApprovalsEffects {
  loadVinsList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VinsApprovalsActions.loadVinsList),
      concatMap((data) =>
        this.semanticSearchService.getVinsList(data).pipe(
          map((data) => VinsApprovalsActions.loadVinsListSuccess(data)),
          catchError((error: GraphQLQueryError) => {
            const messages: string = error.errors
              .map((error) => error.message)
              .join();

            this.snackBarService.open(
              `Error of getting vins list: ${messages}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
            return of(VinsApprovalsActions.loadVinsListFailure(error));
          })
        )
      )
    );
  });

  loadDriveIdsApprovalList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VinsApprovalsActions.loadDriveIdsApprovalList),
      concatMap((data) =>
        this.semanticSearchService.getDriveIdsApprovalList(data).pipe(
          map((data) =>
            VinsApprovalsActions.loadDriveIdsApprovalListSuccess(data)
          ),
          catchError((error: GraphQLQueryError) => {
            const messages: string = error.errors
              .map((error) => error.message)
              .join();

            this.snackBarService.open(
              `Error of getting drive ids approval list: ${messages}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
            return of(
              VinsApprovalsActions.loadDriveIdsApprovalListFailure(error)
            );
          })
        )
      )
    );
  });

  setDriveIdApprovals$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VinsApprovalsActions.setDriveIdApprovals),
      concatMap((data) =>
        this.semanticSearchService.setDriveIdApprovals(data).pipe(
          map((data) => VinsApprovalsActions.setDriveIdApprovalsSuccess(data)),
          catchError((error: GraphQLQueryError) => {
            const messages: string = error.errors
              .map((error) => error.message)
              .join();

            this.snackBarService.open(
              `Error of setting drive id approvals: ${messages}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
            return of(VinsApprovalsActions.setDriveIdApprovalsFailure(error));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private semanticSearchService: SemanticSearchService,
    private snackBarService: SnackBarService
  ) {}
}
