<mat-card>
  <mat-card-header>
    <mat-card-title>Validation verdict</mat-card-title>
    <mat-card-subtitle>Type: {{ type }}</mat-card-subtitle>
    <mat-card-subtitle>Campaign: {{ campaign }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <app-table
      [displayedColumns]="validationVerdictsTableColumns"
      [dataSource]="filteredValidationVerdictsTableDataSource"
      [isSelectionButtonVisible]="false"
      [showItemHover]="false"
      [showFilter]="false"
      [isSortDisabled]="true"
      [enableDetails]="false"
    ></app-table>
  </mat-card-content>

  <mat-card-actions>
    <a
      mat-raised-button
      color="primary"
      matTooltip="Download report"
      matTooltipPosition="below"
      [href]="reportLink"
      [disabled]="!reportLink"
      ><mat-icon>file_download</mat-icon></a
    >
  </mat-card-actions>
</mat-card>
