<mat-card>
  <mat-card-header *ngIf="title">
    <mat-card-title>{{ title }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field appearance="fill">
      <mat-label>Drive ID</mat-label>
      <input
        matInput
        (keyup.enter)="onSearchByDriveId($event)"
        [placeholder]="'Ex.G000351_20230324_142430'"
        matTooltip="Input Drive ID and click 'Enter'"
        matTooltipPosition="below"
      />
    </mat-form-field>

    <app-table
      [displayedColumns]="driveIdsColumns"
      [dataSource]="filteredDriveIdsDataSource"
      (tableItemSelected)="onDriveIdsTableItemSelected($event)"
      [selection]="driveIdsTableSelection"
      [spinner]="driveIdsTableSpinner"
      [multipleSelections]="false"
      [showFilter]="false"
      [isSortDisabled]="true"
      [enableDetails]="false"
    ></app-table>
  </mat-card-content>

  <mat-card-actions fxLayout="row" fxLayoutAlign="start center">
    <button
      mat-raised-button
      color="primary"
      type="button"
      (click)="onDriveIdsTableReloaded()"
      matTooltip="Refresh table data"
      matTooltipPosition="below"
      [disabled]="driveIdsTableSpinner"
    >
      <mat-icon>refresh</mat-icon>
    </button>
    <button
      mat-raised-button
      color="primary"
      type="button"
      (click)="onLoadMoreDriveIds()"
      matTooltip="Load more items"
      matTooltipPosition="below"
      [disabled]="driveIdsTableSpinner"
    >
      <mat-icon>system_update_alt</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
