import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ProjectsConstant } from '@constants/projects.constant';
import { SnackBarConstant } from '@constants/snack-bar.constant';
import { TestOrderVersionsConstant } from '@constants/test-order-versions.constant';
import { UiMessagesConstant } from '@constants/ui-messages.constant';
import {
  SaveTestRunListQuery,
  TestRunConfigurationQuery,
} from '@core/amplify/aws-apmlify-query.constant';
import { CsvParserService } from '@core/services/csv-parser.service';
import { SnackBarService } from '@core/services/snack-bar.service';
import { TestRunConfigurationService } from '@core/services/test-run-configuration.service';
import { TmaUtilsStaticService } from '@core/services/tma-utils-static.service';
import { GraphQLQueryError } from '@model/payloads';
import { LoadTestRunConfigurationData } from '@modules/test-run-configuration/store/test-run-configuration.actions';
import * as fromTestRunConfiguration from '@modules/test-run-configuration/store/test-run-configuration.reducer';
import { selectFeatureReproceccingConfigLabels } from '@modules/test-run-configuration/store/test-run-configuration.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-trained-parking-assist',
  templateUrl: './trained-parking-assist.component.html',
  styleUrls: ['./trained-parking-assist.component.scss'],
})
export class TrainedParkingAssistComponent implements OnInit {
  @ViewChild('fileUploadInput')
  fileUploadElementRef!: ElementRef;

  fileName = '';

  recordingGroupsMap: RecordingGroupsMap = {};

  deleteClipPlaylist: TestSessionPlayList = {};

  groupedTestRuns: GroupedTestRun[] = [];

  reproceccingConfigLabels$: Observable<string[]> = this.trcStore.select(
    selectFeatureReproceccingConfigLabels
  );

  reproceccingConfigurationControlsMetadata: TpaReprocessingControlsMetadata = {
    ReProcessingConfigLabel: TmaUtilsStaticService.getFromControlMetadata(
      'ReProcessingConfigLabel',
      'Reprocessing config label',
      'autocomplete',
      {
        inputOptions: [],
        filteredInputOptions: [],
        tooltip: '',
      }
    ),
    DeleteSlotConfigLabel: TmaUtilsStaticService.getFromControlMetadata(
      'DeleteSlotConfigLabel',
      'Delete slot config label',
      'autocomplete',
      {
        inputOptions: [],
        filteredInputOptions: [],
        tooltip: '',
      }
    ),
    TestType: TmaUtilsStaticService.getFromControlMetadata(
      'TestType',
      'Test type',
      'autocomplete',
      {
        inputOptions: [
          { value: 'AnnotellKPI', placeholder: 'AnnotellKPI' },
          { value: 'ExidaKPI', placeholder: 'ExidaKPI' },
          { value: 'AevKPI', placeholder: 'AevKPI' },
          { value: 'MagnaKPI', placeholder: 'MagnaKPI' },
          {
            value: 'HilOnlyNoTranscoding',
            placeholder: 'HilOnlyNoTranscoding',
          },
          { value: 'PnC', placeholder: 'PnC' },
          { value: 'CI', placeholder: 'CI' },
        ],
        filteredInputOptions: [
          { value: 'AnnotellKPI', placeholder: 'AnnotellKPI' },
          { value: 'ExidaKPI', placeholder: 'ExidaKPI' },
          { value: 'AevKPI', placeholder: 'AevKPI' },
          { value: 'MagnaKPI', placeholder: 'MagnaKPI' },
          {
            value: 'HilOnlyNoTranscoding',
            placeholder: 'HilOnlyNoTranscoding',
          },
          { value: 'PnC', placeholder: 'PnC' },
          { value: 'CI', placeholder: 'CI' },
        ],
        tooltip: '',
      }
    ),
    TestRunPriority: TmaUtilsStaticService.getFromControlMetadata(
      'TestRunPriority',
      'Test run priority',
      'autocomplete',
      {
        inputOptions: [
          { value: 'Low', placeholder: 'Low' },
          { value: 'Medium', placeholder: 'Medium' },
          { value: 'High', placeholder: 'High' },
        ],
        filteredInputOptions: [
          { value: 'Low', placeholder: 'Low' },
          { value: 'Medium', placeholder: 'Medium' },
          { value: 'High', placeholder: 'High' },
        ],
        tooltip: '',
      }
    ),
    Label: TmaUtilsStaticService.getFromControlMetadata('Label', 'Label'),
  };

  reproceccingConfigurationControlsConfig: FormControlsConfig = {
    Label: [null, [Validators.required]],
    ReProcessingConfigLabel: [null, [Validators.required]],
    DeleteSlotConfigLabel: [null, [Validators.required]],
    TestType: [null, [Validators.required]],
    TestRunPriority: 'Low',
  };

  reproceccingConfigurationFormGroup = this.formBuilder.group(
    this.reproceccingConfigurationControlsConfig
  );

  parsedTpaSheetTableColumns: string[] = [];

  parsedTpaSheetTableDataSource = new MatTableDataSource<ParsedTpaSheetItem>();

  filteredParsedTpaSheetTableDataSource =
    new MatTableDataSource<ParsedTpaSheetItem>();

  parsedTpaSheetInvalidTableColumns: string[] = [];

  parsedTpaSheetInvalidTableDataSource =
    new MatTableDataSource<ParsedTpaSheetInvalidItem>();

  filteredParsedTpaSheetInvalidTableDataSource =
    new MatTableDataSource<ParsedTpaSheetInvalidItem>();

  tpaReproGroupsStatusesTableColumns: string[] = [];

  tpaReproGroupsStatusesTableDataSource =
    new MatTableDataSource<SaveTesRunListResponseItem>();

  filteredTpaReproGroupsStatusesTableDataSource =
    new MatTableDataSource<SaveTesRunListResponseItem>();

  tpaReproGroupsStatusesTableSpinner = false;

  attachFileSuccessMessage = '';
  attachFileErrorMessage = '';

  tpaReproResultsSuccessMessage = '';

  onAttachFile(event: Event) {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    if (file) {
      this.fileName = file.name;

      this.csvParserService.tpaCsvParse(file).subscribe(
        (parsedTpaSheetItems) => {
          if (
            this.validateCsvColumnsNames(parsedTpaSheetItems[0]) &&
            this.checkDeleteClip(parsedTpaSheetItems[0])
          ) {
            try {
              this.deleteClipPlaylist[parsedTpaSheetItems[0].drive_id] =
                JSON.parse(parsedTpaSheetItems[0].splits);

              this.recordingGroupsMap = this.getRecordingGroupsMap(
                parsedTpaSheetItems.slice(1)
              );
            } catch (error) {
              this.attachFileSuccessMessage = '';
              this.attachFileErrorMessage =
                UiMessagesConstant.splitsFormatIsInvalid;
              return;
            }

            this.renderParsedTpaSheetTable(parsedTpaSheetItems);

            this.renderParsedTpaSheetInvalidTable(
              parsedTpaSheetItems.slice(1),
              this.recordingGroupsMap
            );

            this.attachFileErrorMessage = '';
            this.attachFileSuccessMessage =
              UiMessagesConstant.fileParsedSuccessMessage;
          } else {
            this.attachFileSuccessMessage = '';
            this.attachFileErrorMessage =
              UiMessagesConstant.csvInvalidColumnsMessage;
          }
        },
        (error) => {
          this.attachFileSuccessMessage = '';
          this.attachFileErrorMessage = `${UiMessagesConstant.fileParsedErrorMessage}: ${error.message}`;
        }
      );
    }
  }

  onResetFileData() {
    this.attachFileSuccessMessage = '';
    this.attachFileErrorMessage = '';

    this.tpaReproResultsSuccessMessage = '';

    this.recordingGroupsMap = {};
    this.groupedTestRuns = [];

    this.deleteAttachedFile();
    this.resetParsedTpaSheetTable();
    this.resetParsedTpaSheetInvalidTable();
    this.resetTpaReproGroupsStatusesTable();
  }

  onTpaReprocessingFormSubmit(formControlsConfig: FormControlsConfig): void {
    this.tpaReproResultsSuccessMessage = '';

    for (const [key, value] of Object.entries(this.recordingGroupsMap)) {
      const test_run_config = {
        TestOrderVersion: TestOrderVersionsConstant.current,
        ProjectID: ProjectsConstant.MFK120.id,
        ProductName: ProjectsConstant.MFK120.name,
        ReprocessingType: ProjectsConstant.L2H4310.ReProcessingTypes[0].value,
        GroupID: value.hil_group,
        ReprocessingConfigLabel: formControlsConfig.ReProcessingConfigLabel,
        TestType: formControlsConfig.TestType,
        TestRunPriority: formControlsConfig.TestRunPriority,
        Label: formControlsConfig.Label,
        PlayList: value.playlist,
        PlayListLearning: value.playlist_learning,
        PlayListDeleteSlot: this.deleteClipPlaylist,
        DeleteSlotConfigLabel: formControlsConfig.DeleteSlotConfigLabel,
        PlayListVersion: '2',
      };

      this.groupedTestRuns.push({
        rec_group: key,
        test_run_config: JSON.stringify(test_run_config),
      });
    }

    this.tpaReproGroupsStatusesTableSpinner = true;

    this.testRunConfigurationService
      .saveTestRunList({
        query: SaveTestRunListQuery,
        variables: {
          testrun_list: this.groupedTestRuns,
        },
      })
      .subscribe(
        (saveTestRunResponse) => {
          this.tpaReproGroupsStatusesTableSpinner = false;

          const saveTesRunListResponseItems = JSON.parse(
            saveTestRunResponse.data.save_test_run_list
          );

          this.renderTpaReproGroupsStatusesTable(saveTesRunListResponseItems);

          this.tpaReproResultsSuccessMessage =
            UiMessagesConstant.tpaReproResultsSuccessMessage;
        },
        (error: GraphQLQueryError) => {
          this.tpaReproGroupsStatusesTableSpinner = false;

          const messages: string = error.errors
            .map((error) => error.message)
            .join();

          this.tpaReproResultsSuccessMessage = '';

          this.snackBarService.open(
            `${UiMessagesConstant.tpaReproResultsErrorMessage}: ${messages}`,
            SnackBarConstant.actions.dismiss,
            3000
          );
        }
      );
  }

  ngOnInit(): void {
    this.reproceccingConfigLabels$.subscribe((reproceccingConfigLabels) => {
      this.reproceccingConfigurationControlsMetadata.ReProcessingConfigLabel.options.inputOptions =
        reproceccingConfigLabels.map((reproceccingConfigLabel) => ({
          value: reproceccingConfigLabel,
          placeholder: reproceccingConfigLabel,
        }));

      this.reproceccingConfigurationControlsMetadata.ReProcessingConfigLabel.options.filteredInputOptions =
        this.reproceccingConfigurationControlsMetadata.ReProcessingConfigLabel.options.inputOptions;

      this.reproceccingConfigurationControlsMetadata.DeleteSlotConfigLabel.options.inputOptions =
        reproceccingConfigLabels.map((reproceccingConfigLabel) => ({
          value: reproceccingConfigLabel,
          placeholder: reproceccingConfigLabel,
        }));

      this.reproceccingConfigurationControlsMetadata.DeleteSlotConfigLabel.options.filteredInputOptions =
        this.reproceccingConfigurationControlsMetadata.DeleteSlotConfigLabel.options.inputOptions;
    });

    this.trcStore.dispatch(
      new LoadTestRunConfigurationData({
        query: TestRunConfigurationQuery,
        variables: {},
      })
    );
  }

  private getRecordingGroupsMap(
    parsedTpaSheetItems: ParsedTpaSheetItem[]
  ): RecordingGroupsMap {
    const recordingGroupsMap: RecordingGroupsMap = {};

    parsedTpaSheetItems.map((parsedTpaSheetItem: ParsedTpaSheetItem) => {
      const rec_group = parsedTpaSheetItem.rec_group;
      const type = parsedTpaSheetItem.type;
      const drive_id = parsedTpaSheetItem.drive_id;
      const splits = parsedTpaSheetItem.splits;
      const hil_group = parsedTpaSheetItem.hil_group;

      if (!recordingGroupsMap[rec_group]) {
        recordingGroupsMap[rec_group] = {
          hil_group: '',
          playlist_learning: {},
          playlist: {},
        };
      }

      try {
        if (type === 'reference') {
          recordingGroupsMap[rec_group].hil_group = hil_group;

          recordingGroupsMap[rec_group].playlist_learning[drive_id] =
            JSON.parse(splits);
        } else {
          recordingGroupsMap[rec_group].playlist[drive_id] = JSON.parse(splits);
        }
      } catch (error) {
        throw error;
      }
    });

    return recordingGroupsMap;
  }

  private deleteAttachedFile(): void {
    this.fileUploadElementRef.nativeElement.value = '';
    this.fileName = '';
  }

  private renderParsedTpaSheetTable(
    parsedTpaSheetItems: ParsedTpaSheetItem[]
  ): void {
    this.parsedTpaSheetTableColumns = Object.keys(parsedTpaSheetItems[0] || {});

    this.parsedTpaSheetTableDataSource =
      new MatTableDataSource<ParsedTpaSheetItem>(parsedTpaSheetItems);

    this.filteredParsedTpaSheetTableDataSource = new MatTableDataSource(
      this.parsedTpaSheetTableDataSource.data
    );
  }

  private resetParsedTpaSheetTable(): void {
    this.parsedTpaSheetTableColumns = [];

    this.parsedTpaSheetTableDataSource =
      new MatTableDataSource<ParsedTpaSheetItem>();

    this.filteredParsedTpaSheetTableDataSource =
      new MatTableDataSource<ParsedTpaSheetItem>();
  }

  private renderParsedTpaSheetInvalidTable(
    parsedTpaSheetItems: ParsedTpaSheetItem[],
    recordingGroupsMap: RecordingGroupsMap
  ): void {
    const errorsMap: InvalidStringsMap = {};

    parsedTpaSheetItems.map(
      (parsedTpaSheetItem: ParsedTpaSheetItem, index: number) => {
        if (!parsedTpaSheetItem.drive_id) {
          if (!errorsMap[index]) {
            errorsMap[index] = {
              errors: [],
            };
          }

          errorsMap[index].errors.push('drive_id is empty');
        }

        if (
          !parsedTpaSheetItem.rec_group.length &&
          parsedTpaSheetItem.type !== 'delete_clip'
        ) {
          if (!errorsMap[index]) {
            errorsMap[index] = {
              errors: [],
            };
          }

          errorsMap[index].errors.push('rec_group is empty');
        } else {
          const referencesIds = Object.keys(
            recordingGroupsMap[parsedTpaSheetItem.rec_group].playlist_learning
          );

          if (referencesIds.length > 1) {
            for (let referenceId of referencesIds) {
              if (referenceId === parsedTpaSheetItem.drive_id) {
                if (!errorsMap[index]) {
                  errorsMap[index] = {
                    errors: [],
                  };
                }

                errorsMap[index].errors.push('duplicated reference');
              }
            }
          }
        }

        if (
          parsedTpaSheetItem.type === 'reference' &&
          !parsedTpaSheetItem.hil_group.length
        ) {
          if (!errorsMap[index]) {
            errorsMap[index] = {
              errors: [],
            };
          }

          errorsMap[index].errors.push('hil_group is empty');
        }

        if (
          parsedTpaSheetItem.hil_group.length &&
          parsedTpaSheetItem.type !== 'reference'
        ) {
          if (!errorsMap[index]) {
            errorsMap[index] = {
              errors: [],
            };
          }

          errorsMap[index].errors.push('reference is empty or wrong value');
        }
      }
    );

    const dataSource: ParsedTpaSheetInvalidItem[] = [];

    for (let [key, errorsList] of Object.entries(errorsMap)) {
      let string_number = +key;
      string_number = string_number += 2;

      dataSource.push({
        csv_string_number: string_number,
        validation_errors: errorsList.errors.join(', '),
      });
    }

    this.parsedTpaSheetInvalidTableColumns = Object.keys(dataSource[0] || {});

    this.parsedTpaSheetInvalidTableDataSource =
      new MatTableDataSource<ParsedTpaSheetInvalidItem>(dataSource);

    this.filteredParsedTpaSheetInvalidTableDataSource = new MatTableDataSource(
      this.parsedTpaSheetInvalidTableDataSource.data
    );
  }

  private resetParsedTpaSheetInvalidTable(): void {
    this.parsedTpaSheetInvalidTableColumns = [];

    this.parsedTpaSheetInvalidTableDataSource =
      new MatTableDataSource<ParsedTpaSheetInvalidItem>();

    this.filteredParsedTpaSheetInvalidTableDataSource =
      new MatTableDataSource<ParsedTpaSheetInvalidItem>();
  }

  private renderTpaReproGroupsStatusesTable(
    saveTesRunListResponseItems: SaveTesRunListResponseItem[]
  ): void {
    this.tpaReproGroupsStatusesTableColumns = Object.keys(
      saveTesRunListResponseItems[0] || {}
    );

    this.tpaReproGroupsStatusesTableDataSource =
      new MatTableDataSource<SaveTesRunListResponseItem>(
        saveTesRunListResponseItems
      );

    this.filteredTpaReproGroupsStatusesTableDataSource = new MatTableDataSource(
      this.tpaReproGroupsStatusesTableDataSource.data
    );
  }

  private resetTpaReproGroupsStatusesTable(): void {
    this.tpaReproGroupsStatusesTableColumns = [];

    this.tpaReproGroupsStatusesTableDataSource =
      new MatTableDataSource<SaveTesRunListResponseItem>();

    this.filteredTpaReproGroupsStatusesTableDataSource =
      new MatTableDataSource<SaveTesRunListResponseItem>();
  }

  private validateCsvColumnsNames(
    parsedTpaSheetItem: ParsedTpaSheetItem
  ): boolean {
    return !(
      parsedTpaSheetItem.rec_group === undefined ||
      parsedTpaSheetItem.type === undefined ||
      parsedTpaSheetItem.drive_id === undefined ||
      parsedTpaSheetItem.splits === undefined ||
      parsedTpaSheetItem.hil_group === undefined
    );
  }

  private checkDeleteClip(parsedTpaSheetItem: ParsedTpaSheetItem): boolean {
    return parsedTpaSheetItem.type === 'delete_clip';
  }

  constructor(
    private csvParserService: CsvParserService,
    private formBuilder: UntypedFormBuilder,
    private trcStore: Store<fromTestRunConfiguration.State>,
    private testRunConfigurationService: TestRunConfigurationService,
    private snackBarService: SnackBarService
  ) {}
}
