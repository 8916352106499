import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { SnackBarConstant } from '@constants/snack-bar.constant';
import {
  GetKpiReportsDownloadLinksQuery,
  GetReprocessingProgressQuery,
  ListReproResultByTestRunIdQuery,
  ListTestRunExecutionsByOwnerQuery,
  ListTestRunExecutionsByTestRunIdQuery,
  TestrunStartQuery,
} from '@core/amplify/aws-apmlify-query.constant';
import { AuthService } from '@core/services/auth.service';
import { DynamoDbService } from '@core/services/dynamo-db.service';
import { KpiService } from '@core/services/kpi.service';
import { SnackBarService } from '@core/services/snack-bar.service';
import { TmaUtilsStaticService } from '@core/services/tma-utils-static.service';
import { GraphQLQueryError } from '@model/payloads';
import { StartTestrunData } from '@modules/test-run-configuration/store/test-run-configuration.actions';
import * as fromTestRunConfiguration from '@modules/test-run-configuration/store/test-run-configuration.reducer';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-test-run-configuration-new',
  templateUrl: './test-run-configuration-new.component.html',
  styleUrls: ['./test-run-configuration-new.component.scss'],
})
export class TestRunConfigurationNewComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;

  itemsPerPage = 5;

  isReproResultsLoadingResults = false;

  isTestRunExecutionsLoadingResults = true;

  kpiJobExecutionsColumns = ['progress', 'kpiJobStatus', 'metadataJobStatus'];
  kpiJobExecutionsDataSource = new MatTableDataSource<any>();
  kpiJobExecutionsTableSelection = new SelectionModel<any>(true, []);
  kpiJobExecutionReportLink = '';

  reproResultsNextToken = '';
  reproResultsDisplayedColumns = [
    'created_date',
    'recording_id',
    'hil_group_id',
    'status',
    'vbs_transcoded',
    'hil_error',
  ];
  reproResultsData: MatTableDataSource<any> = new MatTableDataSource();
  reproResultsItemsNumber = 0;
  reproResultDetailsDisplayedColumns = ['property', 'value'];
  reproResultDetailsData: { property: string; value: string }[] = [];

  reprocessingPercentComplete: number | null = null;
  reprocessingSuccessPercent: number | null = null;

  selectedTestRunId = '';

  selectedReproQueueId = '';

  testRunExecutionsNextToken = '';
  testRunExecutionsDisplayedColumns = [
    'test-order-id',
    'createdTimeStamp',
    'testrun-config-label',
    'owner',
  ];
  testRunExecutionsData: MatTableDataSource<any> = new MatTableDataSource();
  testRunExecutionsItemsNumber = 0;

  transcodingSuccessPercent: number | null = null;

  userName = '';

  constructor(
    private dynamoDbService: DynamoDbService,
    private snackBarService: SnackBarService,
    private store: Store<fromTestRunConfiguration.State>,
    private authService: AuthService,
    private kpiService: KpiService
  ) {}

  get isStartButtonDisabled(): boolean {
    return (
      this.reprocessingPercentComplete !== null || this.selectedTestRunId === ''
    );
  }

  private getReprocessingSuccessPercent(
    reprocessingStatusMap: ReprocessingStatusMap
  ): number | null {
    let reprocessingSuccessPercentResult = null;

    let successDriveIds = [];

    if (reprocessingStatusMap.drive_id_reprocessing_status) {
      successDriveIds =
        reprocessingStatusMap.drive_id_reprocessing_status.successful.filter(
          (driveId) => driveId?.length
        );
    }

    const playlistDsCount = reprocessingStatusMap.playlist_ds_count;

    if (successDriveIds.length && playlistDsCount) {
      reprocessingSuccessPercentResult = Math.round(
        (successDriveIds.length * 100) / playlistDsCount
      );
    }

    return reprocessingSuccessPercentResult;
  }

  private getReprocessingPercentComplete(
    reprocessingStatusMap: ReprocessingStatusMap
  ): number | null {
    let reprocessingPercentCompleteResult = null;

    const reprocessingStatus = reprocessingStatusMap.progress_status;
    const playlistDsCount = reprocessingStatusMap.ds_count;

    if (reprocessingStatus && playlistDsCount) {
      const S04211 = reprocessingStatus['04211'] || 0;
      const S04212 = reprocessingStatus['04212'] || 0;
      const sum_S04211_S04211 = S04211 + S04212;

      if (playlistDsCount > 0) {
        reprocessingPercentCompleteResult = Math.round(
          (sum_S04211_S04211 * 100) / playlistDsCount
        );
      }
    }

    return reprocessingPercentCompleteResult;
  }

  private getTranscodingSuccessComplete(
    reprocessingStatusMap: ReprocessingStatusMap
  ): number | null {
    let transcodingSuccessPercent = null;

    const vbsTranscodingStatus = reprocessingStatusMap.vbs_transcoding_status;
    const playlistDsCount = reprocessingStatusMap.playlist_ds_count;

    if (vbsTranscodingStatus && playlistDsCount) {
      const tcSuccessJobs = vbsTranscodingStatus.success || 0;

      if (playlistDsCount > 0 && tcSuccessJobs > 0) {
        transcodingSuccessPercent = Math.round(
          (tcSuccessJobs * 100) / playlistDsCount
        );
      }
    }

    return transcodingSuccessPercent;
  }

  private resetTestRunExecutions(): void {
    this.selectedTestRunId = '';

    this.transcodingSuccessPercent = null;

    this.reprocessingPercentComplete = null;

    this.reprocessingSuccessPercent = null;

    this.testRunExecutionsData.data = [];

    this.testRunExecutionsItemsNumber = this.testRunExecutionsData.data.length;

    this.testRunExecutionsNextToken = '';

    this.itemsPerPage = 5;
  }

  private resetReproResults(): void {
    this.selectedReproQueueId = '';

    this.reproResultsData.data = [];

    this.reproResultsItemsNumber = this.reproResultsData.data.length;

    this.reproResultsNextToken = '';
  }

  private renderTestRunExecutions(items: any): void {
    const concatenatedSourceData = TmaUtilsStaticService.getUniqueArray(
      this.testRunExecutionsData.data.concat(JSON.parse(items)),
      'test-order-id'
    );

    concatenatedSourceData.map((testRunExecution) => {
      testRunExecution.createdTimeStamp = TmaUtilsStaticService.formatDate(
        testRunExecution.createdTimeStamp
      );
    });

    this.testRunExecutionsItemsNumber = concatenatedSourceData.length;

    this.testRunExecutionsData.data = concatenatedSourceData;
  }

  private resetReproDetailsMap(): void {
    this.reproResultDetailsData = [];
  }

  private renderReproResults(items: string): void {
    const sourceData = this.reproResultsData.data.concat(JSON.parse(items));

    sourceData.forEach((reproResult) => {
      reproResult['created_date'] = TmaUtilsStaticService.formatDate(
        reproResult['created_date']
      );
    });

    this.reproResultsData.data = sourceData;

    this.reproResultsItemsNumber = this.reproResultsData.data.length;
  }

  private showGraphQLQueryErrorSnackBar(error: GraphQLQueryError): void {
    const messages = TmaUtilsStaticService.getGraphQLQueryErrorMessages(error);

    this.snackBarService.open(
      `Error of getting test run executions: ${messages}`,
      SnackBarConstant.actions.dismiss,
      3000
    );
  }

  ngAfterViewInit(): void {
    this.testRunExecutionsData.sort = this.sort;
  }

  ngOnInit(): void {
    this.authService.getCurrentUser().subscribe((user) => {
      this.userName = user.username;

      this.onFetchTestRunExecutions(null);
    });
  }

  onFetchTestRunExecutions($event: any): void {
    this.resetTestRunExecutions();
    this.resetReproResults();
    this.resetReproDetailsMap();

    this.loadTestRunExecutions();
  }

  loadTestRunExecutions(): void {
    this.isTestRunExecutionsLoadingResults = true;

    this.dynamoDbService
      .graphQLQuery({
        query: ListTestRunExecutionsByOwnerQuery,
        variables: {
          owner: this.userName,
          limit: this.itemsPerPage,
          nextToken: this.testRunExecutionsNextToken,
        },
      })
      .subscribe(
        (data) => {
          this.isTestRunExecutionsLoadingResults = false;

          this.testRunExecutionsNextToken = data.data
            .listTestRunExecutionsByOwner.nextToken
            ? data.data.listTestRunExecutionsByOwner.nextToken
            : '';

          this.renderTestRunExecutions(
            data.data.listTestRunExecutionsByOwner.items
          );
        },
        (error: GraphQLQueryError) => {
          this.isTestRunExecutionsLoadingResults = false;

          this.showGraphQLQueryErrorSnackBar(error);
        }
      );
  }

  onLoadMoreTestRunExecutions($event: any): void {
    if (this.testRunExecutionsNextToken.length) {
      this.loadTestRunExecutions();
    }
  }

  onLoadMoreTestRunExecutionsByTestRunId(event: Event): void {
    this.resetTestRunExecutions();
    this.resetReproResults();
    this.resetReproDetailsMap();

    const test_order_id = (event.target as HTMLInputElement).value;

    if (test_order_id.length) {
      this.isTestRunExecutionsLoadingResults = true;

      this.dynamoDbService
        .graphQLQuery({
          query: ListTestRunExecutionsByTestRunIdQuery,
          variables: {
            test_order_id,
            limit: this.itemsPerPage,
            nextToken: this.testRunExecutionsNextToken,
          },
        })
        .subscribe(
          (data) => {
            this.isTestRunExecutionsLoadingResults = false;

            this.testRunExecutionsNextToken = data.data
              .listTestRunExecutionsByTestRunId.nextToken
              ? data.data.listTestRunExecutionsByTestRunId.nextToken
              : '';

            if (this.testRunExecutionsData.data.length === 1) {
              this.testRunExecutionsData.data = [];
            }

            this.renderTestRunExecutions(
              data.data.listTestRunExecutionsByTestRunId.items
            );
          },
          (error: GraphQLQueryError) => {
            this.isTestRunExecutionsLoadingResults = false;

            this.showGraphQLQueryErrorSnackBar(error);
          }
        );
    } else {
      this.snackBarService.open(
        `Test Run ID is required`,
        SnackBarConstant.actions.dismiss,
        3000
      );
    }
  }

  loadReproResultsByTestRunId(): void {
    this.isReproResultsLoadingResults = true;

    this.dynamoDbService
      .graphQLQuery({
        query: ListReproResultByTestRunIdQuery,
        variables: {
          ext_test_order_id: this.selectedTestRunId,
          limit: this.itemsPerPage,
          nextToken: this.reproResultsNextToken,
        },
      })
      .subscribe(
        (data) => {
          this.isReproResultsLoadingResults = false;

          this.reproResultsNextToken = data.data.listReproResultByTestRunId
            .nextToken
            ? data.data.listReproResultByTestRunId.nextToken
            : '';

          this.renderReproResults(data.data.listReproResultByTestRunId.items);
        },
        (error: GraphQLQueryError) => {
          this.isReproResultsLoadingResults = false;

          this.showGraphQLQueryErrorSnackBar(error);
        }
      );
  }

  onLoadMoreReproResultsByTestRunId($event: any) {
    if (this.reproResultsNextToken.length) {
      this.loadReproResultsByTestRunId();
    }
  }

  onStartTestRunExecutions(): void {
    this.store.dispatch(
      new StartTestrunData({
        query: TestrunStartQuery,
        variables: {
          test_run_id: this.selectedTestRunId,
        },
      })
    );
  }

  onTestRunExecutionSelected($event: Event, row: any) {
    this.resetReproResults();
    this.resetReproDetailsMap();

    this.selectedTestRunId = row['test-order-id'];

    this.loadReproResultsByTestRunId();

    this.dynamoDbService
      .graphQLQuery({
        query: GetReprocessingProgressQuery,
        variables: {
          ext_test_order_id: this.selectedTestRunId,
        },
      })
      .subscribe((data: any) => {
        const reprocessingStatusMap: any =
          TmaUtilsStaticService.transformSemanticSearchLambdaResponse(
            data.data.getReprocessingProgress
          );

        this.reprocessingPercentComplete = this.getReprocessingPercentComplete(
          reprocessingStatusMap
        );

        this.reprocessingSuccessPercent = this.getReprocessingSuccessPercent(
          reprocessingStatusMap
        );

        this.transcodingSuccessPercent = this.getTranscodingSuccessComplete(
          reprocessingStatusMap
        );
      });
  }

  renderKpiJobExecutions(KPIJobExecutions: KPIJobExecution[]): void {
    this.kpiJobExecutionsColumns = [
      'progress',
      'kpiJobStatus',
      'metadataJobStatus',
    ];

    this.kpiJobExecutionsDataSource = new MatTableDataSource(
      KPIJobExecutions.filter(
        (KPIJobExecution) =>
          KPIJobExecution.testRunId === this.selectedTestRunId
      )
    );
  }

  onKpiJobExecutionsItemSelected(selected: any): void {
    this.kpiService
      .getKpiReportsDownloadLinks({
        query: GetKpiReportsDownloadLinksQuery,
        variables: {
          project_id: selected.projectID,
          job_id: selected.kpiJobID,
        },
      })
      .subscribe((GetKpiReportsDownloadLinksResponse) => {
        const getKpiReportsDownloadLinks: any = JSON.parse(
          GetKpiReportsDownloadLinksResponse.data.getKpiReportsDownloadLinks
        );

        this.kpiJobExecutionReportLink =
          getKpiReportsDownloadLinks[0]?.download_link_report;
      });
  }

  renderReproDetailsMap($event: Event, row: any): void {
    this.selectedReproQueueId = row['queue_id'];

    this.reproResultDetailsData = [];

    const reproDetailsMap = row;

    for (const property in reproDetailsMap) {
      this.reproResultDetailsData.push({
        property,
        value: reproDetailsMap[property],
      });
    }
  }
}
