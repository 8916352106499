import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';
import {
  GetDriveIdsApprovalListPayload,
  GetDriveIdsApprovalListResponse,
  GetDrivingSessionPayload,
  GetDrivingSessionResponse,
  GetDrivingSessionS3StructurePayload,
  GetDrivingSessionS3StructureResponse,
  GetVinsListPayload,
  GetVinsListResponse,
  QueryBuilderPayload,
  QueryBuilderResponse,
  QueryDataBrowserPayload,
  QueryDataBrowserResponse,
  QueryVrctPayload,
  QueryVrctResponse,
  SetDriveIdApprovalsPayload,
  SetDriveIdApprovalsResponse,
} from '@model/payloads';

@Injectable({
  providedIn: 'root',
})
export class SemanticSearchService {
  constructor(private graphqlApiService: AwsAmplifyApiService) {}

  queryBuilderQuery(
    queryBuilderPayload: QueryBuilderPayload
  ): Observable<QueryBuilderResponse> {
    return this.graphqlApiService.graphQLQuery(queryBuilderPayload);
  }

  queryVrct(queryVrctPayload: QueryVrctPayload): Observable<QueryVrctResponse> {
    return this.graphqlApiService.graphQLQuery(queryVrctPayload);
  }

  queryDataBrowser(
    queryDataBrowserPayload: QueryDataBrowserPayload
  ): Observable<QueryDataBrowserResponse> {
    return this.graphqlApiService.graphQLQuery(queryDataBrowserPayload);
  }

  getDrivingSession(
    getDrivingSessionPayload: GetDrivingSessionPayload
  ): Observable<GetDrivingSessionResponse> {
    return this.graphqlApiService.graphQLQuery(getDrivingSessionPayload);
  }

  getDrivingSessionS3Structure(
    getDrivingSessionS3StructurePayload: GetDrivingSessionS3StructurePayload
  ): Observable<GetDrivingSessionS3StructureResponse> {
    return this.graphqlApiService.graphQLQuery(
      getDrivingSessionS3StructurePayload
    );
  }

  getVinsList(
    getVinsListPayload: GetVinsListPayload
  ): Observable<GetVinsListResponse> {
    return this.graphqlApiService.graphQLQuery(getVinsListPayload);
  }

  getDriveIdsApprovalList(
    getDriveIdsApprovalListPayload: GetDriveIdsApprovalListPayload
  ): Observable<GetDriveIdsApprovalListResponse> {
    return this.graphqlApiService.graphQLQuery(getDriveIdsApprovalListPayload);
  }

  setDriveIdApprovals(
    setDriveIdApprovalsPayload: SetDriveIdApprovalsPayload
  ): Observable<SetDriveIdApprovalsResponse> {
    return this.graphqlApiService.graphQLQuery(setDriveIdApprovalsPayload);
  }
}
