import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SharedModule } from '@shared/shared.module';
import { CoreModule } from '@core/core.module';
import { DmrHomeModule } from '@modules/dmr-home/dmr-home.module';
import { TmaDashboardModule } from '@modules/tma-dashboard.module';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './root-reducer';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    SharedModule,
    DmrHomeModule,
    TmaDashboardModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
