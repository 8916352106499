import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SnackBarConstant } from '@constants/snack-bar.constant';
import { TableConstant } from '@constants/table.constant';
import { UiMessagesConstant } from '@constants/ui-messages.constant';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';
import {
  GetDrivingSessionNewQuery,
  GetMfk120AggregatedValidation,
} from '@core/amplify/aws-apmlify-query.constant';
import { S3Service } from '@core/services/s3.service';
import { SnackBarService } from '@core/services/snack-bar.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-approval-validation',
  templateUrl: './approval-validation.component.html',
  styleUrls: ['./approval-validation.component.scss'],
})
export class ApprovalValidationComponent implements OnChanges {
  @Input() driveId: string;
  @Input() type: string;

  reportLink: string | null = null;

  campaign = '';

  validationVerdictsTableColumns: string[] = [];
  validationVerdictsTableDataSource = new MatTableDataSource<any>();
  filteredValidationVerdictsTableDataSource = new MatTableDataSource<any>();

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.driveId.currentValue.length &&
      changes.driveId.previousValue !== changes.driveId.currentValue
    ) {
      this.awsAmplifyApiService
        .graphQLQuery({
          query: GetDrivingSessionNewQuery,
          variables: {
            drive_id: changes.driveId.currentValue,
            verbose: true,
          },
        })
        .subscribe(
          (driveSession) => {
            try {
              const parsedDriveSession = JSON.parse(
                driveSession.data.getDrivingSessionNew
              );

              this.campaign = parsedDriveSession['campaign'] || '';
            } catch (e) {
              this.snackBarService.open(
                `DriveSession JSON parsing error: ${e}`,
                SnackBarConstant.actions.dismiss,
                3000
              );
            }
          },
          (error) => {
            this.snackBarService.showGraphQlErrorMessage(error);
          }
        );

      this.fetchValidationResult(
        changes.driveId.currentValue,
        'ValidationVerdict'
      ).subscribe(
        (validationResult) => {
          try {
            const validationData: ValidationResultData = JSON.parse(
              validationResult.data.getMfk120AggregatedValidation || '{}'
            );

            const validation_verdicts = validationData?.validation_verdicts;

            if (validation_verdicts) {
              this.renderValidationVerdictsTable(validation_verdicts);

              this.s3Service
                .downloadS3File(
                  validationData?.reports_archive?.s3_bucket,
                  validationData?.reports_archive?.s3_prefix
                )
                .then((link) => {
                  this.reportLink = link;
                });
            }
          } catch (e) {
            this.snackBarService.open(
              `ValidationVerdict ${UiMessagesConstant.mfk120ValidationJSONParsingError}: ${e}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
          }
        },
        (error) => {
          this.snackBarService.showGraphQlErrorMessage(error);
        }
      );
    } else if (!changes.driveId.currentValue.length) {
      this.resetValidationVerdictsTable();

      this.campaign = '';

      this.reportLink = '';
    }
  }

  private fetchValidationResult(
    drive_id: string,
    validation_id: string
  ): Observable<any> {
    return this.awsAmplifyApiService.graphQLQuery({
      query: GetMfk120AggregatedValidation,
      variables: {
        drive_id,
        validation_id,
      },
    });
  }

  private renderValidationVerdictsTable(
    validation_verdicts: ValidationVerdict[]
  ): void {
    if (validation_verdicts?.length) {
      this.validationVerdictsTableColumns = [
        ...TableConstant.mfk120ValidationVerdictColumns,
      ];
    }

    const tableValidationVerdicts: any[] = [];

    validation_verdicts.map((validationVerdict, index) => {
      tableValidationVerdicts.push({
        '#': (index += 1),
        'overall result': validationVerdict.Overall_Result || '',
        'upload status': validationVerdict.upload_status || '',
        'l0 checks': validationVerdict.L0_checks || '',
        l1: validationVerdict.L1?.UseCase
          ? `${validationVerdict.L1.UseCase}: ${validationVerdict.L1.Validation_verdict}`
          : '',
        'l2 me': validationVerdict.L2_ME?.UseCase
          ? `${validationVerdict.L2_ME?.UseCase}: ${validationVerdict.L2_ME?.Validation_verdict}`
          : '',
        'l2 signal': validationVerdict.L2_signal?.UseCase
          ? `${validationVerdict.L2_signal?.UseCase}: ${validationVerdict.L2_signal?.Validation_verdict}`
          : '',
      });
    });

    this.validationVerdictsTableDataSource = new MatTableDataSource(
      tableValidationVerdicts
    );

    this.filteredValidationVerdictsTableDataSource = new MatTableDataSource(
      this.validationVerdictsTableDataSource.data
    );
  }

  private resetValidationVerdictsTable(): void {
    this.validationVerdictsTableColumns = [];

    this.validationVerdictsTableDataSource = new MatTableDataSource<any>();

    this.filteredValidationVerdictsTableDataSource =
      new MatTableDataSource<any>();
  }

  constructor(
    private s3Service: S3Service,
    private awsAmplifyApiService: AwsAmplifyApiService,
    private snackBarService: SnackBarService
  ) {}
}
