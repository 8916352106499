import { Injectable } from '@angular/core';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CsvParserService {
  constructor(private ngxCsvParser: NgxCsvParser) {}

  tpaCsvParse(file: File): Observable<any> {
    return this.ngxCsvParser
      .parse(file, {
        header: true,
        delimiter: ',',
        encoding: 'utf8',
      })
      .pipe(
        map(
          (parsedTpaSheetItems: any) =>
            parsedTpaSheetItems as ParsedTpaSheetItem[]
        ),
        catchError((ngxCSVParserError: NgxCSVParserError) =>
          throwError(ngxCSVParserError)
        )
      );
  }
}
