import { ProjectsEnvironmentsConstant } from '@constants/projects-environments.constant';
import { ProjectsConstant } from '@constants/projects.constant';
import { environment } from '@environments/environment';
import {
  GraphQLQueryError,
  QueryBuilderResponse,
  QueryVrctResponse,
  TestrunExecution,
} from '@model/payloads';

// @ts-ignore
import moment from 'moment';

export class TmaUtilsStaticService {
  static getAdGroups(rawAdGroups: any): string[] {
    return rawAdGroups
      .replace(/[[\]]/g, '')
      .replace(' ', '')
      .split(',')
      .map((adGroup: any) => adGroup.trim());
  }

  static transformSemanticSearchLambdaResponse(data: any): object {
    const map: any = {};

    for (const key in data) {
      const value = JSON.parse(data[key]);

      if (typeof value === 'object' && value !== null) {
        value.forEach((item: any) => {
          if (map[key]) {
            map[key][item.key] = item.value;
          } else {
            map[key] = {};

            map[key][item.key] = item.value;
          }
        });
      } else {
        if (map[key]) {
          map[key] = value;
        } else {
          map[key] = {};

          map[key] = value;
        }
      }
    }

    return map;
  }

  static getUniqueArray<T>(inputArray: T[], key?: keyof T): T[] {
    if (key) {
      const uniqueSet = new Set(inputArray.map((item) => item[key]));
      // @ts-ignore
      return Array.from(uniqueSet).map((uniqueKey) =>
        inputArray.find((item) => item[key] === uniqueKey)
      );
    } else {
      return Array.from(new Set(inputArray));
    }
  }

  static getGraphQLQueryErrorMessages(error: GraphQLQueryError): string {
    return error.errors.map((error) => error.message).join();
  }

  static isBoolean(value: any): boolean {
    return typeof value === 'boolean';
  }

  static getDeepCopy<T>(source: T): T;
  static getDeepCopy<T>(source: T[]): T[] {
    return JSON.parse(JSON.stringify(source));
  }

  static formatDate(timeStamp: string | null): string {
    return moment(timeStamp).local().format('YYYY-MM-DD HH:mm:ss');
  }

  static getLegacyKpiPlayList(reproResultsEntryList: any): any {
    let kpiPlayList = '';

    reproResultsEntryList.map((item: any) => {
      if (item['streams']) {
        item['streams'].map((parquetUrl: any) => {
          kpiPlayList += `${parquetUrl}\n`;
        });
      }

      if (
        item['parquet'] &&
        item['parquet_missing'] !== undefined &&
        !item['parquet_missing']
      ) {
        kpiPlayList += `${item['parquet']}\n`;
      }
    });

    return kpiPlayList;
  }

  static getLegacyTestRunConfigFromTestRun(test_run: TestrunExecution): any {
    let testRunConfig = JSON.parse(test_run.testRunConfig);
    const testOrderVersion = testRunConfig.TestOrderVersion;
    // Additional check for legacy test order v1.3 implementation
    if (!testOrderVersion) {
      testRunConfig = JSON.parse(JSON.parse(test_run.testRunConfig));
    }

    return testRunConfig;
  }

  static getLegacyRandomTestrunId(): string {
    const now = moment();
    return now.format('YYYYMMDDHHmmss');
  }

  static getLegacyProjectIdFromTestRun(test_run: TestrunExecution): any {
    const testRunConfig = this.getLegacyTestRunConfigFromTestRun(test_run);
    const testOrderVersion = testRunConfig.TestOrderVersion;

    switch (testOrderVersion) {
      case '1.3':
        return testRunConfig.TestObject.ProjectID;
      case '1.4':
        return testRunConfig.ProjectID;
    }
  }

  static getLegacyProjectIdFromTestOrder(test_order: any): string {
    try {
      const testOrder = JSON.parse(test_order);

      switch (testOrder.TestOrderVersion) {
        case '1.3':
          return testOrder.TestObject.ProjectID;
        case '1.4':
          return testOrder.ProjectID;
        default:
          return '';
      }
    } catch (e) {
      return '';
    }
  }

  static getLegacySessionToKPIScriptsMapping(
    test_session: any,
    kpi_scripts: any
  ): any {
    const session_to_kpiscript: any = {};
    const comma_seperated_kpi_script_ids: any = [];

    kpi_scripts.forEach((kpi_script: any) => {
      comma_seperated_kpi_script_ids.push(kpi_script.id);
    });
    session_to_kpiscript['testSessionId'] = test_session.sessionId;
    session_to_kpiscript['kpiScripts'] = comma_seperated_kpi_script_ids;
    session_to_kpiscript['testSessionLabel'] = test_session.LabelText;

    return session_to_kpiscript;
  }

  static getLegacySessionToKPIThresholdsMapping(
    test_session: any,
    kpi_thresholds: any
  ): any {
    const session_to_kpithresholds: any = {};
    const comma_seperated_kpi_thresholds: any = [];

    kpi_thresholds.forEach((kpi_threshold: any) => {
      comma_seperated_kpi_thresholds.push(kpi_threshold);
    });
    session_to_kpithresholds['testSessionId'] = test_session.sessionId;
    session_to_kpithresholds['kpiThresholds'] = comma_seperated_kpi_thresholds;
    session_to_kpithresholds['testSessionLabel'] = test_session.LabelText;
    return session_to_kpithresholds;
  }

  static getLegacyTestOrderJson(
    Label: any,
    selectedSessions: any,
    reProcessingConfig: any,
    EcuInitTriggeringMode: any
  ): any {
    const PlayList: any = {};
    const PlayListLearning: any = {};

    selectedSessions.forEach((selectedSession: any) => {
      const parsedPlayList = JSON.parse(selectedSession.PlayList || '{}');
      const parsedPlayListLearning = JSON.parse(
        selectedSession.PlayListLearning || '{}'
      );

      // eslint-disable-next-line guard-for-in
      for (const key in parsedPlayList) {
        PlayList[key] = parsedPlayList[key];
      }
      // eslint-disable-next-line guard-for-in
      for (const key in parsedPlayListLearning) {
        PlayListLearning[key] = parsedPlayListLearning[key];
      }
    });

    const testOrderJson: any = {
      TestOrderVersion: '1.4',
      ProjectID: reProcessingConfig.ProjectID,
      ProductName: reProcessingConfig.ProductName,
      ReprocessingType: reProcessingConfig.ReProcessingType,
      GroupID: reProcessingConfig.HIL,
      ReprocessingConfigLabel: reProcessingConfig.ReProcessingConfigLabel,
      TestType: reProcessingConfig.TestType,
      TestRunPriority: reProcessingConfig.TestRunPriority,
      Label,
      PlayList,
      // TODO: Check previous implementation, was hardcoded with version "2"
      PlayListVersion: selectedSessions[0].PlayListVersion,
      EcuInitTriggeringMode,
    };

    if (environment.AWS_REGION === ProjectsEnvironmentsConstant.cn.region) {
      delete testOrderJson.EcuInitTriggeringMode;
    }

    if (testOrderJson.ProjectID === ProjectsConstant.L2H4310.id) {
      testOrderJson.PlayListLearning = PlayListLearning;
    }

    return JSON.stringify(testOrderJson);
  }

  static unsortedKeyValuePipeCompareFn(): number {
    return 0;
  }

  static getFromControlMetadata(
    formControlName: string,
    placeHolder: string,
    inputType: InputType = 'input',
    options: {
      inputOptions?: inputOption[];
      filteredInputOptions?: inputOption[];
      tooltip: string;
      selectOptions?: string[];
    } = { tooltip: '' }
  ): FormControlMetadata {
    return {
      formControlName,
      placeHolder,
      inputType,
      options,
    };
  }

  // @ts-ignore
  static filterEmptyFormFieldsValues<T>(fromValue: T) {
    let filtered: any = {};

    // @ts-ignore
    for (const [key, value] of Object.entries(fromValue)) {
      if (value) {
        filtered[key] = value;
      }
    }
    return filtered;
  }

  static generatePlayListFromLegacyTestSessions(
    queryBuilderResponse: QueryBuilderResponse,
    queryVrctResponse: QueryVrctResponse
  ): TestSessionPlayList {
    const PlayList: TestSessionPlayList = {};
    const parsedQueryBuilderQuery = JSON.parse(
      queryBuilderResponse.data.queryBuilderQuery || '[]'
    );
    const parsedQueryVRCT = JSON.parse(
      queryVrctResponse.data.queryVRCT || '{}'
    );

    parsedQueryBuilderQuery.forEach(
      (driveId: string) => (PlayList[driveId] = [])
    );
    for (const driveId in parsedQueryVRCT) {
      if (parsedQueryVRCT.hasOwnProperty(driveId)) {
        if (!PlayList[driveId]) {
          PlayList[driveId] = [];
        }
        PlayList[driveId].push(parsedQueryVRCT[driveId]);
      }
    }

    return PlayList;
  }

  static getLegacyQueryVrctPayload(
    queryBuilderResponse: QueryBuilderResponse,
    select_bordnet: string | undefined
  ): any {
    const raw = queryBuilderResponse.data.queryBuilderQuery;
    const queryText = {};
    if (select_bordnet) {
      // @ts-ignore
      queryText['select-bordnet'] = select_bordnet.toUpperCase();
    } else {
      // @ts-ignore
      queryText['select-bordnet'] = '';
    }
    // @ts-ignore
    queryText['drive-id'] = (
      'in(' +
      raw
        .replace('[', '')
        .replace(']', '')
        // @ts-ignore
        .replaceAll(' ', '')
        .replaceAll('"', '') +
      ')'
    )
      // @ts-ignore
      .replaceAll('#' + queryText['select-bordnet'], '');

    const outArr = [];
    const driveArr = raw
      .replace('[', '')
      .replace(']', '')
      // @ts-ignore
      .replaceAll(' ', '')
      .replaceAll('"', '')
      // @ts-ignore
      .replaceAll('#' + queryText['select-bordnet'], '')
      .split(',');
    for (let i = 0; i < driveArr.length; i++) {
      const driveIDJSON = {};
      // @ts-ignore
      driveIDJSON['drive_id'] = driveArr[i];
      // @ts-ignore
      driveIDJSON['a_step'] = queryText['select-bordnet'];
      // @ts-ignore
      driveIDJSON['use_case'] = 'VISION';
      // @ts-ignore
      driveIDJSON['splits_policy'] = 'LONGEST';
      // @ts-ignore
      driveIDJSON['verbose'] = true;
      outArr.push(driveIDJSON);
    }

    return {
      queries: {
        queries: outArr,
      },
    };
  }
}
