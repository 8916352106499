<div fxLayout="row" fxLayoutAlign="space-between start">
  <div fxLayout="column" fxFlex="90" fxLayoutAlign="space-between start">
    <mat-card class="full-width-card">
      <mat-card-header>
        <mat-card-title> General information about drive id </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-divider></mat-divider>

        <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="viewport">
          <mat-list>
            <mat-list-item
              *ngFor="let item of drivingSessionForMFK120 | keyvalue: unsorted"
            >
              <div mat-line>
                {{ item.key }}: <b>{{ item.value }}</b>
              </div>
            </mat-list-item>
          </mat-list>
        </cdk-virtual-scroll-viewport>

        <mat-divider></mat-divider>
      </mat-card-content>
    </mat-card>

    <mat-card class="full-width-card">
      <mat-card-header>
        <mat-card-title> Mandatory event validation </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-list>
          <mat-list-item
            *ngFor="let item of mandatoryEventValidation | keyvalue: unsorted"
          >
            <div mat-line>
              {{ item.key }}: <b>{{ item.value }}</b>
            </div>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>

  <div fxFlex="10">
    <mat-card>
      <mat-card-content>
        <a
          mat-raised-button
          color="primary"
          matTooltip="Download report"
          matTooltipPosition="below"
          [href]="reportLink"
          [disabled]="!reportLink"
          ><mat-icon>file_download</mat-icon></a
        >
      </mat-card-content>
    </mat-card>
  </div>
</div>
