import { VinsApprovalsConstant } from '@modules/vins-approvals/constants/vins-approvals.constant';

export const SplitsApprovalsConstant = {
  mfk120InitApprovalsValueForApprovalClip: [
    {
      approval: VinsApprovalsConstant.statuses.notSet.value,
      comment: '',
      user: VinsApprovalsConstant.approval_users_mfk120.data_manager.name,
      email: '',
    },
    {
      approval: VinsApprovalsConstant.statuses.notSet.value,
      comment: '',
      user: VinsApprovalsConstant.approval_users_mfk120.data_collection.name,
      email: '',
    },
    {
      approval: VinsApprovalsConstant.statuses.notSet.value,
      comment: '',
      user: VinsApprovalsConstant.approval_users_mfk120.kpi_team.name,
      email: '',
    },
    {
      approval: VinsApprovalsConstant.statuses.notSet.value,
      comment: '',
      user: VinsApprovalsConstant.approval_users_mfk120.reprocessing.name,
      email: '',
    },
  ],
  mfk120InitApprovalsValueForNormalDriveId: [
    {
      approval: VinsApprovalsConstant.statuses.notSet.value,
      comment: '',
      user: VinsApprovalsConstant.approval_users_mfk120.data_manager.name,
      email: '',
    },
  ],
};
