import { GraphQLOptions } from '@aws-amplify/api-graphql';
import {
  GetKpiMetaPayload,
  GetKpiMetaResponse,
  GetKpiProjectsResponse,
  GetKpiScriptConfigurationsPayload,
  GetKpiScriptConfigurationsResponse,
  GetKpiTestSuitesPayload,
  GetKpiTestsuitesResponse,
  GetReprocessingConfigurationsPayload,
  GetReprocessingConfigurationsResponse,
  GetReprocessingInfoPayload,
  GetReprocessingInfoResponse,
  GetTestrunExecutionsPayload,
  GetTestrunExecutionsResponse,
  GetTestRunSubmissionsPayload,
  GetTestRunSubmissionsResponse,
  GetTestSessionsPayload,
  GetTestSessionsResponse,
  GraphQLQueryError,
  QueryBuilderPayload,
  QueryBuilderResponse,
  QuerySaveTestSessionPayload,
  QuerySaveTestSessionResponse,
  QueryVrctPayload,
  QueryVrctResponse,
  SaveKpitoolTriggerPayload,
  SaveKpitoolTriggerResponse,
  SaveReprocessingConfigurationPayload,
  SaveReprocessingConfigurationResponse,
  SaveTestOrderPayload,
  SaveTestOrderResponse,
  SaveTestRunPayload,
  SaveTestRunResponse,
  TestRunConfigurationPayload,
  TestRunConfigurationResponse,
  TestrunExecutionRetriggerPayload,
  TestrunExecutionRetriggerResponse,
  TestrunStartPayload,
  TestrunStartResponse,
  TestrunStoreResponse,
  TestSessionPlayListPayload,
} from '@model/payloads';
import { Action } from '@ngrx/store';

export enum TestRunConfigurationActionTypes {
  ResetTestRunConfigurationStore = '[TestRunConfiguration] Reset TestRunConfiguration Store',
  LoadSemanticSearchData = '[TestRunConfiguration] Load LoadSemanticSearchData',
  LoadSemanticSearchDataSuccess = '[TestRunConfiguration] Load SemanticSearchData Success',
  GraphQLQueryFailure = '[TestRunConfiguration] Load GraphQLQuery Failure',
  LoadQueryVrtcData = '[TestRunConfiguration] Load LoadQueryVrtcData',
  LoadQueryVrtcDataSuccess = '[TestRunConfiguration] Load LoadQueryVrtcData Success',
  LoadTestSessionPlayListData = '[TestRunConfiguration] Load TestSessionPlayListData',
  LoadTestSessionPlayListDataSuccess = '[TestRunConfiguration] Load TestSessionPlayListData Success',
  SaveTestSessionData = '[TestRunConfiguration] Save TestSessionData',
  SaveTestSessionDataSuccess = '[TestRunConfiguration] Save TestSessionData Success',
  LoadTestRunConfigurationData = '[TestRunConfiguration] Load TestRunConfigurationData',
  LoadTestRunConfigurationDataSuccess = '[TestRunConfiguration] Load TestRunConfigurationData Success',
  SaveReproceccingConfigurationData = '[TestRunConfiguration] Save ReproceccingConfigurationData',
  SaveReproceccingConfigurationDataSuccess = '[TestRunConfiguration] Save ReproceccingConfigurationData Success',
  GetReproceccingConfigurationsData = '[TestRunConfiguration] Get ReproceccingConfigurationsData',
  GetReproceccingConfigurationsDataSuccess = '[TestRunConfiguration] Get GetReproceccingConfigurationsData Success',
  GetTestSessionsData = '[TestRunConfiguration] Get GetTestSessionsData',
  GetTestSessionsDataSuccess = '[TestRunConfiguration] Get GetTestSessionsData Success',
  SaveTestOrderData = '[TestRunConfiguration] Save TestOrderData',
  SaveTestOrderDataSuccess = '[TestRunConfiguration] Save TestOrderData Success',
  GetTestRunSubmissionsData = '[TestRunConfiguration] Get TestRunSubmissionsData',
  GetTestRunSubmissionsDataSuccess = '[TestRunConfiguration] Get TestRunSubmissionsData Success',
  StoreTestrunConfigurationsData = '[TestRunConfiguration] Store TestRunConfigurationsData',
  StoreTestrunConfigurationsDataSuccess = '[TestRunConfiguration] Store TestRunConfigurationsData Success',
  GetTestrunExecutionsData = '[TestRunConfiguration] Get TestrunExecutionsData',
  GetTestrunExecutionsDataSuccess = '[TestRunConfiguration] Get TestrunExecutionsData Success',
  StartTestrunData = '[TestRunConfiguration] Start TestrunData',
  StartTestrunDataSuccess = '[TestRunConfiguration] Start TestrunData Success',
  StartTestrunDataFailed = '[TestRunConfiguration] Start TestrunData Failed',
  RetriggerTestrunData = '[TestRunConfiguration] Retrigger TestrunData',
  RetriggerTestrunDataSuccess = '[TestRunConfiguration] Retrigger TestrunData Success',
  RetriggerTestrunDataFailed = '[TestRunConfiguration] Retrigger TestrunData Failed',
  GetKpiMetaData = '[TestRunConfiguration] Get KpiMetaData',
  GetKpiMetaDataSuccess = '[TestRunConfiguration] Get KpiMetaData Success',
  GetKpiProjectsData = '[TestRunConfiguration] Get KpiProjectsData',
  GetKpiProjectsDataSuccess = '[TestRunConfiguration] Get KpiProjectsData Success',
  GetKpiTestsuitesData = '[TestRunConfiguration] Get KpiTestsuitesData',
  GetKpiTestsuitesDataSuccess = '[TestRunConfiguration] Get KpiTestsuitesData Success',
  GetKpiScriptConfigurationsData = '[TestRunConfiguration] Get KpiScriptConfigurationsData',
  GetKpiScriptConfigurationsDataSuccess = '[TestRunConfiguration] Get KpiScriptConfigurationsData Success',
  SaveTestRunData = '[TestRunConfiguration] Save TestRunData',
  SaveTestRunDataSuccess = '[TestRunConfiguration] Save TestRunData Success',
  GetReprocessingInfoData = '[TestRunConfiguration] Get ReprocessingInfoData',
  GetReprocessingInfoDataSuccess = '[TestRunConfiguration] Get ReprocessingInfoData Success',
  SaveKpitoolTriggerData = '[TestRunConfiguration] Save KpitoolTriggerData',
  SaveKpitoolTriggerDataSuccess = '[TestRunConfiguration] Save KpitoolTriggerData Success',
  SaveKpitoolTriggerDataFailed = '[TestRunConfiguration] Save KpitoolTriggerData Failed',
  GetKPIJobExecutionsData = '[TestRunConfiguration] Get KPIJobExecutionsData',
  GetKPIJobExecutionsDataSuccess = '[TestRunConfiguration] Get KPIJobExecutionsData Success',
}

export class ResetTestRunConfigurationStore implements Action {
  readonly type =
    TestRunConfigurationActionTypes.ResetTestRunConfigurationStore;
}

export class LoadSemanticSearchData implements Action {
  readonly type = TestRunConfigurationActionTypes.LoadSemanticSearchData;
  constructor(public payload: QueryBuilderPayload) {}
}

export class LoadSemanticSearchDataSuccess implements Action {
  readonly type = TestRunConfigurationActionTypes.LoadSemanticSearchDataSuccess;
  constructor(
    public payload: {
      select_bordnet?: string;
      queryBuilderResponse: QueryBuilderResponse;
    }
  ) {}
}

export class GraphQLQueryFailure implements Action {
  readonly type = TestRunConfigurationActionTypes.GraphQLQueryFailure;
  constructor(public payload: GraphQLQueryError) {}
}

export class LoadQueryVrtcData implements Action {
  readonly type = TestRunConfigurationActionTypes.LoadQueryVrtcData;
  constructor(public payload: QueryVrctPayload) {}
}

export class LoadQueryVrtcDataSuccess implements Action {
  readonly type = TestRunConfigurationActionTypes.LoadQueryVrtcDataSuccess;
  constructor(public payload: QueryVrctResponse) {}
}

export class LoadTestSessionPlayListData implements Action {
  readonly type = TestRunConfigurationActionTypes.LoadTestSessionPlayListData;
  constructor(public payload: QueryBuilderPayload) {}
}

export class LoadTestSessionPlayListDataSuccess implements Action {
  readonly type =
    TestRunConfigurationActionTypes.LoadTestSessionPlayListDataSuccess;
  constructor(public payload: TestSessionPlayListPayload) {}
}

export class SaveTestSessionData implements Action {
  readonly type = TestRunConfigurationActionTypes.SaveTestSessionData;
  constructor(public payload: QuerySaveTestSessionPayload) {}
}

export class SaveTestSessionDataSuccess implements Action {
  readonly type = TestRunConfigurationActionTypes.SaveTestSessionDataSuccess;
  constructor(public payload: QuerySaveTestSessionResponse) {}
}
export class LoadTestRunConfigurationData implements Action {
  readonly type = TestRunConfigurationActionTypes.LoadTestRunConfigurationData;
  constructor(public payload: TestRunConfigurationPayload) {}
}

export class LoadTestRunConfigurationDataSuccess implements Action {
  readonly type =
    TestRunConfigurationActionTypes.LoadTestRunConfigurationDataSuccess;
  constructor(public payload: TestRunConfigurationResponse) {}
}

export class SaveReproceccingConfigurationData implements Action {
  readonly type =
    TestRunConfigurationActionTypes.SaveReproceccingConfigurationData;
  constructor(public payload: SaveReprocessingConfigurationPayload) {}
}

export class SaveReproceccingConfigurationDataSuccess implements Action {
  readonly type =
    TestRunConfigurationActionTypes.SaveReproceccingConfigurationDataSuccess;
  constructor(public payload: SaveReprocessingConfigurationResponse) {}
}

export class GetReproceccingConfigurationsData implements Action {
  readonly type =
    TestRunConfigurationActionTypes.GetReproceccingConfigurationsData;
  constructor(public payload: GetReprocessingConfigurationsPayload) {}
}

export class GetReproceccingConfigurationsDataSuccess implements Action {
  readonly type =
    TestRunConfigurationActionTypes.GetReproceccingConfigurationsDataSuccess;
  constructor(public payload: GetReprocessingConfigurationsResponse) {}
}

export class GetTestSessionsData implements Action {
  readonly type = TestRunConfigurationActionTypes.GetTestSessionsData;
  constructor(public payload: GetTestSessionsPayload) {}
}

export class GetTestSessionsDataSuccess implements Action {
  readonly type = TestRunConfigurationActionTypes.GetTestSessionsDataSuccess;
  constructor(public payload: GetTestSessionsResponse) {}
}

export class SaveTestOrderData implements Action {
  readonly type = TestRunConfigurationActionTypes.SaveTestOrderData;
  constructor(public payload: SaveTestOrderPayload) {}
}

export class SaveTestOrderDataSuccess implements Action {
  readonly type = TestRunConfigurationActionTypes.SaveTestOrderDataSuccess;
  constructor(public payload: SaveTestOrderResponse) {}
}

export class GetTestRunSubmissionsData implements Action {
  readonly type = TestRunConfigurationActionTypes.GetTestRunSubmissionsData;
  constructor(public payload: GetTestRunSubmissionsPayload) {}
}

export class GetTestRunSubmissionsDataSuccess implements Action {
  readonly type =
    TestRunConfigurationActionTypes.GetTestRunSubmissionsDataSuccess;
  constructor(public payload: GetTestRunSubmissionsResponse) {}
}

export class StoreTestrunConfigurationsData implements Action {
  readonly type =
    TestRunConfigurationActionTypes.StoreTestrunConfigurationsData;
  constructor(public payload: TestRunSubmission) {}
}

export class StoreTestrunConfigurationsDataSuccess implements Action {
  readonly type =
    TestRunConfigurationActionTypes.StoreTestrunConfigurationsDataSuccess;
  constructor(public payload: TestrunStoreResponse) {}
}

export class GetTestrunExecutionsData implements Action {
  readonly type = TestRunConfigurationActionTypes.GetTestrunExecutionsData;
  constructor(public payload: GetTestrunExecutionsPayload) {}
}

export class GetTestrunExecutionsDataSuccess implements Action {
  readonly type =
    TestRunConfigurationActionTypes.GetTestrunExecutionsDataSuccess;
  constructor(public payload: GetTestrunExecutionsResponse) {}
}

export class StartTestrunData implements Action {
  readonly type = TestRunConfigurationActionTypes.StartTestrunData;
  constructor(public payload: TestrunStartPayload) {}
}

export class StartTestrunDataSuccess implements Action {
  readonly type = TestRunConfigurationActionTypes.StartTestrunDataSuccess;
  constructor(public payload: TestrunStartResponse) {}
}

export class StartTestrunDataFailed implements Action {
  readonly type = TestRunConfigurationActionTypes.StartTestrunDataFailed;
}

export class RetriggerTestrunData implements Action {
  readonly type = TestRunConfigurationActionTypes.RetriggerTestrunData;
  constructor(public payload: TestrunExecutionRetriggerPayload) {}
}

export class RetriggerTestrunDataSuccess implements Action {
  readonly type = TestRunConfigurationActionTypes.RetriggerTestrunDataSuccess;
  constructor(public payload: TestrunExecutionRetriggerResponse) {}
}

export class RetriggerTestrunDataFailed implements Action {
  readonly type = TestRunConfigurationActionTypes.RetriggerTestrunDataFailed;
}

export class GetKpiMetaData implements Action {
  readonly type = TestRunConfigurationActionTypes.GetKpiMetaData;
  constructor(public payload: GetKpiMetaPayload) {}
}

export class GetKpiMetaDataSuccess implements Action {
  readonly type = TestRunConfigurationActionTypes.GetKpiMetaDataSuccess;
  constructor(public payload: GetKpiMetaResponse) {}
}

export class GetKpiProjectsData implements Action {
  readonly type = TestRunConfigurationActionTypes.GetKpiProjectsData;
  constructor(public payload: GraphQLOptions) {}
}

export class GetKpiProjectsDataSuccess implements Action {
  readonly type = TestRunConfigurationActionTypes.GetKpiProjectsDataSuccess;
  constructor(public payload: GetKpiProjectsResponse) {}
}

export class GetKpiTestsuitesData implements Action {
  readonly type = TestRunConfigurationActionTypes.GetKpiTestsuitesData;
  constructor(public payload: GetKpiTestSuitesPayload) {}
}

export class GetKpiTestsuitesDataSuccess implements Action {
  readonly type = TestRunConfigurationActionTypes.GetKpiTestsuitesDataSuccess;
  constructor(public payload: GetKpiTestsuitesResponse) {}
}

export class GetKpiScriptConfigurationsData implements Action {
  readonly type =
    TestRunConfigurationActionTypes.GetKpiScriptConfigurationsData;
  constructor(public payload: GetKpiScriptConfigurationsPayload) {}
}

export class GetKpiScriptConfigurationsDataSuccess implements Action {
  readonly type =
    TestRunConfigurationActionTypes.GetKpiScriptConfigurationsDataSuccess;
  constructor(public payload: GetKpiScriptConfigurationsResponse) {}
}

export class SaveTestRunData implements Action {
  readonly type = TestRunConfigurationActionTypes.SaveTestRunData;
  constructor(public payload: SaveTestRunPayload) {}
}

export class SaveTestRunDataSuccess implements Action {
  readonly type = TestRunConfigurationActionTypes.SaveTestRunDataSuccess;
  constructor(public payload: SaveTestRunResponse) {}
}

export class GetReprocessingInfoData implements Action {
  readonly type = TestRunConfigurationActionTypes.GetReprocessingInfoData;
  constructor(public payload: GetReprocessingInfoPayload) {}
}

export class GetReprocessingInfoDataSuccess implements Action {
  readonly type =
    TestRunConfigurationActionTypes.GetReprocessingInfoDataSuccess;
  constructor(public payload: GetReprocessingInfoResponse) {}
}

export class SaveKpitoolTriggerData implements Action {
  readonly type = TestRunConfigurationActionTypes.SaveKpitoolTriggerData;
  constructor(public payload: SaveKpitoolTriggerPayload) {}
}

export class SaveKpitoolTriggerDataSuccess implements Action {
  readonly type = TestRunConfigurationActionTypes.SaveKpitoolTriggerDataSuccess;
  constructor(public payload: SaveKpitoolTriggerResponse) {}
}

export class SaveKpitoolTriggerDataFailed implements Action {
  readonly type = TestRunConfigurationActionTypes.SaveKpitoolTriggerDataFailed;
}

export type TestRunConfigurationActions =
  | ResetTestRunConfigurationStore
  | LoadSemanticSearchData
  | LoadSemanticSearchDataSuccess
  | GraphQLQueryFailure
  | LoadQueryVrtcData
  | LoadQueryVrtcDataSuccess
  | LoadTestSessionPlayListData
  | LoadTestSessionPlayListDataSuccess
  | SaveTestSessionData
  | SaveTestSessionDataSuccess
  | LoadTestRunConfigurationData
  | LoadTestRunConfigurationDataSuccess
  | SaveReproceccingConfigurationData
  | SaveReproceccingConfigurationDataSuccess
  | GetReproceccingConfigurationsData
  | GetReproceccingConfigurationsDataSuccess
  | GetTestSessionsData
  | GetTestSessionsDataSuccess
  | SaveTestOrderData
  | SaveTestOrderDataSuccess
  | GetTestRunSubmissionsData
  | GetTestRunSubmissionsDataSuccess
  | StoreTestrunConfigurationsData
  | StoreTestrunConfigurationsDataSuccess
  | GetTestrunExecutionsData
  | GetTestrunExecutionsDataSuccess
  | StartTestrunData
  | StartTestrunDataSuccess
  | StartTestrunDataFailed
  | RetriggerTestrunData
  | RetriggerTestrunDataSuccess
  | RetriggerTestrunDataFailed
  | GetKpiMetaData
  | GetKpiMetaDataSuccess
  | GetKpiProjectsData
  | GetKpiProjectsDataSuccess
  | GetKpiTestsuitesData
  | GetKpiTestsuitesDataSuccess
  | GetKpiScriptConfigurationsData
  | GetKpiScriptConfigurationsDataSuccess
  | SaveTestRunData
  | SaveTestRunDataSuccess
  | GetReprocessingInfoData
  | GetReprocessingInfoDataSuccess
  | SaveKpitoolTriggerData
  | SaveKpitoolTriggerDataSuccess
  | SaveKpitoolTriggerDataFailed;
