import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ProjectsConstant } from '@constants/projects.constant';
import { TableConstant } from '@constants/table.constant';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';
import {
  GetVehicleApprovalsByDriveId,
  GetVehicleApprovalsByProject,
} from '@core/amplify/aws-apmlify-query.constant';
import { SnackBarService } from '@core/services/snack-bar.service';
import { TmaUtilsStaticService } from '@core/services/tma-utils-static.service';
import { TmaUtilsService } from '@core/services/tma-utils.service';
import { GraphQLQueryError } from '@model/payloads';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-drive-ids-list',
  templateUrl: './drive-ids-list.component.html',
  styleUrls: ['./drive-ids-list.component.scss'],
})
export class DriveIdsListComponent implements OnChanges {
  @Input() title: string;
  @Input() isApproval: string;
  @Input() approvalClipId: string | null;

  itemsLimit = 100;

  driveIdsColumns: string[] = [];
  driveIdsDataSource = new MatTableDataSource<any>([]);
  filteredDriveIdsDataSource = new MatTableDataSource<any>([]);
  driveIdsTableSelection = new SelectionModel<any>(true, []);
  driveIdsTableSpinner = false;
  driveIdsNextToken: string | null = null;

  @Output() driveIdsTableItemSelected: EventEmitter<object> =
    new EventEmitter();
  @Output() driveIdsTableReloaded: EventEmitter<object> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges) {
    if (this.isApproval === 'true') {
      this.fetchDriveIds(null);
    } else {
      if (
        changes.approvalClipId?.currentValue.length &&
        changes.approvalClipId.previousValue !==
          changes.approvalClipId.currentValue
      ) {
        this.fetchDriveIds(changes.approvalClipId.currentValue);
      } else {
        this.resetDriveIdsTableData();
      }
    }
  }

  onDriveIdsTableItemSelected(selected: any): void {
    this.driveIdsTableItemSelected.emit(selected);
  }

  onDriveIdsTableReloaded() {
    this.resetDriveIdsTableData();

    if (this.isApproval === 'true') {
      this.fetchDriveIds(null);
    } else {
      if (this.approvalClipId?.length) {
        this.fetchDriveIds(this.approvalClipId);
      }
    }

    this.driveIdsTableReloaded.emit();
  }

  onLoadMoreDriveIds(): void {
    if (this.driveIdsNextToken) {
      this.driveIdsTableSpinner = true;

      this.fetchVehicleApprovalsByProject(
        `${this.isApproval}#${ProjectsConstant.MFK120.name}`,
        this.isApproval === 'true' ? null : this.approvalClipId,
        this.itemsLimit,
        this.driveIdsNextToken
      ).subscribe(
        (response) => {
          this.driveIdsNextToken =
            response.data.getVehicleApprovalsByProject.nextToken;

          const parsedItems = this.tmaUtilsService.parseDDbResolverResponse(
            response,
            'getVehicleApprovalsByProject'
          );

          this.renderDriveIdsTable(parsedItems);

          this.driveIdsTableSpinner = false;
        },
        (error: GraphQLQueryError) => {
          this.snackBarService.showGraphQlErrorMessage(error);

          this.driveIdsTableSpinner = false;
        }
      );
    }
  }

  onSearchByDriveId($event: Event): void {
    this.resetDriveIdsTableData();

    const drive_id = ($event?.target as HTMLInputElement).value;

    if (drive_id?.length) {
      this.driveIdsTableSpinner = true;

      if (this.isApproval === 'true') {
        this.fetchVehicleApprovalsByProject(
          `${this.isApproval}#${ProjectsConstant.MFK120.name}`,
          drive_id,
          this.itemsLimit,
          null
        ).subscribe(
          (response) => {
            this.driveIdsNextToken =
              response.data.getVehicleApprovalsByProject.nextToken;

            const parsedItems = this.tmaUtilsService.parseDDbResolverResponse(
              response,
              'getVehicleApprovalsByProject'
            );

            this.renderDriveIdsTable(parsedItems);

            this.driveIdsTableSpinner = false;
          },
          (error: GraphQLQueryError) => {
            this.snackBarService.showGraphQlErrorMessage(error);

            this.driveIdsTableSpinner = false;
          }
        );
      } else {
        this.fetchVehicleApprovalsByDriveId(
          this.approvalClipId,
          drive_id,
          this.itemsLimit,
          null
        ).subscribe(
          (response) => {
            this.driveIdsNextToken =
              response.data.getVehicleApprovalsByDriveId.nextToken;

            const parsedItems = this.tmaUtilsService.parseDDbResolverResponse(
              response,
              'getVehicleApprovalsByDriveId'
            );

            this.renderDriveIdsTable(parsedItems);

            this.driveIdsTableSpinner = false;
          },
          (error: GraphQLQueryError) => {
            this.snackBarService.showGraphQlErrorMessage(error);

            this.driveIdsTableSpinner = false;
          }
        );
      }
    } else {
      this.onDriveIdsTableReloaded();
    }
  }

  fetchDriveIds(approval_clip_id: string | null): void {
    this.driveIdsTableSpinner = true;

    this.resetDriveIdsTableData();

    this.fetchVehicleApprovalsByProject(
      `${this.isApproval}#${ProjectsConstant.MFK120.name}`,
      approval_clip_id,
      this.itemsLimit,
      null
    ).subscribe(
      (response) => {
        this.driveIdsNextToken =
          response.data.getVehicleApprovalsByProject.nextToken;

        const parsedItems = this.tmaUtilsService.parseDDbResolverResponse(
          response,
          'getVehicleApprovalsByProject'
        );

        this.renderDriveIdsTable(parsedItems);

        this.driveIdsTableSpinner = false;
      },
      (error: GraphQLQueryError) => {
        this.snackBarService.showGraphQlErrorMessage(error);

        this.driveIdsTableSpinner = false;
      }
    );
  }

  private fetchVehicleApprovalsByProject(
    is_approval_clip_project: string,
    approval_clip_id: string | null,
    limit: number,
    nextToken: string | null
  ): Observable<any> {
    return this.awsAmplifyApiService.graphQLQuery({
      query: GetVehicleApprovalsByProject,
      variables: {
        is_approval_clip_project,
        approval_clip_id,
        limit,
        nextToken,
      },
    });
  }

  private fetchVehicleApprovalsByDriveId(
    approval_clip_id: string | null,
    drive_id: string,
    limit: number,
    nextToken: string | null
  ): Observable<any> {
    return this.awsAmplifyApiService.graphQLQuery({
      query: GetVehicleApprovalsByDriveId,
      variables: {
        approval_clip_id,
        drive_id,
        limit,
        nextToken,
      },
    });
  }

  private resetDriveIdsTableData(): void {
    this.driveIdsDataSource = new MatTableDataSource<any>([]);

    this.filteredDriveIdsDataSource = new MatTableDataSource<any>([]);
  }

  private renderDriveIdsTable(driveIds: any[]): void {
    if (driveIds.length) {
      this.driveIdsColumns = [...TableConstant.driveIdsColumns];
    }

    driveIds.map((driveIdData) => {
      if (driveIdData['drive-id']) {
        driveIdData['drive id'] = driveIdData['drive-id'];
      }

      if (driveIdData['approval-status']) {
        driveIdData['status'] = driveIdData['approval-status'];
      }

      if (driveIdData['collection-type']) {
        driveIdData['type'] = driveIdData['collection-type'];
      }

      if (driveIdData['updated-timestamp']) {
        driveIdData['updated-timestamp'] = TmaUtilsStaticService.formatDate(
          driveIdData['updated-timestamp']
        );
      }
    });

    this.driveIdsDataSource = new MatTableDataSource(
      this.driveIdsDataSource.data.concat(driveIds)
    );

    this.filteredDriveIdsDataSource = new MatTableDataSource(
      this.driveIdsDataSource.data
    );
  }

  constructor(
    private awsAmplifyApiService: AwsAmplifyApiService,
    private snackBarService: SnackBarService,
    private tmaUtilsService: TmaUtilsService
  ) {}
}
