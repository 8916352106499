export const TableConstant = {
  kpiJobsExecutionsColumns: [
    'kpiJobExecutionId',
    'testRunId',
    'progress',
    'kpiJobStatus',
    'pcpPostprocessingJobState',
    'createdTimeStamp',
    'updatedTimeStamp',
  ],
  tcSessionsColumns: [
    'tc-session-id',
    'created-timestamp',
    'label',
    'tc-session-type',
    'project',
    'owner',
  ],
  rmTestRunConfigurationsTableColumns: [
    'id',
    'label',
    'vehicle_type',
    'bordnet_version',
    'dut_version',
    'rbs_version',
    'sil_sw_version',
    'hil_sw_version',
    'type',
  ],
  testOrdersTableColumns: [
    'testrun-config-label',
    'createdTimeStamp',
    'test-run-config-update-task-status',
    'owner',
  ],
  testrunExecutionsTableColumns: [
    'test-order-id',
    'createdTimeStamp',
    'testrun-config-label',
    'progress',
    'owner',
  ],
  tcSessionExecutionsColumns: [
    'drive-id',
    'queue-id',
    'transcoding status',
    'matching status',
  ],
  driveIdsColumns: ['drive id', 'status', 'type'],
  splitsTableColumns: ['Split_Index', 'Split_Name', 'Split_Validity'],
  splitDetailsColumns: [
    'Parameter_Name',
    'Value',
    'Expected_Value',
    'Status',
    'Priority',
  ],
  reproceccingInfoColumns: [
    'ext_test_order_id',
    'created_date',
    'recording_id',
    'queue_id',
    'hil_group_id',
    'status',
  ],
  testRunSubmissionsColumns: [
    'orderId',
    'createdTimeStamp',
    'testRunConfigLabel',
    'testRunConfigUpdateTaskStatus',
  ],
  reprocessingConfigsColumns: [
    'createdTimeStamp',
    'HIL',
    'TestType',
    'LabelText',
    'Owner',
  ],
  testSessionsColumns: ['createdTimeStamp', 'LabelText', 'Owner'],
  testrunExecutionsColumns: [
    'testRunId',
    'createdTimeStamp',
    'testRunConfigLabel',
    'transcodingProgress',
    'transcodingStatus',
    'reprocessingProgress',
    'reprocessingStatus',
    'owner',
  ],
  mfk120ValidationVerdictColumns: [
    '#',
    'overall result',
    'upload status',
    'l0 checks',
    'l1',
    'l2 me',
    'l2 signal',
  ],
};
