<app-textarea-form
  [formGroup]="dataBrowserFormGroup"
  [formTitle]="'Athena search form'"
  [formControlsMetadata]="dataBrowserControlsMetadata"
  [formControlsConfig]="dataBrowserControlsConfig"
  (formSubmitted)="onSubmitDataBrowserForm($event)"
></app-textarea-form>
<div fxLayout="row" fxLayoutAlign="space-around start">
  <app-table
    class="athena-table"
    fxFlex="20"
    [displayedColumns]="dataBrowserTableColumns"
    [dataSource]="filteredDataBrowserTableDataSource"
    (tableItemSelected)="onDataBrowserTableItemSelected($event)"
    [filterPlaceholder]="'BP41325_20200808_134332'"
    [multipleSelections]="false"
    [selection]="dataBrowserTableSelection"
    [enableDetails]="false"
    [spinner]="dataBrowserTableSpinner"
  ></app-table>

  <div fxFlex="20">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Driving session metadata</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div>
            <p>
              A-Step: <b>{{ (drivingSession$ | async)!['a_step'] }}</b>
            </p>
            <p>
              Bordnet: <b>{{ (drivingSession$ | async)!['bordnet'] }}</b>
            </p>
            <p>
              Country: <b>{{ (drivingSession$ | async)!['country'] }}</b>
            </p>
            <p>
              Drive Duration:
              <b
                >{{
                  (drivingSession$ | async)!['drive_duration_in_seconds']
                }}
                s</b
              >
            </p>
            <p>
              Data Size:
              <b
                >{{
                  (drivingSession$ | async)!['total_data_size_GB']
                    | number: '1.0-0'
                }}
                GB</b
              >
            </p>
            <p>
              No. Recordings:
              <b>{{ (drivingSession$ | async)!['total_recordings'] }}</b>
            </p>
          </div>
          <div>
            <p>
              Distance Driven:
              <b>{{ (drivingSession$ | async)!['distance_driven_km'] }} KM</b>
            </p>
            <p>
              Logger: <b>{{ (drivingSession$ | async)!['logger'] }}</b>
            </p>
            <p>
              L0 Score:
              <b>{{
                (drivingSession$ | async)!['level_0_validation_score']
              }}</b>
            </p>
            <p>
              Drive Date:
              <b>{{ (drivingSession$ | async)!['local_drive_date'] }}</b>
            </p>
            <p>
              Customer: <b>{{ (drivingSession$ | async)!['customer'] }}</b>
            </p>
            <p>
              Provider:
              <b>{{ (drivingSession$ | async)!['data_provider'] }}</b>
            </p>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div fxFlex="60">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Driving session S3 files</mat-card-title>
        <mat-card-subtitle
          >Recording location: <b>{{ s3RecordingPath }}</b></mat-card-subtitle
        >
      </mat-card-header>
      <mat-card-content fxLayout="row" fxLayoutAlign="space-around start">
        <div fxFlex="30">
          <cdk-virtual-scroll-viewport
            appendOnly
            itemSize="50"
            class="folders-viewport"
          >
            <mat-selection-list [multiple]="false">
              <div
                class="spinner"
                *ngIf="s3StructureSpinner"
                fxLayout="row"
                fxLayoutAlign="center center"
              >
                <mat-spinner></mat-spinner>
              </div>
              <mat-list-option
                #folderNameOption
                *cdkVirtualFor="let folderName of foldersNames"
                [value]="folderName"
                (click)="onFolderClick(folderNameOption.value)"
              >
                <mat-icon mat-list-icon>folder</mat-icon>
                <div mat-line>
                  {{ folderName }}
                </div>
              </mat-list-option>
            </mat-selection-list>
            <mat-list>
              <mat-list-item *ngFor="let file of s3Folders['recording']">
                <mat-icon mat-list-icon>file_present</mat-icon>
                <div mat-line>{{ file.object_name }}</div>
                <div mat-line>{{ file.last_modified_date }}</div>
              </mat-list-item>
            </mat-list>
          </cdk-virtual-scroll-viewport>
        </div>
        <div fxFlex="70">
          <mat-list>
            <cdk-virtual-scroll-viewport
              appendOnly
              itemSize="50"
              class="files-viewport"
            >
              <mat-list-item
                *cdkVirtualFor="let s3Object of s3Objects"
                class="example-item"
              >
                <mat-icon mat-list-icon>file_present</mat-icon>
                <div mat-line>{{ s3Object.object_name }}</div>
                <div mat-line>{{ s3Object.last_modified_date }}</div>
              </mat-list-item>
            </cdk-virtual-scroll-viewport>
          </mat-list>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
