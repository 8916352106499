import { Injectable } from '@angular/core';
import { GraphQLOptions, GraphQLResult } from '@aws-amplify/api-graphql';
import { API } from 'aws-amplify';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AwsAmplifyApiService {
  graphQLQuery(graphQLOptions: GraphQLOptions): Observable<any> {
    return from<Promise<GraphQLResult<any>> | Observable<object>>(
      API.graphql(graphQLOptions)
    );
  }
}
