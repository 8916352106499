<mat-card>
  <mat-card-content>
    <input
      type="file"
      accept=".csv"
      class="file-input"
      (change)="onAttachFile($event)"
      #fileUploadInput
    />

    <div class="file-upload">
      <button
        mat-mini-fab
        color="primary"
        (click)="fileUploadInput.click()"
        matTooltip="Click to upload .csv file"
        matTooltipPosition="below"
      >
        <mat-icon>attach_file</mat-icon>
      </button>

      <button
        mat-mini-fab
        color="primary"
        type="button"
        matTooltip="Click to remove existing file before upload new one"
        matTooltipPosition="below"
        (click)="onResetFileData()"
      >
        <mat-icon>delete</mat-icon>
      </button>

      <span class="file-name">{{ fileName || 'No file uploaded yet' }}</span>
    </div>

    <div
      *ngIf="attachFileSuccessMessage"
      class="mat-card-actions-result-message"
    >
      <strong> {{ attachFileSuccessMessage }}</strong>
    </div>

    <mat-error
      *ngIf="attachFileErrorMessage"
      class="mat-card-actions-error-message"
      ><strong>{{ attachFileErrorMessage }}</strong></mat-error
    >

    <mat-card>
      <mat-card-content>
        <mat-expansion-panel expanded="true">
          <mat-expansion-panel-header>
            <mat-panel-title>.CSV file parsing result</mat-panel-title>
          </mat-expansion-panel-header>

          <cdk-virtual-scroll-viewport
            appendOnly
            itemSize="50"
            class="recording-groups-viewport"
          >
            <mat-card-header>
              <mat-card-title>Parsed .csv data</mat-card-title>
            </mat-card-header>
            <app-table
              [displayedColumns]="parsedTpaSheetTableColumns"
              [dataSource]="filteredParsedTpaSheetTableDataSource"
              [filterPlaceholder]="'yes'"
              [multipleSelections]="false"
              [showPagination]="false"
              [isSelectionButtonVisible]="false"
              [enableDetails]="false"
              [isSortDisabled]="true"
            ></app-table>
          </cdk-virtual-scroll-viewport>

          <cdk-virtual-scroll-viewport
            appendOnly
            itemSize="50"
            class="recording-groups-viewport"
          >
            <mat-card-header>
              <mat-card-title>Invalid .csv strings</mat-card-title>
            </mat-card-header>
            <app-table
              [displayedColumns]="parsedTpaSheetInvalidTableColumns"
              [dataSource]="filteredParsedTpaSheetInvalidTableDataSource"
              [filterPlaceholder]="'1'"
              [multipleSelections]="false"
              [showPagination]="false"
              [isSelectionButtonVisible]="false"
              [enableDetails]="false"
              [isSortDisabled]="true"
            ></app-table>
          </cdk-virtual-scroll-viewport>
        </mat-expansion-panel>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-content>
        <app-form
          [formGroup]="reproceccingConfigurationFormGroup"
          [formControlsMetadata]="reproceccingConfigurationControlsMetadata"
          [formControlsConfig]="reproceccingConfigurationControlsConfig"
          (submitted)="onTpaReprocessingFormSubmit($event)"
          [isSubmitButtonDisabled]="
            !parsedTpaSheetTableDataSource.data.length ||
            !!parsedTpaSheetInvalidTableDataSource.data.length
          "
        ></app-form>

        <div
          *ngIf="tpaReproResultsSuccessMessage"
          class="mat-card-actions-result-message"
        >
          <strong> {{ tpaReproResultsSuccessMessage }}</strong>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-content>
        <mat-expansion-panel expanded="true">
          <mat-expansion-panel-header>
            <mat-panel-title>Submitted groups</mat-panel-title>
          </mat-expansion-panel-header>

          <cdk-virtual-scroll-viewport
            appendOnly
            itemSize="50"
            class="recording-groups-viewport"
          >
            <app-table
              [displayedColumns]="tpaReproGroupsStatusesTableColumns"
              [dataSource]="filteredTpaReproGroupsStatusesTableDataSource"
              [filterPlaceholder]="''"
              [multipleSelections]="false"
              [showPagination]="false"
              [isSelectionButtonVisible]="false"
              [isSortDisabled]="true"
              [spinner]="tpaReproGroupsStatusesTableSpinner"
            ></app-table>
          </cdk-virtual-scroll-viewport>
        </mat-expansion-panel>
      </mat-card-content>
    </mat-card>
  </mat-card-content>
</mat-card>
