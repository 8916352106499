import { Action } from '@ngrx/store';
import {
  GetDrivingSessionPayload,
  GetDrivingSessionResponse,
  GetDrivingSessionS3StructurePayload,
  GetDrivingSessionS3StructureResponse,
  QueryDataBrowserPayload,
  QueryDataBrowserResponse,
} from '@model/payloads';
import { GraphQLQueryError } from '@model/payloads';

export enum DataBrowserActionTypes {
  ResetDataBrowserStore = '[DataBrowser] Reset DataBrowser Store',
  ResetDrivingSessionStore = '[DataBrowser] Reset DrivingSession Store',
  ResetDrivingSessionS3StructureStore = '[DataBrowser] Reset DrivingSessionS3Structure Store',
  LoadDataBrowser = '[DataBrowser] Load DataBrowser',
  LoadDataBrowserSuccess = '[DataBrowser] Load DataBrowser Success',
  GraphQLQueryFailure = '[DataBrowser] Load GraphQLQuery Failure',
  LoadDrivingSession = '[DataBrowser] Load DrivingSession',
  LoadDrivingSessionSuccess = '[DataBrowser] Load DrivingSession Success',
  LoadDrivingSessionS3Structure = '[DataBrowser] Load DrivingSessionS3Structure',
  LoadDrivingSessionS3StructureSuccess = '[DataBrowser] Load DrivingSessionS3Structure Success',
}

export class ResetDataBrowserStore implements Action {
  readonly type = DataBrowserActionTypes.ResetDataBrowserStore;
}

export class ResetDrivingSessionStore implements Action {
  readonly type = DataBrowserActionTypes.ResetDrivingSessionStore;
}

export class ResetDrivingSessionS3StructureStore implements Action {
  readonly type = DataBrowserActionTypes.ResetDrivingSessionS3StructureStore;
}

export class LoadDataBrowser implements Action {
  readonly type = DataBrowserActionTypes.LoadDataBrowser;
  constructor(public payload: QueryDataBrowserPayload) {}
}

export class LoadDataBrowserSuccess implements Action {
  readonly type = DataBrowserActionTypes.LoadDataBrowserSuccess;
  constructor(public payload: QueryDataBrowserResponse) {}
}

export class GraphQLQueryFailure implements Action {
  readonly type = DataBrowserActionTypes.GraphQLQueryFailure;
  constructor(public payload: GraphQLQueryError) {}
}

export class LoadDrivingSession implements Action {
  readonly type = DataBrowserActionTypes.LoadDrivingSession;
  constructor(public payload: GetDrivingSessionPayload) {}
}

export class LoadDrivingSessionSuccess implements Action {
  readonly type = DataBrowserActionTypes.LoadDrivingSessionSuccess;
  constructor(public payload: GetDrivingSessionResponse) {}
}

export class LoadDrivingSessionS3Structure implements Action {
  readonly type = DataBrowserActionTypes.LoadDrivingSessionS3Structure;
  constructor(public payload: GetDrivingSessionS3StructurePayload) {}
}

export class LoadDrivingSessionS3StructureSuccess implements Action {
  readonly type = DataBrowserActionTypes.LoadDrivingSessionS3StructureSuccess;
  constructor(public payload: GetDrivingSessionS3StructureResponse) {}
}

export type DataBrowserActions =
  | ResetDataBrowserStore
  | ResetDrivingSessionStore
  | ResetDrivingSessionS3StructureStore
  | LoadDataBrowser
  | LoadDataBrowserSuccess
  | GraphQLQueryFailure
  | LoadDrivingSession
  | LoadDrivingSessionSuccess
  | LoadDrivingSessionS3Structure
  | LoadDrivingSessionS3StructureSuccess;
