import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { FormConstant } from '@constants/form.constant';
import { ProjectsConstant } from '@constants/projects.constant';
import { SnackBarConstant } from '@constants/snack-bar.constant';
import { TableConstant } from '@constants/table.constant';
import { UiMessagesConstant } from '@constants/ui-messages.constant';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';
import {
  CancelTestRunExecution,
  GetKPIJobsExecutionsChunks,
  GetKpiReportsDownloadLinksQuery,
  GetMikReproValidationResults,
  GetReprocessingInfoQuery,
  GetTestRunExecution,
  QueryTestOrdersByDates,
  QueryTestRunExecutionsByDates,
  SaveReprocessingConfigurationQuery,
  SaveTestSessionQuery,
  TestrunStartQuery,
} from '@core/amplify/aws-apmlify-query.constant';
import { KpiService } from '@core/services/kpi.service';
import { SnackBarService } from '@core/services/snack-bar.service';
import { TmaUtilsStaticService } from '@core/services/tma-utils-static.service';
import { TmaUtilsService } from '@core/services/tma-utils.service';
import { environment } from '@environments/environment';
import {
  GraphQLQueryError,
  QuerySaveTestSessionPayload,
  SaveReprocessingConfigurationPayload,
  TestrunExecution,
  TestRunExecutionFromDDB,
} from '@model/payloads';
import { TestRunConfigurationConstant } from '@modules/test-run-configuration/constants/test-run-configuration.constant';
import {
  GetReprocessingInfoData,
  GetReprocessingInfoDataSuccess,
  ResetTestRunConfigurationStore,
  SaveReproceccingConfigurationData,
  SaveTestSessionData,
  StartTestrunData,
  StoreTestrunConfigurationsData,
  TestRunConfigurationActions,
  TestRunConfigurationActionTypes,
} from '@modules/test-run-configuration/store/test-run-configuration.actions';
import * as fromTestRunConfiguration from '@modules/test-run-configuration/store/test-run-configuration.reducer';
import { selectFeatureReprocessingInfo } from '@modules/test-run-configuration/store/test-run-configuration.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
// @ts-ignore
import moment from 'moment/moment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-test-run-configuration',
  templateUrl: './test-run-configuration.component.html',
  styleUrls: ['./test-run-configuration.component.scss'],
})
export class TestRunConfigurationComponent implements OnInit, OnDestroy {
  kpiJobExecutionReports: KpiReport[];

  reproceccingInfo$: Observable<ReproceccingInfo[]> = this.store.select(
    selectFeatureReprocessingInfo
  );

  projectsConstant = ProjectsConstant;

  formConstant = FormConstant;

  testRunConfigurationConstant = TestRunConfigurationConstant;

  projectsNames = environment.projectsNames;

  currentProjectName = environment.projectName;

  testOrdersRangeFormGroup = this.fb.group({
    start: [
      moment().subtract(4, 'days').format('YYYY-MM-DD'),
      Validators.required,
    ],
    end: [moment().add(1, 'days').format('YYYY-MM-DD'), Validators.required],
  });
  testOrdersTableColumns: string[] = [];
  testOrdersTableDataSource = new MatTableDataSource<any>([]);
  filteredTestOrdersTableDataSource = new MatTableDataSource<any>([]);
  testOrdersTableSelection = new SelectionModel<any>(true, []);
  testOrdersTableSpinner = false;
  testOrdersTableNextToken: string | null = null;
  testOrdersTableLimit = 100;
  testRunSubmissionSelected: TestRunSubmission = {
    orderId: '',
    createdTimeStamp: '',
    testOrder: '',
    reprocessingConfig: '',
    testSessions: '',
    sessionToKPIScriptsMapping: '',
    sessionToKPIThresholdsMapping: '',
    testRunConfigLabel: '',
    transcodingExecutionType: '',
    owner: '',
    testRunConfigUpdateTaskStatus: '',
  };
  testRunConfigLabel = '';

  testRunExecutionsRangeFormGroup = this.fb.group({
    start: [
      moment().subtract(4, 'days').format('YYYY-MM-DD'),
      Validators.required,
    ],
    end: [moment().add(1, 'days').format('YYYY-MM-DD'), Validators.required],
  });
  testRunExecutionsTableColumns: string[] = [];
  testRunExecutionsTableDataSource = new MatTableDataSource<any>();
  filteredTestRunExecutionsTableDataSource = new MatTableDataSource<any>();
  testRunExecutionsTableSelection = new SelectionModel<any>(true, []);
  testRunExecutionsTableSpinner = false;
  testRunExecutionsTableNextToken: string | null = null;
  testRunExecutionsTableLimit = 100;
  testRunId = '';

  kpiJobExecutionsColumns = TableConstant.kpiJobsExecutionsColumns;
  kpiJobExecutionsDataSource = new MatTableDataSource<any>([]);
  filteredKpiJobExecutionsDataSource = new MatTableDataSource<any>([]);
  kpiJobExecutionsTableSelection = new SelectionModel<any>(true, []);
  kpiJobExecutionsTableSpinner = false;

  reproceccingInfoColumns = TableConstant.reproceccingInfoColumns;
  reproceccingInfoDataSource = new MatTableDataSource<any>();

  getMikReproValidationVerdict:
    | MikValidationResultDdbItem['validation-verdict']
    | '' = '';

  getMikReproValidationResults:
    | MikValidationResultDdbItem['validation-results']
    | null = null;

  primaryActionLabel = '';

  startTestrunExecutionSuccessMessage = '';
  startTestrunExecutionErrorMessage = '';

  startSaveKpiTriggerSuccessMessage = '';
  startSaveKpiTriggerErrorMessage = '';

  private _testrunExecutionSelected: TestrunExecution =
    this.testRunConfigurationConstant.initSelections.testrunExecution;

  get testrunExecutionSelected(): TestrunExecution {
    return this._testrunExecutionSelected;
  }

  set testrunExecutionSelected(testrunExecution: TestrunExecution) {
    this._testrunExecutionSelected = testrunExecution;

    this.primaryActionLabel = '';

    this.startTestrunExecutionSuccessMessage = '';
    this.startTestrunExecutionErrorMessage = '';

    this.startSaveKpiTriggerSuccessMessage = '';
    this.startSaveKpiTriggerErrorMessage = '';

    this.testRunId = this._testrunExecutionSelected.testRunId;

    this.kpiJobExecutionReports = [];
    this.renderKpiJobExecutionsTable(this.testRunId);
  }

  get isStartTestRunButtonEnabled(): boolean {
    return this.primaryActionLabel === this.formConstant.actions.start;
  }

  get isSubmitTestRunExecutionButtonDisabled(): boolean {
    return (
      this.testRunSubmissionSelected.testRunConfigUpdateTaskStatus.toLowerCase() !==
      'success'
    );
  }

  onProjectNameChange(currentProjectName: string): void {
    this.currentProjectName = currentProjectName;

    this.resetTestOrdersTableData();

    this.testOrdersTableNextToken = null;

    this.loadTestOrdersByDates();

    this.resetTestRunExecutionsTableData();

    this.testRunExecutionsTableNextToken = null;

    this.loadTestRunExecutionsByDates();

    this.resetReproJobsTable();

    this.resetKpiJobsTable();

    this.kpiJobExecutionReports = [];
  }

  openCancelTestRunExecutionConfirmDialog() {
    const config = new MatDialogConfig();

    config.data = {
      message: `Test run configuration execution ${this.testrunExecutionSelected.testRunId} will be aborted. Do you confirm the request?`,
      buttonText: {
        ok: 'Yes',
        cancel: 'No',
      },
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, config);

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.onCancelTestRunExecution();
      }
    });
  }

  onCancelTestRunExecution(): void {
    this.awsAmplifyApiService
      .graphQLQuery({
        query: CancelTestRunExecution,
        variables: {
          id: this.testrunExecutionSelected.testRunId,
        },
      })
      .subscribe(
        (response) => {
          this.snackBarService.open(
            `Test run configuration execution ${this.testrunExecutionSelected.testRunId} has been canceled successfully, state: ${response.data.cancelTestRunExecution.test_run_execution_state}`,
            SnackBarConstant.actions.dismiss,
            3000
          );
        },
        (error) => {
          this.snackBarService.showGraphQlErrorMessage(error);
        }
      );
  }

  onReproResultsItemSelected(selected: any): void {
    // this.getMikReproValidationVerdict = 'invalid';
    //
    // this.getMikReproValidationResults = {
    //   Duration: 40.000538141,
    //   MIK_DriverChange_0_No_driver_change: 401,
    //   MIK_DriverChange_1_Driver_change: 0,
    //   MIK_DriverChange_5_Driver_change_unknown: 0,
    //   MIK_DriverChange_6_Init: 0,
    //   MIK_DriverChange_7_Error: 0,
    //   MIK_DriverPresence_0_No_driver_present: 1,
    //   MIK_DriverPresence_1_Driver_present: 78,
    //   MIK_DriverPresence_2_Driver_present_and_buckled: 0,
    //   MIK_DriverPresence_6_Init: 322,
    //   MIK_DriverPresence_7_Error: 0,
    //   MIK_Status_0_Inactive: 0,
    //   MIK_Status_1_Running: 81,
    //   MIK_Status_2_Not_available: 0,
    //   MIK_Status_6_Init: 320,
    //   MIK_Status_7_Error: 0,
    //   Percentage_MIK_Status_1_Running: 0.2,
    //   Total_count_MIK_DriverChange: 401,
    //   Total_Count_MIK_DriverPresence: 401,
    //   Total_Count_MIK_Status: 401,
    // };

    if (this.currentProjectName === this.projectsConstant.MIK.name) {
      this.awsAmplifyApiService
        .graphQLQuery({
          query: GetMikReproValidationResults,
          variables: {
            ext_test_order_id: selected['ext_test_order_id'],
            queue_id: selected['queue_id'],
          },
        })
        .subscribe(
          (response) => {
            const getMikReproValidationResults = JSON.parse(
              response.data.getMikReproValidationResults ||
                '{"validation-verdict": "", "validation-results": {}}'
            );

            this.getMikReproValidationVerdict =
              getMikReproValidationResults['validation-verdict'];

            this.getMikReproValidationResults =
              getMikReproValidationResults['validation-results'];
          },
          (error) => {
            this.snackBarService.showGraphQlErrorMessage(error);
          }
        );
    }
  }

  onKpiJobExecutionsItemSelected(selected: any): void {
    this.kpiJobExecutionReports = [];

    if (selected.projectID && selected.kpiJobID) {
      this.kpiService
        .getKpiReportsDownloadLinks({
          query: GetKpiReportsDownloadLinksQuery,
          variables: {
            project_id: selected.projectID,
            job_id: selected.kpiJobID,
          },
        })
        .subscribe((GetKpiReportsDownloadLinksResponse) => {
          try {
            this.kpiJobExecutionReports = JSON.parse(
              GetKpiReportsDownloadLinksResponse.data.getKpiReportsDownloadLinks
            );
          } catch (e) {
            this.snackBarService.open(
              'KPI report links parsing error',
              SnackBarConstant.actions.dismiss,
              3000
            );
          }
        });
    }
  }

  onTestRunSubmissionItemSelected(selected: any | TestOrderItemFromDDB): void {
    this.testRunSubmissionSelected = {
      orderId: selected['test-order-id'],
      createdTimeStamp: selected.createdTimeStamp,
      testOrder: selected['testrun-config'],
      reprocessingConfig: selected['reprocessing-config'],
      testSessions: selected['test-sessions'],
      sessionToKPIScriptsMapping: selected['session-to-kpi-scripts-mapping'],
      sessionToKPIThresholdsMapping:
        selected['session-to-kpi-thresholds-mapping'],
      testRunConfigLabel: selected['testrun-config-label'],
      transcodingExecutionType: selected['transcoding-execution-type'],
      owner: selected.owner,
      testRunConfigUpdateTaskStatus:
        selected['test-run-config-update-task-status'],
    };
  }

  onTestrunExecutionsItemSelected(selected: any): void {
    this.getMikReproValidationVerdict = '';

    this.getMikReproValidationResults = null;

    this.fetchTestRunExecution(selected['test-order-id']).subscribe(
      (response: { data: { getTestRunExecution: TestrunExecution } }) => {
        this.testrunExecutionSelected = response.data.getTestRunExecution;
      }
    );

    this.store.dispatch(
      new GetReprocessingInfoData({
        query: GetReprocessingInfoQuery,
        variables: {
          ext_test_order_id: selected['test-order-id'],
        },
      })
    );
  }

  onLoadMoreTestOrders(): void {
    if (this.testOrdersTableNextToken) {
      this.loadTestOrdersByDates();
    }
  }

  onLoadMoreTestRunExecutions(): void {
    if (this.testRunExecutionsTableNextToken) {
      this.loadTestRunExecutionsByDates();
    }
  }

  onSubmitTestOrdersByDates(): void {
    this.resetTestOrdersTableData();

    this.testRunConfigLabel = '';

    this.testOrdersTableNextToken = null;

    this.loadTestOrdersByDates();
  }

  onSubmitTestRunExecutionsByDates(): void {
    this.testrunExecutionSelected =
      this.testRunConfigurationConstant.initSelections.testrunExecution;

    this.getMikReproValidationVerdict = '';

    this.getMikReproValidationResults = null;

    this.resetTestRunExecutionsTableData();

    this.testRunExecutionsTableNextToken = null;

    this.loadTestRunExecutionsByDates();

    this.store.dispatch(
      new GetReprocessingInfoDataSuccess({
        data: { getReprocessingInfo: '[]' },
      })
    );
  }

  loadTestOrdersByDates() {
    this.testOrdersTableSpinner = true;

    const { start, end } = this.getFormattedDatePickerDatesRange(
      this.testOrdersRangeFormGroup.value.start,
      this.testOrdersRangeFormGroup.value.end
    );

    this.fetchTestOrders(
      this.currentProjectName,
      start,
      end,
      this.testRunConfigLabel,
      this.testOrdersTableLimit,
      this.testOrdersTableNextToken
    ).subscribe(
      (response) => {
        this.testOrdersTableNextToken =
          response.data.queryTestOrdersByDates.nextToken;

        const parsedItems = this.tmaUtilsService.parseDDbResolverResponse(
          response,
          'queryTestOrdersByDates'
        );

        this.renderTestOrdersTable(parsedItems);

        this.testOrdersTableSpinner = false;
      },
      (error: GraphQLQueryError) => {
        this.snackBarService.showGraphQlErrorMessage(error);

        this.testOrdersTableSpinner = false;
      }
    );
  }

  loadTestRunExecutionsByDates() {
    this.testRunExecutionsTableSpinner = true;

    const { start, end } = this.getFormattedDatePickerDatesRange(
      this.testRunExecutionsRangeFormGroup.value.start,
      this.testRunExecutionsRangeFormGroup.value.end
    );

    this.fetchTestRunExecutions(
      this.currentProjectName,
      start,
      end,
      this.testRunExecutionsTableLimit,
      this.testRunExecutionsTableNextToken
    ).subscribe(
      (response) => {
        this.testRunExecutionsTableNextToken =
          response.data.queryTestRunExecutionsByDates.nextToken;

        const parsedItems: TestRunExecutionFromDDB[] =
          this.tmaUtilsService.parseDDbResolverResponse(
            response,
            'queryTestRunExecutionsByDates'
          );

        this.renderTestRunExecutionsTable(parsedItems);

        this.testRunExecutionsTableSpinner = false;
      },
      (error: GraphQLQueryError) => {
        this.snackBarService.showGraphQlErrorMessage(error);

        this.testRunExecutionsTableSpinner = false;
      }
    );
  }

  onSubmitReprocessingConfigurationForm(
    formControlsConfig: FormControlsConfig
  ): void {
    formControlsConfig[this.formConstant.formControlsNames.ProjectID] =
      this.projectsConstant[
        formControlsConfig[this.formConstant.formControlsNames.ProductName]
      ].id;
    formControlsConfig.ProductName =
      this.projectsConstant[
        formControlsConfig[this.formConstant.formControlsNames.ProductName]
      ].name;

    const payload: SaveReprocessingConfigurationPayload = {
      query: SaveReprocessingConfigurationQuery,
      variables: {
        reprocessing_configuration: JSON.stringify(formControlsConfig),
      },
    };

    this.store.dispatch(new SaveReproceccingConfigurationData(payload));
  }

  onSubmitTestSessionForm(formControlsConfig: FormControlsConfig): void {
    const payload: QuerySaveTestSessionPayload = {
      query: SaveTestSessionQuery,
      variables: {
        test_session: JSON.stringify(formControlsConfig),
      },
    };

    this.store.dispatch(new SaveTestSessionData(payload));
  }

  onSubmitTestOrderForm(): void {
    this.store.dispatch(
      new StoreTestrunConfigurationsData(this.testRunSubmissionSelected)
    );
  }

  onStartTestRunConfig(): void {
    this.startTestrunExecutionSuccessMessage = '';
    this.startTestrunExecutionErrorMessage = '';

    this.store.dispatch(
      new StartTestrunData({
        query: TestrunStartQuery,
        variables: {
          test_run_id: this.testRunId,
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetTestRunConfigurationStore());
  }

  ngOnInit(): void {
    this.loadTestOrdersByDates();
    this.loadTestRunExecutionsByDates();

    this.actions$
      .pipe(ofType(TestRunConfigurationActionTypes.StartTestrunDataSuccess))
      .subscribe(() => {
        this.startTestrunExecutionSuccessMessage =
          UiMessagesConstant.startTestrunExecutionSuccessMessage;
        this.startTestrunExecutionErrorMessage = '';
      });

    this.actions$
      .pipe(ofType(TestRunConfigurationActionTypes.StartTestrunDataFailed))
      .subscribe(() => {
        this.startTestrunExecutionErrorMessage =
          UiMessagesConstant.startTestrunExecutionErrorMessage;
        this.startTestrunExecutionSuccessMessage = '';
      });

    this.actions$
      .pipe(
        ofType(TestRunConfigurationActionTypes.SaveKpitoolTriggerDataSuccess)
      )
      .subscribe(() => {
        this.startSaveKpiTriggerSuccessMessage =
          UiMessagesConstant.startSaveKpiTriggerSuccessMessage;
        this.startSaveKpiTriggerErrorMessage = '';
      });

    this.actions$
      .pipe(
        ofType(TestRunConfigurationActionTypes.SaveKpitoolTriggerDataFailed)
      )
      .subscribe(() => {
        this.startSaveKpiTriggerErrorMessage =
          UiMessagesConstant.startSaveKpiTriggerErrorMessage;
        this.startSaveKpiTriggerSuccessMessage = '';
      });

    this.reproceccingInfo$.subscribe((reprocessingInfo) => {
      this.reproceccingInfoColumns = [...TableConstant.reproceccingInfoColumns];

      this.reproceccingInfoDataSource = new MatTableDataSource(
        reprocessingInfo
      );
    });
  }

  unsorted = TmaUtilsStaticService.unsortedKeyValuePipeCompareFn;

  private getFormattedDatePickerDatesRange(
    start: any,
    end: any
  ): { start: string; end: string } {
    return {
      start: moment(start).format('YYYY-MM-DD'),
      end: moment(end).format('YYYY-MM-DD'),
    };
  }

  private fetchTestOrders(
    project_id: string,
    start: string,
    end: string,
    testrun_config_label: string,
    limit: number,
    nextToken: string | null
  ): Observable<any> {
    return this.awsAmplifyApiService.graphQLQuery({
      query: QueryTestOrdersByDates,
      variables: {
        project_id,
        start,
        end,
        testrun_config_label,
        limit,
        nextToken,
      },
    });
  }

  private fetchTestRunExecution(test_run_id: string): Observable<any> {
    return this.awsAmplifyApiService.graphQLQuery({
      query: GetTestRunExecution,
      variables: {
        test_run_id,
      },
    });
  }

  private fetchTestRunExecutions(
    project_id: string,
    start: string,
    end: string,
    limit: number,
    nextToken: string | null
  ): Observable<any> {
    return this.awsAmplifyApiService.graphQLQuery({
      query: QueryTestRunExecutionsByDates,
      variables: {
        project_id,
        start,
        end,
        limit,
        nextToken,
      },
    });
  }

  private resetTestOrdersTableData(): void {
    this.testRunSubmissionSelected = {
      orderId: '',
      createdTimeStamp: '',
      testOrder: '',
      reprocessingConfig: '',
      testSessions: '',
      sessionToKPIScriptsMapping: '',
      sessionToKPIThresholdsMapping: '',
      testRunConfigLabel: '',
      transcodingExecutionType: '',
      owner: '',
      testRunConfigUpdateTaskStatus: '',
    };

    this.testOrdersTableDataSource = new MatTableDataSource<any>([]);

    this.filteredTestOrdersTableDataSource = new MatTableDataSource<any>([]);
  }

  private renderTestOrdersTable(items: any[]): void {
    if (items.length) {
      this.testOrdersTableColumns = [...TableConstant.testOrdersTableColumns];
    }

    items.map((testOrder) => {
      testOrder['createdTimeStamp'] = TmaUtilsStaticService.formatDate(
        testOrder['createdTimeStamp']
      );
    });

    this.testOrdersTableDataSource = new MatTableDataSource(
      this.testOrdersTableDataSource.data.concat(items)
    );

    this.filteredTestOrdersTableDataSource = new MatTableDataSource(
      this.testOrdersTableDataSource.data
    );
  }

  private resetTestRunExecutionsTableData(): void {
    this.testRunExecutionsTableDataSource = new MatTableDataSource<any>([]);

    this.filteredTestRunExecutionsTableDataSource = new MatTableDataSource<any>(
      []
    );
  }

  private renderTestRunExecutionsTable(items: TestRunExecutionFromDDB[]): void {
    if (items.length) {
      this.testRunExecutionsTableColumns = [
        ...TableConstant.testrunExecutionsTableColumns,
      ];
    }

    items.map((item) => {
      item['createdTimeStamp'] = TmaUtilsStaticService.formatDate(
        item['createdTimeStamp']
      );

      item.progress = '';

      if (item['processing-state']) {
        const succeedSum =
          item['processing-state'].repro.succeeded +
          item['processing-state'].transcoding.succeeded +
          item['processing-state'].matching.succeeded +
          item['processing-state'].repro.failed +
          item['processing-state'].transcoding.failed +
          item['processing-state'].matching.failed;

        const requestedSum =
          item['processing-state'].repro.requested +
          item['processing-state'].transcoding.requested +
          item['processing-state'].matching.requested;

        const progressPercentage = Math.round(
          100 * (succeedSum / requestedSum)
        );

        if (requestedSum !== 0) {
          item.progress = `${progressPercentage}%`;
        }
      }
    });

    this.testRunExecutionsTableDataSource = new MatTableDataSource(
      this.testRunExecutionsTableDataSource.data.concat(items)
    );

    this.filteredTestRunExecutionsTableDataSource = new MatTableDataSource(
      this.testRunExecutionsTableDataSource.data
    );
  }

  private renderKpiJobExecutionsTable(tcSessionId: string): void {
    this.kpiJobExecutionsTableSpinner = true;

    this.fetchKPIJobsExecutionsChunks(tcSessionId).subscribe(
      (response) => {
        const parsedTcSessionKpiChunks = this.parseProxyLambdaResolverResponse(
          response,
          'getKPIJobsExecutionsChunks'
        );

        this.fillTestRunExecutionKpiTableData(parsedTcSessionKpiChunks);

        this.kpiJobExecutionsTableSpinner = false;
      },
      () => {
        this.kpiJobExecutionsTableSpinner = false;
      }
    );
  }

  private fillTestRunExecutionKpiTableData(
    testRunExecutionKpiChunks: any[]
  ): void {
    const tableData = testRunExecutionKpiChunks;

    if (tableData.length) {
      this.kpiJobExecutionsColumns = [
        ...TableConstant.kpiJobsExecutionsColumns,
      ];
    }

    tableData.map((tcSessionKpi) => {
      tcSessionKpi.createdTimeStamp = TmaUtilsStaticService.formatDate(
        tcSessionKpi.createdTimeStamp
      );

      tcSessionKpi.updatedTimeStamp = TmaUtilsStaticService.formatDate(
        tcSessionKpi.updatedTimeStamp
      );
    });

    this.kpiJobExecutionsDataSource = new MatTableDataSource(tableData);

    this.filteredKpiJobExecutionsDataSource = new MatTableDataSource(
      this.kpiJobExecutionsDataSource.data
    );
  }

  private fetchKPIJobsExecutionsChunks(id: string): Observable<any> {
    return this.awsAmplifyApiService.graphQLQuery({
      query: GetKPIJobsExecutionsChunks,
      variables: {
        id,
      },
    });
  }

  private parseProxyLambdaResolverResponse(
    response: any,
    dataKey: string
  ): any[] {
    let parsedList = [];

    try {
      parsedList = JSON.parse(response.data[dataKey]);
    } catch (e) {
      this.snackBarService.open(
        `${UiMessagesConstant.appsyncResponseParsingErrorMessage + dataKey}`,
        SnackBarConstant.actions.dismiss,
        3000
      );
    }

    return parsedList;
  }

  private resetReproJobsTable(): void {
    this.reproceccingInfoDataSource = new MatTableDataSource<any>();
  }

  private resetKpiJobsTable(): void {
    this.kpiJobExecutionsDataSource = new MatTableDataSource<any>();

    this.filteredKpiJobExecutionsDataSource = new MatTableDataSource<any>();
  }

  constructor(
    public dialog: MatDialog,
    private store: Store<fromTestRunConfiguration.State>,
    private snackBarService: SnackBarService,
    private actions$: Actions<TestRunConfigurationActions>,
    private kpiService: KpiService,
    private awsAmplifyApiService: AwsAmplifyApiService,
    private fb: FormBuilder,
    private tmaUtilsService: TmaUtilsService
  ) {}

  protected readonly Object = Object;
  protected readonly ProjectsConstant = ProjectsConstant;
}
