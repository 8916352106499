<h2>Test suites</h2>
<div class="container mat-elevation-z8">
  <div class="loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="rate-limit-reached" *ngIf="isRateLimitReached">Empty</div>
  </div>

  <div class="table-container">
    <table mat-table [dataSource]="data" class="table">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let row">{{ row.name }}</td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let row">{{ row.description }}</td>
      </ng-container>

      <!-- Created Column -->
      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef>Created</th>
        <td mat-cell *matCellDef="let row">{{ row.created_at }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        [ngClass]="row.id === selectedTestSuiteId ? 'row-selected' : ''"
        (click)="onTestSuiteSelected($event, row)"
        mat-row
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>
  </div>

  <mat-paginator
    [length]="resultsLength"
    [pageSize]="10"
    aria-label="Select page of GitHub search results"
  ></mat-paginator>
</div>
