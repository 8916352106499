import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SnackBarConstant } from '@constants/snack-bar.constant';
import { UiMessagesConstant } from '@constants/ui-messages.constant';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';
import { DataCollectionApprovalClipsLinkage } from '@core/amplify/aws-apmlify-query.constant';
import { CsvParserService } from '@core/services/csv-parser.service';
import { SnackBarService } from '@core/services/snack-bar.service';

@Component({
  selector: 'app-approval-clips-uploader',
  templateUrl: './approval-clips-uploader.component.html',
  styleUrls: ['./approval-clips-uploader.component.scss'],
})
export class ApprovalClipsUploaderComponent {
  @ViewChild('fileUploadInput')
  fileUploadElementRef!: ElementRef;

  fileName = '';

  file: any = null;

  attachFileSuccessMessage = '';
  attachFileErrorMessage = '';
  resultsSuccessMessage = '';

  parsedSheetTableColumns: string[] = [];

  parsedSheetTableDataSource = new MatTableDataSource<ParsedTpaSheetItem>();

  filteredParsedSheetTableDataSource =
    new MatTableDataSource<ParsedTpaSheetItem>();

  dataCollectionApprovalClipsLinkageResponse: DataCollectionApprovalClipsLinkageResponse | null =
    null;

  onAttachFile(event: Event) {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    if (file) {
      this.file = file;

      this.fileName = this.file.name;

      this.csvParserService.tpaCsvParse(file).subscribe(
        (parsedApprovalsClips) => {
          if (this.validateCsvColumnsNames(parsedApprovalsClips[0])) {
            this.attachFileSuccessMessage =
              UiMessagesConstant.fileParsedSuccessMessage;

            this.renderParsedSheetTable(parsedApprovalsClips);
          } else {
            this.attachFileSuccessMessage = '';
            this.attachFileErrorMessage =
              UiMessagesConstant.normalDriveIdsToApprovalsClipsCsvInvalidColumnsMessage;
          }
        },
        (error) => {
          this.attachFileSuccessMessage = '';
          this.attachFileErrorMessage = `${UiMessagesConstant.fileParsedErrorMessage}: ${error.message}`;
        }
      );
    }
  }

  onResetFileData() {
    this.attachFileSuccessMessage = '';
    this.attachFileErrorMessage = '';

    this.resultsSuccessMessage = '';

    this.dataCollectionApprovalClipsLinkageResponse = null;

    this.deleteAttachedFile();
    this.resetParsedSheetTable();
  }

  onSubmit(): void {
    if (this.file) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const data = btoa(reader.result as string);

        this.awsAmplifyApiService
          .graphQLQuery({
            query: DataCollectionApprovalClipsLinkage,
            variables: {
              data,
            },
          })
          .subscribe(
            (response) => {
              try {
                this.dataCollectionApprovalClipsLinkageResponse = JSON.parse(
                  response.data.dataCollectionApprovalClipsLinkage
                );
              } catch (e) {
                this.snackBarService.open(
                  `dataCollectionApprovalClipsLinkageResponse JSON parsing error: ${e}`,
                  SnackBarConstant.actions.dismiss,
                  3000
                );
              }
            },
            (error) => {
              this.snackBarService.showGraphQlErrorMessage(error);
            }
          );
      };

      reader.readAsBinaryString(this.file);
    }
  }

  private deleteAttachedFile(): void {
    this.fileUploadElementRef.nativeElement.value = '';
    this.fileName = '';
  }

  private renderParsedSheetTable(parsedSheetItems: ParsedTpaSheetItem[]): void {
    this.parsedSheetTableColumns = Object.keys(parsedSheetItems[0] || {});

    this.parsedSheetTableDataSource =
      new MatTableDataSource<ParsedTpaSheetItem>(parsedSheetItems);

    this.filteredParsedSheetTableDataSource = new MatTableDataSource(
      this.parsedSheetTableDataSource.data
    );
  }

  private resetParsedSheetTable(): void {
    this.parsedSheetTableColumns = [];

    this.parsedSheetTableDataSource =
      new MatTableDataSource<ParsedTpaSheetItem>();

    this.filteredParsedSheetTableDataSource =
      new MatTableDataSource<ParsedTpaSheetItem>();
  }

  private validateCsvColumnsNames(
    parsedNormalDriveIdsToApprovalClipsSheetItem: ParsedNormalDriveIdsToApprovalClipsSheetItem
  ): boolean {
    return !(
      parsedNormalDriveIdsToApprovalClipsSheetItem.approval_drive_id ===
        undefined ||
      parsedNormalDriveIdsToApprovalClipsSheetItem.datacollection_drive_id ===
        undefined
    );
  }

  constructor(
    private csvParserService: CsvParserService,
    private awsAmplifyApiService: AwsAmplifyApiService,
    private snackBarService: SnackBarService
  ) {}
}
