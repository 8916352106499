import { Injectable } from '@angular/core';
import { SaveTestOrderPayload, SaveTestOrderResponse } from '@model/payloads';
import { Observable } from 'rxjs';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';

@Injectable({
  providedIn: 'root',
})
export class TestOrderService {
  constructor(private graphqlApiService: AwsAmplifyApiService) {}

  saveTestOrder(
    saveTestOrderPayload: SaveTestOrderPayload
  ): Observable<SaveTestOrderResponse> {
    return this.graphqlApiService.graphQLQuery(saveTestOrderPayload);
  }
}
