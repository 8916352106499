export const UiMessagesConstant = {
  startTestrunExecutionSuccessMessage:
    'Job has been started successfully. Click sync button to see updated reprocessing statuses',
  retriggerTestrunExecutionSuccessMessage:
    "Job has been retriggered successfully. Click sync button and start new job you've retriggered",
  startTestrunExecutionErrorMessage: 'Job has not been started, check inputs',
  retriggerTestrunExecutionErrorMessage:
    'Job has not been retriggered, check inputs',
  startSaveKpiTriggerSuccessMessage: 'KPI script has been started successfully',
  startSaveKpiTriggerErrorMessage:
    'KPI script has not been started, check inputs',
  onInnovizHilSwExchangeStartedSuccessMessage: 'Exchanges have been started',
  onInnovizHilSwExchangedSuccessMessage:
    'Exchanges have been exchanged successfully, click sync button to fetch updated data',
  onInnovizHilSwExchangeStartedErrorMessage:
    'Exchanges have not been started, check inputs',
  fileParsedSuccessMessage:
    'File has been parsed successfully, check results below',
  fileParsedErrorMessage: 'Error during parsing .csv file',
  csvInvalidColumnsMessage:
    'Uploaded file has wrong column names! Please use this format: rec_group | type | drive_id | splits | hil_group. Also first string should have mandatory type: delete_clip',
  tpaReproResultsSuccessMessage:
    'TPA groups have been submitted successfully, check results below',
  tpaReproResultsErrorMessage: 'Error of submitting TPA groups',
  setDriveIdApprovalsSuccessMessage: 'Approvals have been set successfully',
  splitsFormatIsInvalid:
    'Splits are mandatory for delete_slot and reference items types. Splits should be in JSON format. Even if empty use: []',
  KPIScriptsParsingErrorMessage:
    'Parsing error of KPI scripts in selected configuration',
  ReprocessingStatusesParsingErrorMessage:
    'Parsing error of reprocessing statuses in selected configuration',
  KpiOdConfigParsingErrorMessage:
    'Matching configuration file parsing error. Should be JSON format',
  appsyncResponseParsingErrorMessage: 'Parsing error of: ',
  testRunConfigurationPayloadError:
    'Chosen Test sessions or Repro config has JSON parsing error',
  mfk120ApprovalsForApprovalClipSubmitSuccessMessage:
    'Approvals have been sent successfully. Refresh approval clips table data',
  mfk120ApprovalsForNormalIdSubmitSuccessMessage:
    'Approvals have been sent successfully. Refresh normal drive ids table data',
  normalDriveIdsToApprovalsClipsCsvInvalidColumnsMessage:
    'Uploaded file has wrong column names! Please use this format: approval_drive_id | datacollection_drive_id',
  mfk120ValidationJSONParsingError:
    'validation response data JSON parsing error',
};
