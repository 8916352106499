import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTestRunConfiguration from './test-run-configuration.reducer';

export const selectTestRunConfigurationState =
  createFeatureSelector<fromTestRunConfiguration.State>(
    fromTestRunConfiguration.testRunConfigurationFeatureKey
  );

export const selectFeatureSemanticSearchData = createSelector(
  selectTestRunConfigurationState,
  (state: fromTestRunConfiguration.State) => state.semanticSearchData
);

export const selectFeatureQueryVrctData = createSelector(
  selectTestRunConfigurationState,
  (state: fromTestRunConfiguration.State) => state.queryVrctData
);

export const selectFeatureTesSessionPlayListData = createSelector(
  selectTestRunConfigurationState,
  (state: fromTestRunConfiguration.State) => state.testSessionPlayList
);

export const selectFeatureRmTestRunConfigurations = createSelector(
  selectTestRunConfigurationState,
  (state: fromTestRunConfiguration.State) => state.rmTestRunConfigurations
);

export const selectFeatureReproceccingConfigLabels = createSelector(
  selectTestRunConfigurationState,
  (state: fromTestRunConfiguration.State) => state.reproceccingConfigLabels
);

export const selectFeatureReproceccingConfigurations = createSelector(
  selectTestRunConfigurationState,
  (state: fromTestRunConfiguration.State) => state.reproceccingConfigurations
);

export const selectFeatureTestSessions = createSelector(
  selectTestRunConfigurationState,
  (state: fromTestRunConfiguration.State) => state.testSessions
);

export const selectFeatureTestrunExecutions = createSelector(
  selectTestRunConfigurationState,
  (state: fromTestRunConfiguration.State) => state.testrunExecutions
);

export const selectFeatureKpiMeta = createSelector(
  selectTestRunConfigurationState,
  (state: fromTestRunConfiguration.State) => state.kpiMeta
);

export const selectFeatureKpiProjects = createSelector(
  selectTestRunConfigurationState,
  (state: fromTestRunConfiguration.State) => state.kpiProjects
);

export const selectFeatureKpiTestsuites = createSelector(
  selectTestRunConfigurationState,
  (state: fromTestRunConfiguration.State) => state.kpiTestsuites
);

export const selectFeatureKpiScriptsConfigurations = createSelector(
  selectTestRunConfigurationState,
  (state: fromTestRunConfiguration.State) => state.kpiScriptsConfigurations
);

export const selectFeatureReprocessingInfo = createSelector(
  selectTestRunConfigurationState,
  (state: fromTestRunConfiguration.State) => state.reprocessingInfo
);
