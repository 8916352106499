import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatLegacySelectChange } from '@angular/material/legacy-select';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SnackBarConstant } from '@constants/snack-bar.constant';
import { TableConstant } from '@constants/table.constant';
import { UiMessagesConstant } from '@constants/ui-messages.constant';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';
import {
  GetMfk120AggregatedValidation,
  GetMfk120AggregatedValidationSplits,
} from '@core/amplify/aws-apmlify-query.constant';
import { S3Service } from '@core/services/s3.service';
import { SnackBarService } from '@core/services/snack-bar.service';
import { TmaUtilsStaticService } from '@core/services/tma-utils-static.service';
import { GraphQLQueryError } from '@model/payloads';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-l-validation',
  templateUrl: './l-validation.component.html',
  styleUrls: ['./l-validation.component.scss'],
})
export class LValidationComponent implements OnChanges {
  @Input() validationLevel: string;
  @Input() driveId: string;

  unsorted = TmaUtilsStaticService.unsortedKeyValuePipeCompareFn;

  splitValidationSummary: any | Split_Validation_Summary = {};
  reportLink: string | null = null;
  itemsLimit = 100;

  useCases: Use_Case[] = [];
  useCaseSelectedId = '';
  useCaseSplitValidationSummary: UseCaseSummary | null = null;

  splitsTableColumns: string[] = [];
  splitsTableDataSource = new MatTableDataSource<any>([]);
  filteredSplitsTableDataSource = new MatTableDataSource<any>([]);
  splitsTableSpinner = false;
  splitsTableNextToken: string | null = null;

  splitDetailsTableColumns: string[] = [];
  splitDetailsTableDataSource = new MatTableDataSource<any>([]);
  filteredSplitDetailsTableSource = new MatTableDataSource<any>([]);

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.driveId.currentValue.length &&
      changes.driveId.previousValue !== changes.driveId.currentValue
    ) {
      this.useCaseSelectedId = '';

      this.fetchValidationResult(
        changes.driveId.currentValue,
        this.validationLevel
      ).subscribe(
        (validationResult) => {
          try {
            const validationData: ValidationResultData = JSON.parse(
              validationResult.data.getMfk120AggregatedValidation
            );

            this.useCases = validationData?.Use_Cases;

            this.splitValidationSummary =
              validationData?.Split_Validation_Summary || {};

            if (this.useCases?.length) {
              this.setUseCase(this.useCases[0].UseCaseId);
            }
          } catch (e) {
            this.snackBarService.open(
              `${this.validationLevel} ${UiMessagesConstant.mfk120ValidationJSONParsingError}: ${e}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
          }
        },
        (error) => {
          this.snackBarService.showGraphQlErrorMessage(error);
        }
      );
    } else if (!changes.driveId.currentValue.length) {
      this.useCases = [];

      this.useCaseSelectedId = '';
    }
  }

  onUseCaseSelect(event: MatLegacySelectChange): void {
    this.setUseCase(event.value);
  }

  onLoadInitSplits(): void {
    this.splitsTableSpinner = true;

    this.resetSplitsTable();

    this.resetSplitDetailsTable();

    this.fetchValidationSplits(
      this.driveId,
      `${this.validationLevel}#${this.useCaseSelectedId}`,
      this.itemsLimit,
      null,
      null
    ).subscribe(
      (response) => {
        this.splitsTableNextToken =
          response.data.getMfk120AggregatedValidationSplits.nextToken;

        try {
          this.renderSplitsTable(
            JSON.parse(response.data.getMfk120AggregatedValidationSplits.items)
          );
        } catch (e) {
          this.snackBarService.open(
            `getMfk120AggregatedValidationSplits JSON parsing error: ${e}`,
            SnackBarConstant.actions.dismiss,
            3000
          );
        }

        this.splitsTableSpinner = false;
      },
      (error: GraphQLQueryError) => {
        this.snackBarService.showGraphQlErrorMessage(error);

        this.splitsTableSpinner = false;
      }
    );
  }

  onLoadMoreSplits(): void {
    if (this.splitsTableNextToken) {
      this.splitsTableSpinner = true;

      this.fetchValidationSplits(
        this.driveId,
        `${this.validationLevel}#${this.useCaseSelectedId}`,
        this.itemsLimit,
        this.splitsTableNextToken,
        null
      ).subscribe(
        (response) => {
          this.splitsTableNextToken =
            response.data.getMfk120AggregatedValidationSplits.nextToken;

          try {
            this.renderSplitsTable(
              JSON.parse(
                response.data.getMfk120AggregatedValidationSplits.items
              )
            );
          } catch (e) {
            this.snackBarService.open(
              `getMfk120AggregatedValidationSplits JSON parsing error: ${e}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
          }

          this.splitsTableSpinner = false;
        },
        (error: GraphQLQueryError) => {
          this.snackBarService.showGraphQlErrorMessage(error);

          this.splitsTableSpinner = false;
        }
      );
    }
  }

  onSearchBySplitIndex($event: Event): void {
    this.resetSplitsTable();

    const split_id = (event?.target as HTMLInputElement).value;

    if (split_id?.length) {
      this.splitsTableSpinner = true;

      this.fetchValidationSplits(
        this.driveId,
        `${this.validationLevel}#${this.useCaseSelectedId}#${split_id}`,
        this.itemsLimit,
        null,
        null
      ).subscribe(
        (response) => {
          this.splitsTableNextToken =
            response.data.getMfk120AggregatedValidationSplits.nextToken;

          try {
            this.renderSplitsTable(
              JSON.parse(
                response.data.getMfk120AggregatedValidationSplits.items
              )
            );
          } catch (e) {
            this.snackBarService.open(
              `getMfk120AggregatedValidationSplits JSON parsing error: ${e}`,
              SnackBarConstant.actions.dismiss,
              3000
            );
          }

          this.splitsTableSpinner = false;
        },
        (error: GraphQLQueryError) => {
          this.snackBarService.showGraphQlErrorMessage(error);

          this.splitsTableSpinner = false;
        }
      );
    } else {
      this.onLoadInitSplits();
    }
  }

  onSplitsTableItemSelected(selected: any) {
    this.resetSplitDetailsTable();

    this.renderSplitDetailsTable(selected.Validation_Breakdown);
  }

  onSplitsFilterChange($event: MatButtonToggleChange) {
    this.resetSplitsTable();
    this.resetSplitDetailsTable();

    this.splitsTableSpinner = true;

    const filter =
      $event.value === 'all'
        ? null
        : {
            Split_Validity: {
              eq: $event.value,
            },
          };

    this.fetchValidationSplits(
      this.driveId,
      `${this.validationLevel}#${this.useCaseSelectedId}`,
      this.itemsLimit,
      null,
      filter
    ).subscribe(
      (response) => {
        this.splitsTableNextToken =
          response.data.getMfk120AggregatedValidationSplits.nextToken;

        try {
          this.renderSplitsTable(
            JSON.parse(response.data.getMfk120AggregatedValidationSplits.items)
          );
        } catch (e) {
          this.snackBarService.open(
            `getMfk120AggregatedValidationSplits JSON parsing error: ${e}`,
            SnackBarConstant.actions.dismiss,
            3000
          );
        }

        this.splitsTableSpinner = false;
      },
      (error: GraphQLQueryError) => {
        this.snackBarService.showGraphQlErrorMessage(error);

        this.splitsTableSpinner = false;
      }
    );
  }

  setUseCase(useCaseSelectedId: string): void {
    const useCaseSplitValidationSummary =
      this.splitValidationSummary[useCaseSelectedId] || null;

    this.s3Service
      .downloadS3File(
        useCaseSplitValidationSummary?.report?.s3_bucket,
        useCaseSplitValidationSummary?.report?.s3_key
      )
      .then((link) => {
        this.reportLink = link;
      });

    this.useCaseSplitValidationSummary = useCaseSplitValidationSummary;

    this.useCaseSelectedId = useCaseSelectedId;

    this.onLoadInitSplits();
  }

  private fetchValidationResult(
    drive_id: string,
    validation_id: string
  ): Observable<any> {
    return this.awsAmplifyApiService.graphQLQuery({
      query: GetMfk120AggregatedValidation,
      variables: {
        drive_id,
        validation_id,
      },
    });
  }

  private fetchValidationSplits(
    drive_id: string,
    validation_id: string,
    limit: number,
    nextToken: string | null,
    filter: object | null
  ): Observable<any> {
    return this.awsAmplifyApiService.graphQLQuery({
      query: GetMfk120AggregatedValidationSplits,
      variables: {
        drive_id,
        validation_id,
        limit,
        nextToken,
        filter,
      },
    });
  }

  private renderSplitsTable(splits: any[]): void {
    if (splits.length) {
      this.splitsTableColumns = [...TableConstant.splitsTableColumns];
    }

    this.splitsTableDataSource = new MatTableDataSource(
      this.splitsTableDataSource.data.concat(splits)
    );

    this.filteredSplitsTableDataSource = new MatTableDataSource(
      this.splitsTableDataSource.data
    );
  }

  private resetSplitsTable(): void {
    this.splitsTableDataSource = new MatTableDataSource<any>([]);

    this.filteredSplitsTableDataSource = new MatTableDataSource<any>([]);
  }

  private renderSplitDetailsTable(splits: any[]): void {
    if (splits.length) {
      this.splitDetailsTableColumns = [...TableConstant.splitDetailsColumns];
    }

    this.splitDetailsTableDataSource = new MatTableDataSource(
      this.splitDetailsTableDataSource.data.concat(splits)
    );

    this.filteredSplitDetailsTableSource = new MatTableDataSource(
      this.splitDetailsTableDataSource.data
    );
  }

  private resetSplitDetailsTable(): void {
    this.splitDetailsTableDataSource = new MatTableDataSource<any>([]);

    this.filteredSplitDetailsTableSource = new MatTableDataSource<any>([]);
  }

  constructor(
    private s3Service: S3Service,
    private awsAmplifyApiService: AwsAmplifyApiService,
    private snackBarService: SnackBarService
  ) {}
}
