import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormConstant } from '@constants/form.constant';
import { ProjectsConstant } from '@constants/projects.constant';
import { BreakpointGridColumnsService } from '@core/services/breakpoint-grid-columns.service';
import { TmaUtilsStaticService } from '@core/services/tma-utils-static.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  @Input() formGroup = new UntypedFormGroup({});
  @Input() formTitle = '';
  @Input() cols: number | null = null;
  @Input() rowHeight = '5em';
  @Input() formControlsMetadata: FormControlsMetadata = {};
  @Input() formControlsConfig: FormControlsConfig = {};
  @Input() actionName: string | null = null;
  @Input() isFormActionsVisible: boolean = true;
  @Input() currentProjectName: string = '';
  @Input() isSubmitButtonDisabled: boolean = false;
  @Output() submitted: EventEmitter<FormControlsConfig> = new EventEmitter();
  @Output() formValueChanged: EventEmitter<FormControlsConfig> =
    new EventEmitter();
  @Output() actionTriggered: EventEmitter<FormControlsConfig> =
    new EventEmitter();

  unsorted = TmaUtilsStaticService.unsortedKeyValuePipeCompareFn;

  cols$: Observable<number> = this.breakpointGridColumnsService.cols$;

  inputTypes = FormConstant.inputTypes;

  action() {
    this.actionTriggered.emit();
  }

  ngOnInit() {
    for (const formControlName in this.formGroup.controls) {
      this.formGroup.controls[formControlName].valueChanges.subscribe(
        (value) => {
          if (
            this.formControlsMetadata[formControlName].formControlName ===
            FormConstant.formControlsNames.ReProcessingType
          ) {
            if (value === FormConstant.formControlsValues.mik_sil) {
              this.formGroup.get(FormConstant.formControlsNames.HIL)?.disable();
            } else {
              this.formGroup.get(FormConstant.formControlsNames.HIL)?.enable();
            }
          }

          if (
            this.formControlsMetadata[formControlName].formControlName ===
            FormConstant.formControlsNames.ProjectID
          ) {
            if (value === ProjectsConstant.MFK120.id) {
              this.formGroup
                .get(FormConstant.formControlsNames.PlayListLearning)
                ?.enable();
            } else {
              this.formGroup
                .get(FormConstant.formControlsNames.PlayListLearning)
                ?.disable();
            }
          }

          if (
            this.formControlsMetadata[formControlName].inputType ===
            FormConstant.inputTypes.autocomplete
          ) {
            this.formControlsMetadata[
              formControlName
            ].options.filteredInputOptions = this.filter(
              value,
              formControlName
            );
          }
        }
      );
    }

    this.formGroup.valueChanges.subscribe(
      (formControlsConfig: FormControlsConfig) => {
        this.formValueChanged.emit(formControlsConfig);
      }
    );
  }

  submit(): void {
    this.submitted.emit(this.formGroup.value);
  }

  private filter(
    value: string,
    formControlName: string
  ): inputOption[] | undefined {
    const filterValue = value.toLowerCase();

    return this.formControlsMetadata[
      formControlName
    ].options.inputOptions?.filter((inputOption) => {
      return inputOption.value.toLowerCase().includes(filterValue);
    });
  }

  constructor(
    private breakpointGridColumnsService: BreakpointGridColumnsService
  ) {}
}
