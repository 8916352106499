import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';
import {
  GetPipelineDataPayload,
  GetPipelineDataResponse,
  LoadInnovizHilSwExchangesPayload,
  LoadInnovizHilSwExchangesResponse,
  QueryInnovizHilSwExchangesByVersionPayload,
  QueryInnovizHilSwExchangesByVersionResponse,
} from '@model/payloads';
import { GraphQLOptions } from '@aws-amplify/api-graphql';

@Injectable({
  providedIn: 'root',
})
export class DynamoDbService {
  constructor(private graphqlApiService: AwsAmplifyApiService) {}

  getPipeline(
    getPipelineDataPayload: GetPipelineDataPayload
  ): Observable<GetPipelineDataResponse> {
    return this.graphqlApiService.graphQLQuery(getPipelineDataPayload);
  }

  loadInnovizHilSwExchanges(
    loadInnovizHilSwExchangesPayload: LoadInnovizHilSwExchangesPayload
  ): Observable<LoadInnovizHilSwExchangesResponse> {
    return this.graphqlApiService.graphQLQuery(
      loadInnovizHilSwExchangesPayload
    );
  }

  queryInnovizHilSwExchangesByVersion(
    queryInnovizHilSwExchangesByVersionQueryPayload: QueryInnovizHilSwExchangesByVersionPayload
  ): Observable<QueryInnovizHilSwExchangesByVersionResponse> {
    return this.graphqlApiService.graphQLQuery(
      queryInnovizHilSwExchangesByVersionQueryPayload
    );
  }

  graphQLQuery(payload: GraphQLOptions): Observable<any> {
    return this.graphqlApiService.graphQLQuery(payload);
  }
}
