<mat-card>
  <mat-card-content>
    <form
      [formGroup]="createRmTestRunConfigurationForm"
      novalidate
      (ngSubmit)="onSubmit()"
    >
      <div class="row" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="fill" fxFlex="33">
          <mat-label>Label</mat-label>
          <input matInput placeholder="Label" formControlName="label" />
          <mat-error
            *ngIf="
              createRmTestRunConfigurationForm.controls.label.hasError(
                'required'
              )
            "
          >
            Field is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Vehicle type</mat-label>
          <mat-select formControlName="vehicle_type">
            <mat-option
              *ngFor="
                let rmTestRunConfigurationVehicleType of rmTestRunConfigurationVehicleTypes
              "
              [value]="rmTestRunConfigurationVehicleType"
              >{{ rmTestRunConfigurationVehicleType }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              createRmTestRunConfigurationForm.controls.vehicle_type.hasError(
                'required'
              )
            "
          >
            Field is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="fill" fxFlex="20.5">
          <mat-label>DUT</mat-label>
          <input matInput placeholder="DUT" formControlName="dut_version" />
          <mat-error
            *ngIf="
              createRmTestRunConfigurationForm.controls.dut_version.hasError(
                'required'
              )
            "
          >
            Field is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>RBS</mat-label>
          <input matInput placeholder="RBS" formControlName="rbs_version" />
          <mat-error
            *ngIf="
              createRmTestRunConfigurationForm.controls.rbs_version.hasError(
                'required'
              )
            "
          >
            Field is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Bordnet</mat-label>
          <input
            matInput
            placeholder="Bordnet"
            formControlName="bordnet_version"
          />
          <mat-error
            *ngIf="
              createRmTestRunConfigurationForm.controls.bordnet_version.hasError(
                'required'
              )
            "
          >
            Field is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="fill" fxFlex="10">
          <mat-label>SIL</mat-label>
          <input matInput placeholder="SIL" formControlName="sil_sw_version" />
          <mat-error
            *ngIf="
              createRmTestRunConfigurationForm.controls.sil_sw_version.hasError(
                'required'
              )
            "
          >
            Field is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" fxFlex="10">
          <mat-label>HIL</mat-label>
          <input matInput placeholder="HIL" formControlName="hil_sw_version" />
          <mat-error
            *ngIf="
              createRmTestRunConfigurationForm.controls.hil_sw_version.hasError(
                'required'
              )
            "
          >
            Field is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" fxFlex="12">
          <mat-label>ECU Init</mat-label>
          <input
            matInput
            placeholder="ECU Init"
            formControlName="ecu_init_version"
          />
          <mat-error
            *ngIf="
              createRmTestRunConfigurationForm.controls.ecu_init_version.hasError(
                'required'
              )
            "
          >
            Field is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="fill" fxFlex="22.5">
          <mat-label>ECU Init String version</mat-label>
          <input
            matInput
            placeholder="ECU Init String version"
            formControlName="ecu_init_string_version"
          />
          <mat-error
            *ngIf="
              createRmTestRunConfigurationForm.controls.ecu_init_string_version.hasError(
                'required'
              )
            "
          >
            Field is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" fxFlex="22.5">
          <mat-label>Config Update version</mat-label>
          <input
            matInput
            placeholder="Config Update version"
            formControlName="configupdate_version"
          />
          <mat-error
            *ngIf="
              createRmTestRunConfigurationForm.controls.configupdate_version.hasError(
                'required'
              )
            "
          >
            Field is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <mat-card-actions class="form-buttons-group">
        <button
          mat-raised-button
          color="primary"
          type="button"
          matTooltip="Reset form"
          matTooltipPosition="below"
          (click)="onRefreshData()"
        >
          <mat-icon>refresh</mat-icon>
        </button>

        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="!createRmTestRunConfigurationForm.valid"
        >
          {{
            createRmTestRunConfigurationForm.controls.id.value
              ? 'Update'
              : 'Create'
          }}
        </button>
      </mat-card-actions>
    </form>

    <app-table
      class="trc-accordion_trc-panel_trs-table"
      [displayedColumns]="rmTestRunConfigurationsTableColumns"
      [dataSource]="filteredRmTestRunConfigurationsTableDataSource"
      [multipleSelections]="false"
      [selection]="rmTestRunConfigurationsTableSelection"
      [spinner]="rmTestRunConfigurationsTableSpinner"
      [sortKey]="'id'"
      [isSortDisabled]="true"
      (tableItemSelected)="onRmTestRunConfigurationItemSelected($event)"
    ></app-table>
  </mat-card-content>

  <mat-card-actions fxLayout="row" fxLayoutAlign="start center">
    <button
      mat-raised-button
      color="primary"
      type="button"
      matTooltip="Refresh table"
      matTooltipPosition="below"
      (click)="onRefreshData()"
    >
      <mat-icon>refresh</mat-icon>
    </button>

    <button
      mat-raised-button
      color="primary"
      type="button"
      matTooltipPosition="below"
      (click)="deleteRmTestRunConfiguration()"
      [disabled]="!createRmTestRunConfigurationForm.valid"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
