import { NgModule } from '@angular/core';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';
import { AuthService } from '@core/services/auth.service';
import { SemanticSearchService } from '@core/services/semantic-search.service';
import { SnackBarService } from './services/snack-bar.service';
import { TestSessionService } from './services/test-session.service';
import { TmaUtilsStaticService } from './services/tma-utils-static.service';

@NgModule({
  providers: [
    AwsAmplifyApiService,
    AuthService,
    TmaUtilsStaticService,
    SemanticSearchService,
    SnackBarService,
    TestSessionService,
  ],
})
export class CoreModule {}
