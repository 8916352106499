import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const isCommaSeparatorValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  let isCommaSeparatorValidator: { isCommaSeparator: boolean } | null = null;

  if (control.value) {
    const split = control.value.toLowerCase().split(',');

    if (split.length === 1) {
      isCommaSeparatorValidator = { isCommaSeparator: true };
    } else {
      isCommaSeparatorValidator = null;
    }
  }

  return isCommaSeparatorValidator;
};
