<div fxLayout="column" fxLayoutAlign="space-between center">
  <mat-card>
    <mat-card-header fxLayout="column" fxLayoutAlign="space-between center">
      <mat-card-title>DMR - HOME</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column" fxLayoutAlign="space-between center">
        <mat-divider></mat-divider>
      </div>
      <app-vendors [magnaVendors]="magnaVendors"></app-vendors>
    </mat-card-content>
  </mat-card>
</div>
