import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SnackBarConstant } from '@constants/snack-bar.constant';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';
import { CariadInbound } from '@core/amplify/aws-apmlify-query.constant';
import { SnackBarService } from '@core/services/snack-bar.service';
import { GraphQLQueryError } from '@model/payloads';
import { isCommaSeparatorValidator } from '@validators/is-comma-separator.validator';

@Component({
  selector: 'app-cariad-form',
  templateUrl: './cariad-form.component.html',
  styleUrls: ['./cariad-form.component.scss'],
})
export class CariadFormComponent {
  cariadExchangeForm = this.fb.group({
    target_folder_name: ['', [Validators.required]],
    download_links: ['', [Validators.required, isCommaSeparatorValidator]],
    extract_zip_files: false,
    delete_after_unzip: false,
    unzip_only: false,
    comment: ['', [Validators.required]],
  });

  onSubmit(): void {
    const variables: any = { ...this.cariadExchangeForm.getRawValue() };

    variables.download_links = variables.download_links
      .split(',')
      .map((link: string) => link.trim());

    this.awsAmplifyApiService
      .graphQLQuery({
        query: CariadInbound,
        variables,
      })
      .subscribe(
        (response) => {
          this.snackBarService.open(
            `Files have been successfully downloaded: ${response.data.cariadInbound.num_of_files_to_download}`,
            SnackBarConstant.actions.dismiss,
            3000
          );
        },
        (error: GraphQLQueryError) => {
          const messages: string = error.errors
            .map((error) => error.message)
            .join();

          this.snackBarService.open(
            `Error of downloading files: ${messages}`,
            SnackBarConstant.actions.dismiss,
            3000
          );
        }
      );
  }

  constructor(
    private fb: FormBuilder,
    private snackBarService: SnackBarService,
    private awsAmplifyApiService: AwsAmplifyApiService
  ) {}
}
