export const FormConstant = {
  actions: {
    start: 'Start',
    retrigger: 'Retrigger',
  },
  inputTypes: {
    input: 'input',
    autocomplete: 'autocomplete',
    checkbox: 'checkbox',
    radio: 'radio',
    textarea: 'textarea',
    select: 'select',
  },
  formControlsNames: {
    ProductName: 'ProductName',
    ProjectID: 'ProjectID',
    PlayListLearning: 'PlayListLearning',
    ReProcessingType: 'ReProcessingType',
    HIL: 'HIL',
  },
  formControlsValues: {
    mik_sil: 'mik_sil',
  },
};
