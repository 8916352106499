import { environment } from '@environments/environment';
import * as fromDataBrowser from '@modules/data-browser/store/data-browser.reducer';
import * as fromInnoviz from '@modules/innoviz/store/innoviz.reducer';
import * as fromPlaylist from '@modules/playlists/store/playlist.reducer';

import * as fromTestRunConfiguration from '@modules/test-run-configuration/store/test-run-configuration.reducer';
import * as fromVinsApprovals from '@modules/vins-approvals/store/vins-approvals.reducer';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';

export interface State {
  testRunConfiguration: fromTestRunConfiguration.State;
  dataBrowser: fromDataBrowser.State;
  innoviz: fromInnoviz.State;
  vinsApprovals: fromVinsApprovals.State;
  playlist: fromPlaylist.State;
}

export const reducers: ActionReducerMap<State, any> = {
  testRunConfiguration: fromTestRunConfiguration.reducer,
  dataBrowser: fromDataBrowser.reducer,
  innoviz: fromInnoviz.reducer,
  vinsApprovals: fromVinsApprovals.reducer,
  playlist: fromPlaylist.reducer,
};

export function logger(
  reducer: ActionReducer<State, any>
): ActionReducer<State, any> {
  return (state: any, action: any): State => {
    if (!environment.production) {
      console.info('action', action);
      console.info('state', state);
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State, any>[] = [logger];
