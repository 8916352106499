import { createAction, props } from '@ngrx/store';

export const loadPlaylists = createAction('[Playlist] Load Playlists');

export const loadPlaylistsSuccess = createAction(
  '[Playlist] Load Playlists Success',
  props<{ data: any }>()
);

export const loadPlaylistsFailure = createAction(
  '[Playlist] Load Playlists Failure',
  props<{ error: any }>()
);

export const loadPlaylist = createAction(
  '[Playlist] Load Playlist',
  props<GetPlaylistRequestVariables>()
);

export const loadPlaylistSuccess = createAction(
  '[Playlist] Load Playlist Success',
  props<{ data: GetPlaylistResponse }>()
);

export const loadPlaylistFailure = createAction(
  '[Playlist] Load Playlist Failure',
  props<{ error: any }>()
);

export const loadDriveIds = createAction(
  '[Playlist] Load Drive Ids',
  props<GetPlaylistRequestVariables>()
);

export const loadDriveIdsSuccess = createAction(
  '[Playlist] Load Drive Ids Success',
  props<{ data: GetPlaylistResponse }>()
);

export const loadDriveIdsFailure = createAction(
  '[Playlist] Load Drive Ids Failure',
  props<{ error: any }>()
);
