<div fxLayout="row" fxLayoutAlign="start center">
  <mat-form-field appearance="fill" *ngIf="showFilter" fxFlex="20">
    <mat-label>Filter</mat-label>
    <input
      #filter
      matInput
      (keyup)="applyFilter($event)"
      [placeholder]="'Ex.' + filterPlaceholder"
      [matTooltip]="filterTooltip"
      matTooltipPosition="below"
    />
  </mat-form-field>

  <ng-container *ngIf="showExactFilterCheckbox">
    <mat-checkbox class="exactFilter" [(ngModel)]="exactFilter"
      >Exact filter matching</mat-checkbox
    >
  </ng-container>
</div>

<div class="mat-elevation-z8 table-container">
  <div
    class="spinner"
    *ngIf="spinner"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <mat-spinner></mat-spinner>
  </div>

  <table
    class="main-table"
    mat-table
    [dataSource]="dataSource"
    multiTemplateDataRows
    matSort
    [matSortDisabled]="isSortDisabled"
    [matSortActive]="sortKey"
    [matSortDirection]="'desc'"
  >
    <ng-container matColumnDef="select">
      <th class="select" mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          *ngIf="showAllSelectionsCheckbox"
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td class="select" mat-cell *matCellDef="let row">
        <mat-checkbox
          *ngIf="multipleSelections"
          (click)="$event.stopPropagation()"
          (change)="checkboxRowSelected($event, row)"
          [value]="row"
          [checked]="checked(row)"
        ></mat-checkbox>
        <mat-radio-group [value]="selectedRadioElement">
          <mat-radio-button
            *ngIf="!multipleSelections"
            (click)="$event.stopPropagation()"
            (change)="radioRowSelected($event, row)"
            [value]="row"
          ></mat-radio-button>
        </mat-radio-group>
      </td>
    </ng-container>

    <ng-container
      [matColumnDef]="column"
      *ngFor="let column of displayedColumns"
    >
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ column | uppercase }}
      </th>
      <td mat-cell *matCellDef="let element">
        <ng-container [ngSwitch]="isBoolean(element[column])">
          <ng-container *ngSwitchCase="false">
            <ng-container [ngSwitch]="element[column]">
              <span
                *ngSwitchCase="vinsApprovalsConstant.statuses.approved.value"
              >
                <mat-icon class="green-icon">check_circle</mat-icon>
              </span>
              <span
                *ngSwitchCase="vinsApprovalsConstant.statuses.rejected.value"
              >
                <mat-icon class="red-icon">cancel</mat-icon>
              </span>
              <span
                *ngSwitchCase="vinsApprovalsConstant.statuses.notSet.value"
              ></span>
              <span *ngSwitchDefault>
                <ng-container [ngSwitch]="column">
                  <span
                    *ngSwitchCase="
                      trainedParkingAssistConstant.columns
                        .csvValidationResultsTable.columnsMap.validation_errors
                        .name
                    "
                  >
                    <mat-error
                      ><strong>{{ element[column] }}</strong></mat-error
                    >
                  </span>
                  <span *ngSwitchDefault>{{ element[column] }}</span>
                </ng-container>
              </span>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="true">
            <ng-container [ngSwitch]="element[column]">
              <mat-icon class="green-icon" *ngSwitchCase="true"
                >check_circle</mat-icon
              >
              <mat-icon class="red-icon" *ngSwitchCase="false">cancel</mat-icon>
            </ng-container>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="expand">
      <th
        mat-header-cell
        class="expand"
        *matHeaderCellDef
        aria-label="row actions"
      ></th>
      <td mat-cell class="expand" *matCellDef="let element">
        <button
          mat-icon-button
          aria-label="expand row"
          (click)="
            expandedElement = expandedElement === element ? null : element;
            $event.stopPropagation();
            $event.preventDefault()
          "
        >
          <mat-icon *ngIf="expandedElement !== element"
            >keyboard_arrow_down</mat-icon
          >
          <mat-icon *ngIf="expandedElement === element"
            >keyboard_arrow_up</mat-icon
          >
        </button>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <div matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="displayedColumnsWithExpand.length"
      >
        <div
          class="element-detail"
          [@detailExpand]="
            element === expandedElement ? 'expanded' : 'collapsed'
          "
        >
          <div class="table-container">
            <table
              class="details-table"
              mat-table
              [dataSource]="detailsDataSource"
            >
              <ng-container matColumnDef="property">
                <th mat-header-cell *matHeaderCellDef>PROPERTY</th>
                <td mat-cell *matCellDef="let row">{{ row.property }}:</td>
              </ng-container>
              <ng-container matColumnDef="value" [sticky]="true">
                <th mat-header-cell *matHeaderCellDef>VALUE</th>
                <td mat-cell *matCellDef="let row">
                  <ng-container [ngSwitch]="row.property">
                    <ng-container
                      *ngSwitchCase="
                        tableConstant.jsonPipeProperties.GT_settings
                      "
                      >{{ row.value | json }}</ng-container
                    >
                    <ng-container
                      *ngSwitchCase="
                        tableConstant.jsonPipeProperties.original_tc_paths
                      "
                      >{{ row.value | json }}</ng-container
                    >
                    <ng-container
                      *ngSwitchCase="
                        tableConstant.jsonPipeProperties.notifications
                      "
                      >{{ row.value | json }}</ng-container
                    >
                    <ng-container
                      *ngSwitchCase="
                        tableConstant.jsonPipeProperties.reproResults
                      "
                      >{{ row.value | json }}</ng-container
                    >
                    <ng-container
                      *ngSwitchCase="tableConstant.jsonPipeProperties.tcStatus"
                      >{{ row.value | json }}</ng-container
                    >
                    <ng-container
                      *ngSwitchCase="tableConstant.jsonPipeProperties.matching"
                      >{{ row.value | json }}</ng-container
                    >
                    <ng-container
                      *ngSwitchCase="
                        tableConstant.jsonPipeProperties.matchingStatus
                      "
                      >{{ row.value | json }}</ng-container
                    >
                    <ng-container
                      *ngSwitchCase="
                        tableConstant.jsonPipeProperties.tcKpiRequest
                      "
                      >{{ row.value | json }}</ng-container
                    >
                    <ng-container
                      *ngSwitchCase="
                        tableConstant.jsonPipeProperties.tcProcessingState
                      "
                      >{{ row.value | json }}</ng-container
                    >
                    <ng-container
                      *ngSwitchCase="
                        tableConstant.jsonPipeProperties.tcSessionRequest
                      "
                      >{{ row.value | json }}</ng-container
                    >
                    <ng-container
                      *ngSwitchCase="
                        tableConstant.jsonPipeProperties.processingState
                      "
                      >{{ row.value | json }}</ng-container
                    >
                    <ng-container
                      *ngSwitchCase="
                        tableConstant.jsonPipeProperties.gtSettings
                      "
                      >{{ row.value | json }}</ng-container
                    >
                    <ng-container
                      *ngSwitchCase="
                        tableConstant.jsonPipeProperties.kpiJobReport
                      "
                      >{{ row.value | json }}</ng-container
                    >
                    <ng-container
                      *ngSwitchCase="
                        tableConstant.jsonPipeProperties.metadataJobReport
                      "
                      >{{ row.value | json }}</ng-container
                    >
                    <ng-container
                      *ngSwitchCase="
                        tableConstant.jsonPipeProperties.kpiProjectIds
                      "
                      >{{ row.value | json }}</ng-container
                    >
                    <ng-container *ngSwitchDefault>{{
                      row.value
                    }}</ng-container>
                  </ng-container>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="detailsDisplayedColumns"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: detailsDisplayedColumns"
              ></tr>
            </table>
          </div>
        </div>
      </td>
    </div>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsWithExpand"></tr>

    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumnsWithExpand"
      class="element-row"
      [ngClass]="showItemHover ? 'element-row-hover' : ''"
      (click)="
        $event.stopPropagation();
        multipleSelections
          ? checkboxRowSelected(null, row)
          : radioRowSelected(null, row)
      "
    ></tr>

    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="details-row"
    ></tr>
  </table>

  <mat-paginator
    *ngIf="showPagination"
    [pageSizeOptions]="[5, 10, 25, 100]"
    showFirstLastButtons
  ></mat-paginator>
</div>
