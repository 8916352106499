import { Component } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SnackBarConstant } from '@constants/snack-bar.constant';
import { UiMessagesConstant } from '@constants/ui-messages.constant';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';
import {
  GetApprovalsListQuery,
  PostApprovalsListQuery,
} from '@core/amplify/aws-apmlify-query.constant';
import { SnackBarService } from '@core/services/snack-bar.service';
import { TmaUtilsStaticService } from '@core/services/tma-utils-static.service';
import { SplitsApprovalsConstant } from '@modules/splits-approvals/constants/splits-approvals.constant';
import { VinsApprovalsConstant } from '@modules/vins-approvals/constants/vins-approvals.constant';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-splits-approvals',
  templateUrl: './splits-approvals.component.html',
  styleUrls: ['./splits-approvals.component.scss'],
})
export class SplitsApprovalsComponent {
  selectedTabIndex = 0;

  approvalClipIdSelected = '';

  approvalDriveIdSelected = '';

  approvalDriveIdTypeSelected = '';

  normalDriveIdSelected = '';

  normalDriveIdTypeSelected = '';

  approvalsNames = [
    'Data Manager',
    'Data Collection',
    'KPI Team',
    'Reprocessing',
  ];

  approvalStatuses = [
    VinsApprovalsConstant.statuses.notSet.value,
    VinsApprovalsConstant.statuses.approved.value,
    VinsApprovalsConstant.statuses.rejected.value,
  ];

  approvalsForApprovalClip: Approval[] =
    SplitsApprovalsConstant.mfk120InitApprovalsValueForApprovalClip;

  approvalsForNormalDriveId: Approval[] =
    SplitsApprovalsConstant.mfk120InitApprovalsValueForNormalDriveId;

  approvalsListPayloadForApprovalClip: any = {
    query: PostApprovalsListQuery,
    variables: {
      drive_ids_list: [],
      approvals: TmaUtilsStaticService.getDeepCopy(
        this.approvalsForApprovalClip
      ),
    },
  };

  approvalsListPayloadForNormalDriveId: any = {
    query: PostApprovalsListQuery,
    variables: {
      drive_ids_list: [],
      approvals: TmaUtilsStaticService.getDeepCopy(
        this.approvalsForNormalDriveId
      ),
    },
  };

  vinsApprovalsConstant = VinsApprovalsConstant;

  onDriveIdsTableItemSelected(selected: any): void {
    this.approvalDriveIdSelected = selected['drive-id'];

    this.approvalDriveIdTypeSelected = selected['collection-type'];

    this.approvalsListPayloadForApprovalClip.variables.drive_ids_list = [
      this.approvalDriveIdSelected,
    ];

    this.approvalClipIdSelected = selected['approval-clip-id'];

    this.getApprovalsList(this.approvalDriveIdSelected).subscribe(
      (response) => {
        const approvals = response.data.getApprovalsList?.approval_list;

        if (approvals?.length) {
          this.approvalsListPayloadForApprovalClip.variables.approvals =
            approvals;
        } else {
          this.approvalsListPayloadForApprovalClip.variables.approvals =
            TmaUtilsStaticService.getDeepCopy(this.approvalsForApprovalClip);
        }
      },
      (error) => {
        this.snackBarService.showGraphQlErrorMessage(error);
      }
    );
  }

  onApprovalClipsTableReloaded(): void {
    this.approvalDriveIdSelected = '';

    this.approvalDriveIdTypeSelected = '';

    this.approvalClipIdSelected = '';

    this.normalDriveIdSelected = '';

    this.normalDriveIdTypeSelected = '';

    this.approvalsListPayloadForApprovalClip.variables.drive_ids_list = [];

    this.approvalsListPayloadForNormalDriveId.variables.drive_ids_list = [];

    this.approvalsListPayloadForApprovalClip.variables.approvals =
      SplitsApprovalsConstant.mfk120InitApprovalsValueForApprovalClip;

    this.approvalsListPayloadForNormalDriveId.variables.approvals =
      SplitsApprovalsConstant.mfk120InitApprovalsValueForNormalDriveId;
  }

  onNormalDriveIdsTableReloaded(): void {
    this.normalDriveIdSelected = '';

    this.normalDriveIdTypeSelected = '';

    this.approvalsListPayloadForNormalDriveId.variables.drive_ids_list = [];

    this.approvalsListPayloadForNormalDriveId.variables.approvals =
      SplitsApprovalsConstant.mfk120InitApprovalsValueForNormalDriveId;
  }

  onNormalDriveIdsTableItemSelected(selected: any): void {
    this.normalDriveIdSelected = selected['drive-id'];

    this.normalDriveIdTypeSelected = selected['collection-type'];

    this.approvalsListPayloadForNormalDriveId.variables.drive_ids_list = [
      this.normalDriveIdSelected,
    ];

    this.getApprovalsList(this.normalDriveIdSelected).subscribe(
      (response) => {
        const approvals = response.data.getApprovalsList?.approval_list;

        if (approvals?.length) {
          this.approvalsListPayloadForNormalDriveId.variables.approvals =
            approvals;
        } else {
          this.approvalsListPayloadForNormalDriveId.variables.approvals =
            TmaUtilsStaticService.getDeepCopy(this.approvalsForNormalDriveId);
        }
      },
      (error) => {
        this.snackBarService.showGraphQlErrorMessage(error);
      }
    );
  }

  onSelectedTabChange($event: MatTabChangeEvent): void {
    this.selectedTabIndex = $event.index;
  }

  onApprovalClipApprovalsSubmit(): void {
    this.postApprovalsList(this.approvalsListPayloadForApprovalClip).subscribe(
      (response) => {
        this.snackBarService.open(
          UiMessagesConstant.mfk120ApprovalsForApprovalClipSubmitSuccessMessage,
          SnackBarConstant.actions.dismiss,
          3000
        );
      },
      (error) => {
        this.snackBarService.showGraphQlErrorMessage(error);
      }
    );
  }

  onNormalDriveIdApprovalsSubmit(): void {
    this.postApprovalsList(this.approvalsListPayloadForNormalDriveId).subscribe(
      (response) => {
        this.snackBarService.open(
          UiMessagesConstant.mfk120ApprovalsForNormalIdSubmitSuccessMessage,
          SnackBarConstant.actions.dismiss,
          3000
        );
      },
      (error) => {
        this.snackBarService.showGraphQlErrorMessage(error);
      }
    );
  }

  private getApprovalsList(drive_id: string): Observable<any> {
    return this.awsAmplifyApiService.graphQLQuery({
      query: GetApprovalsListQuery,
      variables: {
        drive_id,
      },
    });
  }

  private postApprovalsList(graphQLOptions: any): Observable<any> {
    return this.awsAmplifyApiService.graphQLQuery(graphQLOptions);
  }

  constructor(
    private awsAmplifyApiService: AwsAmplifyApiService,
    private snackBarService: SnackBarService
  ) {}
}
