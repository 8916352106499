import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromInnoviz from '@modules/innoviz/store/innoviz.reducer';

export const selectInnovizState = createFeatureSelector<fromInnoviz.State>(
  fromInnoviz.innovizFeatureKey
);

export const selectFeaturePipelineMetaData = createSelector(
  selectInnovizState,
  (state: fromInnoviz.State) => state.pipelineMetaData
);

export const selectFeatureInnovizHilSwExchanges = createSelector(
  selectInnovizState,
  (state: fromInnoviz.State) => state.innovizHilSwExchanges
);
