import { Injectable } from '@angular/core';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';
import { QueryGetPlaylist } from '@core/amplify/aws-apmlify-query.constant';
import { SnackBarService } from '@core/services/snack-bar.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import * as PlaylistActions from './playlist.actions';

@Injectable()
export class PlaylistEffects {
  loadPlaylist$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PlaylistActions.loadPlaylist),
      concatMap((data) =>
        this.awsAmplifyApiService
          .graphQLQuery({
            query: QueryGetPlaylist,
            variables: {
              playlist_id: data.playlist_id,
              sk: 'config',
              limit: data.limit,
              nextToken: data.nextToken,
            },
          })
          .pipe(
            map((data) => PlaylistActions.loadPlaylistSuccess({ data })),
            catchError((error) => {
              this.snackBarService.showGraphQlErrorMessage(error);

              return of(PlaylistActions.loadPlaylistFailure({ error }));
            })
          )
      )
    );
  });

  loadDriveIds$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PlaylistActions.loadDriveIds),
      concatMap((data) =>
        this.awsAmplifyApiService
          .graphQLQuery({
            query: QueryGetPlaylist,
            variables: {
              playlist_id: data.playlist_id,
              sk: 'drive',
              limit: data.limit,
              nextToken: data.nextToken,
            },
          })
          .pipe(
            map((data) => PlaylistActions.loadDriveIdsSuccess({ data })),
            catchError((error) => {
              this.snackBarService.showGraphQlErrorMessage(error);

              return of(PlaylistActions.loadDriveIdsFailure({ error }));
            })
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private awsAmplifyApiService: AwsAmplifyApiService,
    private snackBarService: SnackBarService
  ) {}
}
