import { Injectable } from '@angular/core';
import { AwsAmplifyApiService } from '@core/amplify/aws-amplify-api.service';
import {
  GetKpiMetaPayload,
  GetKpiMetaResponse,
  GetKpiProjectsResponse,
  GetKpiReportsDownloadLinksPayload,
  GetKpiReportsDownloadLinksResponse,
  GetKpiScriptConfigurationsPayload,
  GetKpiScriptConfigurationsResponse,
  GetKpiTestSuitesPayload,
  GetKpiTestsuitesResponse,
  SaveKpitoolTriggerPayload,
  SaveKpitoolTriggerResponse,
} from '@model/payloads';
import { Observable } from 'rxjs';
import { GraphQLOptions } from '@aws-amplify/api-graphql';

@Injectable({
  providedIn: 'root',
})
export class KpiService {
  constructor(private graphqlApiService: AwsAmplifyApiService) {}

  getKpiMeta(
    getKpiMetaPayload: GetKpiMetaPayload
  ): Observable<GetKpiMetaResponse> {
    return this.graphqlApiService.graphQLQuery(getKpiMetaPayload);
  }

  getKpiProjects(
    getKpiProjectsPayload: GraphQLOptions
  ): Observable<GetKpiProjectsResponse> {
    return this.graphqlApiService.graphQLQuery(getKpiProjectsPayload);
  }

  getKpiTestSuites(
    getKpiTestSuitsPayload: GetKpiTestSuitesPayload
  ): Observable<GetKpiTestsuitesResponse> {
    return this.graphqlApiService.graphQLQuery(getKpiTestSuitsPayload);
  }

  getKpiScriptConfigurations(
    getKpiScriptConfigurationsPayload: GetKpiScriptConfigurationsPayload
  ): Observable<GetKpiScriptConfigurationsResponse> {
    return this.graphqlApiService.graphQLQuery(
      getKpiScriptConfigurationsPayload
    );
  }

  saveKpitoolTrigger(
    saveKpitoolTriggerPayload: SaveKpitoolTriggerPayload
  ): Observable<SaveKpitoolTriggerResponse> {
    return this.graphqlApiService.graphQLQuery(saveKpitoolTriggerPayload);
  }

  getKpiReportsDownloadLinks(
    getKpiReportsDownloadLinksPayload: GetKpiReportsDownloadLinksPayload
  ): Observable<GetKpiReportsDownloadLinksResponse> {
    return this.graphqlApiService.graphQLQuery(
      getKpiReportsDownloadLinksPayload
    );
  }
}
