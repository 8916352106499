import { Injectable } from '@angular/core';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarConfig as MatSnackBarConfig,
} from '@angular/material/legacy-snack-bar';
import { SnackBarConstant } from '@constants/snack-bar.constant';
import { GraphQLQueryError } from '@model/payloads';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private _snackBar: MatSnackBar) {}

  open(
    message: string,
    action: string,
    duration?: number,
    matSnackBarConfig: MatSnackBarConfig = {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    }
  ): void {
    this._snackBar.open(message, action, {
      ...matSnackBarConfig,
      duration,
    });
  }

  showGraphQlErrorMessage(error: GraphQLQueryError): void {
    const message: string = error.errors.map((error) => error.message).join();

    this.open(
      `GraphQL request error: ${message}`,
      SnackBarConstant.actions.dismiss
    );
  }
}
